import { FormEvent } from "react"
import { KTSVG } from "_metronic/helpers"

type Props = {
    id: string
    value: boolean
    onUpdate: (value: boolean) => void
}

const SnapshotSwitch: React.FC<Props> = ({ id, value, onUpdate }) => {

    return (
        <div className='row ' role='button' onClick={() => onUpdate(!value)}>
            <div className='symbol symbol-40px'>
                <span className={`symbol-label ${value ? 'bg-primary' : 'bg-light'}`}>
                    <KTSVG path='/media/svg/general/camera.svg' className={`${value ? 'svg-icon-light fa-light' : 'fa-light svg-icon-primary'}`} />
                </span>
            </div>
        </div>
    )

}

SnapshotSwitch.defaultProps = {
    value: false
}

export { SnapshotSwitch }