import React from "react";
import { type PEData } from "@components/chart/types";
import Chart from "react-apexcharts";


export interface ProgrammeExplorerChartProps {
  data: PEData[]
  showAxis?: boolean
}
const ProgrammeExplorerChart: React.FC<ProgrammeExplorerChartProps> = ({ data, showAxis, children }) => {

  return (
    <>
      {
        data.length > 0 ?
          (<Chart
            type={'area'}
            height={200}
            series={data}
            options={{
              chart: {
                id: 'realtime',
                height: 150,
                type: 'pie',
                zoom: {
                  enabled: false
                },
                animations: {
                  enabled: true,
                  easing: 'easein',
                  dynamicAnimation: {
                    speed: 500
                  }
                },
                toolbar: {
                  show: false
                },
              },
              dataLabels: {
                enabled: false
              },
              xaxis: {
                crosshairs: {
                  show: showAxis
                },
                labels: {
                  show: showAxis,
                },
                decimalsInFloat: 0,
                categories: data[0]?.labels?.map((value: string) => value),
              },
              tooltip: {
                y: {
                    formatter: function (val) {
                        return val.toString();
                    }
                }
              },
              yaxis: {
                show: showAxis,
                opposite: false,
                axisBorder: {
                  show: false
                },
                crosshairs: {
                  show: false
                },
                axisTicks: {
                  show: false
                }
              },
              stroke: {
                curve: 'smooth',
                colors: ['primary']
              },
              fill: {
                opacity: 0.8,
                type: ['solid'],
                gradient: {
                  type: 'horizontal',
                  opacityFrom: 0.6,
                  opacityTo: 0.8,
                }
              },
              markers: {
                size: 0
              },
              grid: {
                show: false
              },
              legend: {
                show: false
              }
            }}>
          </Chart>)
          : null
      }

      {children}
    </>
  )
}

export default ProgrammeExplorerChart