import { MetricService } from "app/services/metric/MetricService";
import { MetricUtil } from "app/services/metric/MetricUtil";
import React, { useEffect, useState } from "react";
import { Metric, ReachMetric } from "../../../components/metrics/ReachMetric";
import { useDataReport } from "../../../DataReportProvider";
import { metrics } from "app/utils/data/metrics";
import { useDispatch } from "react-redux";
import { updateLoadedMetrics } from "app/store/reducers/metric_reducer";
import { getLoadedMetricById } from "app/utils/utils";

const EngagementNewsletter: React.FC = () => {

    const [totalEmailOpens, setTotalEmailOpens] = useState<Metric[] | null>(null)
    const [uniqueEmailOpens, setUniqueEmailOpens] = useState<Metric[] | null>(null)
    const [totalLinkClicks, setTotalLinkClicks] = useState<Metric[] | null>(null)
    const [uniqueLinkClicks, setUniqueLinkClicks] = useState<Metric[] | null>(null)
    const [uniqueLinkClickRate, setUniqueLinkClickRate] = useState<Metric[] | null>(null)
    const [uniqueEmailOpenRate, setUniqueEmailOpenRate] = useState<Metric[] | null>(null)
    const [clickToOpenRate, setClickToOpenRate] = useState<Metric[] | null>(null)

    const dataReport = useDataReport()
    const dispatch = useDispatch();
    
    const getTotalEmailOpens = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.totalEmailOpens.endpoint)).getResponseData();
        if (response.success === true) {
            setTotalEmailOpens(response.data);
            dispatch(updateLoadedMetrics({id: metrics.totalEmailOpens.id, data: response.data}));
        }
    }

    const getUniqueEmailOpens = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.uniqueEmailOpens.endpoint)).getResponseData();
        if (response.success === true) {
            setUniqueEmailOpens(response.data);
            dispatch(updateLoadedMetrics({id: metrics.uniqueEmailOpens.id, data: response.data}));
        }
    }

    const getTotalLinkClicks = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.totalLinkClicks.endpoint)).getResponseData();
        if (response.success === true) {
            setTotalLinkClicks(response.data);
            dispatch(updateLoadedMetrics({id: metrics.totalLinkClicks.id, data: response.data}));
        }
    }

    const getUniqueLinkClicks = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.uniqueLinkClicks.endpoint)).getResponseData();
        if (response.success === true) {
            setUniqueLinkClicks(response.data);
            dispatch(updateLoadedMetrics({id: metrics.uniqueLinkClicks.id, data: response.data}));
        }
    }

    const getUniqueLinkClickRate = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.uniqueLinkClickRate.endpoint)).getResponseData();
        if (response.success === true) {
            setUniqueLinkClickRate(response.data);
            dispatch(updateLoadedMetrics({id: metrics.uniqueLinkClickRate.id, data: response.data}));
        }
    }

    const getUniqueEmailOpenRate = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.uniqueEmailOpenRate.endpoint)).getResponseData();
        if (response.success === true) {
            setUniqueEmailOpenRate(response.data);
            dispatch(updateLoadedMetrics({id: metrics.uniqueEmailOpenRate.id, data: response.data}));
        }
    }

    const getClickToOpenRateRate = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.clickToOpenRate.endpoint)).getResponseData();
        if (response.success === true) {
            setClickToOpenRate(response.data);
            dispatch(updateLoadedMetrics({id: metrics.clickToOpenRate.id, data: response.data}));
        }
    }

    /** 
     * To calculate amount and percent of latest "n" periods, we need to modify date filtered before 
     * make request. We need to do that because the widget needs to retrive last 24
     * times periods before the requested date to calculate the amount 
    */
    const getMetrics = () => {

        const calculateStartDate = MetricUtil.calculateStartDate(dataReport.params.filter_filters.between_dates.from, dataReport.params.filter_filters.mode);

        const modifiedParams = {
            ...dataReport.params,
            filter_filters: {
                ...dataReport.params.filter_filters,
                between_dates: {
                    from: calculateStartDate,
                    to: dataReport.params.filter_filters.between_dates.to
                }
            }
        };

        let total_email_opens = getLoadedMetricById(metrics.totalEmailOpens.id);
        let unique_email_opens = getLoadedMetricById(metrics.uniqueEmailOpens.id);
        let total_link_clicks = getLoadedMetricById(metrics.totalLinkClicks.id);
        let unique_link_clicks = getLoadedMetricById(metrics.uniqueLinkClicks.id);
        let unique_link_click_rate = getLoadedMetricById(metrics.uniqueLinkClickRate.id);
        let unique_email_open_rate = getLoadedMetricById(metrics.uniqueEmailOpenRate.id);
        let click_to_open_rate = getLoadedMetricById(metrics.clickToOpenRate.id);


        if(total_email_opens !== null && total_email_opens !== undefined) {
            setTotalEmailOpens(total_email_opens);
        }else{
            getTotalEmailOpens(modifiedParams);
        }

        if(unique_email_opens !== null && unique_email_opens !== undefined) {
            setUniqueEmailOpens(unique_email_opens);
        }else{
            getUniqueEmailOpens(modifiedParams);
        }

        if(total_link_clicks !== null && total_link_clicks !== undefined) {
            setTotalLinkClicks(total_link_clicks);
        }else{
            getTotalLinkClicks(modifiedParams);
        }

        if(unique_link_clicks !== null && unique_link_clicks !== undefined) {
            setUniqueLinkClicks(unique_link_clicks);
        }else{
            getUniqueLinkClicks(modifiedParams);
        }

        if(unique_link_click_rate !== null && unique_link_click_rate !== undefined) {
            setUniqueLinkClickRate(unique_link_click_rate);
        }else{
            getUniqueLinkClickRate(modifiedParams);
        }

        if(unique_email_open_rate !== null && unique_email_open_rate !== undefined) {
            setUniqueEmailOpenRate(unique_email_open_rate);
        }else{
            getUniqueEmailOpenRate(modifiedParams);
        }

        if(click_to_open_rate !== null && click_to_open_rate !== undefined) {
            setClickToOpenRate(click_to_open_rate);
        }else{
            getClickToOpenRateRate(modifiedParams);
        }

    }

    const resetReports = () => {
        setTotalEmailOpens(null);
        setUniqueEmailOpens(null);
        setTotalLinkClicks(null);
        setUniqueLinkClicks(null);
        setUniqueLinkClickRate(null);
        setUniqueEmailOpenRate(null);
        setClickToOpenRate(null);
    }


    useEffect(() => {
        resetReports();
        getMetrics();
    }, [dataReport.params])


    return (
        <>
            <div className='row g-5 g-xl-8' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                <div className="col-xl-12">
                    <hr></hr>
                </div>
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/'+metrics.totalEmailOpens.logo}
                        snapshotId={metrics.totalEmailOpens.id}
                        title={metrics.totalEmailOpens.title}
                        subtitle={metrics.totalEmailOpens.subtitle}
                        startPeriod={24}
                        metrics={totalEmailOpens}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/'+metrics.uniqueEmailOpens.logo}
                        snapshotId={metrics.uniqueEmailOpens.id}
                        title={metrics.uniqueEmailOpens.title}
                        subtitle={metrics.uniqueEmailOpens.subtitle}
                        startPeriod={24}
                        metrics={uniqueEmailOpens}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/'+metrics.totalLinkClicks.logo}
                        snapshotId={metrics.totalLinkClicks.id}
                        title={metrics.totalLinkClicks.title}
                        subtitle={metrics.totalLinkClicks.subtitle}
                        startPeriod={24}
                        metrics={totalLinkClicks}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}

                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/'+metrics.uniqueLinkClicks.logo}
                        snapshotId={metrics.uniqueLinkClicks.id}
                        title={metrics.uniqueLinkClicks.title}
                        subtitle={metrics.uniqueLinkClicks.subtitle}
                        startPeriod={24}
                        metrics={uniqueLinkClicks}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/'+metrics.uniqueLinkClickRate.logo}
                        snapshotId={metrics.uniqueLinkClickRate.id}
                        title={metrics.uniqueLinkClickRate.title}
                        subtitle={metrics.uniqueLinkClickRate.subtitle}
                        startPeriod={24}
                        metrics={uniqueLinkClickRate}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                        valueType='percent'
                    />
                </div>
                {/* end::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/'+metrics.uniqueEmailOpenRate.logo}
                        snapshotId={metrics.uniqueEmailOpenRate.id}
                        title={metrics.uniqueEmailOpenRate.title}
                        subtitle={metrics.uniqueEmailOpenRate.subtitle}
                        startPeriod={24}
                        metrics={uniqueEmailOpenRate}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                        valueType='percent'
                    />
                </div>
                {/* end::Col */}
                {/* end::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/'+metrics.clickToOpenRate.logo}
                        snapshotId={metrics.clickToOpenRate.id}
                        title={metrics.clickToOpenRate.title}
                        subtitle={metrics.clickToOpenRate.subtitle}
                        startPeriod={24}
                        metrics={clickToOpenRate}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                        valueType='percent'
                    />
                </div>
                {/* end::Col */}
            </div>

        </>
    )
}


export default EngagementNewsletter;