import CustomSelect from "@components/form/CustomSelect"
import CustomModalBody from "@components/modal/body"
import CustomModalHeader from "@components/modal/header"
import CustomModal from "@components/modal/modal"
import { useFormik } from "formik"
import { FC, useEffect, useState } from "react"
import { Alert, Button, FormGroup, FormLabel } from "react-bootstrap"
import './CampaignManagerModal.css'
import { ConnectService } from "app/services/connect/ConnectService"
import toastr from 'toastr'
import { CampaignManagerService } from "app/services/campaign_manager/CampaignManagerService"

type CreateCampaignManagementModalProps = {
    isOpen: boolean,
    setIsOpen: (status: boolean) => void,
}

const CreateCampaignManagementModal: FC<CreateCampaignManagementModalProps> = ({isOpen, setIsOpen}) => {

    const [connects, setConnects] = useState<[]>([])

    const _getConnects = async () => {
        const response = await (await (new ConnectService()).search({})).getResponseData()
        
        if(!response.success)
            toastr.error("Error getting Connects information.")

        setConnects(response.data)

    }

    const _createCampaignManagement = async (values: any) => {

        const connects = values.connects.map((connect: any) => {
            return {
                id: connect.value.id,
                name: connect.value.name
            }
        })

        const data = {
            ...values,
            connects
        };

        const response = await (await (new CampaignManagerService()).create(data)).getResponseData()
        
        if(!response.success) {
            toastr.error("Error creating Campaign condition: " + response.message)
        }

        formik.resetForm();
        
        setIsOpen(false);
    }

    const formik = useFormik({
        initialValues: {
            code_match: null,
            connects: [],
            description: null
        },
        onSubmit: values => {
            if(values.connects.length == 0) {
                toastr.error("You must select one connect at least.")
                return;
            }

            _createCampaignManagement(values);
        }
    })

    useEffect(() => {
        _getConnects()
    },[])

    const _parseConnects = () => {
        return connects.map((connect: any) => {
            return {
                label: connect.name,
                value: connect
            }
        })
    }

    return (
        <CustomModal identifier="create_campaign_condition_modal" show={isOpen} onHideModal={() => {
            setIsOpen(false)
        }}>
            <CustomModalHeader classes="pb-0 border-0">
                <div className="modal-title justify-content-start">
                    <h2>Create Campaign Condition</h2>
                </div>
            </CustomModalHeader>
            <CustomModalBody classes="mx-5 mx-xl-18 pt-5 pb-15">
                {
                    isOpen &&
                    (
                        <>
                            <form onSubmit={formik.handleSubmit}>
                                <Alert>
                                    <p>This functionality allows assigning the results obtained from the campaigns that match the pattern to one or several "Connects". You must be as precise as possible in the code statement to avoid unwanted matches.</p>
                                </Alert>
                                <FormGroup className="mb-2">
                                    <FormLabel>Match pattern</FormLabel>
                                    <input id="code_match" type="text" className="form-control" name="code_match" required value={formik.values.code_match || ''} onChange={formik.handleChange} />
                                </FormGroup>
                                <FormGroup className="mb-2">
                                    <FormLabel>Connects</FormLabel>
                                    <CustomSelect is_multi options={_parseConnects()} onChange={(elements) => { 
                                        formik.setFieldValue('connects',elements);
                                     }} />
                                </FormGroup>

                                <FormGroup className="mb-2">
                                    <FormLabel>Description</FormLabel>
                                    <textarea id="description" required className="form-control" rows={5} onChange={formik.handleChange}>{formik.values.description}</textarea>
                                </FormGroup>

                                <FormGroup className="mt-8 text-end">
                                    <Button type="submit" color="primary">Create</Button>
                                </FormGroup>
                            </form>
                        </>
                    )
                }
            </CustomModalBody>
        </CustomModal>
    )

}

export default CreateCampaignManagementModal