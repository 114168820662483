import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { LoginService } from '../../../services/auth/LoginService'
import { useDispatch } from 'react-redux'
import { setLoginFormStatus } from '../../../store/reducers/login_form_reducer'
import { CognitoUser } from 'amazon-cognito-identity-js'

const resetPasswordSchema = Yup.object().shape({
    code: Yup.string()
        .length(6, 'Wrong code')
        .required('Code is required'),
    password: Yup.string()
        .min(8, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
})

type ResetByCodeFormProps = {
    cognitoUser: CognitoUser|null,
    onLoginSuccess: any,
    onLoginError: any
 }

export const ResetByCodeForm = ({cognitoUser, onLoginSuccess, onLoginError}: ResetByCodeFormProps) => {

    const [loading, setLoading] = useState(false);
    const [expiredCode, setExpiredCode] = useState(false);
    const dispatch = useDispatch();

    const resendCode = async () => {
        console.log("Resend code");
    }

    const resetPasswordForm = useFormik({
        initialValues: {
            code: '',
            password: '',
        },
        validationSchema: resetPasswordSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            
            try {
                setLoading(true);
                let resetPassword = await (new LoginService()).resetPassword(cognitoUser, values.code, values.password);
                if(resetPassword === "SUCCESS") {
                    onLoginSuccess({
                        success: false,
                        message: "You must login with new password to continue",
                        code: 'RELOAD'
                    })
                }
                
                //let loginUserData = await (new LoginService()).loginNormalUser(values.email, values.password, onNeedNewPassword);
            } catch (error: any) {

                if(error.code === "ExpiredCodeException") {
                    dispatch(setLoginFormStatus(error.message));
                    setSubmitting(false)
                    setLoading(false)
                    setExpiredCode(true);
                } else {
                    dispatch(setLoginFormStatus(error.message));
                    setSubmitting(false)
                    setLoading(false)
                }
                
            }
        },
    })
    

    return (
        <form
            autoComplete='off'
            className='form w-100'
            onSubmit={resetPasswordForm.handleSubmit}
            noValidate
            id='kt_login_reset_password_form'
        >

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Code</label>
                <input
                    placeholder='Code'
                    {...resetPasswordForm.getFieldProps('code')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        { 'is-invalid': resetPasswordForm.touched.code && resetPasswordForm.errors.code },
                        {
                            'is-valid': resetPasswordForm.touched.code && !resetPasswordForm.errors.code,
                        }
                    )}
                    type='string'
                    name='code'
                    autoComplete='off'
                />
                {resetPasswordForm.touched.code && resetPasswordForm.errors.code && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert'>{resetPasswordForm.errors.code}</span>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                        {/* begin::Label */}
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>New Password</label>
                        {/* end::Label */}
                    </div>
                </div>
                <input
                    type='password'
                    autoComplete='off'
                    placeholder='New Password'
                    {...resetPasswordForm.getFieldProps('password')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                            'is-invalid': resetPasswordForm.touched.password && resetPasswordForm.errors.password,
                        },
                        {
                            'is-valid': resetPasswordForm.touched.password && !resetPasswordForm.errors.password,
                        }
                    )}
                />
                {resetPasswordForm.touched.password && resetPasswordForm.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{resetPasswordForm.errors.password}</span>
                        </div>
                    </div>
                )}

                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg btn-primary w-100 mb-5 mt-5'
                    disabled={resetPasswordForm.isSubmitting || !resetPasswordForm.isValid}
                >
                    {!loading && <span className='indicator-label'>Continue</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
                {expiredCode && (<button type='button' className='btn btn-lg btn-secondary w-50 mb-5 mt-5' onClick={resendCode}>Resend Code</button>)}
            </div>
            {/* end::Form group */}
        </form>
    );
}