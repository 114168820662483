import { useRef, FC, useCallback, useEffect, useState } from "react";
import { KTSVG } from '_metronic/helpers/components/KTSVG';
import "./style.scss";

interface Props {
    content: Array<any>;
    onItemClick: (item: any, index: number) => void;
    activeCard: number;
    title: string
}

const HorizontalList: FC<Props> = ({ content, onItemClick, activeCard, title }) => {

    const [swipeButtons, setSwipeButtons] = useState<boolean>(true);
    const [leftSwipe, setLeftSwipe] = useState<boolean>(true);
    const [rightSwipe, setRightSwipe] = useState<boolean>(true);

    const innerBoxRef = useRef<HTMLDivElement>(null);

    const startX = useRef<number>(0);
    const [startScrollLeft, setStartScrollLeft] = useState<number>(0);
    const [myMouseDown, setMyMouseDown] = useState<boolean>(false);

    const handleDown = useCallback((e: any) => {
        setMyMouseDown(true); 
        if (!innerBoxRef.current?.contains(e.target)) return;
        startX.current = e.pageX - innerBoxRef.current.offsetLeft;
        setStartScrollLeft(innerBoxRef.current.scrollLeft);
    }, []);

    const handleMove = useCallback(
        (e: any) => {
            e.preventDefault();
            if (!innerBoxRef.current?.contains(e.target)) return;
            const mouseX = e.pageX - innerBoxRef.current.offsetLeft;
            const moveX = mouseX - startX.current;

            if (myMouseDown) {
                innerBoxRef.current.scrollLeft = startScrollLeft - moveX;
            }
        },
        [myMouseDown, startScrollLeft, startX]
    );

    /*
    const handleUp = () => {
 
        setMyMouseDown(false); 
    };
     */

    
    const renderChild = (): React.ReactNode => {
        return content.map((element: any, index: number) => {
            return (
                <div key={index} className="swipe-list-content">
                    
                    <div className="card me-5"> 
                        <div className={`rounded ${activeCard === index && 'swipe-list-bg-active'}`} role="button" onClick={()=> onItemClick(element.data, index)}>
                            <h6 className={`text-center vertical-align-center mt-2 ${activeCard === index && 'text-white'}`}> {element.name} </h6>
                        </div>
                    </div>
                </div>
            );
        })
    }


    const handleLeftSwipe: React.EventHandler<React.MouseEvent<HTMLElement>> = useCallback(
        (e) => {
            if (innerBoxRef.current) {
                innerBoxRef.current.scrollBy({
                    behavior: "smooth",
                    left: -300,
                });
            }
        },
        [innerBoxRef]
    );

    const handleRightSwipe: React.EventHandler<React.MouseEvent<HTMLElement>> = useCallback(
        (e) => {
            if (innerBoxRef.current) {
                innerBoxRef.current.scrollBy({
                    behavior: "smooth",
                    left: 300,
                });
            }
        },
        [innerBoxRef]
    );

    const handleScrollChangeEvent: React.EventHandler<React.UIEvent<HTMLDivElement>> = (e) => {
        const curScrollPos = e.currentTarget.scrollLeft;
        const maxScrollPos = e.currentTarget.scrollWidth - e.currentTarget.clientWidth;

        if (curScrollPos === 0) {
            setLeftSwipe(false);
        } else if (curScrollPos === maxScrollPos) {
            setRightSwipe(false);
        } else {
            setLeftSwipe(true);
            setRightSwipe(true);
        }
    };

    useEffect(() => {
        if (innerBoxRef.current) {
            if (innerBoxRef.current.scrollWidth > innerBoxRef.current.clientWidth) {
                setSwipeButtons(true);
            } else {
                setSwipeButtons(false);
            }
        }
 
        //innerBoxRef.current?.addEventListener('mouseup', handleUp)
        //innerBoxRef.current?.addEventListener('mousedown', handleDown)
        //innerBoxRef.current?.addEventListener('mousemove', handleMove)

    }, [
        innerBoxRef,
        //...(innerBoxRef.current ? [innerBoxRef.current.scrollWidth] : [null]),
        //...(innerBoxRef.current ? [innerBoxRef.current.clientWidth] : [null]),
        swipeButtons,
        setSwipeButtons,
        handleDown, 
        handleMove
    ]);



    return (
        <div>
            <div>
                <h3> {title} </h3>
            </div> 
            <div className="swipe-list-outerBox">
                <div className="swipe-list-innerBox" ref={innerBoxRef} onScroll={handleScrollChangeEvent}>
                    {renderChild()}
                </div>
                {swipeButtons && (
                    <div>
                        {leftSwipe && (
                            <div className="swipe-list-btnBox swipe-list-leftBtnBox" onClick={handleLeftSwipe}>
                                <KTSVG path='/media/icons/duotune/arrows/arr022.svg' className="btn swipe-list-btn swipe-list-leftBtn"/>
                            </div>
                        )}
                        {rightSwipe && (
                            <div className="swipe-list-btnBox swipe-list-rightBtnBox" onClick={handleRightSwipe}>
                                <KTSVG path='/media/icons/duotune/arrows/arr023.svg' className="btn swipe-list-btn swipe-list-rightBtn" />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );

}

HorizontalList.defaultProps = {

}

export default HorizontalList;

