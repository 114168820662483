import { Spinner } from 'react-bootstrap'
import { KTSVG, toAbsoluteUrl } from '_metronic/helpers'

type Props = {
    className: string
    image: string
    title: string
    counter?: string
    ascend?: boolean
    variation?: number
}

const DashboardCard: React.FC<Props> = ({ className, variation, image, title, counter, ascend }) => {

    return (
        <div className={`card ${className}`}>
            <div className='card-body d-flex flex-column'>

                <div className='flex-grow-1'>


                    <div className='d-flex align-items-center '>
                        <div className='symbol symbol-50px'>
                            <span className='symbol-label bg-light'>
                                <img src={toAbsoluteUrl(image)} className='h-50 align-self-center' alt='' />
                            </span>
                        </div>
                        <span className='text-muted fw-bolder fs-5 flex-grow-1 ms-2'>{title}</span>
                    </div>

                    {(ascend != null && variation != null && counter != null) ? (
                        <div>


                            <p className='text-dark fw-bolder text-hover-primary text-center fs-1'>
                                {counter}
                            </p>

                            <div className='d-flex'>
                                {ascend == true ?
                                    <>
                                        <div className='symbol symbol-30px me-5'>

                                            <span className='symbol-label bg-light-success'>
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr056.svg'
                                                    className='svg-icon-2x svg-icon-success' />
                                            </span>


                                        </div>
                                        <p className='fs-3 text-success me-2'> {variation} % </p>
                                        <p className='text-muted align-self-center'> from previous month</p>
                                    </>
                                    :
                                    <>
                                        <div className='symbol symbol-30px me-5'>
                                            <span className='symbol-label bg-light-danger'>
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr057.svg'
                                                    className='svg-icon-2x svg-icon-danger' />
                                            </span>
                                        </div>
                                        <p className='fs-3 text-danger me-2'> {variation} %</p>
                                        <p className='text-muted align-self-center'> from previous month</p>
                                    </>}
                            </div>

                        </div>

                    ) : (
                        <div className="d-flex justify-content-center">
                            <Spinner animation='border'></Spinner>
                        </div>
                    )}

                </div>



            </div>
        </div>
    )
}

export { DashboardCard }
