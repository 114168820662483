import { MetricService } from "app/services/metric/MetricService";
import { MetricUtil } from "app/services/metric/MetricUtil";
import React, { useEffect, useState } from "react";
import { Metric, ReachMetric } from "../../../components/metrics/ReachMetric";
import { useDataReport } from "../../../DataReportProvider";
import { metrics } from "app/utils/data/metrics";
import { updateLoadedMetrics } from "app/store/reducers/metric_reducer";
import { useDispatch } from "react-redux";
import { getLoadedMetricById } from "app/utils/utils";

const EngagementSocialMedia: React.FC = () => {

    const [twitterLikes, setTwitterLikes] = useState<Metric[] | null>(null)
    const [twitterShares, setTwitterShares] = useState<Metric[] | null>(null)
    const [twitterSocialEngagementRate, setTwitterSocialEngagementRate] = useState<Metric[] | null>(null)
    const [twitterLinkClicks, setTwitterLinkClicks] = useState<Metric[] | null>(null)
    const [linkedinShares, setLinkedinShares] = useState<Metric[] | null>(null)
    const [averageLinkedinSocialEngagementRate, setAverageLinkedinSocialEngagementRate] = useState<Metric[] | null>(null)
    const [linkedinLinkClick, setLinkedinLinkClick] = useState<Metric[] | null>(null)
    const [linkedinLikes, setLinkedinLikes] = useState<Metric[] | null>(null)
    const [linkedinComments, setLinkedinComments] = useState<Metric[] | null>(null)

    const [twitterEngagements, setTwitterEngagements] = useState<Metric[] | null>(null)

    const dataReport = useDataReport()
    const dispatch = useDispatch();

    const getTwitterEngagements = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.twitterEngagements.endpoint)).getResponseData();
        if (response.success === true) {
            setTwitterEngagements(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.twitterEngagements.id, data: response.data }));
        }
    }

    const getTwitterLikes = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.twitterLikes.endpoint)).getResponseData();
        if (response.success === true) {
            setTwitterLikes(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.twitterLikes.id, data: response.data }));
        }
    }

    const getTwitterShares = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.twitterShares.endpoint)).getResponseData();
        if (response.success === true) {
            setTwitterShares(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.twitterShares.id, data: response.data }));
        }
    }

    const getTwitterSocialEngagementRate = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.twitterSocialEngagementRate.endpoint)).getResponseData();
        if (response.success === true) {
            setTwitterSocialEngagementRate(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.twitterSocialEngagementRate.id, data: response.data }));
        }
    }

    const getTwitterLinkClicks = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.twitterLinkClicks.endpoint)).getResponseData();
        if (response.success === true) {
            setTwitterLinkClicks(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.twitterLinkClicks.id, data: response.data }));
        }
    }
    const getLinkedinShares = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.linkedinShares.endpoint)).getResponseData();
        if (response.success === true) {
            setLinkedinShares(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.linkedinShares.id, data: response.data }));
        }
    }
    const getAverageLinkedinSocialEngagementRate = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.averageLinkedinSocialEngagementRate.endpoint)).getResponseData();
        if (response.success === true) {
            setAverageLinkedinSocialEngagementRate(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.averageLinkedinSocialEngagementRate.id, data: response.data }));
        }
    }
    const getLinkedinLinkClick = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.linkedinLinkClick.endpoint)).getResponseData();
        if (response.success === true) {
            setLinkedinLinkClick(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.linkedinLinkClick.id, data: response.data }));
        }
    }
    const getLinkedinLikes = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.linkedinLikes.endpoint)).getResponseData();
        if (response.success === true) {
            setLinkedinLikes(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.linkedinLikes.id, data: response.data }));
        }
    }
    const getLinkedinComments = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.linkedinComments.endpoint)).getResponseData();
        if (response.success === true) {
            setLinkedinComments(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.linkedinComments.id, data: response.data }));
        }
    }

    /** 
     * To calculate amount and percent of latest "n" periods, we need to modify date filtered before 
     * make request. We need to do that because the widget needs to retrive last 24
     * times periods before the requested date to calculate the amount 
    */
    const getMetrics = () => {

        const calculateStartDate = MetricUtil.calculateStartDate(dataReport.params.filter_filters.between_dates.from, dataReport.params.filter_filters.mode);

        const modifiedParams = {
            ...dataReport.params,
            filter_filters: {
                ...dataReport.params.filter_filters,
                between_dates: {
                    from: calculateStartDate,
                    to: dataReport.params.filter_filters.between_dates.to
                }
            }
        };

        let twitter_likes = getLoadedMetricById(metrics.twitterLikes.id);
        let twitter_shares = getLoadedMetricById(metrics.twitterShares.id);
        let twitter_social_engagement = getLoadedMetricById(metrics.twitterSocialEngagementRate.id);
        let twitter_link_clicks = getLoadedMetricById(metrics.twitterLinkClicks.id);
        let linkedin_shares = getLoadedMetricById(metrics.linkedinShares.id);
        let average_linkedin_social_engagementRate = getLoadedMetricById(metrics.averageLinkedinSocialEngagementRate.id);
        let linkedin_link_click = getLoadedMetricById(metrics.linkedinLinkClick.id);
        let linkedin_likes = getLoadedMetricById(metrics.linkedinLikes.id);


        let linkedin_comments = getLoadedMetricById(metrics.linkedinComments.id);
        let twitter_engagements = getLoadedMetricById(metrics.twitterEngagements.id);

        if (twitter_engagements !== null && twitter_engagements !== undefined) {
            setTwitterEngagements(twitter_engagements);
        } else {
            getTwitterEngagements(modifiedParams);
        }

        if (twitter_likes !== null && twitter_likes !== undefined) {
            setTwitterLikes(twitter_likes);
        } else {
            getTwitterLikes(modifiedParams);
        }

        if (twitter_shares !== null && twitter_shares !== undefined) {
            setTwitterShares(twitter_shares);
        } else {
            getTwitterShares(modifiedParams);
        }

        if (twitter_social_engagement !== null && twitter_social_engagement !== undefined) {
            setTwitterSocialEngagementRate(twitter_social_engagement);
        } else {
            getTwitterSocialEngagementRate(modifiedParams);
        }

        if (twitter_link_clicks !== null && twitter_link_clicks !== undefined) {
            setTwitterLinkClicks(twitter_link_clicks);
        } else {
            getTwitterLinkClicks(modifiedParams);
        }
        if (linkedin_shares !== null && linkedin_shares !== undefined) {
            setLinkedinShares(linkedin_shares);
        } else {
            getLinkedinShares(modifiedParams);
        }


        if (average_linkedin_social_engagementRate !== null && average_linkedin_social_engagementRate !== undefined) {
            setAverageLinkedinSocialEngagementRate(average_linkedin_social_engagementRate);
        } else {
            getAverageLinkedinSocialEngagementRate(modifiedParams);
        }

        if (linkedin_link_click !== null && linkedin_link_click !== undefined) {
            setLinkedinLinkClick(linkedin_link_click);
        } else {
            getLinkedinLinkClick(modifiedParams);
        }

        if (linkedin_likes !== null && linkedin_likes !== undefined) {
            setLinkedinLikes(linkedin_likes);
        } else {
            getLinkedinLikes(modifiedParams);
        }
        
        if (linkedin_comments !== null && linkedin_comments !== undefined) {
            setLinkedinComments(linkedin_comments);
        } else {
            getLinkedinComments(modifiedParams);
        }

    }

    const resetReports = () => {
        setTwitterEngagements(null);
        setTwitterLikes(null);
        setTwitterShares(null);
        setTwitterSocialEngagementRate(null);
        setTwitterLinkClicks(null);
        setLinkedinShares(null);
        setAverageLinkedinSocialEngagementRate(null)
        setLinkedinLinkClick(null);
        setLinkedinLikes(null);
        setLinkedinComments(null);
    }


    useEffect(() => {
        resetReports();
        getMetrics();
    }, [dataReport.params])


    return (
        <>
            <div className='row g-5 g-xl-8' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                <div className="col-xl-12">
                    <hr></hr>
                </div>
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/brand-logos/' + metrics.twitterEngagements.logo}
                        snapshotId={metrics.twitterEngagements.id}
                        title={metrics.twitterEngagements.title}
                        subtitle={metrics.twitterEngagements.subtitle}
                        startPeriod={24}
                        metrics={twitterEngagements}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/brand-logos/' + metrics.twitterLikes.logo}
                        snapshotId={metrics.twitterLikes.id}
                        title={metrics.twitterLikes.title}
                        subtitle={metrics.twitterLikes.subtitle}
                        startPeriod={24}
                        metrics={twitterLikes}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/brand-logos/' + metrics.twitterShares.logo}
                        snapshotId={metrics.twitterShares.id}
                        title={metrics.twitterShares.title}
                        subtitle={metrics.twitterShares.subtitle}
                        startPeriod={24}
                        metrics={twitterShares}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/brand-logos/' + metrics.twitterSocialEngagementRate.logo}
                        snapshotId={metrics.twitterSocialEngagementRate.id}
                        title={metrics.twitterSocialEngagementRate.title}
                        subtitle={metrics.twitterSocialEngagementRate.subtitle}
                        startPeriod={24}
                        metrics={twitterSocialEngagementRate}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                        valueType='percent'
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/brand-logos/' + metrics.twitterLinkClicks.logo}
                        snapshotId={metrics.twitterLinkClicks.id}
                        title={metrics.twitterLinkClicks.title}
                        subtitle={metrics.twitterLinkClicks.subtitle}
                        startPeriod={24}
                        metrics={twitterLinkClicks}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/brand-logos/' + metrics.linkedinShares.logo}
                        snapshotId={metrics.linkedinShares.id}
                        title={metrics.linkedinShares.title}
                        subtitle={metrics.linkedinShares.subtitle}
                        startPeriod={24}
                        metrics={linkedinShares}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/brand-logos/' + metrics.linkedinLikes.logo}
                        snapshotId={metrics.linkedinLikes.id}
                        title={metrics.linkedinLikes.title}
                        subtitle={metrics.linkedinLikes.subtitle}
                        startPeriod={24}
                        metrics={linkedinLikes}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/brand-logos/' + metrics.averageLinkedinSocialEngagementRate.logo}
                        snapshotId={metrics.averageLinkedinSocialEngagementRate.id}
                        title={metrics.averageLinkedinSocialEngagementRate.title}
                        subtitle={metrics.averageLinkedinSocialEngagementRate.subtitle}
                        startPeriod={24}
                        metrics={averageLinkedinSocialEngagementRate}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/brand-logos/' + metrics.linkedinLinkClick.logo}
                        snapshotId={metrics.linkedinLinkClick.id}
                        title={metrics.linkedinLinkClick.title}
                        subtitle={metrics.linkedinLinkClick.subtitle}
                        startPeriod={24}
                        metrics={linkedinLinkClick}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                        
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/brand-logos/' + metrics.linkedinComments.logo}
                        snapshotId={metrics.linkedinComments.id}
                        title={metrics.linkedinComments.title}
                        subtitle={metrics.linkedinComments.subtitle}
                        startPeriod={24}
                        metrics={linkedinComments}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
            </div>

        </>
    )
}


export default EngagementSocialMedia;