
import { RestServiceConnection } from "../RestServiceConnection";
import { IAuthenticationCallback, ICognitoUserPoolData, AuthenticationDetails, CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";

export class LoginService extends RestServiceConnection {

    loginOauthUser = async (data: any) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/auth/check/google',
            data: data,
            headers: {
                "Content-Type": "application/json"
            }
        });
        return this;
    }

    
    loginNormalUser = async (email: string, password: string, onRequireNewPassword: (cognitoUser: CognitoUser, userAttributes: any) => void) => {

        return new Promise((resolve, reject) => {

            let authenticationData = {
                Username: email,
                Password: password,
            };

            let authenticationDetails = new AuthenticationDetails(authenticationData);

            let cognitoUser = this.getCognitoUser(email);

            let callback: IAuthenticationCallback = {
                onSuccess: (result: any) => {
                    resolve({
                        success: true,
                        data: result
                    });
                },
                onFailure: (err: any) => {
                    reject({cognitoUser, code: err.code, message: err.message});
                },
                newPasswordRequired: (userAttributes: any, requiredAttributes: any) => {
                    
                    delete userAttributes.email_verified;
                    delete userAttributes.email;
                    
                    onRequireNewPassword(cognitoUser, userAttributes);
                    //cognitoUser.completeNewPasswordChallenge("cC30011992bB.",userAttributes, callback);
                },
            }

            cognitoUser.authenticateUser(authenticationDetails, callback);
        })
    }

    resetPassword = async (cognitoUser: CognitoUser|null, verificationCode: string, newPassword: string) => {

        return new Promise((resolve, reject) => {
            if(cognitoUser !== null) {
                cognitoUser.confirmPassword(verificationCode, newPassword, {
                    onSuccess: (result: any) => {
                        resolve(result);
                    },
                    onFailure: (err: any) => {
                        reject(err);
                    }
                })
            }
        })
    }

    renewPassword = async (cognitoUser: CognitoUser|null, newPassword: string, userAttributes: any) => {
        return new Promise((resolve, reject) => {
            if(cognitoUser !== null) {
                cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
                    onSuccess: (result: any) => {
                        resolve(result);
                    },
                    onFailure: (err: any) => {
                        console.error(err);
                        reject(err);
                    }
                })
            }
        })
    }

    loginWithCredentials = async (credentials: any) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/auth/login',
            data: credentials,
            headers: {
                "Content-Type": "application/json"
            }
        });
        return this;
    }


     
}

