import { useFormik } from "formik";
import {FC, useEffect, useState} from "react";
import { Button, Spinner } from "react-bootstrap";
import { UserService } from "../../../services/user/UserService";
import ImgOrDefault from "../../../utils/components/img_or_default";
import {Role} from "../../../models/users/Role";
import { ExternalProviderService } from "app/services/external_povider/ExternalProviderService";
import { useExternalProviders } from "../ExternalProvidersProvider";
import Swal from "sweetalert2";

interface Props {
    successSubmit: (values: any) => void
}

const CreateExternalProviderForm: FC<Props> = ({ successSubmit }) => {

    const [creating, setCreating] = useState(false);

    const providers = useExternalProviders(); 




const validate = (values: any) => {
    const errors: any = {};
    // metricName field
    if (!values.metricName) {
        errors.metricName = 'Required';
    } else if (values.metricName.length < 3) {
        errors.metricName = 'Must be 3 characters or more';
    }

    // metricValue field
    if (!values.metricValue) {
        errors.metricValue = 'Required';
    } else if (values.metricValue < 0) {
        errors.metricValue = 'Must be a positive number';
    }

    // metricDate field
    if (!values.metricDate) {
        errors.metricDate = 'Required';
    }

    // programmeID field
    if (!values.programmeId) {
        errors.programmeId = 'Required';
    } else if (values.programmeId < 0) {
        errors.programmeId = 'Must be a positive number';
    }

    // campaignID field
    if (!values.campaignId) {
        errors.campaignId = 'Required';
    } else if (values.campaignId < 0) {
        errors.campaignId = 'Must be a positive number';
    }

    if (!values.provider) {
        errors.provider = 'Required';
    } else if (!providers.providers.find((provider) => provider.value == values.provider)) {
        errors.provider = 'Invalid provider';
    }
    return errors;
}

    const createExternalProvider = async (values: any) => {
        let request = await (await (new ExternalProviderService()).createExternalProvider(values));
        let response = request.getResponse();

        let data = request.getResponseData();
        
        setCreating(false);

        if (data.success == false || response?.status != 200) {
            Swal.fire({
                title: 'Error!',
                html: 'Something went wrong.<br> Please try again later.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        } else {
            formik.resetForm();
            formik.setFieldValue('enabled', true);
            successSubmit(values);
        }
    }

    // Declare form
    const formik = useFormik({
        initialValues: {
            metricName: '',
            metricValue: '',
            metricDate: '',
            programmeId: '',
            campaignId: '',
            provider: '',
        },
        validate,
        onSubmit: values => {
            setCreating(true);
            createExternalProvider(values);
        }
    })

    return (
        <>
            <form id="create-external-provider-form" onSubmit={formik.handleSubmit}></form>
            <div className='form-group p-2 mb-2'>
                <label className='form-label fw-bold'>Metric Name: <span className="text-danger">*</span></label>
                <input type="text" form="create-external-provider-form" name="metricName" className="form-control" onChange={formik.handleChange} value={formik.values.metricName} placeholder="slide_downloads" />
                {formik.errors.metricName ? <div className="text-danger">{formik.errors.metricName}</div> : null}
            </div>

            <div className='form-group p-2 mb-2'>
                <label className='form-label fw-bold'>Metric Value: <span className="text-danger">*</span></label>
                <input type="number" form="create-external-provider-form" name="metricValue" className="form-control" onChange={formik.handleChange} value={formik.values.metricValue} placeholder="100" />
                {formik.errors.metricValue ? <div className="text-danger">{formik.errors.metricValue}</div> : null}
            </div>

            <div className='form-group p-2 mb-2'>
                <label className='form-label fw-bold'>Metric Date: <span className="text-danger">*</span></label>
                <input type="date" form="create-external-provider-form" name="metricDate" className="form-control" onChange={formik.handleChange} value={formik.values.metricDate} placeholder="2021-09-01" />
                {formik.errors.metricDate ? <div className="text-danger">{formik.errors.metricDate}</div> : null}
            </div>

            <div className='form-group p-2 mb-2'>
                <label className='form-label fw-bold'>Programme ID: <span className="text-danger">*</span></label>
                <input type="number" form="create-external-provider-form" name="programmeId" className="form-control" onChange={formik.handleChange} value={formik.values.programmeId} placeholder="1" />
                {formik.errors.programmeId ? <div className="text-danger">{formik.errors.programmeId}</div> : null}
            </div>

            <div className='form-group p-2 mb-2'>
                <label className='form-label fw-bold'>Campaign ID: <span className="text-danger">*</span></label>
                <input type="number" form="create-external-provider-form" name="campaignId" className="form-control" onChange={formik.handleChange} value={formik.values.campaignId} placeholder="1" />
                {formik.errors.campaignId ? <div className="text-danger">{formik.errors.campaignId}</div> : null}
            </div>

            <div className='form-group p-2 mb-2'>
                <label className='form-label fw-bold'>Provider: <span className="text-danger">*</span></label>
                <select className="form-control" form="create-external-provider-form" name="provider" value={formik.values.provider} onChange={formik.handleChange}>
                    <option value={""}>Select provider</option>
                    {providers.providers.map((provider) => {
                        return <option value={provider.value}>{provider.label}</option>
                    })}
                </select>
                {formik.errors.provider ? <div className="text-danger">{formik.errors.provider}</div> : null}
            </div>

            <div className="d-flex justify-content-end">
                <button disabled={creating} form="create-external-provider-form" type="submit" className="btn btn-primary">
                    {creating && (<Spinner animation="border" color="primary" ></Spinner>)}
                    {!creating && "Create"}
                </button>
            </div>
        </>
    );
}

export default CreateExternalProviderForm;