import CustomSelect from '@components/form/CustomSelect'
import {FC, useContext, useEffect, useState} from 'react'
import DbManagerContext from '../context/DbManagerProvider'

interface ConditionComponentProps {
  row: any
  groupID: number
  rowID: number
  dispatch: any
}

const ConditionSelect: FC<ConditionComponentProps> = ({row, groupID, rowID, dispatch}) => {
  const {fieldsData} = useContext(DbManagerContext)

  const [conditions, setConditions] = useState<any>([])

  const getConditionOptions = () => {
    const selectedFieldData = fieldsData.find((field: any) => field.name === row.field)
    const conditionOptions = Object.entries(selectedFieldData.available_conditions).map(
      ([value, condition]: [any, any]) => ({
        value: value,
        label: condition.label,
      })
    )
    setConditions(conditionOptions)
  }

  useEffect(() => {
    if (row.field !== '') {
    getConditionOptions()
    }
  }, [row])

  return (
    <div className='p-2 flex-fill' style={{minWidth: '200px'}}>
      <div className='form-group'>
        <label className='form-label fw-bold'>Condition</label>
        <CustomSelect
          key={row.condition}
          options={conditions}
          placeholder='Select Condition'
          defaultValue={
            row.condition !== ''
              ? {
                  label: row.condition
                    .trim()
                    .replace(/_/g, ' ')
                    .replace(/^\w/, (c: any) => c.toUpperCase()),
                  value: row.condition,
                }
              : null
          }
          onChangeSingle={(e: any) => {
            dispatch({
              type: 'UPDATE_CONDITION_VALUE',
              payload: {
                groupIndex: groupID,
                conditionIndex: rowID,
                field: row.field,
                condition: e.value,
              },
            })
          }}
        />
      </div>
    </div>
  )
}

export default ConditionSelect
