import { FC, useEffect, useState } from "react";
import { PageTitle, useLayout } from "../../../../_metronic/layout/core";
import { SnapshotService } from "../../../services/snapshot/SnapshotService";
import { useParams } from "react-router-dom";
import { Metric, ReachMetric } from "app/pages/data_report/components/metrics/ReachMetric";
import { getMetric } from "app/utils/utils";
import Sticky from "react-sticky-el";

const breadcrumbs = [

  {
    title: 'Snapshot List',
    path: 'admin/snapshots/list',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '#',
    isSeparator: true,
    isActive: false,
  },
]

export const SnapshotView: FC = () => {

  const [snapshot, setSnapshot] = useState<any>(null);
  const [metrics, setMetrics] = useState<Metric[]| null>(null)
  const { id } = useParams();

  const layout = useLayout();

  useEffect(() => {
    getSnapshot();
  }, []);

  const getSnapshot = async () => {

    if (id !== undefined) {
      let snapshot = (await (new SnapshotService).getSnapshotById(id)).getOnlyData();
      setSnapshot(snapshot);
      let snapshotMetrics = JSON.parse(snapshot.data);
      setMetrics(snapshotMetrics);
    }

  }

  const deleteSnapshot = async (id: string) => {
    let confirmed = await layout.confirmAction({title: "Delete Snapshot", description: "Are you sure you want delete this snapshot?"});
    
    if(confirmed) {
      
      let deleteResponse = (await (new SnapshotService()).deleteSnapshot(id));
      let deleteResponseData = deleteResponse.getResponseData();

      if(deleteResponseData.success == false) {
        layout.toast?.error(`Error delenting snapshot: ${deleteResponseData.message}`)
      }


    }
  }

  const updateSnapshotMetrics = async () => {
    
    if(id !== undefined) {
    let response = (await (new SnapshotService).updateSnapshot(id, metrics)).getResponseData();

      if(response.success === false) {
        await getSnapshot();
      }
    }
  }

  const updateSnapshotField = async (e: any, indexOfElement: keyof Object, metricId: keyof Object) => {
    if (metrics !== null) {
      Object.entries(metrics).map(([metricName, value], index) => {
        if (metricName === metricId) {
          
          metrics[metricId][indexOfElement]['value' as keyof Object] = e.target.value;
        }
      })
    
      await updateSnapshotMetrics();
    }

  }

  return (
    <>
      {
        (snapshot === null || metrics === null) ?
          (
            <div className="text-justify-content-md-center">
              <span className='indicator-progress text-center' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </div>

          )
          :
          (
            <>
              <PageTitle breadcrumbs={breadcrumbs}>{snapshot.name !== undefined ? snapshot.name : 'Snapshot View'}</PageTitle>
              <div className='row g-5 g-xl-8 mt-3'>
                {Object.entries(metrics).map(([key, value]: any) => {
                  let metricInfo = getMetric(key);
                  return (
                    <div key={key} className='col-xl-6 flex-xl-fill mt-0'>
                      <ReachMetric
                        snapshotId={key}
                        updateSnapshotField={updateSnapshotField}
                        title={metricInfo != undefined ? metricInfo.title : key}
                        subtitle={metricInfo != undefined ? metricInfo.title : key}
                        metrics={value}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px' />
                    </div>);
                }
                )}
              </div>
            </>
          )
      }
    </>
  );
}