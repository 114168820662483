import { FC } from "react";
import { KTSVG } from "../../../../_metronic/helpers";

interface Props {
    classes?: string
}
 
const CustomModalHeader: FC<Props> = ({classes, children}) => {
    
    return ( 
        <div className={`modal-header ${classes}`}>
            {children}
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
        </div>
     );
}
 
export default CustomModalHeader;