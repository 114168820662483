
import ConfigRoutes from 'app/routing/ConfigRoutes'
import { FC } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageTitle } from '_metronic/layout/core'
import { SpotifyImportBase } from './spotify/components'
import { YoutubeAnalyticsImportBase } from './youtube_analytics/components'
import { CacheCleanerBase } from './cache/components'
import { BotSettings } from './bots'

const SecurityWrapper: FC = () => {

    return (
        <>
            <Routes>

                <Route element={<> <Outlet /><PageTitle>Spotify Security</PageTitle> </>}>
                    <Route path={ConfigRoutes.admin.imports.security.spotify.path} element={ <SpotifyImportBase/> } />
                </Route>

                <Route element={<> <Outlet /><PageTitle>Youtube Analytics</PageTitle> </>}>
                    <Route path={ConfigRoutes.admin.imports.security.youtubeAnalytics.path} element={ <YoutubeAnalyticsImportBase/> } />
                </Route>

                <Route element={<> <Outlet /><PageTitle>Cache</PageTitle> </>}>
                    <Route path={ConfigRoutes.admin.imports.security.cache.path} element={ <CacheCleanerBase/> } />
                </Route>

                <Route element={<> <Outlet /><PageTitle>Bots</PageTitle> </>}>
                    <Route path={ConfigRoutes.admin.imports.security.bots.path} element={ <BotSettings/> } />
                </Route>

                <Route index element={<Navigate to={ConfigRoutes.admin.imports.security.spotify.path} />} />
            </Routes>
        </>

    )
}

export { SecurityWrapper }