/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Link, Routes, Outlet } from 'react-router-dom'
import { Error500 } from './components/Error500'
import { Error404 } from './components/Error404'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Unathorized } from './components/Unathorized'
import { useDispatch } from 'react-redux'
import { removeUser } from 'app/store/reducers/auth_reducer'

const ErrorsLayout = () => {

  const dispatch = useDispatch();

  return (
    <div className='d-flex flex-column flex-root'>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')` }}
      >
        <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20'>

          <div className='d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px'
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/logos/logo.svg')}')`,
            }}
          >
          </div>

          <div className='pt-lg-10 mb-10'>
            <Outlet />

            <div className='column'>
              <div className='row justify-content-center mb-5'>
                <Link to='/' className='btn btn-lg btn-primary fw-bolder w-auto'>
                  Go to homepage
                </Link>
              </div>
              <div className='row justify-content-center'>
                <a role='button' onClick={() => dispatch(removeUser())} className='btn-sm btn-lg btn-primary fw-bolder w-auto'>
                  Sign Out
                </a>
              </div>
            </div>

          </div>
        </div>

        <div className='d-flex flex-center flex-column-auto p-10'>
          <div className='d-flex align-items-center fw-bold fs-6'>
            <a href='https://cor2ed.com' className='text-muted text-hover-primary px-2'>
              About
            </a>
            <a href='mailto:support@cor2ed.com' className='text-muted text-hover-primary px-2'>
              Contact
            </a>
            <a href='https://cor2ed.com' className='text-muted text-hover-primary px-2'>
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const ErrorsPage = () => (
  <Routes>
    <Route element={<ErrorsLayout />}>
      <Route path='404' element={<Error404 />} />
      <Route path='500' element={<Error500 />} />
      <Route path='unathorized' element={<Unathorized />} />
      <Route index element={<Error404 />} />
    </Route>
  </Routes>
)

export { ErrorsPage }
