const MarketingSocialMediaWrapper = () => {
    return (
    <div className='row'>
      {
          (<h3 className={'text-muted text-center'}>Please select one insinght level</h3>)
      }

    </div>

  )
}

export default MarketingSocialMediaWrapper