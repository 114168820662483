import moment from "moment"
import React, { createContext, useContext, useEffect, useState } from "react"
import _ from 'lodash'
import { useDispatch } from "react-redux";
import { resetLoadedMetrics } from "app/store/reducers/metric_reducer";

export type DataReportFilterMode = 'months' | 'years' | 'weeks' | 'days' | 'Q';

export type DataReportFilters = {
    between_dates: BetweenDatesFilter
    mode: DataReportFilterMode
    connects: string[]
    regions: string[]
    campaigns: string[]
    therapeutic_areas: string[],
    labels: string[],
    programmes: string[],
    include_bots: boolean,
}

export type BetweenDatesFilter = {
    from: string,
    to: string
}

export type DataReportFilterParams = {
    filter_filters: DataReportFilters
    
}

type DataReportContextModel = {
    params: DataReportFilterParams,
    metrics: DataReportMetrics,
    showSnapshotModal: boolean,
    updateFilters: (filters: Partial<DataReportFilters>) => void,
    updateMetrics: (metric: string) => void
    updateShowSnapshotModal: (show: boolean) => void,
}

export const defaultFilterParams: DataReportFilterParams = {
    filter_filters: {
        between_dates: {
            from: moment().startOf('month').format('YYYY-MM-DD'),
            to: moment().startOf('day').format('YYYY-MM-DD'),
        },
        connects: [],
        regions: [],
        campaigns: [],
        therapeutic_areas: [],
        labels: [],
        programmes: [],
        mode: 'months',
        include_bots: true
    }
}

type DataReportMetrics = string[];

type ShowSnapshotModal = boolean;

const defaultDataReportMetrics: DataReportMetrics = [];

const defaultShowSnapshotModal: ShowSnapshotModal = false;

const DataReportContext = createContext<DataReportContextModel>({
    params: defaultFilterParams,
    metrics: defaultDataReportMetrics,
    showSnapshotModal: defaultShowSnapshotModal,
    updateFilters: (filters: Partial<DataReportFilters>) => {},
    updateMetrics: (metricId: string) => {},
    updateShowSnapshotModal: (show: boolean) => {},
})


const DataReportProvider: React.FC = ({children}) => {

    const [params, setParams] = useState<DataReportFilterParams>(defaultFilterParams)
    const [metrics, setMetrics] = useState<DataReportMetrics>(defaultDataReportMetrics)
    const [showSnapshotModal, setShowSnapshotModal] = useState<ShowSnapshotModal>(defaultShowSnapshotModal)
    
    const dispatch = useDispatch();

    const updateShowSnapshotModal = (show: boolean) => {
        setShowSnapshotModal(show)
    }


    const updateFilters = (filters: Partial<DataReportFilters>) => {
        
        dispatch(resetLoadedMetrics());
        
        let newObject = {
            ...params,
            filter_filters: {
                ...params.filter_filters,
                ...filters
            }
        }

        if(_.isEqual(params, newObject) === false) {
            setParams(newObject)
        }
        
    }

    const updateMetrics = (metricId: string) => {

        let newMetrics =  metrics;  
        
        if(newMetrics.includes(metricId)) {
            newMetrics = newMetrics.filter((metric) => metric !== metricId);
        } else {
            newMetrics.push(metricId);
        }

        setMetrics(newMetrics);
    }

    const value: DataReportContextModel = {
        params,
        metrics,
        showSnapshotModal,
        updateFilters,
        updateMetrics,
        updateShowSnapshotModal,
    }

    useEffect(() => {

    }, [params.filter_filters])
    

    return <DataReportContext.Provider value={value}>{children}</DataReportContext.Provider>
}


export {DataReportProvider, DataReportContext}

export function useDataReport() {
    return useContext(DataReportContext);
}