import {toAbsoluteUrl} from "../../../../_metronic/helpers";
import React, {useEffect, useState} from "react";
import WorkLoadComponent from "@components/workload_component/WorkLoadComponent";

interface Props {
  image: string | Promise<Blob | null>,
  title: string,
  description?: string,
  counter?: number | null
}

export const ProgrammeExplorerCardHeader: React.FC<Props> = ({image, title, description, counter}) => {

  const [imageSrc, setImageSrc] = useState<string|undefined>(undefined)

  const getImage = async () => {
    if (typeof image === 'string') {
      setImageSrc(toAbsoluteUrl(image))
    }

    if (image instanceof Promise) {
      const blobResource = await image
      if (blobResource !== null) {
        let url = URL.createObjectURL(blobResource)
        setImageSrc(url)
      }
    }
  }

  useEffect(() => {
    getImage()
  }, [])

  const isNumberLarge = (number: number | undefined | null): boolean => {
    if (number === null || number === undefined) {
      return false;
    }

    const numericNumber = parseFloat(number.toString().replace(/,/g, ''));
    return numericNumber > 99;
  }

  return (
    <div className={'d-flex w-100 justify-content-start align-items-center pb-0'}>
      <div className='symbol symbol-50px'>
        <span className='symbol-label bg-light'>
          <img src={imageSrc} className='h-50 align-self-center' alt=''/>
        </span>
      </div>
      <div className='d-flex ps-5 flex-column pe-2'>
        <h4>{title}</h4>
        <div className='text-muted ps-2'>{description}</div>
      </div>
      <div className='d-flex ms-auto'>
        <WorkLoadComponent evaluateElement={counter}>
          <h1 className={`text-primary ${isNumberLarge(counter) ? 'counter-font-large' : 'counter-font'}`}>{new Intl.NumberFormat('en-EN').format(counter as number)}</h1>
        </WorkLoadComponent>
      </div>
    </div>
  )
}