import React, { useEffect, useState } from 'react'

type Props = {
    placeholder: string,
    onSearch(value: string): void
}

const CustomSearchInput: React.FC<Props> = ({ placeholder, onSearch }) => {

    const [searchValue, setSearchValue] = useState<string>("");

    useEffect(()=> {
        const delaySearch = setTimeout(() => {
            onSearch(searchValue);
        }, 1000);

        return () => clearTimeout(delaySearch);
    }, [searchValue])


    return (
        <div>
            <input className='form-control form-control-solid' placeholder={placeholder} onChange={(e) => setSearchValue(e.target.value)}/>
        </div>
    )
}

CustomSearchInput.defaultProps = {

}

export default CustomSearchInput;

