import { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';

declare const window: any;
/* global gapi */
const google = window.google;

type LoginWithGoogleProps = {
    onOauthLogin: (success: boolean, authData: any, message?: string) => void;
}

export default function LoginWithGoogle({ onOauthLogin }: LoginWithGoogleProps) {

    const getQueryString = function (field: any, url: string) {
        const windowLocationUrl = url ? url : "";
        const reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
        const string = reg.exec(windowLocationUrl);
        return string ? string[1] : null;
    };

    const openPopupAndWaitRedirect = async () => {

        let url = `${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT_URI}&response_type=CODE&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&scope=email openid phone profile aws.cognito.signin.user.admin`;
        let popupWindow = window.open(url, 'Authorization', 'width=400,height=600,status=no,location=no,menubar=no');
        
        let timer = setInterval(() => {

            if (popupWindow.closed) {
                clearInterval(timer);
            }

            try {
                let href = popupWindow.location.href;
                
                if (href !== null && href !== undefined) {
                    if (href.match('code')) {
                        clearInterval(timer);
                        let authorizationCode = getQueryString('code', href);
                        onOauthLogin(true, { code: authorizationCode });
                        popupWindow.close();
                    } else {
                        if (href.match('error')) {
                            clearInterval(timer);
                            let error = getQueryString('error_description', href);
                            console.error(error);
                        }
                    }

                }
            } catch (e) {
                console.log(e);
            }

        }, 1);
       
    }

    return (
        <div>
            <button onClick={openPopupAndWaitRedirect} type="button" className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
                <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                    className='h-20px me-3'
                />
                Sign in with Google
            </button>
        </div>

    )
}
