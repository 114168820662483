
import { PageTitle } from '_metronic/layout/core'
import ConfigRoutes from 'app/routing/ConfigRoutes'
import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import CustomMetricConfiguratorMain from './components/CustomMetricConfiguratorMain'
import { CustomMetricConfiguratorProvider } from './CustomMetricConfiguratorProvider'


const breadcrumbs = [
    {
        title: 'Modules', 
        path: '#',
        isSeparator: false,
        isActive: false,
    },
    {
        title: 'Custom Metric Configurator',
        path: '/metric-custom/configurator',
        isSeparator: true,
        isActive: true,
    },
]

const CustomMetricConfiguratorWrapper: FC = () => {

    return (
        <>
            <Routes>
                <Route
                    element={
                        <CustomMetricConfiguratorProvider>
                            <PageTitle breadcrumbs={breadcrumbs}>Custom Metric Configurator</PageTitle>
                            <CustomMetricConfiguratorMain />
                        </CustomMetricConfiguratorProvider>
                    }
                >
                    <Route index element={<Navigate to={ConfigRoutes.admin.metrics.metric_custom.list.principal} />} />
                </Route>
            </Routes>
        </>
    )
}

export { CustomMetricConfiguratorWrapper } 