import React, {createContext, useContext, useState, useEffect} from 'react'
import {DefaultLayoutConfig} from './DefaultLayoutConfig'
import {
  getEmptyCssClasses,
  getEmptyCSSVariables,
  getEmptyHTMLAttributes,
  LayoutSetup,
} from './LayoutSetup'
import {
  ILayout,
  ILayoutCSSVariables,
  ILayoutCSSClasses,
  ILayoutHTMLAttributes,
} from './LayoutModels'
import { useDispatch, useSelector } from 'react-redux'
import { setConfig } from '../../../app/store/reducers/layout_reducer'
import toastr from 'toastr'
import Swal from 'sweetalert2'

interface ConfirmSweetAlertOptions {
  title: string, 
  description?: string, 
  successButtonText?: string, 
  cancelButtonText?: string
}

export interface LayoutContextModel {
  config: ILayout
  classes: ILayoutCSSClasses
  attributes: ILayoutHTMLAttributes
  cssVariables: ILayoutCSSVariables
  buttons?: Array<JSX.Element>,
  toast: Toastr|null,
  componentIsSettingToolbar: boolean,
  loaded: boolean,
  setLoaded: (loaded: boolean) => void,
  confirmAction:(options: ConfirmSweetAlertOptions) => Promise<boolean>,
  setLayout: (config: LayoutSetup) => void
  setLayoutWidth: (width: 'fixed' | 'fluid') => void,
  setToolbarButtons: (buttons: Array<JSX.Element>) => void,
  resetToolbarButtons: () => void,
}

const LayoutContext = createContext<LayoutContextModel>({
  config: DefaultLayoutConfig,
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
  buttons: [],
  toast: null,
  componentIsSettingToolbar: false,
  loaded: false,
  setLoaded: (loaded: boolean) => {},
  confirmAction: (options: ConfirmSweetAlertOptions) => Promise.resolve(false),
  setLayout: (config: LayoutSetup) => {},
  setLayoutWidth: (width: 'fixed' | 'fluid') => {},
  setToolbarButtons: (buttons: Array<JSX.Element>) => {},
  resetToolbarButtons: () => {},
})

const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex')
  }
}

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none')
  }
}

const LayoutProvider: React.FC = ({children}) => {

  // Todo: Create selector for layout setup configuration.
  const config = useSelector((state: any): ILayout => state.layout );
  
  const dispatch = useDispatch();

  const [classes, setClasses] = useState(LayoutSetup.classes)
  const [attributes, setAttributes] = useState(LayoutSetup.attributes)
  const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables)
  const [buttons, setButtons] = useState<Array<JSX.Element>>([])
  const [toast, setToast] = useState<Toastr|null>(null)
  const [sweetalert, setSweetAlert] = useState(null)
  const [componentIsSettingToolbar, setComponentIsSettingToolbar] = useState(false);
  const [loaded, setLoaded] = useState(false);
  
  const setLayout = (_themeConfig: Partial<ILayout>) => {
    enableSplashScreen()
    const bodyClasses = Array.from(document.body.classList)
    bodyClasses.forEach((cl) => document.body.classList.remove(cl))
    LayoutSetup.updatePartialConfig(_themeConfig)
    dispatch(setConfig(LayoutSetup.config))
    setClasses(LayoutSetup.classes)
    setAttributes(LayoutSetup.attributes)
    setCSSVariables(LayoutSetup.cssVariables)
    setTimeout(() => {
      disableSplashScreen()
    }, 500)
  }


  const setToolbarButtons = (buttons: Array<JSX.Element>) => {
    setComponentIsSettingToolbar(true);
    setButtons(buttons);
  }

  const resetToolbarButtons = () => {
    setButtons([]);
  }

  const configureToast = () => {
    toastr.options = {
      closeButton: true,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      positionClass: "toast-top-right",
      preventDuplicates: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      timeOut: 5000
    }
    setToast(toastr);
    
  }

  const confirmAction = async (options: ConfirmSweetAlertOptions) => {
    return new Promise<boolean>(async (resolve, reject) => {
      await Swal.fire({
        title: options.title,
        text: options.description,
        icon: 'question',
        confirmButtonText: options.successButtonText ? options.successButtonText : "Continue",
        confirmButtonColor: config.main?.primaryColor,
        cancelButtonText: options.cancelButtonText ? options.cancelButtonText : "Cancel",
        cancelButtonColor: config.main?.dangerColor,
        showCancelButton: true
      }).then(result => {
        if(result.isConfirmed) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
    })
  }

  const setLayoutWidth = (width: 'fixed' | 'fluid') => {
    setLayout({
      content: {
        layout: 'default',
        width: width
      }
    });
  }

  const value: LayoutContextModel = {
    config,
    classes,
    attributes,
    buttons,
    cssVariables,
    componentIsSettingToolbar,
    setLayout,
    setLayoutWidth,
    setToolbarButtons,
    toast,
    confirmAction,
    resetToolbarButtons,
    loaded,
    setLoaded
    
  }

  useEffect(() => {
    disableSplashScreen()
    configureToast();
  }, [config])

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export {LayoutContext, LayoutProvider}

export function useLayout() {
  return useContext(LayoutContext)
}
