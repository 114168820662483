import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { LoginService } from '../../../services/auth/LoginService'
import { CognitoUser } from 'amazon-cognito-identity-js'

const resetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
        .min(8, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    repeatPassword: Yup.string()
        .min(8, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
})

type NewPasswordFormProps = {
    cognitoUser: CognitoUser|null,
    userAttributes: any|null,
    onLoginSuccess: any,
    onLoginError: any
 }

export const NewPasswordForm = ({cognitoUser, userAttributes, onLoginSuccess, onLoginError}: NewPasswordFormProps) => {

    const [loading, setLoading] = useState(false);

    const newPasswordForm = useFormik({
        initialValues: {
            newPassword: '',
            repeatPassword: '',
        },
        validationSchema: resetPasswordSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            
            try {
                setLoading(true);
                let resetPasswordresult: any = await (new LoginService()).renewPassword(cognitoUser, values.newPassword, userAttributes);
                // Add reload to indicate to parent fucntion tha must be reload page.
                resetPasswordresult.code = "RELOAD";
                onLoginSuccess(resetPasswordresult);
            } catch (error: any) {
                onLoginError(error);
                setSubmitting(false)
                setLoading(false)
            }
        },
    })
    

    return (
        <form
            autoComplete='off'
            className='form w-100'
            onSubmit={newPasswordForm.handleSubmit}
            noValidate
            id='kt_login_new_password_form'
        >

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>New Password</label>
                <input
                    placeholder='New Password'
                    {...newPasswordForm.getFieldProps('newPassword')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        { 'is-invalid': newPasswordForm.touched.newPassword && newPasswordForm.errors.newPassword },
                        {
                            'is-valid': newPasswordForm.touched.newPassword && !newPasswordForm.errors.newPassword,
                        }
                    )}
                    type='password'
                    name='newPassword'
                    autoComplete='off'
                />
                {newPasswordForm.touched.newPassword && newPasswordForm.errors.newPassword && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert'>{newPasswordForm.errors.newPassword}</span>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                        {/* begin::Label */}
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Repeat Password</label>
                        {/* end::Label */}
                    </div>
                </div>
                <input
                    type='password'
                    autoComplete='off'
                    placeholder='Repeat Password'
                    {...newPasswordForm.getFieldProps('repeatPassword')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                            'is-invalid': newPasswordForm.touched.repeatPassword && newPasswordForm.errors.repeatPassword,
                        },
                        {
                            'is-valid': newPasswordForm.touched.repeatPassword && !newPasswordForm.errors.repeatPassword,
                        }
                    )}
                />
                {newPasswordForm.touched.repeatPassword && newPasswordForm.errors.repeatPassword && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{newPasswordForm.errors.repeatPassword}</span>
                        </div>
                    </div>
                )}

                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg btn-primary w-100 mb-5 mt-5'
                    disabled={newPasswordForm.isSubmitting || !newPasswordForm.isValid}
                >
                    {!loading && <span className='indicator-label'>Continue</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            {/* end::Form group */}
        </form>
    );
}