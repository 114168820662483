import React from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import { Spinner } from "react-bootstrap";
import "./ChartDirectionCounter.css";

export type Direction = "top" | "down"
interface Props {
  title: string
  basedOn: number | null
  counter?: number | null
}

const ChartDirectionCounter: React.FC<Props> = ({ basedOn, counter, title }) => {

  const directionTextClassMapping = {
    "top": 'text-success',
    "down": 'text-danger'
  }

  const directionIconPathMapping = {
    "top": '/media/icons/duotune/arrows/arr081.svg',
    "down": '/media/icons/duotune/arrows/arr082.svg'
  }

  const direction: Direction = basedOn != null && counter ? (basedOn >= counter ? "top" : "down") : "top"

  return (
    <div className={`${directionTextClassMapping[direction]} d-flex flex-row align-content-center justify-content-center gap-2 chart-direction-counter`}>
      <KTSVG className={`${directionTextClassMapping[direction]}`} path={directionIconPathMapping[direction]} />
      <strong className={`${directionTextClassMapping[direction]} h3`}>{counter !== null ? new Intl.NumberFormat('en-EN').format(counter as number) : <Spinner animation={'border'} />}</strong>
      <span>{title}</span>
    </div>

  )
}

ChartDirectionCounter.defaultProps = {
  basedOn: null,
  counter: null
}

export default ChartDirectionCounter
