import ConfigRoutes from 'app/routing/ConfigRoutes';
import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useDataReport } from '../../DataReportProvider';
import EngagementElearning from './tabs/Elearning';
import EngagementNewsletter from './tabs/Newsletter';
import EngagementPodcast from './tabs/Podcast';
import EngagementSlides from './tabs/Slides';
import EngagementSocialMedia from './tabs/SocialMedia';
import EngagementVideo from './tabs/Video';
import EngagementVirtualMeeting from './tabs/VirtualMeeting';
import EngagementWebsite from './tabs/Website';


const EngagementWrapper: FC = () => {

    return (
        <>
            <Routes>

                <Route path={ConfigRoutes.admin.dataReport.engagement.website.path} element={<EngagementWebsite />} />

                <Route path={ConfigRoutes.admin.dataReport.engagement.newsletter.path} element={<EngagementNewsletter />} />

                <Route path={ConfigRoutes.admin.dataReport.engagement.socialMedia.path} element={<EngagementSocialMedia />} />

                <Route path={ConfigRoutes.admin.dataReport.engagement.podcast.path} element={<EngagementPodcast />} />

                <Route path={ConfigRoutes.admin.dataReport.engagement.video.path} element={<EngagementVideo />} />

                <Route path={ConfigRoutes.admin.dataReport.engagement.slides.path} element={<EngagementSlides />} />

                <Route path={ConfigRoutes.admin.dataReport.engagement.elearning.path} element={<EngagementElearning />} />

                <Route path={ConfigRoutes.admin.dataReport.engagement.virtualMeeting.path} element={<EngagementVirtualMeeting />} />

                <Route index element={<Navigate to={ConfigRoutes.admin.dataReport.engagement.website.path} />} />

            </Routes>



        </>

    )
}

export { EngagementWrapper }
