import { RestServiceConnection } from "../RestServiceConnection";


export class ProgrammeService extends RestServiceConnection {
    
    search = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/programme/search',
            data: filter
        }, true);
        return this;
    }

    searchProjectCode = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/programme/search/project_code',
            data: filter
        }, true);
        return this;
    }

    programmeWaves = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/programme/waves',
            data: filter
        }, true);
        return this;
    }

    searchDatesBetweenProgrammes = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/programme/search/between_dates',
            data: filter
        }, true);
        return this;
    }

}