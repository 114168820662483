import {TablesWidget9} from "../../../_metronic/partials/widgets";
import React, {useEffect, useState} from "react";
import {Badge, Tooltip} from "react-bootstrap";
import {CampaignManagerService} from "../../services/campaign_manager/CampaignManagerService";
import {PageTitle, useLayout} from "../../../_metronic/layout/core";
import {KTSVG} from "../../../_metronic/helpers";
import CreateCampaignManagementModal from "./CreateCampaignManagementModal";
import moment from "moment";
import toastr from 'toastr'

const MetricManager = () => {

    const [campaigns, setCampaigns] = useState<any[]>([]);

    const [pageCount, setPageCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({});
    const [order, setOrder] = useState({});

    const layout = useLayout();

    const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)

    const breadcrumbs = [
        {
            title: 'Administration',
            path: '#',
            isSeparator: false,
            isActive: false,
        },
        {
            title: 'Campaign Manager',
            path: 'admin/campaigns',
            isSeparator: true,
            isActive: true,
        },
    ]


    const _fetchData = async () => {
        const response =  (await (new CampaignManagerService()).list({
            page: currentPage,
            pageSize: pageSize,
            sort: order,
            filter_filters: {
                ...filters
            }
        })).getResponseData();

        if(!response.success) return;

        setCampaigns(response.data.data);
    }

    const _renderActionButtons = () => {
        layout.setToolbarButtons([
            (
                <div className='me-4' key={"toolbar-filter-button"}>
                    <button
                        type="button"
                        className={`btn btn-sm btn-flex btn-primary btn-active-primary fw-bolder`}
                        //id='kt_drawer_chat_toggle'
                        onClick={() => {
                            setOpenCreateModal(true)
                        }}
                    >
                        Create
                    </button>
                </div>
            )
        ]);
    }

    useEffect(() => {
        _fetchData();
        _renderActionButtons()
    }, [filters]);

    const _handleSearch = (value: string) => {
        setFilters({search: value})

    }

    const _handlePagination = (page: number) => {

    }

    const _handlePerPage = (value: number) => {

    }

    const _deleteCampaignManager = async (id: number) => {

        let confirmed = await layout.confirmAction({ title: "Delete campaign conditions", description: "Are you sure you want delete this campaign condition?" });

        if (confirmed) {
            const response = (await (new CampaignManagerService()).delete(id)).getResponseData();

            if(!response.success) return;
            
            _fetchData();

            toastr.success("Sucessfuly deleted");
        }

    }

    return (
        <>
            <PageTitle breadcrumbs={breadcrumbs}>Campaign Conditions</PageTitle>
            <TablesWidget9 className="card-primary" title="Campaign Conditions"
                           subtitle="List of Cognito users accesing this application"
                           searchInput={{ placeholder: "Search...", onSearch: _handleSearch }}
                           data={campaigns}
                           pagination={true}
                           paginationData={{
                               pageSize: pageSize, pageCount: pageCount, currentPage: currentPage,
                               handlePagination: _handlePagination, handlePerPage: _handlePerPage
                           }}
                           columns={[
                               {
                                   name: "Code Pattern", sortable: true, keyValue: 'codeMatch', className: "min-w-150px",
                                   sortColumn: (keyValue: string, order: "asc" | "desc") => {
                                       //getUsers(currentPage, pageSize, { field: keyValue, order: order });
                                   },
                               },
                               {
                                    name: "Description", sortable: false, keyValue: 'description', className: "min-w-150px",
                                },
                               {
                                   name: "Connects", sortable: false, keyValue: 'connects', className: "min-w-150px",
                                   render(element: any): JSX.Element {
                                       return (
                                           <>
                                               {
                                                   JSON.parse(element.connects).map((connect: any) => {
                                                       return (
                                                           <Badge key={element.id + "-" + connect.id} color={'primary'} className="mx-1">
                                                               {connect.name}
                                                           </Badge>
                                                       )
                                                   })
                                               }
                                           </>
                                       )
                                   }
                               },
                               {
                                name: "Created", sortable: true, keyValue: 'connects', className: "min-w-150px",
                                sortColumn: (keyValue: string, order: "asc" | "desc") => {
                                    setOrder({
                                        field: keyValue,
                                        ordeR: order
                                    })
                                },
                                render(element: any): JSX.Element {
                                    return (
                                        <>
                                            {
                                                moment(element.createdAt.date).format('DD/MM/YYY HH:mm')
                                            }
                                        </>
                                    )
                                }
                            },
                               { name: "Actions", className: "min-w-100px text-end", isActionCell: true }
                           ]}
                           actions={[
                               {
                                   title: "Delete",
                                   buttonType: "normal",
                                   iconPath: "/media/icons/duotune/general/gen027.svg",
                                   description: "Remove user",
                                   additionalClasses: "btn-danger",
                                   callback: (element: any) => {
                                       _deleteCampaignManager(element.id);

                                   }
                               }
                           ]}
            ></TablesWidget9>

            <CreateCampaignManagementModal isOpen={openCreateModal} setIsOpen={(status: boolean) => {
                setOpenCreateModal(status);
                _fetchData();
            }}/>
        </>
    )
}

export default MetricManager