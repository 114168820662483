import CustomSelect from '@components/form/CustomSelect'
import React, {FC, useContext, useEffect, useState} from 'react'
import DbManagerContext from '../context/DbManagerProvider'

interface OutputSelectionProps {
  dispatch: any
  query: any
}

const OuptutSelection: FC<OutputSelectionProps> = ({dispatch, query}) => {
  const {fieldsData} = useContext(DbManagerContext)

  const [fieldOptions, setFieldOptions] = useState<any>([])

  useEffect(() => {
    const getFieldOptions = () => {
      const options = fieldsData.map((field: any) => {
        return {label: field.label, value: field.name}
      })
      setFieldOptions(options)
    }
    getFieldOptions()
  }, [fieldsData])

  return (
    <>
      <div className='row mt-10'>
        <div className='card w-100 p-0 mt-2'>
          <div className='text-primary fw-bold mt-3 ms-3 d-flex align-items-center'>
            OUTPUT METHOD
          </div>
          <div className='card-body'>
            <div className='row mt-2'>
              <div className='d-flex justify-content-center mt-3 col-4'>
                <div className='p-2 flex-fill' style={{minWidth: '200px'}}>
                  <div className='form-group'>
                    <label className='form-label fw-bold'>Select output method:</label>
                    <CustomSelect
                      key={query.output}
                      defaultValue={{
                        value: query.output,
                        label: query.output.trim().replace(/^\w/, (c: any) => c.toUpperCase()),
                      }}
                      options={[
                        // {value: 'list', label: 'List'},
                        {value: 'breakdown', label: 'Breakdown'},
                        {value: 'count', label: 'Count'},
                      ]}
                      placeholder='Select Output Type'
                      onChangeSingle={(e: any) =>
                        dispatch({type: 'UPDATE_OUTPUT_VALUE', payload: {outputValue: e.value}})
                      }
                    />
                  </div>
                </div>
              </div>
              <div className={query.output === 'breakdown' ? 'col-4 d-block' : 'd-none'}>
                <div className='d-flex justify-content-center mt-3'>
                  <div className='p-2 flex-fill' style={{minWidth: '200px'}}>
                    <div className='form-group'>
                      <label className='form-label fw-bold'>By Field:</label>
                      <CustomSelect
                        key={query.breakdown_field}
                        options={fieldOptions}
                        placeholder='Select Field'
                        is_multi={true}
                        onChange={(e: any) => {
                          let values : any = []
                          e.map((field: any) => {
                            values.push(field.value)
                          })
                          dispatch({
                            type: 'ADD_BREAKDOWN_FIELD',
                            payload: {breakdownField: values},
                          })
                        }}
                        defaultValue={
                          query.breakdown_field && query.breakdown_field.length > 0
                            ? query.breakdown_field.map((valueD: any) => ({
                                value: valueD,
                                label: valueD
                                  .trim()
                                  .replace(/_/g, ' ')
                                  .replace(/^\w/, (c: any) => c.toUpperCase()),
                              }))
                            : ''
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-4'></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OuptutSelection