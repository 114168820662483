import { Spinner } from "react-bootstrap";

interface WorkLoadComponentProps {
    evaluateElement: any | null | []
    message?: string
}
const WorkLoadComponent: React.FC<WorkLoadComponentProps> = ({ evaluateElement, message, children }) => {

    const returnValueOfEvaluateElement = (): JSX.Element => {

        if(evaluateElement === null) {
            return (
              <div className="d-flex justify-content-center p-2" >
                  <Spinner animation="border"></Spinner>
              </div>
            );
        }

        if(Array.isArray(evaluateElement) && evaluateElement.length === 0) {
            return (<div className="text-center h-100 text-muted d-flex justify-content-center align-items-center h3 pt-5 pb-2">{message && message}</div>);
        }


        return (<>{children}</>);
    }

    return (
        <>
            {
                returnValueOfEvaluateElement()
            }
        </>
    );
};

WorkLoadComponent.defaultProps = {
    message: "-"
}
export default WorkLoadComponent;