import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { PageTitle } from "../../../_metronic/layout/core"
import ConfigRoutes from "../../routing/ConfigRoutes"
import { LastUserSnapshots } from "./user_snapshots/LastSnapshots"
import { UserSnapshotList } from "./user_snapshots/UserSnapshotList"

const UserSnapshotsWrapper = () => {

    return (
        <Routes>
            <Route element={
                <>
                    <PageTitle>Your Snapshots</PageTitle>
                    <Outlet />
                </>
            }>

                <Route
                    path={ConfigRoutes.admin.user_snapshots.last_records.path} element={<LastUserSnapshots />}
                />

                <Route
                    path={ConfigRoutes.admin.user_snapshots.list.path} element={<UserSnapshotList />}
                />

                <Route index element={<Navigate to={ConfigRoutes.admin.user_snapshots.last_records.fullPath} replace />} />

            </Route>

        </Routes>

    )
}

export default UserSnapshotsWrapper