import { RestServiceConnection } from "../../RestServiceConnection";


export class ImportService extends RestServiceConnection {

    importSpotifyRemoteAccessToken = async (data: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/remote-access-token/spotify',
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }, true);
        return this;
    }

    importSpotifyRemoteAccessURL = async () => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/remote-access-token/spotify',
            data: "",
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }, true);
        return this;
    }

    importYouTubeAnalyticsRemoteAccessToken = async (data: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/remote-access-token/youtube-analytics',
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }, true);
        return this;
    }

    importYouTubeAnalyticsRemoteAccessURL = async () => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/remote-access-token/youtube-analytics',
            data: "",
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }, true);
        return this;
    }

}