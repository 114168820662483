export const metrics = {

    'websiteVisits': {
        'id': 'ga_visit',
        'title': 'Website Visits',
        'subtitle': 'The number of sessions that began on your website or application',
        'endpoint': '/collector/ga/get/visits',
        'logo': 'google.svg'
    },

    'websiteVisitors': {
        'id': 'ga_total_user',
        'title': 'Website Users',
        'subtitle': 'The total number of unique users who triggered at least one event',
        'endpoint': '/collector/ga/get/total-users',
        'logo': 'google.svg'
    },

    'websiteNewVisitors': {
        'id': 'ga_new_user',
        'title': 'Website New Visitors',
        'subtitle': 'The number of users with 0 previous sessions. Users who already have had a session are returning users',
        'endpoint': '/collector/ga/get/new-users',
        'logo': 'google.svg'
    },

    'googleAdsImpressions': {
        'id': 'ga_adds_impressions',
        'title': 'Google Ads Impressions',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'newsletterDelivered': {
        'id': 'ac_newsletter_delivered',
        'title': 'Newsletters Sent',
        'subtitle': 'Number of emails sent from AC',
        'endpoint': '/collector/ac/get/newsletters-delivered',
        'logo': 'ac.svg'
    },

    'totalMaterialDownloads': {
        'id': 'total_material_downloads',
        'title': 'Total Material Downloads',
        'subtitle': 'Total Material Downloads',
        'endpoint': '/engage/event/material/get/downloaded',
        'logo': 'engage.png'
    },

    'campaignsSent': {
        'id': 'ac_campaign_sent',
        'title': 'Campaigns sent',
        'subtitle': 'Number of campaings/waves sent out from AC',
        'endpoint': '/collector/ac/get/campaigns-sent',
        'logo': 'ac.svg'
    },

    'totalHCPs': {
        'id': 'ga_hcp_reached',
        'title': 'Total HCPs Reached Out',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'twitterImpressions': {
        'id': 'twitter_impressions',
        'title': 'Twitter Impressions',
        'subtitle': 'Twitter Impressions',
        'endpoint': '/twitter/account/get/impressions',
        'logo': 'twitter.svg'
    },

    'twitterVideoImpressions': {
        'id': 'twitter_video_impressions',
        'title': 'Twitter Video Impressions',
        'subtitle': 'Twitter Video Impressions',
        'endpoint': '/twitter/account/get/video/impressions',
        'logo': 'twitter.svg'
    },

    'twitterFollowers': {
        'id': 'twitter_followers',
        'title': 'Twitter Followers',
        'subtitle': 'Twitter Followers',
        'endpoint': '/twitter/account/get/followers',
        'logo': 'twitter.svg'
    },

    'linkedinImpressions': {
        'id': 'linkedin_impressions',
        'title': 'LinkedIn Impressions',
        'subtitle': 'Linkedin Impressions',
        'endpoint': '/linkedin/account/get/impressions',
        'logo': 'linkedin-1.svg'
    },

    'linkedinUniqueImpressions': {
        'id': 'unique_linkedin_impressions',
        'title': 'Linkedin Unique Impressions',
        'subtitle': 'Linkedin Unique Impressions',
        'endpoint': '/linkedin/account/get/impressions/unique',
        'logo': 'linkedin-1.svg'
    },

    'linkedinFollowers': {
        'id': 'linkedin_followers',
        'title': 'LinkedIn Followers',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'videoImpressions': {
        'id': 'video_impressions',
        'title': 'Video Impressions',
        'subtitle': 'Video Impressions',
        'endpoint': '/vimeo/video/stat/get/impresed',
        'logo': 'vimeo.svg'
    },

    'videoUniqueImpressions': {
        'id': 'unique_video_impressions',
        'title': 'Video Unique Impressions',
        'subtitle': 'Video Unique Impressions',
        'endpoint': '/vimeo/video/stat/get/impresed/unique',
        'logo': 'vimeo.svg'
    },

    'returningVisits': {
        'id': 'ga_returning_visit',
        'title': 'Returning Visits',
        'subtitle': 'Returning Visits',
        'endpoint': '/collector/ga/get/returning-visits',
        'logo': 'google.svg'
    },

    'returningVisitors': {
        'id': 'ga_returning_user',
        'title': 'Returning Visitors',
        'subtitle': 'The number of users who have initiated at least one previous session, regardless of whether or not the previous sessions were engaged sessions.',
        'endpoint': '/collector/ga/get/returning-users',
        'logo': 'google.svg'
    },

    'webpageViews': {
        'id': 'ga_page_view',
        'title': 'WebPage Views',
        'subtitle': 'Total number of app screens and/or web pages your users saw. Repeated views of a single screen or page are counted. (The Views metric found in the reporting interface is the combination of pageviews and screenviews.)',
        'endpoint': '/collector/ga/get/page-views',
        'logo': 'google.svg'
    },

    'avgSessionDurationByUsers': {
        'id': 'ga_session_duration_user',
        'title': 'AVG Session Duration by Users',
        'subtitle': 'Avg. Session Duration in Seconds',
        'endpoint': '/collector/ga/get/session-duration',
        'logo': 'google.svg'
    },

    'engagedSessions': {
        'id': 'ga_engaged_sessions',
        'title': 'Engaged Sessions',
        'subtitle': 'Engaged Sessions',
        'endpoint': '/collector/ga/get/engaged-sessions',
        'logo': 'google.svg'
    },

    'engagementRate': {
        'id': 'ga_engaged_rate',
        'title': 'Engagement Rate',
        'subtitle': 'Engagement Rate',
        'endpoint': '/collector/ga/get/engaged-rate',
        'logo': 'google.svg'
    },

    'googleAdsClicks': {
        'id': 'ga_ads_clicks',
        'title': 'Google Ads Clicks',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'googleAdsClickRate': {
        'id': 'ga_ads_ctr',
        'title': 'Google Ads % Click-Through Rate',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'searchesNumberGA': {
        'id': 'ga_searches',
        'title': 'Number of Searches GA4',
        'subtitle': 'Number of Searches',
        'endpoint': '/collector/ga/get/searches',
        'logo': 'google.svg'
    },

    'searchesNumberCMS': {
        'id': 'cms_searches',
        'title': 'Number of searches CMS',
        'subtitle': 'Number of Searches',
        'endpoint': '/search/get',
        'logo': 'cor2ed.png'
    },

    'mostSearchedTerms': {
        'id': 'ga_most_searched',
        'title': 'Most Searched Terms',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'totalEmailOpens': {
        'id': 'ac_total_open_list',
        'title': 'Total Email Opens',
        'subtitle': 'Total Email Opens',
        'endpoint': '/collector/ac/get/opens',
        'logo': 'ac.svg'
    },

    'uniqueEmailOpens': {
        'id': 'ac_unique_open_list',
        'title': 'Unique Email Opens',
        'subtitle': 'Unique Email Opens',
        'endpoint': '/collector/ac/get/unique-opens',
        'logo': 'ac.svg'
    },

    'totalLinkClicks': {
        'id': 'ac_total_clicks',
        'title': 'Total Link Clicks',
        'subtitle': 'Total Link Clicks',
        'endpoint': '/collector/ac/get/total-clicks',
        'logo': 'ac.svg'
    },

    'uniqueLinkClicks': {
        'id': 'ac_unique_click',
        'title': 'Unique Link Clicks',
        'subtitle': 'Unique Link Clicks',
        'endpoint': '/collector/ac/get/unique-clicks',
        'logo': 'ac.svg'
    },

    'uniqueLinkClickRate': {
        'id': 'ac_unique_click_rate',
        'title': 'Unique Link Click Rate',
        'subtitle': 'Unique Link Click Rate',
        'endpoint': '/collector/ac/get/unique-click-rate',
        'logo': 'ac.svg'
    },

    'clickToOpenRate': {
        'id': 'ac_click_rate',
        'title': 'Click to Open Rate',
        'subtitle': 'Click to Open Rate',
        'endpoint': '/collector/ac/get/click-to-open-rate',
        'logo': 'ac.svg'
    },

    'twitterLikes': {
        'id': 'twitter_likes',
        'title': 'Twitter Likes',
        'subtitle': 'Twitter Likes',
        'endpoint': '/twitter/account/get/likes',
        'logo': 'twitter.svg'
    },

    'averageLinkedinSocialEngagementRate': {
        'id': 'average_linkedin_social_engagementRate',
        'title': 'Avg.% Linkedin Social Engagement Rate',
        'subtitle': 'Avg.% Linkedin Social Engagement Rate',
        'endpoint': '/linkedin/account/get/engagement-rate',
        'logo': 'linkedin-1.svg'
    },
    'linkedinLinkClick': {
        'id': 'linkedin_link_click',
        'title': 'Linkedin Link Clicks',
        'subtitle': 'Linkedin Link Clicks',
        'endpoint': '/linkedin/account/get/clicks',
        'logo': 'linkedin-1.svg'
    },
    'linkedinComments': {
        'id': 'linkedin_comments',
        'title': 'Linkedin Comments',
        'subtitle': 'Linkedin Comments',
        'endpoint': '/linkedin/account/get/comments',
        'logo': 'linkedin-1.svg'
    },
    
    'twitterEngagements': {
        'id': 'twitter_engagements',
        'title': 'Twitter Engagements',
        'subtitle': 'Twitter Engagements',
        'endpoint': '/twitter/account/get/engagements',
        'logo': 'twitter.svg'
    },

    'twitterShares': {
        'id': 'twitter_retweets',
        'title': 'Twitter Shares/Retweets',
        'subtitle': 'Twitter Shares/Retweets',
        'endpoint': '/twitter/account/get/retweets',
        'logo': 'twitter.svg'
    },

    'linkedinLikes': {
        'id': 'linkedin_likes',
        'title': 'Linkedin Likes',
        'subtitle': 'Linkedin Likes',
        'endpoint': '/linkedin/account/get/likes',
        'logo': 'linkedin-1.svg'
    },

    'linkedinShares': {
        'id': 'linkedin_shares',
        'title': 'Linkedin Shares',
        'subtitle': 'Linkedin Shares',
        'endpoint': '/linkedin/account/get/shares',
        'logo': 'linkedin-1.svg'
    },

    'twitterSocialEngagementRate': {
        'id': 'twitter_social_engagement_rate',
        'title': 'Avg.% Twitter Social Engagement Rate',
        'subtitle': 'Twitter Social Engagement Rate',
        'endpoint': '/twitter/account/get/engagement-rate',
        'logo': 'twitter.svg'
    },
    'twitterVideoViews75': {
        'id': 'twitter_video_views',
        'title': 'Twitter Video Views 75%',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'twitterLinkClicks': {
        'id': 'twitter_link_clicks',
        'title': 'Twitter Link Clicks',
        'subtitle': 'Twitter Link Clicks',
        'endpoint': '/twitter/account/get/link/clicks',
        'logo': 'twitter.svg'
    },

    'totalPodcastListensCastos': {
        'id': 'castos_total_listens',
        'title': 'Total Podcast Listens - Castos',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'uniquePodcastListensCastos': {
        'id': 'castos_unique_listens',
        'title': 'Unique Podcast Listens - Castos',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'avgPodcastListenedCastos': {
        'id': 'castos_avg_listens',
        'title': 'Avg % Podcast Listened - Castos',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'totalPodcastListensSpotify': {
        'id': 'spotify_total_listens',
        'title': 'Total Podcast Listens - Spotify',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'uniquePodcastListensSpotify': {
        'id': 'spotify_unique_listens',
        'title': 'Unique Podcast Listens - Spotify',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'avgPodcastListenedSpotify': {
        'id': 'spotify_avg_listens',
        'title': 'Avg % Podcast Listened - Spotify',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'totalPodcastListensGooglePodcasts': {
        'id': 'google_podcast_total_listens',
        'title': 'Total Podcast Listens - Google Podcasts',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'uniquePodcastListensGooglePodcasts': {
        'id': 'google_podcast_unique_listens',
        'title': 'Unique Podcast Listens - Google Podcasts',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'avgPodcastListenedGooglePodcasts': {
        'id': 'google_podcast_avg_listens',
        'title': 'Avg % Podcast Listened - Google Podcasts',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'totalPodcastListensApplePodcasts': {
        'id': 'apple_podcast_total_listens',
        'title': 'Total Podcast Listens - Apple Podcasts',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'uniquePodcastListensApplePodcasts': {
        'id': 'apple_podcast_unique_listens',
        'title': 'Unique Podcast Listens - Apple Podcasts',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'avgPodcastListenedApplePodcasts': {
        'id': 'apple_podcast_avg_listens',
        'title': 'Avg % Podcast Listened - Apple Podcasts',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'totalPodcastSubscribe': {
        'id': 'total_podcast_subscribe',
        'title': 'Total Podcast Subscribe',
        'subtitle': 'Total Podcast Subscribe',
        'endpoint': '/collector/ga/get/podcast-subscribe',
        'logo': 'google.svg'
    },

    'totalPodcastListen': {
        'id': 'total_podcast_subscribe',
        'title': 'Total Listens - Castos',
        'subtitle': 'Total Podcast Listen',
        'endpoint': '/castos/listener/get/listens',
        'logo': 'microfono.svg'
    },

    'uniquePodcastsSubscribe': {
        'id': 'unique_podcast_subscribe',
        'title': 'Unique Podcast Subscribe',
        'subtitle': 'Unique Number of clicks on any of the subscribe to our Google Podcast, Spotify, Apple Podcast icons from landing page',
        'endpoint': '/collector/ga/get/unique-podcast-subscribe',
        'logo': 'google.svg'
    },

    'totalVideoViewsYouTube': {
        'id': 'youtube_total_video_views',
        'title': 'Total Video Views - YouTube',
        'subtitle': 'Total Video Views - YouTube',
        'endpoint': '/youtube/video/statistic/get/viewed',
        'logo': 'youtube.svg'
    },
    
    'totalStartsSpotify': {
        'id': 'total_starts_spotify',
        'title': 'Total Starts - Spotify',
        'subtitle': 'Total Starts - Spotify',
        'endpoint': '/spotify/listener/get/starts',
        'logo': 'spotify-2.svg'
    },

    'totalStreamsSpotify': {
        'id': 'total_streams_spotify',
        'title': 'Total Streams - Spotify',
        'subtitle': 'Total Streams - Spotify',
        'endpoint': '/spotify/listener/get/streams',
        'logo': 'spotify-2.svg'
    },
    
    'totalListensSpotify': {
        'id': 'total_listens_spotify',
        'title': 'Total Listens - Spotify',
        'subtitle': 'Total Listens - Spotify',
        'endpoint': '/spotify/listener/get/listens',
        'logo': 'spotify-2.svg'
    },
    'totalVideoCommentsYouTube': {
        'id': 'youtube_total_video_comments',
        'title': 'Total Video Comments - YouTube',
        'subtitle': 'Total Video Comments - YouTube',
        'endpoint': '/youtube/video/statistic/get/commented',
        'logo': 'youtube.svg'
    },

    'totalVideoViewsGA': {
        'id': 'ga_total_video_views',
        'title': 'Total Video Views - GA',
        'subtitle': 'Total Video Views - GA',
        'endpoint': '/collector/ga/get/video-viewed',
        'logo': 'google.svg'
    },

    'totalVideoFavoritesYouTube': {
        'id': 'youtube_total_video_favorites',
        'title': 'Total Video Favorites - YouTube',
        'subtitle': 'Total Video Favorites - YouTube',
        'endpoint': '/youtube/video/statistic/get/favorites',
        'logo': 'youtube.svg'
    },

    'uniqueVideoViewsGA': {
        'id': 'ga_unique_video_views',
        'title': 'Unique Video Views - GA',
        'subtitle': 'Unique Video Views - Google Analytics',
        'endpoint': '/collector/ga/get/unique-video-viewed',
        'logo': 'google.svg'
    },

    'totalVideoViewsVimeo': {
        'id': 'vimeo_total_video_views',
        'title': 'Total Video Views - Vimeo',
        'subtitle': 'Total Video Views - Vimeo',
        'endpoint': '/vimeo/video/stat/get/viewed',
        'logo': 'vimeo.svg'
    },

    'totalVideoLikesYouTube': {
        'id': 'youtube_total_video_likes',
        'title': 'Total Video Likes - YouTube',
        'subtitle': 'Total Video Likes - YouTube',
        'endpoint': '/youtube/video/statistic/get/liked',
        'logo': 'youtube.svg'
    },

    'uniqueVideoViewsVimeo': {
        'id': 'vimeo_unique_video_views',
        'title': 'Unique Video Views - Vimeo',
        'subtitle': 'Unique Video Views - Vimeo',
        'endpoint': '/vimeo/video/stat/get/viewed/unique',
        'logo': 'vimeo.svg'
    },

    'totalUniqueVideoViewsGT': {
        'id': 'total_unique_video_views',
        'title': 'Total Unique Video Views (Google + Twitter)',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'google.svg'
    },

    'avgVideoWatchedVimeo': {
        'id': 'vimeo_avg_video',
        'title': 'Avg % Video Watched - Vimeo',
        'subtitle': 'Indicates the percentage of video viewed',
        'endpoint': '/vimeo/video/stat/get/viewed/mean/percent',
        'logo': 'vimeo.svg'
    },

    'videoProgress10Youtube': {
        'id': 'youtube_video_progress_10',
        'title': 'Video Progress (10%) - YouTube',
        'subtitle': 'Video Progress (10%) - YouTube',
        'endpoint': '/youtube/video/statistic/get/playback/10',
        'logo': 'youtube.svg'
    },

    'videoProgress25Youtube': {
        'id': 'youtube_video_progress_25',
        'title': 'Video Progress (25%) - YouTube',
        'subtitle': 'Video Progress (25%) - YouTube',
        'endpoint': '/youtube/video/statistic/get/playback/25',
        'logo': 'youtube.svg'
    },

    'videoProgress50Youtube': {
        'id': 'youtube_video_progress_50',
        'title': 'Video Progress (50%) - YouTube',
        'subtitle': 'Video Progress (50%) - YouTube',
        'endpoint': '/youtube/video/statistic/get/playback/50',
        'logo': 'youtube.svg'
    },

    'videoProgress75Youtube': {
        'id': 'youtube_video_progress_75',
        'title': 'Video Progress (75%) - YouTube',
        'subtitle': 'Video Progress (75%) - YouTube',
        'endpoint': '/youtube/video/statistic/get/playback/75',
        'logo': 'youtube.svg'
    },

    'videoProgress10Vimeo': {
        'id': 'vimeo_video_progress_10',
        'title': 'Video Progress (10%)',
        'subtitle': 'Video Progress (10%)',
        'endpoint': '/collector/ga/get/video-progress',
        'logo': 'google.svg'
    },

    'videoProgress25Vimeo': {
        'id': 'vimeo_video_progress_25',
        'title': 'Video Progress (25%)',
        'subtitle': 'Video Progress (25%)',
        'endpoint': '/collector/ga/get/video-progress',
        'logo': 'google.svg'
    },

    'videoProgress50Vimeo': {
        'id': 'vimeo_video_progress_50',
        'title': 'Video Progress (50%)',
        'subtitle': 'Video Progress (50%)',
        'endpoint': '/collector/ga/get/video-progress',
        'logo': 'google.svg'
    },

    'videoProgress75Vimeo': {
        'id': 'vimeo_video_progress_75',
        'title': 'Video Progress (75%)',
        'subtitle': 'Video Progress (75%)',
        'endpoint': '/collector/ga/get/video-progress',
        'logo': 'google.svg'
    },

    'videoCompletionsGA': {
        'id': 'ga_video_completed',
        'title': 'Video Completions - GA',
        'subtitle': 'Video Completions - Google Analytics',
        'endpoint': '/collector/ga/get/video-finished',
        'logo': 'google.svg'
    },

    'uniqueVideoCompletionsGA': {
        'id': 'ga_unique_completed',
        'title': 'Unique Video Completions - GA',
        'subtitle': 'Unique Video Completions',
        'endpoint': '/collector/ga/get/unique-video-finished',
        'logo': 'google.svg'
    },

    'videoCompletionsVimeo': {
        'id': 'vimeo_video_completed',
        'title': 'Video Completions - Vimeo',
        'subtitle': 'Total number of video completed provided by Vimeo',
        'endpoint': '/vimeo/video/stat/get/completed',
        'logo': 'vimeo.svg'
    },

    'uniqueVideoCompletionsVimeo': {
        'id': 'vimeo_unique_completed',
        'title': 'Unique Video Completions - Vimeo',
        'subtitle': 'Unique Video Completions',
        'endpoint': '/vimeo/video/stat/get/completed-unique',
        'logo': 'vimeo.svg'
    },

    'usersRegistered': {
        'id': 'user_registered',
        'title': 'Users Registered',
        'subtitle': 'Learners Registered',
        'endpoint': '/engage/subscriber/get/registered',
        'logo': 'engage.png'
    },

    'totalEnrolments': {
        'id': 'total_enrolments',
        'title': 'Total Enrolments',
        'subtitle': 'CheckPoint Enrolments',
        'endpoint': '/checkpoint/enrol/get/registered',
        'logo': 'checkpoint.svg'
    },

    'eLearningCoursesCompleted': {
        'id': 'courses_completed',
        'title': 'E-Learning Courses Completed',
        'subtitle': 'Courses completed',
        'endpoint': '/checkpoint/enrol/get/completed',
        'logo': 'checkpoint.svg'
    },

    'totalIssuedCertificates': {
        'id': 'issued_certificates',
        'title': 'Total Issued Certificates',
        'subtitle': 'CheckPoint Issued Certificates',
        'endpoint': '/checkpoint/certificate/get/issued',
        'logo': 'checkpoint.svg'
    },

    'totalFeedbackCompleted': {
        'id': 'total_feedback_completed',
        'title': 'Total Feedback Completed - 4 Questions - CMS',
        'subtitle': 'Total Feedback Completed',
        'endpoint': '/feedback/all/get/completed',
        'logo': 'cor2ed.png'
    },

    'uniqueFeedbackCompleted': {
        'id': 'unique_feedback_completed',
        'title': 'Unique Feedback Completed - 4 Questions - CMS',
        'subtitle': 'Unique Feedback Completed',
        'endpoint': '/feedback/all/get/completed/unique',
        'logo': 'cor2ed.png'
    },

    'eLearningFeedbackCompleted': {
        'id': 'feedback_completed',
        'title': 'E-Learning Feedback Completed',
        'subtitle': '[SAMPLE DATA]',
        'endpoint': '',
        'logo': 'cor2ed.png'
    },

    'totalSlideDownloads': {
        'id': 'ga_slide_download',
        'title': 'Total Slide Downloads',
        'subtitle': 'Total number of files downloaded from landing pages',
        'endpoint': '/collector/ga/get/slide-download',
        'logo': 'google.svg'
    },

    'uniqueSlideDownloads': {
        'id': 'ga_unique_slide_download',
        'title': 'Unique Slide Downloads',
        'subtitle': 'Unique number of files downloaded from landing pages',
        'endpoint': '/collector/ga/get/unique-slide-download',
        'logo': 'google.svg'
    },

    'feedbackCompletedGA': {
        'id': 'ga_feedback_completed',
        'title': 'Total Feedback Completed - 4 Questions - GA',
        'subtitle': 'Total Feedback Completed',
        'endpoint': '/collector/ga/get/feedback-submitted',
        'logo': 'google.svg'
    },

    'uniqueFeedbackCompletedGA': {
        'id': 'ga_unique_feedback_completed',
        'title': 'Unique Feedback Completed - 4 Questions - GA',
        'subtitle': 'Unique Feedback Completed',
        'endpoint': '/collector/ga/get/unique-feedback-submitted',
        'logo': 'google.svg'
    },

    'avgBounceRate': {
        'id': 'ac_campaign_bounce_rate',
        'title': 'Newsletter AVG Bounce Rate',
        'subtitle': 'Avg Email Bounce Rate from campaigns',
        'endpoint': '/collector/ac/get/campaigns-avg-bounce-rate',
        'logo': 'ac.svg'
    },

    'bounceRatePercentage': {
        'id': 'ga_bounce_rate',
        'title': '% Bounce Rate',
        'subtitle': 'The percentage of single-page sessions in which there was no interaction with the page. A bounced session has a duration of 0 seconds',
        'endpoint': '/collector/ga/get/bounce-rate',
        'logo': 'google.svg'
    },

    'uniqueEmailOpenRate': {
        'id': 'ac_open_rate',
        'title': 'Unique Email Open Rate',
        'subtitle': 'Unique Email Open Rate',
        'endpoint': '/collector/ac/get/open-rate',
        'logo': 'ac.svg'
    },

    'valuableRespondents': {
        'id': 'cms_valuable_respondent',
        'title': 'Valuable - N of respondents',
        'subtitle': 'How many people answered to question #1',
        'endpoint': '/feedback/all/get/question/1/answered',
        'logo': 'cor2ed.png'
    },

    'valuableAgreements': {
        'id': 'cms_valuable_agreement',
        'title': 'Valuable - N of agreements',
        'subtitle': 'How many answers have 4 or 5 stars',
        'endpoint': '/feedback/all/get/question/1/agreement/submited',
        'logo': 'cor2ed.png'
    },

    'valuableAgreementsPercentage': {
        'id': 'cms_valuable_agreement_percent',
        'title': 'Valuable - % agreement',
        'subtitle': 'agreements/respondents',
        'endpoint': '/feedback/all/get/question/1/agreement/submited/percent',
        'logo': 'cor2ed.png'
    },

    'improvingRespondent': {
        'id': 'cms_knowledge_respondent',
        'title': 'Knowledge improving - N of respondents',
        'subtitle': 'How many people answered to question #2',
        'endpoint': '/feedback/all/get/question/2/answered',
        'logo': 'cor2ed.png'
    },

    'improvingAgreements': {
        'id': 'cms_knowledge_agreement',
        'title': 'Knowledge improving - N of agreements',
        'subtitle': 'How many answers have 4 or 5 stars',
        'endpoint': '/feedback/all/get/question/2/agreement/submited',
        'logo': 'cor2ed.png'
    },

    'improvingAgreementsPercentage': {
        'id': 'cms_knowledge_agreement_percent',
        'title': 'Knowledge improving - % agreement',
        'subtitle': 'agreements/respondents',
        'endpoint': '/feedback/all/get/question/2/agreement/submited/percent',
        'logo': 'cor2ed.png'
    },

    'changingRespondent': {
        'id': 'cms_practice_respondent',
        'title': 'Practice changing - N of respondents',
        'subtitle': 'How many people answered to question #3',
        'endpoint': '/feedback/all/get/question/3/answered',
        'logo': 'cor2ed.png'
    },

    'changingAgreements': {
        'id': 'cms_practice_agreement',
        'title': 'Practice changing - N of agreements',
        'subtitle': 'How many answers have 4 or 5 stars',
        'endpoint': '/feedback/all/get/question/3/agreement/submited',
        'logo': 'cor2ed.png'
    },

    'changingAgreementsPercentage': {
        'id': 'cms_practice_agreement_percent',
        'title': 'Practice changing - % agreement',
        'subtitle': 'agreements/respondents',
        'endpoint': '/feedback/all/get/question/3/agreement/submited/percent',
        'logo': 'cor2ed.png'
    },

    'balancedRespondent': {
        'id': 'cms_fair_respondent',
        'title': 'Fair and balanced - N of respondents',
        'subtitle': 'How many people answered to question #4',
        'endpoint': '/feedback/all/get/question/4/answered',
        'logo': 'cor2ed.png'
    },

    'balancedAgreements': {
        'id': 'cms_fair_agreement',
        'title': 'Fair and balanced - N of agreements',
        'subtitle': 'How many answers have 4 or 5 stars',
        'endpoint': '/feedback/all/get/question/4/agreement/submited',
        'logo': 'cor2ed.png'
    },

    'balancedAgreementsPercentage': {
        'id': 'cms_fair_agreement_percent',
        'title': 'Fair and balanced - % agreement',
        'subtitle': 'agreements/respondents',
        'endpoint': '/feedback/all/get/question/4/agreement/submited/percent',
        'logo': 'cor2ed.png'
    },

}