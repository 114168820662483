/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import ConfigRoutes from '../../../routing/ConfigRoutes'
import { KTSVG } from '../../../../_metronic/helpers';
import CustomSelect from '@components/form/CustomSelect';
import Sticky from 'react-sticky-el'
import useProgrammeExplorerHeader from '../hooks/ProgrammeExplorerHeaderHook';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CustomDateRangePicker } from '@components/form/CustomDateRangePicker';
import moment from 'moment';

const ProgrammeExplorerHeader: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showProgrammeInfo, setShowProgrammeInfo] = useState<boolean>(false);

  const {
    isOpened,
    onChangeProgrammeProjectCode,
    getProgrammeValues,
    onChangeProgrammes,
    selectedProjectCode,
    programmes,
    betweenDates,
    projectCodes,
    waveOptions,
    applyFilters,
    onChangeWaves,
    onFixedToggle,
    downloadFileCache,
    statusDownload, 
    target, 
    onChangeTarget,
    targetOptions, 
    loading,
    onChangeDate,
    setIsOpened,
    connects,
    onChangeConnects,
    groupNames,
    programmeMedias,
    onChangeGroups,
    onChangeProgrammeMedias
  } = useProgrammeExplorerHeader();

  return (

    <>
      <Sticky className='dashboard-header' mode='top' topOffset={0} stickyClassName="sticky sticky-header" onFixedToggle={onFixedToggle}>
      <div className='card mb-0 shadow-sm'>
        <div className='card-body p-1'>
          <div className='d-flex flex-wrap justify-content-start'>
              <div className='p-2 flex-fill' style={{ minWidth: '250px' }}>
                <div className='form-group'>
                  <CustomSelect options={targetOptions} onChangeSingle={onChangeTarget} placeholder="Select target..." />
                </div>
              </div>
              {target === 'connect' && (
                <div className='p-2 flex-fill'>
                    <div className='form-group' id={'connect'}>
                        <CustomSelect options={connects} is_multi={true}  placeholder="Select Connects" onChange={onChangeConnects} key={'connect'}/>
                    </div>
                </div>
              )}
              {target === 'project' && (
                <>
                  <div className='p-2 flex-fill'>
                    <div className='form-group'>
                      <CustomSelect options={projectCodes} onChangeSingle={onChangeProgrammeProjectCode} placeholder="Select project code" />
                    </div>
                  </div>
                  {programmes.length > 0 ? (
                    <div className='d-flex flex-fill justify-content-start ' >
                      <div className='p-2 flex-fill' style={{ minWidth: '250px' , maxWidth: 'inherit' }}>
                        <div className='form-group'>
                          <CustomSelect options={getProgrammeValues()} is_multi={true} placeholder="Select programme(s)" onChange={onChangeProgrammes} />
                        </div>
                      </div>
                    </div>
                  ) : <></>}
                  {waveOptions.length > 0 ? (
                    <div className='p-2 flex-fill' style={{ minWidth: '250px' }}>
                        <div className='form-group'>
                          <CustomSelect options={waveOptions} is_multi={true} placeholder="All waves" onChange={onChangeWaves}></CustomSelect>
                        </div>
                    </div>
                  ) : <></>}
                </>
              )}
              {target === 'group' && (
                <>
                  <div className='p-2 flex-fill'>
                    <div className='form-group'>
                      <CustomSelect options={projectCodes} onChangeSingle={onChangeProgrammeProjectCode} placeholder="Select project code" />
                    </div>
                  </div>
                  {groupNames.length > 0 ? (
                    <div className='d-flex flex-fill justify-content-start ' >
                      <div className='p-2 flex-fill' style={{ minWidth: '250px' , maxWidth: 'inherit' }}>
                        <div className='form-group'>
                          <CustomSelect options={groupNames} is_multi={true} placeholder="Select group" onChange={onChangeGroups} />
                        </div>
                      </div>
                    </div>
                  ) : <></>}
                  {programmeMedias.length > 0 ? (
                    <div className='p-2 flex-fill' style={{ minWidth: '250px' }}>
                        <div className='form-group'>
                          <CustomSelect options={programmeMedias} is_multi={true} placeholder="Select media" onChange={onChangeProgrammeMedias}></CustomSelect>
                        </div>
                    </div>
                  ) : <></>}
                </>
              )}
              <div className='p-2 flex-fill' key={betweenDates?.from + '-' + betweenDates?.to}>
                <div className='form-group'>
                  <CustomDateRangePicker onChangeDate={onChangeDate} defaultSelection="custom" customSelection={{
                    startDate: betweenDates?.from ? moment(betweenDates?.from).toDate() : moment().startOf('month').toDate(),
                    endDate: betweenDates?.to ? moment(betweenDates?.to).toDate() : moment().endOf('day').toDate()
                  }} setIsOpen={setIsOpened}/>
                  </div>
              </div>
              <div className='p-2 d-flex justify-content-end'>
                <button className='btn btn-sm btn-apply' disabled={(isOpened) || (loading) || (!betweenDates) || (!betweenDates && (target == undefined || target == null || target == '')) && true} onClick={() => {
                  applyFilters()
                  setShowProgrammeInfo(true);
                }}>Apply</button>
              </div>
          </div>
        </div>
      </div>

        <div className='d-flex overflow-auto h-50px ps-5 pt-5 pb-5 category-nav'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap w-100'>
            {location.pathname !== '/supporterProgrammeExplorer/default' ?
              <li className='nav-item'>
                <button type='button' className='btn btn-sm btn-link me-5 p-0' title={'Go back'} onClick={() => navigate(ConfigRoutes.admin.programme_explorer_supporter.default.base)}>
                  <KTSVG path='/media/icons/duotune/general/gen058.svg' className='svg-icon-2' />
                  Go Back
                </button>
              </li> : null}

            <li className="ms-auto me-5 title-custom">
              {programmes.length > 0 && showProgrammeInfo ?
                (<>
                <div className='buttons'>
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-disabled">{!statusDownload('pdf') ? 'Generating PDF' : 'Download PDF'}</Tooltip>}>
                    <div><button type='button' disabled={!statusDownload('pdf')} className={!statusDownload('pdf') ? 'btn btn-sm btn-link p-0 custom-disabled' : 'btn btn-sm btn-link p-0'} title={!statusDownload('pdf') ? 'Generating PDF' : 'Download PDF'} onClick={() => !statusDownload('pdf') ? null : downloadFileCache('pdf', selectedProjectCode)}>
                      <KTSVG path='/media/svg/programme_explorer_supporter/ppt.svg' className='svg-icon-2' />
                      PDF
                    </button>
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-disabled">{!statusDownload('ppt') ? 'Generating PPT' : 'Download PPT'}</Tooltip>}>
                    <div><button type='button' disabled={!statusDownload('ppt')} className={!statusDownload('ppt') ? 'btn btn-sm btn-link p-0 custom-disabled' : 'btn btn-sm btn-link p-0'} title={!statusDownload('ppt') ? 'Generating PPT' : 'Download PPT'} onClick={() => !statusDownload('ppt') ? null : downloadFileCache('ppt', selectedProjectCode)}>
                      <KTSVG path='/media/svg/programme_explorer_supporter/ppt.svg' className='svg-icon-2' />
                      PPT
                    </button></div>
                  </OverlayTrigger>
                  </div>
                </>) : null}
            </li>
            <li className="ms-5 me-5">
              {programmes.length > 0 && showProgrammeInfo ?
                (<><button type='button' className='btn btn-sm btn-link p-0' title={'Programme info'} onClick={() => navigate(ConfigRoutes.admin.programme_explorer_supporter.project_info.base)}>
                  <KTSVG path='/media/icons/duotune/general/gen045.svg' className='svg-icon-2' />
                  Programme info
                </button></>) : null}
            </li>
            <li className="ms-5 me-2">
              <button type='button' className='btn btn-sm btn-link p-0' title={'Clear filter'} onClick={() => { window.location.reload(); }}>
                <KTSVG path='/media/icons/duotune/art/art004.svg' className='svg-icon-2' />
                Clear
              </button>
            </li>
          </ul>
        </div>
        <hr></hr>

      </Sticky>
    </>
  )
}

export { ProgrammeExplorerHeader }