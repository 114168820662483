import SpinnerCustom from "@components/SpinnerCustom";
import { KTSVG } from "_metronic/helpers";
import { Pagination } from "_metronic/partials/widgets/pagination";
import { formatNumber, limitDecimals } from "app/utils/utils";
import clsx from "clsx";
import React, { ChangeEvent, Key, useEffect, useState } from "react";

interface Column {
    name: string | JSX.Element,
    renderHeader?(element: any): JSX.Element,
    sortable?: boolean,
    keyValue?: string,
    className?: string,
    ordered?: "ASC" | "DESC",
    render?(element: any): JSX.Element,
    isActionCell?: boolean,
    sortColumn?(keyValue: string, order: "asc" | "desc"): void
  }
  
  interface SearchInput {
    placeholder: string,
    onSearch(value: string): void
  }
  
  type Columns = Array<Column>;

  export interface actionCell {
    title: string,
    actionGroup?: Array<action>, 
  }

  export interface action{
    iconPath?: string,
    description?: string,
    buttonType?: "normal" | "icon",
    additionalClasses?: string,
    svgClassName?: string,
    callback?(): void
  }

  type actionCells = Array<actionCell>;
  
  interface PaginationProps {
    pageCount: number,
    currentPage: number,
    pageSize: number
    handlePagination: Function
    handlePerPage: Function
  }
  
  type Props = {
    className: string,
    tableClassName?: string,
    title?: string,
    subtitle?: string,
    data: Array<any> | null,
    columns: Columns
    toolbar?: boolean,
    startElementToShow?: number,
    pagination?: boolean
    paginationData?: PaginationProps,
    selectableItems?: boolean,
    onSelectAllItems?(checked: boolean): void,
    onChangeCheckedItems?(id: string, event: ChangeEvent<HTMLInputElement>, element: any): void,
    onClickRow?(id: string): void,
    searchInput?: SearchInput
    headerButton?: any
    actionRow?:  actionCells,
    totalsRow?:  { [key: string]: number },
    showTotalsColumn?: boolean;   
    totalsRowPosition?: 'top' | 'bottom';
    topHeaderRow?: any;
    headerRow?: any;
  }
  
  
  const DEFAULT_COLUMN_WIDTH = "min-w-100px";
  
  const MarketingTableCustom: React.FC<Props> = ({ className, totalsRowPosition, title, subtitle, data, columns, tableClassName, startElementToShow, pagination, paginationData, onClickRow, totalsRow, showTotalsColumn, actionRow , topHeaderRow}) => {
  
    const [confColumns, setConfColumns] = useState(columns);

    useEffect(() => {
      setConfColumns(columns);
    }, [columns]);
  
    const tableColumn = (column: Column, index: Key, element: any) => {
        let className = column.className ?? DEFAULT_COLUMN_WIDTH;
        let columnName = column.name; 
        let renderHeader = column.renderHeader;

        return (
            <React.Fragment key={'column-'+index}>
                <th key={index} className={className}>
                    {renderHeader ? renderHeader(element) : columnName}
                </th>
            </React.Fragment>
        )
    }

    const renderTopHeaderRow = (headerRow: any) => {

      return (
        <tr key={'topHeaderRow'}>
            {headerRow.map((header: any, index: number) => {
                
                let renderHeader = header.renderHeader;
                let className    = header.className
                let colspan      = header.colspan

                return (
                  <td key={index + "-column"} className={className} colSpan={colspan ? confColumns.length : 0}>
                    <b>{renderHeader ? renderHeader(renderHeader) : header.name}</b>
                  </td>
                )
            })}
        </tr>
      );
    }
  
    /** 
     * Render cell with configured props.
     * 
     * @param element 
     * @param column 
     * @returns 
     */
    const renderCell = (element: any, index: Key, column: Column) => {
  
      if (column.keyValue) {
  
        if (column.render !== undefined) {
          return (
            <td key={index + '-cell'}>
              {column.render(element)}
            </td>
          )
        }
  
        return (
          <td key={index + '-cell'}>
            <div key={index + '-cell-field'} className='text-dark fw-bolder text-hover-primary d-block'>
              {element[column.keyValue]}
            </div>
          </td>
        )
      }
  
      throw new Error(`Column ${column.name} is not defined correctly.`)
  
    }
  
  
    const callToAction = (action: action) => {
      if (action.callback) {
        action.callback();
      }
    }
  
    const renderTotalsRow = (totals: { [key: string]: number }) => {
      return (
        <tr key={'TotalsRow'}>
          <td className='text-center' style={{ backgroundColor: '#F5F5F5'}} key={'Totals'}><b>Totals</b></td>
            {Object.keys(totals).map((key: string, index: React.Key | null | undefined) => (
              <td key={index} className='text-center fw-bold' 
              style={{ backgroundColor: '#F5F5F5'}}>
                <b>{formatNumber(limitDecimals(totals[key]))}</b>
              </td>
            ))}
        </tr>
      );
    };
  
  
    const renderActions = (actions: Array<actionCell>) => {
      return (
        <tr key={'ActionRow'}>
            <td className='text-center bg-marketing-insight-not-rounded' key={'visualisationOptions'}><b>Visualisation options</b></td>
            {actions.map((action: actionCell, index: number) => {
                return actionCell(action, index);
            })}
        </tr>
      );
    }
  
    const actionCell = (action: actionCell, index: Key) => {
      return (
        <td key={index+ '-cell'}>
            <div className='d-flex justify-content-center align-items-center flex-shrink-0'>
            {action.actionGroup?.map((action: action, index: number) => {
                return (
                  <a
                    key={index + '-' + action.description}
                    onClick={() => {
                        callToAction(action);
                    }}
                    title={action.description}
                    data-toogle="tooltip"
                    className={
                        clsx({
                            'btn btn-icon btn-active-color-primary btn-sm me-1': action.buttonType !== "normal",
                            'btn btn-sm btn-primary me-2': action.buttonType === "normal"
                        }) + " " + action.additionalClasses
                    }
                >
                    {
                        action.buttonType !== "normal" ?
                            (<KTSVG path={action.iconPath ?? ""} svgClassName={action.svgClassName ? action.svgClassName : ""} />)
                        :
                            (<span className="btn-label">{/*action.title.toUpperCase()*/}</span>)
                    }
  
                </a>
              )
            })}
            </div>
        </td>
      );
    }
  
    const tableRow = (element: any, index: number, columns: Columns) => {
  
      if (startElementToShow !== undefined && index >= startElementToShow) {
        return (
          <tr key={index} onClick={() => onClickRow != undefined ? onClickRow(element.id) : null} >
            {columns.map((column: Column, index: number) => {
              return renderCell(element, index, column);
            })}
          </tr>
        );
      }
  
    }
  
    return (
        <>
        {/* begin::Table container */}
          <div className='table-loading table-responsive min-h-350px'> 
            {/* begin::Table */}
            <table className={'table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4' + tableClassName} style={{backgroundColor: 'white'}}>
              {/* begin::Table head */}
              <thead>
                {topHeaderRow && renderTopHeaderRow(topHeaderRow)}
                <tr className='fw-bolder text-muted'>
                  {confColumns.map(tableColumn)}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {actionRow && renderActions(actionRow)}
                {showTotalsColumn === true && totalsRow && totalsRowPosition === 'top' && renderTotalsRow(totalsRow)}
                {data !== null ? data.map((element: any, index: number) => tableRow(element, index, columns)) :
                <tr><td colSpan={columns.length + 1}><div className="d-flex justify-content-center"><SpinnerCustom/></div></td></tr>}
                {showTotalsColumn === true && totalsRow && totalsRowPosition === 'bottom' && renderTotalsRow(totalsRow)}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
  
            {pagination == true && paginationData !== undefined && paginationData !== null && (
  
              <Pagination currentPage={paginationData.currentPage} pageCount={paginationData.pageCount}
                handlePagination={paginationData.handlePagination} handlePerPage={paginationData.handlePerPage} />
  
            )}
  
          </div>
          {/* end::Table container */}
        </>
    )
  }
  
  MarketingTableCustom.defaultProps = {
    tableClassName: '',
    startElementToShow: 0
  }

export default MarketingTableCustom