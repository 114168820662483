import { PageTitle } from "../../../../_metronic/layout/core";
import { useAdminMetrics } from "../metric_permissions/AdminMetricsHook";
import { AllowMetricRoleCheckbox } from "../components/AllowMetricRoleCheckbox";
import '@/_metronic/assets/sass/components/_loading.scss';
import toastr from "toastr";
import { InlineInputEdit } from "react-inline-input-edit";
import CustomSelect from "@components/form/CustomSelect";
import { Metric, metricOptions } from "app/models/metrics/Metric";
import AutoFileForm from "@components/form/AutoFileForm/AutoFileForm";
import { MetricManagerService } from "app/services/metric/MetricManagerService";
import Swal from 'sweetalert2'
import { DocumentService } from "app/services/document/DocumentService";
import configRoutes from "../../../routing/ConfigRoutes";

const breadcrumbs = [
    {
        title: 'Administration',
        path: '#',
        isSeparator: false,
        isActive: false,
    },
    {
        title: 'Metrics Manager',
        path: configRoutes.admin.metrics.metric_list.fullPath,
        isSeparator: true,
        isActive: true,
    },
]

const MetricListPage = () => {

    const { metrics, roles, groupedMetrics, currentAllowedMetrics, getAvailableMetrics } = useAdminMetrics();

    const findMetricsByCatName = (categoryName: string): any[] => {
        return metrics.filter((metric: any) => metric.category === categoryName.toLowerCase());
    }

    const showLoading = () => {
        Swal.fire({
            showConfirmButton: false,
            title: '<h3> Uploading Image <h3>',
            loaderHtml: '<div class="custom-spinner mb-5"><span></span><span></span><span></span></div>',
            allowOutsideClick: false,
            validationMessage: '<p class="text-muted"> This operation could take a while </p>',
            html: '<p class="text-muted"> This operation could take a while </p>',

            customClass: {
                loader: 'custom-loader',
                icon: 'd-none',

            },
            willOpen: () => {
                Swal.showLoading()
            }
        });
    }

    const updateMetric = async (metric: Metric) => {
        let responseData =  (await new MetricManagerService().updateMetric(metric)).getResponseData();
        if (responseData.success) {
            toastr.success("Metric updated successfully")
            getAvailableMetrics();
        } else {
            toastr.error("Error during metric update " + responseData.message)
        }

    }

    /**
     * 
     * @param formData 
     * @param metricId 
     * @returns 
     */
    const _updloadMetricImage = async (formData: FormData, metricId: number) => {

        formData.append("id", metricId.toString())
        
        showLoading();
        
        let responseData = (await new MetricManagerService().updateMetricImg(formData)).getResponseData();
        
        Swal.close();

        if(!responseData.success) {
            toastr.error("Error during metric image update " + responseData.message)
            return
        }
        
        toastr.success("Metric updated successfully")
        
        getAvailableMetrics();
    }


    return (
        <>
            <PageTitle breadcrumbs={breadcrumbs}>Metrics Manager</PageTitle>
            <div className={'card card-custom'}>
                <div className={'card-body table-responsive'}>
                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                            <tr>
                                <th ></th>
                                <th ></th>
                                <th className="fw-bold">Metric Name</th>
                                <th className="fw-bold">Description</th>
                                <th className="fw-bold">Category</th>
                                <th className="fw-bold">Can Open</th>
                                <th className="fw-bold">Show Filtered Timeseries</th>
                                <th className="fw-bold">Show Split By Source</th>
                                <th className="fw-bold">Show Split By Region</th>
                                <th className="fw-bold">Show Programmes Ranking</th>
                                <th className="fw-bold">Show TA Ranking</th>
                                <th className="fw-bold">Can Show</th>
                                <th className="fw-bold">Enabled</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(groupedMetrics).map((catName: string, catIndex: number) => {
                                    return groupedMetrics[catName].map((metric: Metric, metricIndex: number) => {
                                        return (
                                            <tr key={metric.id}>
                                                {metricIndex === 0 ? <td rowSpan={groupedMetrics[catName].length} className={"text-center"}><div style={{ transform: 'rotate(-90deg)' }}>{catName}</div></td> : null}
                                                
                                                {/** Metric image */}
                                                <td className="p-0" style={{maxWidth: "300px"}}>
                                                    <AutoFileForm 
                                                        name="img" 
                                                        label="Change Metric Image"
                                                        type={'avatar'}
                                                        asynchronous={true}
                                                        value={metric.img?.id} 
                                                        onChange={(formData: FormData) => {
                                                            _updloadMetricImage(formData, metric.id)
                                                        } }
                                                        onFormatError={(file: File, message: string) => {
                                                            toastr.error("File format error: " + message)
                                                        } }
                                                        />
                                                </td>

                                                {/** Metric name */}
                                                <td className="ps-0">
                                                    <InlineInputEdit
                                                        text={`${metric.name}`}
                                                        labelClassName={"inline-edit-label"}
                                                        inputWidth="200px"
                                                        inputHeight="25px"
                                                        inputMaxLength={50}
                                                        labelFontWeight="bold"
                                                        inputFontWeight="bold"
                                                        onFocus={() => console.log('onFocus')}
                                                        onFocusOut={(text: any) => { updateMetric({
                                                            ...metric,
                                                            name: text
                                                        }) }}
                                                    />
                                                </td>

                                                {/** Metric description */}
                                                <td className="ps-0">
                                                    <InlineInputEdit
                                                        text={`${metric.description}`}
                                                        labelClassName={"inline-edit-label"}
                                                        inputWidth="200px"
                                                        inputHeight="25px"
                                                        inputMaxLength={100}
                                                        labelFontWeight="bold"
                                                        inputFontWeight="bold"
                                                        onFocus={() => console.log('onFocus')}
                                                        onFocusOut={(text: any) => { 
                                                            updateMetric({
                                                                ...metric,
                                                                description: text
                                                            })
                                                         }}
                                                    />
                                                </td>

                                                {/** Metric category */}
                                                <td className="ps-0" style={{minWidth: "200px"}}>
                                                    <CustomSelect
                                                        options={metricOptions}
                                                        defaultValue={metricOptions.find(option => option.value === metric.category)}
                                                        placeholder={""}
                                                        onChangeSingle={(selected: unknown) => { 
                                                            let selection = selected as any;
                                                            updateMetric({
                                                                ...metric,
                                                                category: selection.value
                                                            }) }
                                                        } />
                                                </td>

                                                {/** CanOpen */}
                                                <td className="ps-0">
                                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                                        <AllowMetricRoleCheckbox
                                                            metricId={metric.id}
                                                            defaultChecked={metric.canOpen}
                                                            onChange={(role: number, metricId: number, status: boolean) => { 
                                                                updateMetric({
                                                                    ...metric,
                                                                    canOpen: status
                                                                })
                                                             }}/>
                                                    </div>
                                                </td>

                                                {/** ShowFilteredTimeseries */}
                                                <td className="ps-0">
                                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                                        <AllowMetricRoleCheckbox
                                                            metricId={metric.id}
                                                            defaultChecked={metric.showFilteredTimeseries}
                                                            onChange={(role: number, metricId: number, status: boolean) => { 
                                                                updateMetric({
                                                                    ...metric,
                                                                    showFilteredTimeseries: status
                                                                })
                                                             }}/>
                                                    </div>
                                                </td>

                                                {/** ShowSplitBySource */}
                                                <td className="ps-0">
                                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                                        <AllowMetricRoleCheckbox
                                                            metricId={metric.id}
                                                            defaultChecked={metric.showSplitBySource}
                                                            onChange={(role: number, metricId: number, status: boolean) => { 
                                                                updateMetric({
                                                                    ...metric,
                                                                    showSplitBySource: status
                                                                })
                                                             }}/>
                                                    </div>
                                                </td>

                                                {/** ShowSplitByRegion */}
                                                <td className="ps-0">
                                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                                        <AllowMetricRoleCheckbox
                                                            metricId={metric.id}
                                                            defaultChecked={metric.showSplitByRegion}
                                                            onChange={(role: number, metricId: number, status: boolean) => { 
                                                                updateMetric({
                                                                    ...metric,
                                                                    showSplitByRegion: status
                                                                })
                                                             }}/>
                                                    </div>
                                                </td>

                                                {/** showProgrammesRanking */}
                                                <td className="ps-0">
                                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                                        <AllowMetricRoleCheckbox
                                                            metricId={metric.id}
                                                            defaultChecked={metric.showProgrammesRanking}
                                                            onChange={(role: number, metricId: number, status: boolean) => { 
                                                                updateMetric({
                                                                    ...metric,
                                                                    showProgrammesRanking: status
                                                                })
                                                             }}/>
                                                    </div>
                                                </td>

                                                {/** showTherapeuticAreaRanking */}
                                                <td className="ps-0">
                                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                                        <AllowMetricRoleCheckbox
                                                            metricId={metric.id}
                                                            defaultChecked={metric.showTherapeuticAreaRanking}
                                                            onChange={(role: number, metricId: number, status: boolean) => { 
                                                                updateMetric({
                                                                    ...metric,
                                                                    showTherapeuticAreaRanking: status
                                                                })
                                                             }}/>
                                                    </div>
                                                </td>

                                                {/** canShow */}
                                                <td className="ps-0">
                                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                                        <AllowMetricRoleCheckbox
                                                            metricId={metric.id}
                                                            defaultChecked={metric.canShow}
                                                            onChange={(role: number, metricId: number, status: boolean) => { 
                                                                updateMetric({
                                                                    ...metric,
                                                                    canShow: status
                                                                })
                                                             }}/>
                                                    </div>
                                                </td>

                                                {/** enabled */}
                                                <td className="ps-0">
                                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                                        <AllowMetricRoleCheckbox
                                                            metricId={metric.id}
                                                            defaultChecked={metric.enabled}
                                                            onChange={(role: number, metricId: number, status: boolean) => { 
                                                                updateMetric({
                                                                    ...metric,
                                                                    enabled: status
                                                                })
                                                             }}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default MetricListPage