import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import ConfigRoutes from "../../../routing/ConfigRoutes"
import MetricRolesPage from "./metric_roles/MetricRolesPage";


const AdminMetricsWrapper = () => {

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path={ConfigRoutes.admin.metrics.role_metrics.path}
          element={<MetricRolesPage/>}
        />
      </Route>
      <Route index element={<Navigate to={ConfigRoutes.admin.metrics.role_metrics.fullPath} replace/>} />
    </Routes>
  )
}

export default AdminMetricsWrapper