import { FC, useCallback, useEffect, useState } from "react";
import { PageTitle, useLayout } from "../../../../_metronic/layout/core";
import { TablesWidget9 } from "../../../../_metronic/partials/widgets";
import { SnapshotService } from "../../../services/snapshot/SnapshotService";
import moment from "moment";
import ConfigRoutes from "app/routing/ConfigRoutes";
import { useNavigate } from "react-router-dom";
import { getMetric } from "app/utils/utils";
import SnapshotModal from "../edit/SnapshotModal";
import { CustomDrawer } from "@components/drawer/CustomDrawer";
import { KTSVG } from "_metronic/helpers";
import SnapshotListFilters, { SnapshotFilters } from "../filters/SnapshotListFilters";

const breadcrumbs = [
  {
    title: 'Administration',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Snapshot List',
    path: 'admin/snapshots/list',
    isSeparator: true,
    isActive: true,
  },
]

export const UserSnapshotList: FC = () => {

  const navigate = useNavigate();

  const [snapshots, setSnapshots] = useState(null);

  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [editSnapshot, setEditSnapshot] = useState(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filters, setFilters] = useState <SnapshotFilters | null>(null);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const handleClose = useCallback(() => setOpenFilterDrawer(false), []);
  
  useEffect(() => {
    getSnapshots(currentPage, pageSize, undefined, searchValue);
  }, [searchValue, currentPage, pageSize, filters]);

  const getSnapshots = async (page?: number, limit?: number, orderFilters?: any, textSearch?: string) => {
    let params: any = {
      limit: limit == undefined ? pageSize : limit,
      page: page == undefined ? currentPage + 1 : page + 1,
      filter_filters: filters != null ? filters : {},
    }

    if (orderFilters != undefined) {
      params['filter_order'] = [orderFilters]
    }
    
    if (textSearch != undefined) {
      params.filter_filters['search'] = textSearch
    }

    let snapshots = (await (new SnapshotService).getSnapshots(params)).getResponseData();


      setSnapshots(snapshots.data.data);
      setPageCount(snapshots.data.lastPage)

  }


  const handlePagination = async (pageObject: any) => {

    setCurrentPage(pageObject.selected);

    await getSnapshots(pageObject.selected, pageSize);

  }

  const handlePerPage = async (pageSizeObject: any) => {

    setPageSize(pageSizeObject.value);
    setCurrentPage(0);

    await getSnapshots(0, pageSizeObject.value);

  }

  const handleNavigation = (id: string) => {
    navigate(ConfigRoutes.admin.snapshots.view.fullPath.replace(":id", id));
  }

  const handleSearch = (value: string) => {
    setSearchValue(value);
    getSnapshots(0, pageSize, undefined, value);
  }


  return (
    <>

      <SnapshotModal snapshotId={editSnapshot} onHideModal={setEditSnapshot} reloadTable={getSnapshots} />

      <PageTitle breadcrumbs={breadcrumbs}>Snapshots list</PageTitle>

      <CustomDrawer title="Filters" anchor="right" open={openFilterDrawer} onClose={handleClose}>
        <SnapshotListFilters setFilters={setFilters} filters={filters}/>  
      </CustomDrawer>

      {
        snapshots === null ?
          (
            <div className="text-justify-content-md-center">
              <span className='indicator-progress text-center' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </div>

          )
          :
          (
            <>

              <TablesWidget9 className="card-primary" title="Snapshots"
                subtitle="List of Snapshots the user can access"
                searchInput={{placeholder: "Search...", onSearch: handleSearch}}
                data={snapshots}
                pagination={true}
                onClickRow={handleNavigation}
                paginationData={{
                  pageSize: pageSize, pageCount: pageCount, currentPage: currentPage,
                  handlePagination: handlePagination, handlePerPage: handlePerPage
                }}
                columns={[
                  {
                    name: "Name", sortable: true, keyValue: 'name', className: "min-w-150px",
                    sortColumn: (keyValue: string, order: "asc" | "desc") => { getSnapshots(currentPage, pageSize, { field: keyValue, order: order }); },
                    render: (element: any) => {
                      return (
                        <>
                          <div className='text-dark fw-bolder d-block fs-6'>
                            {element.name}
                          </div>
                        </>
                      );
                    }
                  },
                  {
                    name: "Metrics", keyValue: 'metrics', className: "min-w-120px", render: (element: any) => {
                      let metrics = JSON.parse(element.data);
                      let metricIds = Object.keys(metrics);
                      let metricNames = [];

                      for (let id of metricIds) {
                        metricNames.push(getMetric(id)?.title);
                      }

                      return (
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2'>
                            <span className='text-muted me-2 fs-7 fw-bold'> {metricNames.join(', ').toString()} </span>
                          </div>
                        </div>
                      );
                    }
                  },
                  { name: "Actions", className: "min-w-100px text-end", isActionCell: true }
                ]}
                actions={[
                  {
                    title: "View",
                    iconPath: "/media/icons/duotune/general/gen060.svg",
                    description: "View snapshot",
                    callback: (element: any) => {
                      handleNavigation(element.id);
                    }
                  },
                ]}
              >
              </TablesWidget9></>
          )
      }

    </>
  );
}