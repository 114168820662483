import CustomModalBody from "@components/modal/body";
import CustomModalHeader from "@components/modal/header";
import CustomModal from "@components/modal/modal";
import { DatabaseManagerService } from "app/services/database_manager/databaseManagerService";
import { UserService } from "app/services/user/UserService";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import Swal from "sweetalert2";


interface Props {
    show: boolean,
    setShow: any
    query: any
}

const DbModal: FC<Props> = ({ show , setShow, query }) => {

    const hideModal = () => {
        setShow(false);
    }

    const handleSubmitResponse = (response: any) => {
        if(response == true){
            Swal.fire({
                icon: 'success',
                title: 'Query created successfully',
                showConfirmButton: false,
                timer: 3000
            })
            hideModal();
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                confirmButtonColor: '#477995',
            })
        }
    }

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validate: (values) => {
            const errors: any = {};
            if (values.name.length < 3) {
                errors.name = 'The name must be at least 3 characters.';
            }
            return errors;
        },
        onSubmit: async (values) => {
            handleSubmitResponse(await (await (new DatabaseManagerService()).saveQuery(values.name, query)).getResponseData().success)
        },
    });


    return (
        <CustomModal identifier="create_qery_modal" show={show} onHideModal={hideModal}>
            <CustomModalHeader classes="pb-0 border-0">
                <div className="modal-title justify-content-start">
                    <h2>Save Search</h2>
                </div>
            </CustomModalHeader>
            <CustomModalBody classes="mx-5 mx-xl-18 pt-5 pb-10 overflow-visible">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-lg-12 fv-row">
                            <label className="required fw-bold fs-6 mb-2">Search Title</label>
                            <input
                                type="text"
                                className={`form-control form-control-lg form-control-solid`}
                                name="name"
                                required
                                value={formik.values.name}
                                onChange={formik.handleChange}                               
                            />
                            {formik.errors.name ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block text-danger">{formik.errors.name}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className="col-lg-12 fv-row mt-6 d-flex justify-content-center">
                            <button type="submit" disabled={formik.isSubmitting || !formik.isValid} className="btn btn-primary">Save Search</button>
                        </div>
                    </div>
                </form>
            </CustomModalBody>
        </CustomModal>
    );
}

export default DbModal;