
import ConfigRoutes from 'app/routing/ConfigRoutes'
import { FC } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageTitle } from '_metronic/layout/core'
import { ContactsImportBase } from './components/import'
import { ContactsHeader } from './components/ContactsHeader'
import { ContactsListBase } from './components/list'
import { ContactsViewWrapper } from './components/view/ContactsViewWrapper'
import configRoutes from "../../../routing/ConfigRoutes";

const breadcrumbsContactList = [
    {
        title: 'Administration',
        path: '#',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '#',
        isSeparator: true,
        isActive: false
    },
    {
        title: 'Imports',
        path: '#',
        isSeparator: false,
        isActive: false,
    },
    {
        title: 'Import contacts',
        path: '#',
        isSeparator: true,
        isActive: false
    }
]



const ContactsWrapper: FC = () => {

    return (
        <>
            <Routes>
                <Route
                    element={
                        <>
                            <PageTitle >Import Contacts</PageTitle>
                            <ContactsHeader />
                            <Outlet />
                        </>
                    }
                >
                    <Route element={<> <PageTitle breadcrumbs={breadcrumbsContactList} >Contacts Import</PageTitle> <Outlet />   </>}>
                        <Route path={ConfigRoutes.admin.imports.contacts.import.path} element={<ContactsImportBase />} />
                    </Route>

                    <Route element={<> <Outlet /> <PageTitle breadcrumbs={breadcrumbsContactList}>Contacts List</PageTitle>  </>}>
                        <Route path={ConfigRoutes.admin.imports.contacts.list.path} element={<ContactsListBase />} />
                    </Route>

                    <Route element={<> <Outlet /> </>}>
                        <Route path={ConfigRoutes.admin.imports.contacts.view.path} element={<ContactsViewWrapper />} />
                    </Route>

                    <Route index element={<Navigate to={ConfigRoutes.admin.imports.contacts.list.fullPath} />} />
                </Route>
            </Routes>



        </>

    )
}

export { ContactsWrapper }