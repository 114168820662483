import React, { useEffect, useState } from "react";
import { PEData } from "@components/chart/types";
import CustomSelect from "@components/form/CustomSelect";
import { OptionsOrGroups } from "react-select";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { ProgrammeExplorerFilterMode, ProgrammeExplorerFilterParams } from "../ProgrammeExplorerProvider";
import { sourceOptions } from "app/utils/utils";
import { ProgrammeExplorerFilteredCardMainMetric } from "./ProgrammeExplorerFilteredCardMainMetric";
import { ProgrammeExplorerFilteredCardSplitBySourceMetric } from "./ProgrammeExplorerFilteredCardSplitBySourceMetric";
import { ProgrammeExplorerFilteredCardSplitByRegionMetric } from "./ProgrammeExplorerFilteredCardSplitByRegionMetric";
import { ProgrammeExplorerFilteredCardTop10ProgrammesMetric } from "./ProgrammeExplorerFilteredCardTop10ProgrammesMetric";
import { ProgrammeExplorerFilteredCardTop10TherapeuticAreas } from "./ProgrammeExplorerFilteredCardTop10TherapeuticAreas";

const tempOptions: OptionsOrGroups<any, any> = [
  {
    label: 'Daily',
    value: 'days'
  },
  {
    label: 'Weekly',
    value: 'weeks'
  },
  {
    label: 'Monthly',
    value: 'months'
  },

]

interface Props {
  title: string
  total: number | null
  image: string | Promise<Blob | null>
  taAVG?: JSX.Element
  globalAVG?: JSX.Element
  metric: string
  defaultFilters: ProgrammeExplorerFilterParams,
  defaultSeries?: PEData[]
  showFilteredTimeSeries?: boolean
  showTemFilter?: boolean
  showTrafficSourceFilter?: boolean
  showRegionMetric?: boolean
  showProgrammeRanking?: boolean
  showTherapeuticAreaRanking?: boolean

  onClose?: () => void
}

const ProgrammeExplorerFilteredCard: React.FC<Props> = ({
  title,
  total,
  image,
  taAVG,
  globalAVG,
  metric,
  showFilteredTimeSeries,
  showTemFilter,
  showTrafficSourceFilter,
  onClose,
  showRegionMetric,
  defaultFilters,
  defaultSeries,
  showProgrammeRanking,
  showTherapeuticAreaRanking
}) => {

  const [selectedTrafficSource, setSelectedTrafficSource] = useState<string | null>(null)

  const [currentFilters, setCurrentFilters] = useState<ProgrammeExplorerFilterParams>(defaultFilters)
  const [currentMode, setCurrentMode] = useState<ProgrammeExplorerFilterMode>(defaultFilters.filter_filters.mode)

  const [selectedTemp, setSelectedTemp] = useState<unknown>(tempOptions.find((option) => option.value === defaultFilters.filter_filters.mode))

  const [imageSrc, setImageSrc] = useState<string|undefined>(undefined)

  const getImage = async () => {
    if (typeof image === 'string') {
      setImageSrc(toAbsoluteUrl(image))
    }

    if (image instanceof Promise) {
      const blobResource = await image
      if (blobResource !== null) {
        let url = URL.createObjectURL(blobResource)
        setImageSrc(url)
      }
    }
  }

  useEffect(() => {
    getImage()
  }, [])


  useEffect(() => {

    setCurrentFilters({
      ...defaultFilters,
      filter_filters: {
        ...defaultFilters.filter_filters,
        mode: currentMode,
        source: selectedTrafficSource || undefined
      }
    })

  }, [defaultFilters])


  useEffect(() => {

    setCurrentFilters({
      ...currentFilters,
      filter_filters: {
        ...currentFilters.filter_filters,
        mode: currentMode
      }
    })

  }, [currentMode])



  useEffect(() => {

    setCurrentFilters({
      ...currentFilters,
      filter_filters: {
        ...currentFilters.filter_filters,
        source: selectedTrafficSource || undefined
      }
    })

  }, [selectedTrafficSource])


  const handleTrafficChange = (selected: OptionsOrGroups<string, any> | unknown | null) => {
    setSelectedTrafficSource((selected as any).value)
  }

  const handleSelectedTemp = (selected: OptionsOrGroups<any, any> | unknown | null) => {

    setSelectedTemp(selected)
    setCurrentMode((selected as any).value)
  }

  // Triggered when user press the close button
  const handleClose = (): void => {
    if (typeof onClose !== "undefined") {
      onClose()
    }
  }

  return (
    <div className={'card w-100 p-0'}>
      <div className={'card-header'}>
        
        <h4 className={'card-title gap-4'}>
          <div className='symbol symbol-50px'>
            <span className='symbol-label bg-light'>
              <img src={imageSrc} className='h-50 align-self-center' alt='' />
            </span>
          </div>
          {title}
          <h1 className={'text-primary counter-font'}>{total}</h1>
        </h4>
        
        <div className="card-toolbar d-flex gap-4 align-content-center">
          {showTrafficSourceFilter ? (<CustomSelect options={sourceOptions} is_multi={false} defaultValue={selectedTrafficSource} placeholder="All traffic source" onChangeSingle={handleTrafficChange}></CustomSelect>) : null}
          {showTemFilter ? (<CustomSelect options={tempOptions} is_multi={false} defaultValue={selectedTemp} placeholder="Temps" onChangeSingle={handleSelectedTemp}></CustomSelect>) : null}
          <button type="button" className="btn btn-md btn-link" title={'Close details'} onClick={handleClose}>
            <KTSVG path={'/media/icons/duotune/arrows/arr061.svg'} svgClassName={'w-40px h-40px text-primary'} />
          </button>
        </div>
      </div>
      <div className={'card-body'}>
        <div className={'d-flex flex-column justify-content-center'}>
          {/*taAVG ?? taAVG*/}
          {
            showFilteredTimeSeries ?
              (
                <div className={'d-flex flex-row pt-10 justify-content-center'}>
                  <div className="flex-equal">
                    <ProgrammeExplorerFilteredCardMainMetric filters={currentFilters} metric={metric} showAxis={true} />
                  </div>
                </div>
              )
              : null
          }


          <div className={'d-flex flex-row pt-10 justify-content-center'}>
            {
              showTrafficSourceFilter ?
                (
                  <div className={'mt-5 flex-equal'}>
                    <div className={'h4 text-muted text-center'}>Split by source</div>
                    <ProgrammeExplorerFilteredCardSplitBySourceMetric filters={currentFilters} metric={metric} />

                  </div>
                )
                : null
            }

            {
              showRegionMetric ?
                (
                  <div className={'mt-5 flex-equal'}>
                    <div className={'h4 text-muted text-center'}>Split by region</div>
                    <ProgrammeExplorerFilteredCardSplitByRegionMetric filters={currentFilters} metric={metric} />
                  </div>
                )
                : null
            }

          </div>
          <div className={'d-flex flex-row gap-10'}>
            {
              showProgrammeRanking ?
                (
                  <div className={'mt-5 flex-equal'}>
                    <div className={'h4 text-muted text-center'}>Top 10 Programmes</div>
                    <ProgrammeExplorerFilteredCardTop10ProgrammesMetric filters={currentFilters} metric={metric}></ProgrammeExplorerFilteredCardTop10ProgrammesMetric>
                  </div>
                )
                : null
            }

            {
              showTherapeuticAreaRanking ?
                (
                  <div className={'mt-5 flex-equal'}>
                    <div className={'h4 text-muted text-center'}>Top 10 Therapeutic areas</div>
                    <ProgrammeExplorerFilteredCardTop10TherapeuticAreas filters={currentFilters} metric={metric} />
                  </div>
                )
                : null
            }

          </div>
        </div>
      </div>
    </div>

  )
}

ProgrammeExplorerFilteredCard.defaultProps = {
  showTemFilter: true,
  showTrafficSourceFilter: false,
  showRegionMetric: false,
  showProgrammeRanking: true,
  showTherapeuticAreaRanking: true,
  showFilteredTimeSeries: true
}

export { ProgrammeExplorerFilteredCard }