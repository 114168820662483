import CustomSelect from "@components/form/CustomSelect";
import { Programme } from "app/models/programmes/Programme";
import { ConnectService } from "app/services/connect/ConnectService";
import { FormatService } from "app/services/formats/FormatServie";
import { ProgrammeService } from "app/services/programme/ProgrammeService";
import { TherapeuticAreaService } from "app/services/therapeutic_area/TherapeuticAreaService";
import { replaceUnderscoresWithSpacesAndCapitalize } from "app/utils/utils";
import React, { useEffect, useState } from "react";

interface insightSelectsProps {
    insightLevelSelected: string,
    onChangeConnects : any,
    onChangeProgram : any,
    onChangeFormat : any,
    onChangeTherapeuticArea : any,
    programs: any,
    setPrograms: any
};

const InsightSelects: React.FC<insightSelectsProps> = ({insightLevelSelected, onChangeConnects, onChangeProgram, onChangeFormat, onChangeTherapeuticArea, programs, setPrograms}) => {
    
    const timeoutRef = React.useRef<any>()
    const [searchText, setSearchText] = useState('')
    const [selectedProgrammes, setSelectedProgrammes] = useState<Programme[]>([]);
    const [connects, setConnects] = useState([]);
    const [therapeuticAreas, setTherapeuticAreas] = useState([]);
    const [formats , setFormats] = useState([]);
    
    useEffect(() => {
        getFiltersData();
    }, [])
    
    const getFiltersData = async () => {
        await getConnects();
        await getTherapeuticAreas();
        await getFormats();
    }

    const handleValueInputChange = (newValue: any) => {
        setSearchText(newValue)
    }

    useEffect(() => {
        if (searchText !== '' && searchText.length > 2) {
          clearTimeout(timeoutRef.current)
          timeoutRef.current = setTimeout(() => {
            getProgrammes()
          }, 500)
    
          return () => {
            clearTimeout(timeoutRef.current)
          }
        }
    }, [searchText])

    const getProgrammes = async () => {
        const programmeService = new ProgrammeService()
        const response = (
          await programmeService.search({filter_filters: { name: searchText , limit: 25 }})
        ).response
        if (response?.status === 200) {
          const options = response.data.data.map((element : any) => ({ 
            value: element.id, 
            label: `${element.name} [${element.cmsId}]`,
            published: element.publishedAt?.date
          }))
          setPrograms(options)
        }
    }

    const getConnects = async () => {
        let response = (await (new ConnectService().search({ filter_order: [{ field: 'name', order: 'asc' }] }))).getResponseData();
        if (response.success) {
            // parse connect to select
            let connects = response.data.map((element: any) => ({ value: element.id, label: element.name }))
            setConnects(connects);
        } else {
            console.error("Error getting available connects.")
        }
    }

    const getTherapeuticAreas = async () => {
        let response = (await (new TherapeuticAreaService().search({ filter_order: [{ field: 'name', order: 'asc' }] }))).getResponseData();
        if (response.success) {
            let therapeuticAreas = response.data.map((element: any) => ({ value: element.id, label: element.name }))
            setTherapeuticAreas(therapeuticAreas);
        } else {
            console.error("Error getting available therapeutic areas.")
        }
    }

    const getFormats = async () => {
        let response = (await (new FormatService().search())).getResponseData();
        if (response.success) {
            let formats = response.data.map((element: any) => ({ value: element, label: replaceUnderscoresWithSpacesAndCapitalize(element) }));
            setFormats(formats);
        } else {
            console.error("Error getting available programmes.")
        }
    }
    
    return (
        <>
            {insightLevelSelected === 'connects' && (
                <div className='p-2 flex-fill' style={{ minWidth: '250px' }}>
                    <div className='form-group' id={'connect'}>
                        <CustomSelect options={connects} is_multi={true}  placeholder="Select Connects" onChange={onChangeConnects} key={'connect'}/>
                    </div>
                </div>
            )}
            {insightLevelSelected === 'programmes' && (
                <div className='p-2 flex-fill' style={{ minWidth: '250px' }}>
                    <div className='form-group' id={'program'}>
                        <CustomSelect options={programs} is_multi={true}  placeholder="Select Programs" onChange={onChangeProgram} key={'program'}
                        onInputChange={handleValueInputChange}/>
                    </div>
                </div>
            )}
            {insightLevelSelected === 'formats' && (
                <div className='p-2 flex-fill' style={{ minWidth: '250px' }}>
                    <div className='form-group' id={'format'}>
                        <CustomSelect options={formats} is_multi={true}  placeholder="Select Formats" onChange={onChangeFormat} key={'format'}/>
                    </div>
                </div>
            )}
            {insightLevelSelected === 'therapeutic_areas' && (
                <div className='p-2 flex-fill' style={{ minWidth: '250px' }}>
                    <div className='form-group' id={'therapeutic_area'}>
                        <CustomSelect options={therapeuticAreas} is_multi={true}  placeholder="Select Therapeutic Areas" onChange={onChangeTherapeuticArea} key={'therapeutic_area'}/>
                    </div>
                </div>
            )}
        </>
    )
};

export default InsightSelects;