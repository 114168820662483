import { FC } from "react";

interface SpinnerCustomProps {
  className?: string;
  size?: "large" | "small" | "medium";
}

const SpinnerCustom:FC<SpinnerCustomProps> = ({className , size }) => {

 return (
   <div className={`${size == 'small' ? 'small-spinner-wrapper' :  size == 'medium' ? 'medium-spinner-wrapper' : 'large-spinner-wrapper'}  ${className ? className : ""}`}>
     <svg width="150" height="40">
       <text x="70%" y="70%" dy=".35em" textAnchor="middle">
        P<tspan className="orange-spinner">O</tspan>LUS
       </text>
     </svg>
   </div>
 );

};

SpinnerCustom.defaultProps = {
  size: "small",
};


export default SpinnerCustom;