import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { LoginService } from '../../../services/auth/LoginService'
import { useDispatch } from 'react-redux'
import { setLoginFormStatus } from '../../../store/reducers/login_form_reducer'
import { ResetByCodeForm } from './ResetByCodeForm'
import { LoginForm } from './LoginForm'
import { NewPasswordForm } from './NewPasswordForm'

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
});



const initialValues = {
    email: '',
    password: '',
}

type LoginStatus = 'REQUIRE_RESET' | 'VALIDATION_CODE' | 'NORMAL';

type NormalLoginProps = {
    onNormalLoginSuccess: (result: any) => void
}

export default function NormalLogin({onNormalLoginSuccess}: NormalLoginProps) {

    const [loading, setLoading] = useState(false);
    const [cognitoStatus, setCognitoStatus] = useState('NORMAL');
    const [cognitoUser, setCognitoUser] = useState(null);
    const [userAttributes, setUserAttributes] = useState(null);
    const dispatch = useDispatch();

    const onLoginSuccess = (result: any) => {
        if(result.success === true) {
            onNormalLoginSuccess(result.data);
        } else {
            if(result.code === 'RELOAD') {
                window.location.reload();
            }
        }
    }


    // In case of login error.
    const onLoginError = (error: any) => {
        if(error.code === "PasswordResetRequiredException") {
            dispatch(setLoginFormStatus(error.message + ". Check your email for the code."));
            setCognitoStatus('REQUIRE_RESET');
            setCognitoUser(error.cognitoUser);
        } else if (error.code === "NEW_PASSWORD_REQUIRED") {
            dispatch(setLoginFormStatus(error.message));
            setCognitoStatus('NEW_PASSWORD_REQUIRED');
            setCognitoUser(error.cognitoUser);
            setUserAttributes(error.userAttributes);
        }else {
            dispatch(setLoginFormStatus(error.message));
            setCognitoStatus('NORMAL');
        }
    }


    const renderForm = () => {
        switch (cognitoStatus) {
            case 'REQUIRE_RESET':
                return (<ResetByCodeForm cognitoUser={cognitoUser} onLoginSuccess={onLoginSuccess} onLoginError={onLoginError}></ResetByCodeForm>);
            case 'NEW_PASSWORD_REQUIRED':
                return (<NewPasswordForm cognitoUser={cognitoUser} userAttributes={userAttributes} onLoginSuccess={onLoginSuccess} onLoginError={onLoginError}></NewPasswordForm>);
                break;
            case 'NORMAL':
                return (<LoginForm onLoginSuccess={onLoginSuccess} onLoginError={onLoginError}></LoginForm>);
        }
    }


    return (
        <div>
            { renderForm() }
        </div>
    );
}