import {RestServiceConnection} from '../RestServiceConnection'

export class DatabaseManagerService extends RestServiceConnection {
  getFieldList = async () => {
    this.response = await this.makeRequest(
      {
        method: 'POST',
        url: '/db-manager/contact/fieldMapping',
      },
      true
    )
    return this
  }

  getListSearch = async (data: any) => {
    this.response = await this.makeRequest(
      {
        method: 'POST',
        url: '/db-manager/contact/listSearch',
        data: data,
      },
      true
    )
    return this
  }

  getSavedQueries = async () => {
    this.response = await this.makeRequest(
      {
        method: 'POST',
        url: '/db-manager/contact/getSavedQueries',
      },
      true
    )
    return this
  }

  dispatchQuery = async (data: any) => {
    const restoredConditionGroups = data.condition_groups.map((group: any) => {
      const lastConditionIndex = group.conditions.length - 1
      const restoredConditions = group.conditions.map((condition: any, index: number) => {
        if (index === lastConditionIndex) {
          return {...condition, type: 'AND'}
        }
        return condition
      })

      return {...group, conditions: restoredConditions}
    })

    const modifiedData = {...data, condition_groups: restoredConditionGroups}

    this.response = await this.makeRequest(
      {
        method: 'POST',
        url: '/db-manager/contact/dispatchQuery',
        data: modifiedData,
      },
      true
    )

    return this
  }

  saveQuery = async (name: string, query: any) => {
    const restoredConditionGroups = query.condition_groups.map((group: any) => {
      const lastConditionIndex = group.conditions.length - 1
      const restoredConditions = group.conditions.map((condition: any, index: number) => {
        if (index === lastConditionIndex) {
          return {...condition, type: 'AND'}
        }
        return condition
      })

      return {...group, conditions: restoredConditions}
    })

    const modifiedQuery = {...query, condition_groups: restoredConditionGroups}
    this.response = await this.makeRequest(
      {
        method: 'POST',
        url: '/db-manager/contact/saveQuery',
        data: {
          name: name,
          query_input: modifiedQuery,
        },
      },
      true
    )
    return this
  }

  deleteQuery = async (id: string) => {
    this.response = await this.makeRequest(
      {
        method: 'POST',
        url: '/db-manager/contact/deleteSavedQuery',
        data: {
          id: id,
        },
      },
      true
    )
    return this
  }

  exportQuery = async (query: any) => {
    const restoredConditionGroups = query.condition_groups.map((group: any) => {
      const lastConditionIndex = group.conditions.length - 1
      const restoredConditions = group.conditions.map((condition: any, index: number) => {
        if (index === lastConditionIndex) {
          return {...condition, type: 'AND'}
        }
        return condition
      })

      return {...group, conditions: restoredConditions}
    })

    const modifiedQuery = {...query, condition_groups: restoredConditionGroups}
    this.response = await this.makeRequest(
      {
        method: 'POST',
        url: '/db-manager/contact/exportQuery',
        data: modifiedQuery,
        responseType: 'blob',
      },
      true
    )
    return this
  }
}
