import { MetricService } from "app/services/metric/MetricService";
import { MetricUtil } from "app/services/metric/MetricUtil";
import React, { useEffect, useState } from "react";
import { Metric, ReachMetric } from "../../../components/metrics/ReachMetric";
import { useDataReport } from "../../../DataReportProvider";
import { metrics } from "app/utils/data/metrics";
import { getLoadedMetricById } from "app/utils/utils";

const ReachSocialMedia: React.FC = () => {

    const dataReport = useDataReport()

    //Twitter hooks
    const [twitterFollowers, setTwitterFollowers] = useState<Metric[] | null>(null)
    const [twitterImpressions, setTwitterImpressions] = useState<Metric[] | null>(null)
    const [twitterVideoImpressions, setTwitterVideoImpressions] = useState<Metric[] | null>(null)

    //Linkedin hooks
    const [linkedinImpressions, setLinkedinImpressions] = useState<Metric[] | null>(null)
    const [linkedinUniqueImpressions, setLinkedinUniqueImpressions] = useState<Metric[] | null>(null)


    //Twitter APIs

    const getTwitterFollowers = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.twitterFollowers.endpoint)).getResponseData();
        if (response.success === true) {
            setTwitterFollowers(response.data);
        }
    }

    const getTwitterImpressions = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.twitterImpressions.endpoint)).getResponseData();
        if (response.success === true) {
            setTwitterImpressions(response.data);
        }
    }

    const getTwitterVideoImpressions = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.twitterVideoImpressions.endpoint)).getResponseData();
        if (response.success === true) {
            setTwitterVideoImpressions(response.data);
        }
    }

    //Linkedin APIs
    const getLinkedinImpressions = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.linkedinImpressions.endpoint)).getResponseData();
        if (response.success === true) {
            setLinkedinImpressions(response.data);
        }
    }


    const getLinkedinUniqueImpressions = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.linkedinUniqueImpressions.endpoint)).getResponseData();
        if (response.success === true) {
            setLinkedinUniqueImpressions(response.data);
        }
    }

    /** 
     * To calculate amount and percent of latest "n" periods, we need to modify date filtered before 
     * make request. We need to do that because the widget needs to retrive last 24
     * times periods before the requested date to calculate the amount 
    */
    const getMetrics = () => {

        const calculateStartDate = MetricUtil.calculateStartDate(dataReport.params.filter_filters.between_dates.from, dataReport.params.filter_filters.mode);

        const modifiedParams = {
            ...dataReport.params,
            filter_filters: {
                ...dataReport.params.filter_filters,
                between_dates: {
                    from: calculateStartDate,
                    to: dataReport.params.filter_filters.between_dates.to
                }
            }
        };

        //Twitter get
        let twitter_followers = getLoadedMetricById(metrics.twitterFollowers.id);
        let twitter_impressions = getLoadedMetricById(metrics.twitterImpressions.id);
        let twitter_video_impressions = getLoadedMetricById(metrics.twitterLikes.id);
        let linkedin_unique_impressions = getLoadedMetricById(metrics.linkedinUniqueImpressions.id);

        //Linkedin get
        let linkedin_impressions = getLoadedMetricById(metrics.linkedinImpressions.id);


        //Twitter conditions
        if (twitter_followers !== null && twitter_followers !== undefined) {
            setTwitterFollowers(twitter_followers);
        } else {
            getTwitterFollowers(modifiedParams);
        }

        if (twitter_impressions !== null && twitter_impressions !== undefined) {
            setTwitterImpressions(twitter_impressions);
        } else {
            getTwitterImpressions(modifiedParams);
        }

        if (twitter_video_impressions !== null && twitter_video_impressions !== undefined) {
            setTwitterVideoImpressions(twitter_video_impressions);
        } else {
            getTwitterVideoImpressions(modifiedParams);
        }
        if (linkedin_unique_impressions !== null && linkedin_unique_impressions !== undefined) {
            setLinkedinUniqueImpressions(linkedin_unique_impressions);
        } else {
            getLinkedinUniqueImpressions(modifiedParams);
        }

        //Linkedin condition
        if (linkedin_impressions !== null && linkedin_impressions !== undefined) {
            setLinkedinImpressions(linkedin_impressions);
        } else {
            getLinkedinImpressions(modifiedParams);
        }

    }

    const resetReports = () => {
        //Twitter
        setTwitterFollowers(null);
        setTwitterImpressions(null);
        setTwitterVideoImpressions(null);

        //Linkedin
        setLinkedinImpressions(null)

        setLinkedinUniqueImpressions(null);
    }


    useEffect(() => {
        resetReports();
        getMetrics();
    }, [dataReport.params])


    return (
        <>
            <div className='row g-5 g-xl-8' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                <div className="col-xl-12">
                    <hr></hr>
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/brand-logos/'+metrics.twitterImpressions.logo}
                        snapshotId={metrics.twitterImpressions.id}
                        title={metrics.twitterImpressions.title}
                        subtitle={metrics.twitterImpressions.subtitle}
                        startPeriod={24}
                        metrics={twitterImpressions}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/brand-logos/'+metrics.twitterFollowers.logo}
                        snapshotId={metrics.twitterFollowers.id}
                        title={metrics.twitterFollowers.title}
                        subtitle={metrics.twitterFollowers.subtitle}
                        startPeriod={24}
                        metrics={twitterFollowers}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/brand-logos/'+metrics.twitterVideoImpressions.logo}
                        snapshotId={metrics.twitterVideoImpressions.id}
                        title={metrics.twitterVideoImpressions.title}
                        subtitle={metrics.twitterVideoImpressions.subtitle}
                        startPeriod={24}
                        metrics={twitterVideoImpressions}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/brand-logos/' + metrics.linkedinUniqueImpressions.logo}
                        snapshotId={metrics.linkedinUniqueImpressions.id}
                        title={metrics.linkedinUniqueImpressions.title}
                        subtitle={metrics.linkedinUniqueImpressions.subtitle}
                        startPeriod={24}
                        metrics={linkedinUniqueImpressions}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>

                {/* Linkedin */}

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/brand-logos/' + metrics.linkedinImpressions.logo}
                        snapshotId={metrics.linkedinImpressions.id}
                        title={metrics.linkedinImpressions.title}
                        subtitle={metrics.linkedinImpressions.subtitle}
                        startPeriod={24}
                        metrics={linkedinImpressions}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>

            </div>
        </>
    )
}


export default ReachSocialMedia;