import { SnapshotService } from "app/services/snapshot/SnapshotService";
import { validateYupSchema } from "formik";
import { FC, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import { string } from "yup";

interface Props {
    users: Array<Object>,
    handleSubmitResponse: Function,
    defaultUsers: Array<Object>,
    defaultName: string
    snapshotId: string
}

const SnapshotForm: FC<Props> = ({ users, handleSubmitResponse, defaultUsers, defaultName, snapshotId }) => {

    const [selectedUsers, setSelectedUsers] = useState<Array<Object>>(defaultUsers);
    const [creating, setCreating] = useState(false);
    const [fieldError, setFieldError] = useState<Array<string>>([]);
    
    const updateSnapshot = async (snapshotName: string, selectedUsersValue: Array<string>) => {

        let response = await (await (new SnapshotService()).updateSnapshotUsers(snapshotId, snapshotName, selectedUsersValue)).getResponseData();

        return response.success;
    }

    const validateData = (snapshotName: string) => {
        if (snapshotName !== "" && selectedUsers.length > 0) {
            return true;
        } else {
            if (snapshotName === "") {
                setFieldError(value => [...value, "snapshotName"]);
            }
            if (selectedUsers.length === 0) {
                setFieldError(value => [...value, "selectedUsers"]);
            }

            setTimeout(() => {
                setFieldError([]);
            }, 5000);

            return false;
        }
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        let snapshotName = event.target[0].value;

        if (validateData(snapshotName)) {

            setCreating(true);

            let selectedUsersValue = selectedUsers.map((user: any) => user.value);

            let response = await updateSnapshot(snapshotName, selectedUsersValue);

            setCreating(false);

            setSelectedUsers([]);
            event.target[0].value = "";

            if (response == true) {
                handleSubmitResponse(true);
            } else {
                handleSubmitResponse(false);
            }
        }
    }

    const handleChange = (values: any) => {
        setSelectedUsers(values);
    }

    return (
        <>
            <form id="create-user-form" onSubmit={handleSubmit}></form>
            <div className='form-group p-2 mb-2'>
                <label className='form-label fw-bold'>Name: <span className="text-danger">*</span></label>
                <input defaultValue={defaultName} type="text" form="create-user-form" id="snapshotName" name="snapshotName" className="form-control" placeholder="Snapshot name" />
                {fieldError.includes('snapshotName') && <small className="text-danger mt-1"> Required field </small>}
            </div>

            <div className='form-group p-2 mb-5'>
                <label className='form-label fw-bold'>Users <span className="text-danger">*</span></label>
                <div className='form-group'>
                    <Select options={users} form="create-user-form" id="users" value={selectedUsers} name="users" placeholder="Select Users" onChange={handleChange} isMulti={true}></Select>
                    {fieldError.includes('selectedUsers') && <small className="text-danger mt-1"> Required field </small>}
                </div>
            </div>

            <div className="d-flex justify-content-end">
                <button disabled={creating} form="create-user-form" type="submit" className="btn btn-primary">
                    {creating && (<Spinner animation="border" color="primary" size="sm" ></Spinner>)}
                    {!creating && "Create"}
                </button>
            </div>
        </>
    );
}

export default SnapshotForm;








