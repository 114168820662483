import React, { createContext, useContext, useEffect, useState } from "react"
import _ from 'lodash'
import { Programme } from "app/models/programmes/Programme";

export type IndivualMetricFilterMode = 'months' | 'years' | 'weeks' | 'days' | 'Q';

export type IndivualMetricFilters = {
    category: string[],
    group: string[],
    tags: string[],
    custom_tags: string[],
    mode: IndivualMetricFilterMode,
    connects?: number[],
    programmes?: number[]
    campaigns?: number[],
    therapeutic_areas?: number[],
    between_dates?: any,
    source?: string | undefined
}

export type IndivualMetricFilterParams = {
    filter_filters: IndivualMetricFilters
}

type IndivualMetricContextModel = {
    params: IndivualMetricFilterParams,
    metrics: IndivualMetricMetrics,
    showSnapshotModal: boolean,
    programmes: Programme[],
    updateFilters: (filters: Partial<IndivualMetricFilters>) => void,
    updateMetrics: (metric: string) => void,
    updateIndivualMetricProgrammes: (programmes: Programme[]) => void,
    resetFilters: () => void,
    updateShowSnapshotModal: (show: boolean) => void
}

const defaultFilterParams: IndivualMetricFilterParams = {
    filter_filters: {
        category: [],
        group: [],
        tags: [],
        custom_tags: [],
        campaigns: [],
        connects: [],
        programmes: [],
        mode: 'weeks'
    }
}

type IndivualMetricMetrics = string[];

type ShowSnapshotModal = boolean;

const defaultIndividualMetricMetrics: IndivualMetricMetrics = [];

const defaultShowSnapshotModal: ShowSnapshotModal = false;

const IndivualMetricContext = createContext<IndivualMetricContextModel>({
    params: defaultFilterParams,
    metrics: defaultIndividualMetricMetrics,
    showSnapshotModal: defaultShowSnapshotModal,
    programmes: [],
    updateFilters: (filters: Partial<IndivualMetricFilters>) => { },
    updateMetrics: (metricId: string) => { },
    updateShowSnapshotModal: (show: boolean) => { },
    updateIndivualMetricProgrammes: (programmes: Programme[]) => { },
    resetFilters: () => { }
})


const IndivualMetricProvider: React.FC = ({ children }) => {

    const [params, setParams] = useState<IndivualMetricFilterParams>(defaultFilterParams)
    const [metrics, setMetrics] = useState<IndivualMetricMetrics>(defaultIndividualMetricMetrics)
    const [showSnapshotModal, setShowSnapshotModal] = useState<ShowSnapshotModal>(defaultShowSnapshotModal)
    const [programmes, setProgrammes] = useState<Programme[]>([])
    // const [allowedMetrics, setAllowedMetrics] = useState<string[]>([])


    const updateShowSnapshotModal = (show: boolean) => {
        setShowSnapshotModal(show)
    }


    const updateFilters = (filters: Partial<IndivualMetricFilters>) => {

        let newObject = {
            ...params,
            filter_filters: {
                ...params.filter_filters,
                ...filters
            }
        }

        if (_.isEqual(params, newObject) === false) {
            setParams(newObject)
        }

    }

    const resetFilters = () => {
        setParams(defaultFilterParams)
    }

    const updateMetrics = (metricId: string) => {

        let newMetrics = metrics;

        if (newMetrics.includes(metricId)) {
            newMetrics = newMetrics.filter((metric) => metric !== metricId);
        } else {
            newMetrics.push(metricId);
        }

        setMetrics(newMetrics);
    }

    const updateIndivualMetricProgrammes = (programmes: Programme[]) => {
        setProgrammes(programmes)
    }

    const value: IndivualMetricContextModel = {
        params,
        metrics,
        showSnapshotModal,
        programmes,
        updateFilters,
        updateMetrics,
        resetFilters,
        updateIndivualMetricProgrammes,
        updateShowSnapshotModal
    }

    useEffect(() => {
        // console.log("do request...");
    }, [params.filter_filters])


    return <IndivualMetricContext.Provider value={value}>{children}</IndivualMetricContext.Provider>
}


export { IndivualMetricProvider, IndivualMetricContext }

export function useIndividualMetric() {
    return useContext(IndivualMetricContext);
}