import CustomSelect from '@components/form/CustomSelect'
import {KTSVG} from '_metronic/helpers'
import {FC, useContext, useEffect, useState} from 'react'
import DbManagerContext from '../context/DbManagerProvider'
import FieldComponent from './FieldComponent'
import ConditionSelect from './ConditionSelect'

interface ConditionIProps {
  group: any
  dispatch: any
  groupID: number
}

const ConditionI: FC<ConditionIProps> = ({group, dispatch, groupID}) => {

  const {fieldsData} = useContext(DbManagerContext)

  const [fieldOptions, setFieldOptions] = useState<any>([])

  const [isDeleted , setIsDeleted] = useState<boolean>(false)

  const [conditions, setConditions] = useState<any>([])

  const getFieldDefault = (field: any) => {
    const fieldData = fieldsData.find((fieldData: any) => fieldData.name === field)
    if (fieldData) {
      return {label: fieldData.label, value: fieldData.name}
    }
    return null
  }

  const getFieldOptions = () => {
    const options = fieldsData.map((field: any) => {
      return {label: field.label, value: field.name}
    })
    setFieldOptions(options)
  }

  const getType = (fieldSelected: any, conditionSelected: any) => {
    const selectedFieldData = fieldsData.find((field: any) => field.name === fieldSelected)
    if (selectedFieldData) {
      const selectedConditionData = selectedFieldData.available_conditions[conditionSelected]
      if (selectedConditionData) {
        return selectedConditionData.type
      }
    }
    return null
  }

  useEffect(() => {
    getFieldOptions()
  }, [fieldsData])

  return (
    <>
      {group.conditions.map((row: any, rowID: number) => (
        <div className='row mt-4' key={rowID}>
          <div className='col-xl-3'>
            <div className='p-2 flex-fill' style={{minWidth: '200px'}}>
              <div className='form-group'>
                <label className='form-label fw-bold'>Field</label>
                <CustomSelect
                  key={row.field}
                  options={fieldOptions}
                  placeholder='Select Field'
                  defaultValue={getFieldDefault(row.field)}
                  onChangeSingle={(e: any) => {
                    dispatch({
                      type: 'UPDATE_CONDITION_VALUE',
                      payload: {groupIndex: groupID, conditionIndex: rowID, field: e.value},
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className='col-xl-3'>
            <ConditionSelect dispatch={dispatch} groupID={groupID} rowID={rowID} row={row} />
          </div>
          <FieldComponent
            dispatch={dispatch}
            groupID={groupID}
            rowID={rowID}
            row={row}
            getType={getType}
            isDeleted = {isDeleted}
            setIsDeleted = {setIsDeleted}
          />
          <div className='col-xl-3 d-flex align-items-center justify-content-around mt-3 mb-3'>
            <div className='row'>
              <div
                className='col-4'
                role='button'
                onClick={() => {
                  dispatch({
                    type: 'UPDATE_CONDITION_TYPE',
                    payload: {groupIndex: groupID, conditionIndex: rowID, conditionType: 'AND'},
                  })
                  if (rowID === group.conditions.length - 1) {
                    dispatch({
                      type: 'ADD_CONDITION',
                      payload: {groupIndex: groupID},
                    })
                  }
                }}
              >
                <div className='symbol symbol-60px'>
                  <span
                    className={`symbol-label rounded-60 ${
                      row.type === 'AND' ? 'bg-primary text-light' : 'bg-light text-primary'
                    }`}
                  >
                    AND
                  </span>
                </div>
              </div>
              <div className='col-4' role='button'>
                <div
                  className='symbol symbol-60px'
                  onClick={() => {
                    dispatch({
                      type: 'UPDATE_CONDITION_TYPE',
                      payload: {groupIndex: groupID, conditionIndex: rowID, conditionType: 'OR'},
                    })
                    if (rowID === group.conditions.length - 1) {
                      dispatch({
                        type: 'ADD_CONDITION',
                        payload: {groupIndex: groupID},
                      })
                    }
                  }}
                >
                  <span
                    className={`symbol-label rounded-60 ${
                      row.type === 'OR' ? 'bg-primary text-light' : 'bg-light text-primary'
                    }`}
                  >
                    OR
                  </span>
                </div>
              </div>
              <div
                className={`col-4`}
                role='button'
                onClick={() =>{
                  if (group.conditions.length === 1) {
                    return
                  }
                  dispatch({
                    type: 'DELETE_CONDITION',
                    payload: {groupIndex: groupID, conditionIndex: rowID},
                  })
                  setIsDeleted(true)
                }}
              >
                <div className='symbol symbol-60px'>
                  <span className={`symbol-label rounded-60 bg-light`}>
                    <KTSVG
                      path='/media/icons/duotune/general/gen027.svg'
                      className={`svg-icon-2 svg-icon-primary`}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default ConditionI
