import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type AuthState = {
    show_bots: boolean
}

const initialState: AuthState = {
    show_bots: true
};

const botsSlice = createSlice({
    name: 'bots',
    initialState,
    reducers: {
        setShow: (state: AuthState, action: PayloadAction<boolean>) => {
            state.show_bots = action.payload;
        }
    }
});

export const {setShow} = botsSlice.actions;

export default botsSlice.reducer;
