import { RestServiceConnection } from "../RestServiceConnection";


export class cacheService extends RestServiceConnection {
    
    clear = async () => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/settings/backend-cache-clear',
        }, true);
        return this;
    }
}