import { CustomDateRangePicker } from "@components/form/CustomDateRangePicker";
import _ from "lodash";
import { FC, useState } from "react";
import { KTSVG } from "_metronic/helpers";

export type SnapshotFilters = {
  between_dates?: RangeDate | null;
}

export type RangeDate = {
  startDate: string | null,
  endDate: string | null
}

type Props = {
  setFilters: (filters: SnapshotFilters | null) => void;
  filters: SnapshotFilters | null;
}

const SnapshotListFilters: FC<Props> = ({ setFilters, filters }) => {

  const [betweenDates, setBetweenDates] = useState<RangeDate | null>(null);

  const onChangeDate = (range: RangeDate) => {

    if (range.startDate && range.endDate) {
      updateFilters({
        between_dates: {
          startDate: range.startDate,
          endDate: range.endDate
        }
      });
    }

  }

  const updateFilters = (value: Partial<SnapshotFilters>) => {

    let newObject = {
      ...filters,
      ...value

    }

    if (_.isEqual(filters, newObject) === false) {
      setFilters(newObject)
    }

  }

  return (

    <div className="column">
      <div className="row">
        <button type='button' className='btn btn-sm btn-link p-0 mb-5 d-flex justify-content-end' title={'Clear filter'} onClick={() => { setFilters(null) }}>
          <KTSVG path='/media/icons/duotune/art/art004.svg' className='svg-icon-2' />
          Clear
        </button>
      </div>
      <div className="row">
        <h6 className="text-muted"> Date range </h6>
        <CustomDateRangePicker active={filters != null ? true : false} onChangeDate={(range) => onChangeDate(range)} > </CustomDateRangePicker>
      </div>
    </div>
  )
}


export default SnapshotListFilters;