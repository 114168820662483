import { Metric } from 'app/models/metrics/Metric';
import { MetricService } from 'app/services/metric/MetricService';
import { FC, useState, useEffect } from 'react'
import { ProgrammeExplorerCard } from '../../components/ProgrammeExplorerCard';
import { useProgrammeExplorer } from '../../ProgrammeExplorerProvider';


const ProgrammeExplorerEngagementWrapper: FC = () => {

  const [showMetrics, setShowMetrics] = useState<boolean>(false);
  const [allowedMetrics, setAllowedMetrics] = useState<Metric[]>([]);

  const ProgrammeExplorer = useProgrammeExplorer();


  const toggleMetricsShow = () => {
    let metricsShowStatus = false
    const { programmes, campaigns , connects, between_dates } = ProgrammeExplorer.params.filter_filters;
    if ( 
      (programmes !== undefined && programmes.length > 0)
      || (campaigns !== undefined && campaigns.length > 0)
    ) {
      metricsShowStatus = true
    } else if (
      (between_dates !== undefined && between_dates?.from !== undefined && between_dates?.to !== undefined)
    ) {
      metricsShowStatus = true
    } else if (
      (connects !== undefined && connects.length > 0)
    ) {
      metricsShowStatus = true
    }
    setShowMetrics(metricsShowStatus);
  }

  const getAllowedMetrics = async () => {
      const response = await (await ( (new MetricService()).getAllowedMetricsByCategory('engagement', true))).getResponseData();

      if (response.success === false) {
          console.error("Error getting allowed metrics", response);
          toastr.error("Error getting allowed metrics");
      }

      setAllowedMetrics(response.data);
  }

  useEffect(() => {
    getAllowedMetrics();
  }, [])

  useEffect(() => {
    toggleMetricsShow();
  }, [ProgrammeExplorer.params.filter_filters])


  return (
    <div className='row'>
      {
        !showMetrics ?
          (<h3 className={'text-muted text-center'}>Please select project code to visualise data</h3>)
          : allowedMetrics.map((metric) => {
              return ProgrammeExplorer.getMetricProgrammeExplorerCard(metric, ProgrammeExplorer.params) 
          })
          
          /*(
            <div className='row'>
              <ProgrammeExplorerCard
                className='card-xl-stretch mb-5 mb-xl-8'
                image='/media/svg/brand-logos/google-icon.svg'
                title='Web Visits (sessions)'
                filters={ProgrammeExplorer.params}
                metric='website_visits'
                canOpen={true}
              />
              <ProgrammeExplorerCard
                className='card-xl-stretch mb-5 mb-xl-8'
                image='/media/svg/brand-logos/google-icon.svg'
                title='Page Views'
                filters={ProgrammeExplorer.params}
                metric='page_views'
                canOpen={true}
              />
              <ProgrammeExplorerCard
                className='card-xl-stretch mb-5 mb-xl-8'
                image='/media/svg/brand-logos/google-icon.svg'
                title='Slide Downloads'
                filters={ProgrammeExplorer.params}
                metric='slide_downloads'
                canOpen={true}
              />
              <ProgrammeExplorerCard
                className='card-xl-stretch mb-5 mb-xl-8'
                image='/media/svg/brand-logos/google-icon.svg'
                title='Video Views'
                filters={ProgrammeExplorer.params}
                metric='video_views'
                canOpen={true}
              />
              <ProgrammeExplorerCard
                className='card-xl-stretch mb-5 mb-xl-8'
                image='/media/svg/brand-logos/active-campaign.svg'
                title='Open Rate'
                filters={ProgrammeExplorer.params}
                metric='newsletter_open_rate'
                canOpen={true}
                showFilteredTimeSeries={true}
                showFilteredSplitBySource={false}
                showFilteredSplitByRegion={true}
                showFilteredProgrammesRanking={true}
                showFilteredTherapeuticAreaRanking={false}
              />
              <ProgrammeExplorerCard
                className='card-xl-stretch mb-5 mb-xl-8'
                image='/media/svg/brand-logos/spotify.svg'
                title='Podcast Listens'
                filters={ProgrammeExplorer.params}
                metric='podcast_listens'
                canOpen={true}
                showFilteredTimeSeries={true}
                showFilteredSplitBySource={false}
                showFilteredSplitByRegion={false}
                showFilteredProgrammesRanking={false}
                showFilteredTherapeuticAreaRanking={false}
              />
            </div>
          )
          */
      }

    </div>

  )
}

export { ProgrammeExplorerEngagementWrapper }
