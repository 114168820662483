import { useDataReport } from 'app/pages/data_report/DataReportProvider';
import ConfigRoutes from 'app/routing/ConfigRoutes';
import { resetLoadedMetrics } from 'app/store/reducers/metric_reducer';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom'

export default function ResetMetricsComponent() {
    
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if(location.pathname.includes(ConfigRoutes.admin.dataReport.principal) === false) {
            dispatch(resetLoadedMetrics());
        }
    }, [location.key])
    
  return (
    <> </>
  )
}
