import {useMarketing} from '../../MarketingProvider'
import { useEffect, useState } from 'react';
import MarketingTableCustom from '../../components/MarketingTableCustom';
import { formatNumber, limitDecimals, replaceUnderscoresWithSpacesAndCapitalize } from 'app/utils/utils';
import SpinnerCustom from '@components/SpinnerCustom';
import { metricMarketingUtils } from 'app/services/metric/MetricMarketingUtils';

const MarketingConversionsWrapper = () => {

  const Marketing = useMarketing();

  const [data, setData] = useState<any>(null);

  const [oldData, setOldData] = useState<any>(null);

  const abortControllers = {
    slideDownloads: new AbortController(),
    videoViews: new AbortController(),
    podcastListens: new AbortController(),
    feedbackSubmitted: new AbortController(),
  };

  const getMetricsData = async () => {
    let group = Marketing.params.filter_filters.group; // GET GROUP

    if (group === 'therapeutic_areas') {
      group = 'therapeutic_areas_ids';
    }

    const marketingUtils = new metricMarketingUtils();

    // GET DATA FOR EACH METRIC

    const metricsArray = await Promise.all([
      marketingUtils.getTotalMetriConversion(abortControllers.slideDownloads.signal, 'slide_downloads', Marketing),
      marketingUtils.getTotalMetriConversion(abortControllers.videoViews.signal, 'video_views', Marketing),
      marketingUtils.getTotalMetriConversion(abortControllers.podcastListens.signal, 'podcast_listens', Marketing),
      marketingUtils.getTotalMetriConversion(abortControllers.feedbackSubmitted.signal, 'feedback_submitted', Marketing),
    ]).then((values) => ({
      slideDownloadsData: values[0],
      videoViewsData: values[1],
      podcastListensData: values[2],
      feedbackSubmittedData: values[3],
    }));
  
    const arrayColumns = Marketing.params.filter_filters.selected_names; // GET ARRAY OF SELECTED VALUES

    const idSelected = Marketing.params.filter_filters[`${group}` as keyof typeof Marketing.params.filter_filters] as any;

    const data = Array.isArray(arrayColumns) ?  // IF ARRAY, RETURN DATA FOR EACH VALUE
      await Promise.all(arrayColumns.map(async (column: any, index: number) => {

          let slideDownloads = limitDecimals(metricsArray.slideDownloadsData.find((element: any) => element.group === column)?.total == null ? 0 : metricsArray.slideDownloadsData.find((element: any) => element.group === column).total);
          let videoViews = limitDecimals(metricsArray.videoViewsData.find((element: any) => element.group === column)?.total == null ? 0 : metricsArray.videoViewsData.find((element: any) => element.group === column).total);
          let podcastListens= limitDecimals(metricsArray.podcastListensData.find((element: any) => element.grouped === column)?.total == null ? 0 : metricsArray.podcastListensData.find((element: any) => element.grouped === column).total);
          let feedbackSubmitted= limitDecimals(metricsArray.feedbackSubmittedData.find((element: any) => element.grouped === column)?.total == null ? 0 : metricsArray.feedbackSubmittedData.find((element: any) => element.grouped === column).total);

          if ( Marketing.params.filter_filters.group === 'overall' && Marketing.params.filter_filters.selected_group === 'overall' ) {
            slideDownloads = limitDecimals(metricsArray.slideDownloadsData[0].total == null ? 0 : metricsArray.slideDownloadsData[index].total);
            videoViews = limitDecimals(metricsArray.videoViewsData[0]?.total == null ? 0 : metricsArray.videoViewsData[0].total);
            podcastListens = limitDecimals(metricsArray.podcastListensData[0]?.total == null ? 0 : metricsArray.podcastListensData[0].total);
            feedbackSubmitted = limitDecimals(metricsArray.feedbackSubmittedData[0]?.total == null ? 0 : metricsArray.feedbackSubmittedData[0].total);
          }

          if ( Marketing.params.filter_filters.group == 'formats' && Marketing.params.filter_filters.selected_group == 'formats' ) {
            let formatColumn = column.toLowerCase().replace(' ', '-').trim();
            slideDownloads = limitDecimals(metricsArray.slideDownloadsData.find((element: any) => element.group === formatColumn)?.total == null ? 0 : metricsArray.slideDownloadsData.find((element: any) => element.group === formatColumn).total);
            videoViews = limitDecimals(metricsArray.videoViewsData.find((element: any) => element.group === formatColumn)?.total == null ? 0 : metricsArray.videoViewsData.find((element: any) => element.group === formatColumn).total);
            podcastListens= limitDecimals(metricsArray.podcastListensData.find((element: any) => element.grouped === formatColumn)?.total == null ? 0 : metricsArray.podcastListensData.find((element: any) => element.grouped === formatColumn).total);
            feedbackSubmitted= limitDecimals(metricsArray.feedbackSubmittedData.find((element: any) => element.grouped === formatColumn)?.total == null ? 0 : metricsArray.feedbackSubmittedData.find((element: any) => element.grouped === formatColumn).total);
          }

          return {
            insight:  Marketing.params.filter_filters.selected_names[index], // GET NAME OF SELECTED VALUE
            slideDownloads,
            videoViews,
            podcastListens,
            feedbackSubmitted
          };
        }))
      : []; // IF NOT ARRAY, RETURN EMPTY ARRAY

    return data;
  };

  useEffect(() => {

    if (Marketing.params.filter_filters.group === '') return

    setOldData(data); // SET OLD DATA

    const fetchData = async () => {
      const data = await getMetricsData();
      setData(data);
    };

    fetchData();

    return () => {
      Object.values(abortControllers).forEach(controller => controller.abort());
    };

  }, [Marketing.params.filter_filters]);

  const calculateTotals = (data: any) => {

    let totals = {
      totalSlideDownloads: 0,
      totalVideoViews: 0,
      totalPodcastListens: 0,
      totalFeedbackSubmitted: 0,
    };

    data.forEach((element: any) => {
      totals.totalSlideDownloads += parseFloat(element.slideDownloads);
      totals.totalVideoViews += parseFloat(element.videoViews);
      totals.totalPodcastListens += parseFloat(element.podcastListens);
      totals.totalFeedbackSubmitted += parseFloat(element.feedbackSubmitted);
    });

    return totals;
  };  

  return (
    <>
      {Marketing.params.filter_filters.group !== '' ? 
        Marketing.params.filter_filters.selected_names.length > 0 ? (
          <div className='col-xl-12 position-relative'>
          {data ?  (
          <>
          <div className='bg-primary text-center fw-bolder text-light p-2 fs-4' style={{borderTopLeftRadius : '5px' ,borderTopRightRadius : '5px'}}>CONVERSIONS KPIs</div>
          <div className="p-1" style={{backgroundColor: '#33B4E5'}}></div>
          <MarketingTableCustom
            className='card-primary'
            title='Third-party data'
            subtitle='List of third-party data'
            key={Marketing.params.filter_filters.group}
            showTotalsColumn={true}
            totalsRow={calculateTotals(data)}
            totalsRowPosition='bottom'
            data={data}
            columns={[
              {
                name: `Insight level selected\n ${replaceUnderscoresWithSpacesAndCapitalize(
                  Marketing.params.filter_filters.group || ''
                )}`,
                keyValue: 'insight',
                className: 'text-center bg-marketing-insight pre-line',
                render: (element: any) => {
                  return (
                    <>
                      <div className='text-center fw-bold'>
                        <span>{element.insight}</span>
                      </div>
                    </>
                  )
                },
              },
              {
                name: 'Slide downloads',
                keyValue: 'slideDownloads',
                className: 'align-middle text-center bg-marketing-fields',
                render: (element: any) => {
                  return (
                    <>
                      <div className='text-center'>
                        <span>{formatNumber(limitDecimals(element.slideDownloads))}</span>
                      </div>
                    </>
                  )
                },
              },
              {
                name: 'Video Views',
                keyValue: 'videoViews',
                className: 'align-middle text-center bg-marketing-fields',
                render: (element: any) => {
                  return (
                    <>
                      <div className='text-center'>
                        <span>{formatNumber(limitDecimals(element.videoViews))}</span>
                      </div>
                    </>
                  )
                },
              },
              {
                name: 'Podcast Listens',
                keyValue: 'podcastListens',
                className: 'align-middle text-center bg-marketing-fields',
                render: (element: any) => {
                  return (
                    <>
                      <div className='text-center'>
                        <span>{formatNumber(limitDecimals(element.podcastListens))}</span>
                      </div>
                    </>
                  )
                },
              },
              {
                name: 'Feedback Submitted',
                keyValue: 'feedbackSubmitted',
                className: 'align-middle text-center bg-marketing-fields',
                render: (element: any) => {
                  return (
                    <>
                      <div className='text-center'>
                        <span>{formatNumber(limitDecimals(element.feedbackSubmitted))}</span>
                      </div>
                    </>
                  )
                },
              }
            ]}
          />
        </>
      ) : <></>}
        <div className={data == oldData ? 'custom-overlay' : 'd-none'}>
          <SpinnerCustom className='mt-custom' size='medium'/>
        </div>
        </div>
      ) : (
        <h3 className={'text-muted text-center'}>Please select one or more {`${replaceUnderscoresWithSpacesAndCapitalize(Marketing.params.filter_filters.group)}s`}</h3>
      ) : (
        <h3 className={'text-muted text-center'}>Please select one insinght level</h3>
      )}
    </>
  )
}

export default MarketingConversionsWrapper
