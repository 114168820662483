/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import CustomSelect from '@components/form/CustomSelect';
import Sticky from 'react-sticky-el'
import useIndividualMetricHeader from '../hooks/IndividualMetricHeaderHook';
import { CustomDateRangePicker } from '@components/form/CustomDateRangePicker';
import moment from 'moment';

const IndividualMetricHeader: React.FC = () => {

    const {
        target,
        isOpened,
        betweenDates,
        applyFilters,
        onFixedToggle,
        targetOptions,
        onChangeTarget,
        loading,
        projectCodes,
        onChangeProgrammeProjectCode,
        onChangeConnects,
        connects,
        programmes,
        getProgrammeValues,
        onChangeProgrammes,
        waveOptions,
        onChangeWaves,
        onChangeDate,
        setIsOpened,
        categories,
        stateCustomMetrics,
        onChangeCategories,
        onChangeGroups,
        groups, 
        metrics, 
        onChangeMetrics,
        selectedMetrics,
        selectedCustomMetrics
    } = useIndividualMetricHeader();

    const getDefaultValue = () => {

        let individualMetrics = metrics.filter(metric => selectedMetrics.includes(metric.value));
        
        let customMetrics = metrics.filter(metric => stateCustomMetrics.includes(metric.value));

        return individualMetrics.concat(customMetrics);
    }

    return (

        <>
            <Sticky className='dashboard-header' mode='top' topOffset={0} stickyClassName="sticky sticky-header" onFixedToggle={onFixedToggle}>
                <div className='card mb-0 shadow-sm'>
                    <div className='card-body p-1'>
                        <div className='d-flex flex-wrap justify-content-start'>
                            <div className='p-2 flex-fill' style={{ minWidth: '250px' }}>
                                <div className='form-group'>
                                    <CustomSelect options={targetOptions} onChangeSingle={onChangeTarget} placeholder="Select target..." />
                                </div>
                            </div>
                            {target === 'connect' && (
                                 <div className='p-2 flex-fill' style={{ minWidth: '250px' , maxWidth: 'inherit' }}>
                                    <div className='form-group' id={'connect'}>
                                        <CustomSelect options={connects} is_multi={true}  placeholder="Select Connects" onChange={onChangeConnects} key={'connect'}/>
                                    </div>
                                </div>
                            )}
                            {target === 'project' && (
                                <>
                                 <div className='p-2 flex-fill' style={{ minWidth: '250px' , maxWidth: 'inherit' }}>
                                    <div className='form-group'>
                                    <CustomSelect options={projectCodes} onChangeSingle={onChangeProgrammeProjectCode} placeholder="Select project code" />
                                    </div>
                                </div>
                                {programmes.length > 0 ? (
                                    <div className='d-flex flex-fill justify-content-start ' >
                                    <div className='p-2 flex-fill' style={{ minWidth: '250px' , maxWidth: 'inherit' }}>
                                        <div className='form-group'>
                                        <CustomSelect options={getProgrammeValues()} is_multi={true} placeholder="Select programme(s)" onChange={onChangeProgrammes} />
                                        </div>
                                    </div>
                                    </div>
                                ) : <></>}
                                {waveOptions.length > 0 ? (
                                    <div className='p-2 flex-fill' style={{ minWidth: '250px' }}>
                                        <div className='form-group'>
                                        <CustomSelect options={waveOptions} is_multi={true} placeholder="All waves" onChange={onChangeWaves}></CustomSelect>
                                        </div>
                                    </div>
                                ) : <></>}
                                </>
                            )}
                            <div className='d-flex flex-fill justify-content-start ' >
                                <div className='p-2 flex-fill' style={{ minWidth: '250px' , maxWidth: 'inherit' }}>
                                    <div className='form-group' key={metrics.length}>
                                        <CustomSelect options={metrics} is_multi={true} placeholder="Select Metrics..." onChange={onChangeMetrics} 
                                        defaultValue={getDefaultValue()}/>                             
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex flex-fill justify-content-start ' >
                                <div className='p-2 flex-fill' style={{ minWidth: '250px' , maxWidth: 'inherit' }}>
                                    <div className='form-group'>
                                        <CustomSelect options={categories} is_multi={true} placeholder="Select Categories..." onChange={onChangeCategories} 
                                        disabled={selectedMetrics.length > 0 || selectedCustomMetrics.length > 0 ? true : false}/>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex flex-fill justify-content-start ' >
                                <div className='p-2 flex-fill' style={{ minWidth: '250px' , maxWidth: 'inherit' }}>
                                    <div className='form-group'>
                                        <CustomSelect options={groups} is_multi={true} placeholder="Select Groups..." onChange={onChangeGroups} 
                                        disabled={selectedMetrics.length > 0 || selectedCustomMetrics.length > 0 ? true : false}/>
                                    </div>
                                </div>
                            </div>
                            <div className='p-2 flex-fill' style={{ minWidth: '250px' , maxWidth: 'inherit' }} key={betweenDates?.from + '-' + betweenDates?.to}>
                                <div className='form-group'>
                                <CustomDateRangePicker onChangeDate={onChangeDate} defaultSelection="custom" customSelection={{
                                    startDate: betweenDates?.from ? moment(betweenDates?.from).toDate() : moment().startOf('month').toDate(),
                                    endDate: betweenDates?.to ? moment(betweenDates?.to).toDate() : moment().endOf('day').toDate()
                                }} setIsOpen={setIsOpened}/>
                                </div>
                            </div>
                            <div className='p-2 d-flex justify-content-end'>
                                <button className='btn btn-sm btn-apply' disabled={(isOpened) || (loading) || (!betweenDates) || (!betweenDates && (target == undefined || target == null || target == '') && true)} onClick={() => applyFilters()}>Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Sticky>
            <hr className='mt-6 mb-2'/>
        </>
    )
}

export { IndividualMetricHeader }
