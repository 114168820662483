
import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { PageTitle } from '_metronic/layout/core'
import ConfigRoutes from 'app/routing/ConfigRoutes'
import MetricMergerWrapperTable from './components/MetricMergerWrapperTable'


const breadcrumbs = [
    {
        title: 'Modules', 
        path: '#',
        isSeparator: false,
        isActive: false,
    },
    {
        title: 'Metric Custom',
        path: '/metric-custom/list',
        isSeparator: true,
        isActive: true,
    },
]

const MetricMergerWrapper: FC = () => {

    return (
        <>
            <Routes>

                <Route
                    element={
                        <>
                            <PageTitle breadcrumbs={breadcrumbs}>Metric Custom</PageTitle>
                            <MetricMergerWrapperTable />
                        </>
                    }
                >
                    <Route index element={<Navigate to={ConfigRoutes.admin.metrics.metric_custom.list.principal} />} />
                </Route>

            </Routes>



        </>

    )
}

export { MetricMergerWrapper } 