import {FC, useEffect, useState} from 'react'
import '@/_metronic/assets/sass/components/_loading.scss'
import Swal from 'sweetalert2'
import toastr from "toastr";
import {PageTitle} from '../../../../_metronic/layout/core'
import {ExternalProviderService} from 'app/services/external_povider/ExternalProviderService'
import {KTSVG} from '_metronic/helpers'

const breadcrums = [
  {
    title: 'Administration',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '#',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Third-party Data',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '#',
    isSeparator: true,
    isActive: false,
  },
]

type FormErrors = {
  fileError?: string | null
}

const ExternalProvidersImport: FC = () => {
  const FIELD_MAPPING = [
    {
      name: 'Programme',
      field: 'programmeId',
      error: 'Programme',
    },
    {
      name: 'Campaign',
      field: 'campaignId',
      error: 'Campaign',
    },
    {
      name: 'Date',
      field: 'metricDate',
      error: 'Date',
    },
    {
      name: 'Metric Name',
      field: 'metricName',
      error: 'Metric Name',
    },
    {
      name: 'Metric Value',
      field: 'metricValue',
      error: 'Metric Value',
    },
    {
      name: 'Provider',
      field: 'provider',
      error: 'Provider',
    },
  ]

  const [errors, setErrors] = useState<FormErrors>()
  const [file, setFile] = useState<File | null>(null)
  const [fields, setFields] = useState<string[] | null>(null)
  const [selectedFields, setSelectedFields] = useState<string[]>(
    Array(FIELD_MAPPING.length).fill('')
  )
  const [selectOptions, setSelectOptions] = useState<string[][]>(() =>
    FIELD_MAPPING.map((_, index) =>
      FIELD_MAPPING.map((option) => option.field).filter(
        (field) => field !== FIELD_MAPPING[index].field
      )
    )
  )
  const [formErrors, setFormErrors] = useState<FormErrors>({})
  const [formData, setFormData] = useState<Array<{field: string; selectedValue: string}>>([])

  useEffect(() => {
    setSelectOptions((prevOptions) =>
      prevOptions.map((options, index) =>
        options.filter((option) => !selectedFields.includes(option))
      )
    )
  }, [selectedFields])

  const handleFileChange = (files: FileList | null) => {
    if (files !== null && files.length > 0) {
      setFile(files[0])
    }
  }

  const handleFieldSelect = (field: string, index: number) => {
    const updatedSelectedFields = [...selectedFields]
    updatedSelectedFields[index] = field
    setSelectedFields(updatedSelectedFields)
  }

  const submitData = async (data: FormData) => {
    Swal.fire({
      showConfirmButton: false,
      title: '<h3> Importing Data... <h3>',
      loaderHtml: '<div class="custom-spinner mb-5"><span></span><span></span><span></span></div>',
      allowOutsideClick: false,
      validationMessage: '<p class="text-muted"> This operation could take a while </p>',
      html: '<p class="text-muted"> This operation could take a while </p>',

      customClass: {
        loader: 'custom-loader',
        icon: 'd-none',
      },
      willOpen: () => {
        Swal.showLoading()
      },
    })

    let response = (await new ExternalProviderService().getFieldsUpload(data)).getResponseData()

    

    Swal.close()

    if (response != null && response.success == true && response.data.length > 5 && response.data.length < 7) {
      setFields(response.data)
    } else if (response != null && response.success == true && response.data.length < 6 ) {
      toastr.error('EL archivo debe de tener al menos 6 columnas')
    } else if (response != null && response.success == true && response.data.length > 6 ) {
      toastr.error('EL archivo debe de tener como máximo 6 columnas')
    } else {
      toastr.error('Error al cargar el archivo')
    }
  }

  const handleSubmit = async (event: any) => {
    setErrors((errors) => ({...errors, fileError: null}))
    event.preventDefault()

    if (file !== null) {
      const formData = new FormData()
      formData.append('file', file)
      await submitData(formData)
    } else {
      if (file === null) {
        setErrors((errors) => ({...errors, fileError: 'File is required'}))
      }

      setTimeout(() => {
        setErrors((errors) => ({...errors, fileError: null}))
      }, 5000)
    }
  }

  const handleSubmitMapping = async (event: any) => {
    event.preventDefault()

    setFormErrors({})

    const selectedFieldsSet = new Set(selectedFields)

    if (selectedFields.some((field) => field === '')) {

      // FIND THE MISSING FIELDS
      const missingFields = FIELD_MAPPING.filter(mapping => !selectedFields.includes(mapping.field)).map(mapping => mapping.field);

      // FIND THE ERROR MESSAGES FOR THE MISSING FIELDS
      const missingErrors = FIELD_MAPPING.filter(mapping => missingFields.includes(mapping.field)).map(mapping => mapping.error);

      // JOIN THE ERROR MESSAGES
      let allErrors = '';
      if (missingErrors.length > 1) {
          const lastErrorIndex = missingErrors.length - 1;
          allErrors = missingErrors.slice(0, lastErrorIndex).join(', ') + ` and ${missingErrors[lastErrorIndex]}`;
      } else {
          allErrors = missingErrors.join(', ');
      }

      // SET THE ERROR MESSAGE
      setFormErrors({ fileError: allErrors + ' is required' || 'All fields are required' });
      return
    }
    
    if (selectedFieldsSet.size < selectedFields.length) {
      setFormErrors({fileError: 'You cannot select the same field twice'})
      return
    }

    if (file !== null && selectedFields.every((field) => field !== '')) {
      const formDataArray = fields?.map((field, index) => ({
        field,
        selectedValue: selectedFields[index],
      }))

      setFormData(formDataArray as any)

      const formData = new FormData()
      formData.append('file', file)
      formData.append('fieldMapping', JSON.stringify(formDataArray))

      let response = (await new ExternalProviderService().uploadFile(formData))?.getResponseData()
      if (response.success == true) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Third-party Data imported successfully',
          })
      } else { 
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.message,
        })
      }

        setFile(null)
        setFields(null)
        setSelectedFields(Array(FIELD_MAPPING.length).fill(''))
    } else {
      if (file === null) {
        setErrors((errors) => ({...errors, fileError: 'File is required'}))
      }

      if (selectedFields.some((field) => field === '')) {
        setErrors((errors) => ({...errors, fileError: 'All fields are required'}))
      }
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={breadcrums}>New Import List</PageTitle>
      <div className='justify-content-center d-flex'>
        <div className='card card-custom col-sm-6'>
          <div className='card-header'>
            <h3 className='card-title'>
              {' '}
              <KTSVG
                path='/media/icons/duotune/technology/teh0012.svg'
                className='svg-icon-1 me-2'
              />{' '}
              New Import List{' '}
            </h3>
          </div>
          {fields ? (
            <>
              <form onSubmit={handleSubmitMapping} className='form'>
                <div className='card-body'>
                  {fields.map((field, index) => (
                    <div className='row' key={index}>
                      <div className='col-5 d-flex justify-content-center align-items-center'>
                        <label>{field}</label>
                      </div>
                      <div className='col-2 d-flex justify-content-center align-items-center'>
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-1 me-2'
                        />
                      </div>
                      <div className='col-5 d-flex justify-content-center align-items-center'>
                        <select
                          onChange={(e) => handleFieldSelect(e.target.value, index)}
                          value={selectedFields[index]}
                          className='form-control'
                        >
                          <option value=''>Select Field</option>
                          {FIELD_MAPPING.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.field}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ))}
                  {formErrors.fileError && (
                    <div className='row mt-3'>
                      <div className='col-12 text-center'>
                        <p className='text-danger'>{formErrors.fileError}</p>
                      </div>
                    </div>
                  )}
                  <div className='card-footer d-flex justify-content-end'>
                    <button type='submit' className='btn btn-primary font-weight-bold me-2'>
                      Submit Mapping
                    </button>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <form onSubmit={handleSubmit} className='form'>
              <div className='card-body'>
                <div className={"alert alert-primary"}><KTSVG path='/media/icons/duotune/general/gen045.svg' className='svg-icon-1 me-2' />
                  The file to be uploaded needs to follow a specific structure. You Download template <a href="/media/templates/external_provider.xlsx" target="_blank" download className='fw-bold text-apply'>here</a>.
                  <br/>
                  Once the data is imported you can edit the data from section “Third party data {'>'} List"
                </div>
                <div className='form-group validated mt-3'>
                  <label className='form-label fw-bold'>
                    Select and upload your external provider file
                  </label>
                  <div className='input-group'>
                    <input
                      accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                      type='file'
                      onChange={(e) => handleFileChange(e.target.files)}
                      className={`form-control`}
                    />
                  </div>
                  {errors?.fileError !== null && errors?.fileError !== undefined && (
                    <small className='text-danger'> {errors.fileError} </small>
                  )}
                  <div className='row'>
                    <small className='form-text text-muted'>
                      Supported file extensions: XLS, XLSX, CSV
                    </small>
                  </div>
                </div>
              </div>
              <div className='card-footer d-flex justify-content-end'>
                <button type='submit' className='btn btn-primary font-weight-bold me-2'>
                  Import
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  )
}

export {ExternalProvidersImport}