import { DataReportFilterMode } from "app/pages/data_report/DataReportProvider";
import moment from "moment";

export class MetricUtil {

    // Calculate start date for metrics. This is used for get the last N periods before the start date.
    static calculateStartDate(fromDate: string, mode: DataReportFilterMode) {
        let substractPeriod = 24;

        return moment(fromDate)
            .subtract(substractPeriod, mode)
            .format('YYYY-MM-DD')
    }
}