import { RestServiceConnection } from "../RestServiceConnection";
import QueryString from "qs" ;

export class DocumentService extends RestServiceConnection{

    renderDocument = async (documentId?: string|null, url?: string|null): Promise<Blob|null> => {
        
        if(documentId === undefined) {
            return null;
        }

        if(documentId === null) {
            return null;
        }
        
        await this.makeRequest({
            method: "get",
            responseType: "blob",
            url: `/document/render/${documentId}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            }
        });

        return this.response?.data
    }

    downloadFile = async(typeFile: string, filter?: any): Promise<Blob> => {
        try {
            await this.makeRequest({
                withCredentials: true,
                method: "get",
                responseType: "blob",
                url: `/metric-data-export/${typeFile}`,
                params: filter,
                paramsSerializer: {
                    serialize: (params: any) => QueryString.stringify(params) ,
                }
            });
            if (this.response !== null) {
                return this.response?.data 
            } else {
                throw new Error('Error download file');
            }
        } catch (error) {
            throw new Error('Error download file');
        }
    }

}