import {CustomDateRangePicker, RangeDate} from '@components/form/CustomDateRangePicker'
import CustomSelect from '@components/form/CustomSelect'
import {DatabaseManagerService} from 'app/services/database_manager/databaseManagerService'
import React, {FC, useEffect, useState} from 'react'
import {SingleValue} from 'react-select'

interface FieldComponentProps {
  groupID: number
  rowID: number
  row: any
  getType: any
  dispatch: any
  isDeleted: boolean
  setIsDeleted: any
}

const FieldComponent: FC<FieldComponentProps> = ({row, getType, dispatch, groupID, rowID, isDeleted, setIsDeleted}) => {
  const [searchText, setSearchText] = useState('')

  const [options, setOptions] = useState<any>([])

  const [refreshKey, setRefreshKey] = useState(0);

  const timeoutRef = React.useRef<any>()

  useEffect(() => {
    if (getType(row.field, row.condition) === 'search' && searchText !== '' && searchText.length > 2) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        getTypeValue()
      }, 500)

      return () => {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [searchText])

  useEffect(() => {
    if (row.field !== '') {
      setOptions([])
      let type = getType(row.field, row.condition)
      if ((type === 'list' || type === 'search') && isDeleted == false) {
        if (row.isLoaded == false) {
          row.values = []
        } else {
          row.isLoaded = false
        }
      }
      getTypeValue()
    }
  }, [row.field])

  useEffect(() => {
    setRefreshKey(key => key + 1);
    setIsDeleted(false)
  }, [row.condition, row.values]);

  const getTypeValue = async () => {
    const databaseManagerService = new DatabaseManagerService()
    const response = (
      await databaseManagerService.getListSearch({field: row.field, search: searchText})
    ).response
    if (response?.status === 200) {
      const options = response.data.data.map((item: {id: number; name: string}) => ({
        value: item.name,
        label: item.name,
      }))
      options.sort((a: any, b: any) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setOptions(options)
    }
  }

  const handleValueInputChange = (newValue: any) => {
    setSearchText(newValue)
  }

  const handleRangeChange = (range: RangeDate) => {
    if (range.startDate && range.endDate) {
      dispatch({
        type: 'UPDATE_CONDITION_VALUE',
        payload: {
          groupIndex: groupID,
          conditionIndex: rowID,
          field: row.field,
          condition: row.value,
          values: [range.startDate + ' 00:00', range.endDate + ' 00:00'],
        },
      })
    }
  }

  const handleValueChange = (newValue: SingleValue<unknown>) => {
    let valueSelected: any = newValue
    let values = valueSelected.map((value: any) => value.value)
    dispatch({
      type: 'UPDATE_CONDITION_VALUE',
      payload: {
        groupIndex: groupID,
        conditionIndex: rowID,
        field: row.field,
        condition: row.value,
        values: values,
      },
    })
  }

  const getInput = () => {
    switch (getType(row.field, row.condition)) {
      case 'none':
        return (
          <input
            disabled={true}
            type='text'
            name='value'
            className='form-control'
            onChange={(e) => {
              dispatch({
                type: 'UPDATE_CONDITION_VALUE',
                payload: {
                  groupIndex: groupID,
                  conditionIndex: rowID,
                  field: row.field,
                  condition: row.value,
                  values: [e.target.value],
                },
              })
            }}
            value={row?.values && row.values.length > 0 ? row.values[0] : ''}
          />
        )
      case 'text':
        return (
          <input
            autoFocus={true}
            type='text'
            name='value'
            className='form-control'
            onChange={(e) => {
              dispatch({
                type: 'UPDATE_CONDITION_VALUE',
                payload: {
                  groupIndex: groupID,
                  conditionIndex: rowID,
                  field: row.field,
                  condition: row.value,
                  values: [e.target.value],
                },
              })
            }}
            value={row?.values && row.values.length > 0 ? row.values[0] : ''}
          />
        )
      case 'list':
        return (
          <CustomSelect
            options={options}
            key={row.field}
            customFontSize='0.9rem'
            placeholder='Start typing to display options'
            onInputChange={handleValueInputChange}
            is_multi={true}
            onChange={handleValueChange}
            defaultValue={ row?.values ? row.values.map((valueD: any) => ({value: valueD, label: valueD})) : ''
            }
          />
        )
      case 'search':
        return (
          <CustomSelect
            options={options}
            customFontSize='0.9rem'
            placeholder='Start typing to display options'
            onInputChange={handleValueInputChange}
            is_multi={true}
            onChange={handleValueChange}
            defaultValue={ row?.values ? row.values.map((valueD: any) => ({value: valueD, label: valueD})) : ''
            }
          />
        )
      case 'date':
        return (
          <input
            required
            type='date'
            name='value'
            className='form-control'
            onChange={(e) =>
              dispatch({
                type: 'UPDATE_CONDITION_VALUE',
                payload: {
                  groupIndex: groupID,
                  conditionIndex: rowID,
                  field: row.field,
                  condition: row.value,
                  values: [e.target.value + ' 00:00'],
                },
              })
            }
          />
        )
      case 'date_range':
        return (
          <CustomDateRangePicker onChangeDate={handleRangeChange} defaultSelection='this week' />
        )
      default:
        return (
          <input
            autoFocus={true}
            type='text'
            name='value'
            className='form-control'
            onChange={(e) => {
              dispatch({
                type: 'UPDATE_CONDITION_VALUE',
                payload: {
                  groupIndex: groupID,
                  conditionIndex: rowID,
                  field: row.field,
                  condition: row.value,
                  values: [e.target.value],
                },
              })
            }}
            value={row?.values && row.values.length > 0 ? row.values[0] : ''}
          />
        )
    }
  }

  return (
    <div className={'col-xl-3 d-block'}>
      <div className='p-2 flex-fill' style={{minWidth: '200px'}}>
        <div className='form-group'>
          <label className='form-label fw-bold'>Value(s)</label>        
            <div key={refreshKey}>
              {getInput()}
            </div>
        </div>
      </div>
    </div>
  )
}

export default FieldComponent
