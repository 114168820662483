import { UserService } from "app/services/user/UserService";
import { FC, useEffect, useState } from "react";
import Swal from "sweetalert2";
import CustomModalBody from "../../../../utils/components/modal/body";
import CustomModalHeader from "../../../../utils/components/modal/header";
import CustomModal from "../../../../utils/components/modal/modal";
import { useDataReport } from "../../DataReportProvider";
import SnapshotData from "./SnapshotData";
import SnapshotForm from "./SnapshotForm";


interface Props {
    show?: boolean,
}

const SnapshotModal: FC<Props> = ({ show }) => {

    const dataReport = useDataReport();

    const [showStatus, setShowStatus] = useState<boolean>(false);
    const [users, setUsers] = useState([{}]);

    const hideModal = () => {
        
        dataReport.updateShowSnapshotModal(false);
    }

    const handleSubmitResponse = (response: any) => {
        if(response == true){
            Swal.fire({
                icon: 'success',
                title: 'Snapshot created successfully',
                showConfirmButton: false,
                timer: 3000
            })
            hideModal();
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                confirmButtonColor: '#477995',
            })
        }
    }

    const getUsers = async () => {
        let response = await (await (new UserService()).getUsers()).getResponseData();
        let users = [];
        for(let user of response.data.data){
            users.push({value: user.id, label: user.name});
        }
        setUsers(users);
    }

    useEffect(() => {
        setShowStatus(dataReport.showSnapshotModal);
        getUsers();
    }, [dataReport.showSnapshotModal])


    return (
        <CustomModal identifier="create_user_modal" show={showStatus} onHideModal={hideModal}>
            <CustomModalHeader classes="pb-0 border-0">
                <div className="modal-title justify-content-start">
                    <h2>Create Snapshot</h2>
                </div>
            </CustomModalHeader>
            <CustomModalBody classes="mx-5 mx-xl-18 pt-5 pb-15 overflow-visible">
                <SnapshotData metrics={dataReport.metrics} params={dataReport.params.filter_filters}/>
                <SnapshotForm metrics={dataReport.metrics} params={dataReport.params} users={users} 
                handleSubmitResponse={handleSubmitResponse}/>
            </CustomModalBody>
        </CustomModal>
    );
}

SnapshotModal.defaultProps = {
    show: false
}

export default SnapshotModal;