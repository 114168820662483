import { RestServiceConnection } from "../RestServiceConnection";


export class UserService extends RestServiceConnection {

    getProfile = async () => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/user/profile',
        }, true);
        return this;
    }

    getUserById = async (userId: string) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/user/get',
            data: {
                id: userId
            }
        }, true)
        return this;
    }

    getUsers = async (params?: any) => {


        this.response = await this.makeRequest({
            method: "POST",
            url: '/user/list',
            data: params !== undefined ? params : {}
        }, true)
        return this;
    }

    createUser = async (data: any) => {

        this.response = await this.makeRequest({
            method: "POST",
            url: '/user/create',
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: data,
        }, true)
        return this;
    }

    deleteUser = async (data: any) => {

        this.response = await this.makeRequest({
            method: "POST",
            url: '/user/delete',
            data: data,
        }, true)
        return this;
    }

    updateUser = async (data: FormData) => {

        this.response = await this.makeRequest({
            method: "POST",
            url: '/user/update',
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: data,
        }, true)
        return this;
    }

    getAvailableRoles = async () => {

        this.response = await this.makeRequest({
            method: "GET",
            url: '/user/roles',
        }, true)
        return this;
    }
}