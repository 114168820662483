/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { FC } from 'react'
import { KTSVG } from '../../../helpers'
import { useLayout } from '../../core'
import { DefaultTitle } from '../header/page-title/DefaultTitle'

const Toolbar1: FC = () => {
  const { classes, buttons } = useLayout()


  return (
    <div className='toolbar' id='kt_toolbar'>
      {/* begin::Container */}
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
      >
        <DefaultTitle />

        {/* begin::Actions */}
        <div className='d-flex align-items-center py-1'>
          {/* begin::ButtonsWrapper */}
          { buttons?.map((button, index) =>
              <div key={index}>{button}</div>
          )}
          {/* end::ButtonsWrapper */}
        </div>
        {/* end::Actions */}
        
      </div>
      {/* end::Container */}
    </div>
  )
}

export { Toolbar1 }
