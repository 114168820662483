import { PEData } from "@components/chart/types"
import WorkLoadComponent from "@components/workload_component/WorkLoadComponent"
import { MetricTimeSerie, MetricTimeSerieObject } from "app/models/metrics/MetricTimeSerie"
import { MetricService } from "app/services/metric/MetricService"
import { getMetricColors } from "app/utils/utils"
import React, { useEffect, useRef, useState } from "react"
import { ProgrammeExplorerFilterParams } from "../ProgrammeExplorerProvider"
import ProgrammeExplorerChart from "./ProgrammeExplorerChart"

interface Props {
    filters: ProgrammeExplorerFilterParams,
    metric: string
    showAxis?: boolean
}


export const ProgrammeExplorerFilteredCardMainMetric: React.FC<Props> = ({ filters, metric, showAxis }) => {

    const [series, setSeries] = useState<PEData[]>([])

    const abortControllerMain = new AbortController()
    const abortControllerSecond = new AbortController()


    useEffect(() => {

        setSeries([])

        getMetrics(abortControllerMain.signal, abortControllerSecond.signal)

        return () => {
            abortControllerMain.abort()
            abortControllerSecond.abort()
        }

    }, [filters])


    useEffect(() => {
        console.log(series)
    }, [series])

    const getMetrics = async (abortMain: AbortSignal, abortSecond: AbortSignal) => {
        let firstMetric = await getMainSeries(abortMain)
        let secondMetric = false//await getSecondarySeries(abortSecond)
        let series = [];

        firstMetric && series.push(firstMetric)
        secondMetric && series.push(secondMetric)

        setSeries(series)
    }


    const getMainSeries = async (signal?: any): Promise<PEData | null> => {

        let { programmes, campaigns } = filters.filter_filters

        if (campaigns !== undefined && campaigns.length > 0) {
            programmes = []
        }

        let modifiedFilters = {
            ...filters,
            filter_filters: {
                ...filters.filter_filters,
                therapeutic_areas: [],
                programmes
            }
        }

        const responseFiltered = (await (new MetricService()).getMetricEndpointData(modifiedFilters, metric, 'timeseries', signal)).getResponseData()
        if (!responseFiltered?.success) return null


        const serie1: MetricTimeSerieObject = {
            labels: responseFiltered.data.map((metric: MetricTimeSerie) => metric.date),
            values: responseFiltered.data.map((metric: MetricTimeSerie) => metric.value),
            color: getMetricColors(0)
        }

        return {
            color: serie1.color,
            name: "Current filtered data",
            data: serie1.values,
            labels: serie1.labels
        }

    }



    /** Get secondary series for all therapeutic area data. */
    const getSecondarySeries = async (signal?: any): Promise<PEData | null> => {

        let modifiedFiltersSameTA = {
            ...filters,
            filter_filters: {
                ...filters.filter_filters,
                programmes: [],
                therapeutic_areas: filters.filter_filters.therapeutic_areas,
            }
        }
        const responseSameTA = (await (new MetricService()).getMetricEndpointData(modifiedFiltersSameTA, metric, 'timeseries', signal)).getResponseData()

        if (!responseSameTA?.success) return null

        const serie2: MetricTimeSerieObject = {
            labels: responseSameTA.data.map((metric: MetricTimeSerie) => metric.date),
            values: responseSameTA.data.map((metric: MetricTimeSerie) => metric.value),
            color: getMetricColors(1)
        }

        return {
            color: serie2.color,
            name: "Same therapeutic area data",
            data: serie2.values,
            labels: serie2.labels
        }

    }



    return (
        <WorkLoadComponent evaluateElement={series}>
            <ProgrammeExplorerChart data={series ? series : []} showAxis={showAxis} ></ProgrammeExplorerChart>
        </WorkLoadComponent>
    )
}