import ImgOrDefault from "@components/img_or_default"
import { DocumentService } from "app/services/document/DocumentService";
import { useEffect, useRef, useState } from "react"
import "./AutoFileForm.css"

const DEFAULT_MIMETYPES = ["image/png", "image/jpeg", "image/jpg", "image/svg", "image/svg+xml", "image/gif"];

type AutoFileFormProps = {
    name: string
    label: string
    value: string|null|undefined
    asynchronous?: boolean
    type?: "avatar" | "preview" | null
    mimetypes?: string[],
    onChange: (formData: FormData) => void,
    onFormatError?: (file: File, message: string) => void
}


/**
 * Component to fast generate a form with a file input and a preview of the file
 */
const AutoFileForm: React.FC<AutoFileFormProps> = ({name, label, value, type, mimetypes, asynchronous, onChange, onFormatError}) => {

    const [resource, setResource] = useState<string|Blob|null>(null)

    let formRef = useRef<HTMLFormElement|null>(null)

    if(mimetypes === undefined) {
        mimetypes = DEFAULT_MIMETYPES
    }

    if(asynchronous === undefined) {
        asynchronous = false
    }

    const getAsynchronousDocument = async () => {
        let resource = await (new DocumentService()).renderDocument(value)
        console.log(resource)
        if (resource === null) {
            return null
        }

        let url = URL.createObjectURL(resource)
        setResource(url)
    }

    useEffect(() => {
        if(asynchronous) {
            getAsynchronousDocument()
        }
    }, [value])

    

    const _onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {

        let formData = new FormData(formRef.current as HTMLFormElement)
        let file = formData.get(name) as File
        
        // If file is empty (user click on cancel) do nothing
        if(file.name == "") {
            return
        }

        if(!mimetypes?.includes(file.type)) {
            if(onFormatError === undefined) {
                console.error( `File type must be one of ${mimetypes?.join(", ")}`)
            } else {
                onFormatError(file, `File type must be one of ${mimetypes?.join(", ")}`)
            }
            
            return
        }

        onChange(formData);
    }


    const _clickToChange = () => {
        let input = formRef.current?.querySelector("input[type=file]") as HTMLInputElement;
        input.click();
    }

    return (
        <div className="auto-file-form-component">
            <div className="handle-click cursor-pointer" title={label} onClick={_clickToChange}>
                <ImgOrDefault imgSrc={resource} alt={label} type={type ?? "avatar"}></ImgOrDefault>
            </div>
            <form ref={formRef}>
                <input type="file" name={name} onChange={_onChangeFile} />
            </form>
        </div>
    )
}



export default AutoFileForm