import {FC} from 'react'
import {KTSVG} from '_metronic/helpers'
import '@/_metronic/assets/sass/components/_loading.scss'
import { useDispatch, useSelector } from 'react-redux';
import { setShow } from 'app/store/reducers/bots_reducer';

const BotSettings: FC = () => {

  const dispatch = useDispatch();
  const showBotsStatus = useSelector((state: any) => state.bots.show_bots);

  return (
    <div className='justify-content-center d-flex'>
      <div className='card card-custom example example-compact col-sm-4'>
        <div className='card-header'>
          <h3 className='card-title'>
            {' '}
            <KTSVG path='/media/icons/duotune/general/gen060.svg' className='svg-icon-1 me-2' />
            Hide / Show Bots
          </h3>
        </div>

        <form className='form'>
          <div className='card-body'>
            <div className='form-group validated'>
              <div className={"alert alert-primary"}><KTSVG path='/media/icons/duotune/general/gen045.svg' className='svg-icon-1 me-2' /> 
                This button will hide or show the bots option in the application.
              </div>
              <div className='form-check form-switch form-check-custom form-check-solid d-flex justify-content-center'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='flexSwitchCheckChecked'
                  onChange= {() => dispatch(setShow(!showBotsStatus))}
                  checked={showBotsStatus}
                />
                <label className='form-check-label fw-bold' htmlFor='flexSwitchCheckChecked'>
                  Show Bots
                </label>
              </div>
            </div>
          </div>
        </form>
        <div className='card-footer'></div>
      </div>
    </div>
  )
}

export {BotSettings}
