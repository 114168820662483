import { FC, useEffect, useRef, useState } from "react"
import { Modal } from 'bootstrap'


interface Props {
    identifier: string,
    show?: boolean,
    header?: JSX.Element | null,
    onHideModal?(): void, 
    position?: 'center' | 'none'
    aditionalClasses?: string
}


const CustomModal: FC<Props> = ({ identifier, show, header, onHideModal, children, position, aditionalClasses }) => {

    const modalRef = useRef<HTMLDivElement>(null);
    const [modalObject, setModalObject] = useState<Modal | null>(null);


    useEffect(() => {
        if (modalObject === null) {
            initModal();
        }
        toggleModal();
    }, [show])


    /**
     * Instance modal
     */
    const initModal = () => {
        setTimeout(() => {
            let modalElement = modalRef.current as HTMLElement;
            let modal = new Modal(modalElement);
            modalElement.addEventListener('hidden.bs.modal', event => {
                onHideModalStatus()
            })
            setModalObject(modal);
        }, 100);
    }


    /**
     * Show or hide instanced modal depending of show prop.
     */
    const toggleModal = () => {
        let modalElement = modalRef.current as HTMLElement;

        if (show) {
            if(!modalElement.classList.contains('show')) {
                modalObject?.show();
            }
        }

        if (!show) {
            if(modalElement.classList.contains('show')) {
                modalObject?.hide();
            }
        }
    }


    const onHideModalStatus = () => {
        onHideModal && onHideModal();
    }


    return (
        <div ref={modalRef} className={`modal fade ${aditionalClasses}`} id={identifier} aria-hidden='true'>
            <div className={`modal-dialog mw-650px ${position === 'center' ? 'modal-dialog-centered' : ''}`}>
                <div className='modal-content'>
                    {children}
                </div>
            </div>
        </div>
    )
}


CustomModal.defaultProps = {
    show: false,
    header: null,
    position: 'none',
    onHideModal: () => { }
}

export default CustomModal;