import React from "react";
import {PEBarData, type PEData, PEDonutData} from "@components/chart/types";
import Chart from "react-apexcharts";


export interface ProgrammeExplorerDonutChartProps {
  data: PEBarData[],
  position: "right" | "left"
  order?: 'ASC' | 'DESC'
}
const ProgrammeExplorerHorizontalBarChart: React.FC<ProgrammeExplorerDonutChartProps> = ({data, children, position, order}) => {

  // Order elements by its value
  let dataOrdered = data.sort((elementA: PEBarData, elementB: PEBarData) => {

    if(elementA.data === elementB.data) return 0

    if(typeof order !== undefined && order === "ASC")
      return elementA.data < elementB.data ? -1 : +1

    if(typeof order !== undefined && order === "DESC")
      return elementA.data < elementB.data ? +1 : -1

    return 0
  });

  let options: any = {
    yaxis: {
      reversed: true,
      labels: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'bottom'
        }
      }
    },
    dataLabels: {
      offsetX: -20,
    }
  }

  if(position === "left") {
    options = {
      yaxis: {
        reversed: false,
        labels: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        offsetX: 110
      }
    }
  }

  const labels = dataOrdered.map((element: PEBarData) => element.name);
  const values = dataOrdered.map((element: PEBarData) => element.data);
  const colors = dataOrdered.map((element: PEBarData) => element.color);

  return (
    <>
      {
        data.length > 0 ?
          (<Chart
            type={'bar'}
            height={400}
            series={[{
              data: values
            }]}
            options={{
              chart: {
                id: 'realtime',
                zoom: {
                  enabled: false
                },
                animations: {
                  enabled: true,
                  easing: 'linear',
                  dynamicAnimation: {
                    speed: 1000
                  }
                },
                toolbar: {
                  show: false
                },
              },
              colors: colors,
              plotOptions: {
                bar: {
                  barHeight: '80%',
                  columnWidth: '20%',
                  borderRadius: 1,
                  horizontal: true,
                  dataLabels: {
                    position: options.plotOptions.bar.dataLabels.position,
                    orientation: 'horizontal'
                  }
                }
              },
              dataLabels: {
                enabled: true,
                style: {
                  colors: ["#a7afc3"]
                },
                textAnchor: 'end',
                offsetX: options.dataLabels.offsetX,
                formatter: function (val, opt) {
                  return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
                },
              },
              xaxis: {
                categories: labels
              },
              yaxis: options.yaxis,
              stroke: {
                curve: 'smooth',
                colors: ['primary']
              },
              markers: {
                size: 0
              },
              grid: {
                show: false
              },
              tooltip: {
                theme: 'dark',
                x: {
                  show: true
                },
                y: {
                  title: {
                    formatter: function () {
                      return ''
                    }
                  }
                }
              }
            }}>
          </Chart>)
          : null
      }

      {children}
    </>
  )
}

ProgrammeExplorerHorizontalBarChart.defaultProps = {
  position: 'left',
  order: 'DESC'
}

export default ProgrammeExplorerHorizontalBarChart