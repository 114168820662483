import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider, useLayout} from './core'
import {useLocation, useNavigationType} from 'react-router-dom'
import {DrawerMessenger} from '../partials'
import {MenuComponent} from '../assets/ts/components'

const MasterLayout = () => {
  const location = useLocation()
  const navType = useNavigationType()
  const layout = useLayout()

  useEffect(() => {
    
    setTimeout(() => {
      MenuComponent.reinitialization()
      layout.setLoaded(true);
    }, 500)
  })

  // Execute in every route load.
  useEffect(() => {

    // Only Reset toolbar buttons when is a deffinitive route. "REPLACE" is an argument that not 
    // We check
    if(layout.loaded && navType !== "REPLACE") {
      if(layout.componentIsSettingToolbar) {
        layout.componentIsSettingToolbar = false;
      } else {
        layout.resetToolbarButtons();
      }
    }

    // Reset the metrics that we load in data report for fast checking and prevent unnecessary requests
    /*if(location.pathname.includes(ConfigRoutes.admin.dataReport.principal) == false) {
      console.log('Doing')
      dataReport.resetLoadedMetrics();
    }*/

    
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <Toolbar />
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
        <DrawerMessenger />
      {/* end:: Drawers */}

      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
