/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AuthUserState, removeUser } from '../../../../app/store/reducers/auth_reducer'
import ImgOrDefault from '../../../../app/utils/components/img_or_default'

const HeaderUserMenu: FC = () => {
  const currentUser: AuthUserState = useSelector((state: any) => state.auth.user);
  const dispatch = useDispatch();


  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <ImgOrDefault imgSrc={currentUser.picture} alt={"User profile img"} noReferer={true}></ImgOrDefault>
          </div>
         
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
            { currentUser.name.substring(0,10) }
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>{ currentUser.role }</span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email.substring(0,20)}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={() => dispatch(removeUser())} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
