/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import ConfigRoutes from 'app/routing/ConfigRoutes';

const ContactsHeader: React.FC = () => {

    const location = useLocation();
    
    return (

        <>
            {!window.location.pathname.includes(ConfigRoutes.admin.imports.contacts.view.base) && (

                <div className='d-flex overflow-auto h-55px ps-5 pt-5 pb-5 category-nav'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap w-100'>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.imports.contacts.list.path) && 'active')}
                                to={ConfigRoutes.admin.imports.contacts.list.fullPath}
                            >
                                Contact List
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.imports.contacts.import.path) && 'active')}
                                to={ConfigRoutes.admin.imports.contacts.import.fullPath}
                            >
                                Import
                            </Link>
                        </li>
                    </ul>
                </div>
            )}
        </>
    )
}

export { ContactsHeader }
