import ConfigRoutes from 'app/routing/ConfigRoutes';
import { FC } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import ImpactVirtualMeeting from './tabs/VirtualMeeting';
import ImpactWebsite from './tabs/Website';

const ImpactWrapper: FC = () => {

    return (
        <>
            <Routes>
                <Route element={<Outlet />}>
                    
                    <Route path={ConfigRoutes.admin.dataReport.impact.website.path} element={<ImpactWebsite/>} />

                    <Route path={ConfigRoutes.admin.dataReport.impact.virtualMeeting.path} element={<ImpactVirtualMeeting/>} />

                    <Route index element={<Navigate to={ConfigRoutes.admin.dataReport.impact.website.path} />} />

                </Route>
                
            </Routes>
        </>
    )
}

export { ImpactWrapper }
