import {FC, FormEvent} from 'react'
import {KTSVG} from '_metronic/helpers'
import '@/_metronic/assets/sass/components/_loading.scss'
import Swal from 'sweetalert2'
import { ImportService } from 'app/services/import/srcurity/ImportService'
import { cacheService } from 'app/services/cache/cacheService'

const CacheCleanerBase: FC = () => {

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    
        // Mostrar el cuadro de diálogo de confirmación
        const confirmationResult = await Swal.fire({
            title: 'Are you sure you want to clear the cache?',
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes, clear it!',
            cancelButtonText: 'No, cancel!',
            confirmButtonColor: '#477995',
            cancelButtonColor: '#E32D5A',
        });
    
        // Verificar si el usuario confirmó
        if (confirmationResult.isConfirmed) {
            try {
                // Intentar limpiar la caché
                const response = (await (new cacheService).clear()).getResponse();
                
                // Verificar la respuesta del servidor
                if (response?.status === 200 && response.data?.success) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Cache cleared successfully. Please reload the browser cache to see the changes.',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        customClass: {
                            confirmButton: 'btn btn-primary'
                        }
                    });
                } else {
                    // Manejar errores en la respuesta del servidor
                    throw new Error('Server response indicates an error');
                }
            } catch (error) {
                // Mostrar mensaje de error
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong. Please try again.',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            }
        }
    };

  return (
    <div className='justify-content-center d-flex'>
      <div className='card card-custom example example-compact col-sm-4'>
        <div className='card-header'>
          <h3 className='card-title'>
            {' '}
            <KTSVG path='/media/icons/duotune/files/fil027.svg' className='svg-icon-1 me-2' />
            Cache cleaner
          </h3>
        </div>

        <form onSubmit={handleSubmit} className='form'>
          <div className='card-body'>
            <div className='form-group validated'>
              <div className={"alert alert-primary"}><KTSVG path='/media/icons/duotune/general/gen045.svg' className='svg-icon-1 me-2' /> 
                Are you sure you want to clear the cache? Please note that after clearing the cache,
                some queries may take a bit longer to process the first time.
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-center mb-4'>
            <button type='submit' className='btn btn-danger font-weight-bold me-2'>
                Clear Cache
            </button>
          </div>
        </form>
        <div className='card-footer'></div>
      </div>
    </div>
  )
}

export {CacheCleanerBase}
