import React, {useEffect, useMemo, useReducer, useState} from 'react'
import {dbManagerInitialState, dbManagerReducer} from '../reducer/DbManagerReducer'
import {DatabaseManagerService} from 'app/services/database_manager/databaseManagerService'

const DbManagerContext = React.createContext<any>(null)

export const DbManagerProvider: React.FC = ({children}) => {
  const [dbManagerState, dispatch] = useReducer(dbManagerReducer, dbManagerInitialState)

  const [fieldsData, setFieldsData] = useState([])

  useEffect(() => {
    const fetchFields = async () => {
      const options: any = []

      const databaseManagerService = new DatabaseManagerService()
      const response = (await databaseManagerService.getFieldList()).response

      if (response?.status === 200) {
        setFieldsData(response.data.data)
      }
    }

    fetchFields()
  }, [])

  const value = useMemo(
    () => ({dbManagerState, dispatch, fieldsData}),
    [dbManagerState, fieldsData]
  )

  return <DbManagerContext.Provider value={value}>{children}</DbManagerContext.Provider>
}

export default DbManagerContext
