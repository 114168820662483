import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import React from "react";

interface Props {
  image: string
}

export const ProgrammeExplorerSupporterCardHeader: React.FC<Props> = ({ image }) => {
  return (
    <div className="cardimage">
      <img width="20" height="17" className="card-svg-fill-orange" alt="Logo" src={image} />
  </div>
  )
}


