import {KTSVG} from '_metronic/helpers'
import {FC} from 'react'
import BreakDownResult from './BreakDownResult'
import {DatabaseManagerService} from 'app/services/database_manager/databaseManagerService'
import Swal from 'sweetalert2'

interface Props {
  query: any
  setShow: any
  setShowResult: any
  result: any
}

const ShowResult: FC<Props> = ({query, setShowResult, setShow, result}) => {
  const submitExportData = async () => {
    const dbService = new DatabaseManagerService()

    Swal.fire({
      showConfirmButton: false,
      title: '<h3> Please Wait... <h3>',
      loaderHtml: '<div class="custom-spinner mb-5"><span></span><span></span><span></span></div>',
      allowOutsideClick: false,
      validationMessage: '<p class="text-muted"> This operation could take a while </p>',
      html: '<p class="text-muted"> This operation could take a while </p>',

      customClass: {
        loader: 'custom-loader',
        icon: 'd-none',
      },
      willOpen: () => {
        Swal.showLoading()
      },
    })

    // Change output to list if it is count for download breakdown data
    query.output = query.output == 'count' ? 'list' : query.output
    let response = (await dbService.exportQuery(query)).response

    Swal.close()

    if (response != null && response.status == 200) {
      Swal.fire({
        title: 'Success!',
        text: 'The file has been exported successfully',
        icon: 'success',
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'QueryResult.xlsx')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'An error has occurred while exporting the file',
        icon: 'error',
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    }
  }

  return (
    <>
      {query.output === 'breakdown' ? (
        <>
          <BreakDownResult data={result} query={query} setShowResult={setShowResult} exportFunction={submitExportData}/>
        </>
      ) : query.output === 'count' ? (
        <>
          <div className={`card w-100 p-0 mt-8`}>
            <div className={'card-header'}>
              <div className='card-title'>
                <h3 className='card-label text-muted fw-bold'>Search Result</h3>
              </div>
              <div className='d-flex align-items-center'>
                <button
                  onClick={() => {
                    setShow(true)
                  }}
                  className='me-2 btn float-right btn-primary'
                >
                  <KTSVG
                    path='/media/icons/duotune/technology/teh011.svg'
                    className='svg-icon-2 svg-icon-light text-light'
                  />
                  Save Search
                </button>
              </div>
            </div>
            <div className={'card-body'}>
              <div className={'d-flex justify-content-around align-items-center'}>
                <div className={'d-flex fs-4'}>
                  Total number of records found: &nbsp;
                  <div key={result} className={'fw-bolder'}>{result ? result.length == 1  ? result[0].count : 0 : 0}</div>
                </div>
                <button className={'btn btn-apply'} onClick={submitExportData}>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr091.svg'
                    className='svg-icon-2 svg-icon-light'
                  />
                  Download Search Results
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`card w-100 p-0 mt-8`}>
            <div className={'card-header'}>
              <div className='card-title'>
                <h3 className='card-label text-muted fw-bold'>Search Result</h3>
              </div>
              <div className='d-flex align-items-center'>
                <button
                  onClick={() => {
                    setShow(true)
                  }}
                  className='me-2 btn float-right btn-primary'
                >
                  <KTSVG
                    path='/media/icons/duotune/technology/teh011.svg'
                    className='svg-icon-2 svg-icon-light text-light'
                  />
                  Save Search
                </button>
              </div>
            </div>
            <div className={'card-body'}>
              <div className={'d-flex justify-content-around align-items-center'}>
                <div className={'fs-4'}>
                  The total number of results is
                  <span className={'ms-2 fw-bolder'}>{result ? result.length : 0}</span>
                </div>
                <button className={'btn btn-apply'} onClick={submitExportData}>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr091.svg'
                    className='svg-icon-2 svg-icon-light'
                  />
                  Download Search Results
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ShowResult
