import { MetricService } from "app/services/metric/MetricService";
import { MetricUtil } from "app/services/metric/MetricUtil";
import React, { useEffect, useState } from "react";
import { Metric, ReachMetric } from "../../../components/metrics/ReachMetric";
import { useDataReport } from "../../../DataReportProvider";
import { metrics } from "app/utils/data/metrics";
import { updateLoadedMetrics } from "app/store/reducers/metric_reducer";
import { useDispatch } from "react-redux";
import { getLoadedMetricById } from "app/utils/utils";

const EngagementVideo: React.FC = () => {

    const [videoProgress10, setVideoProgress10] = useState<Metric[] | null>(null)
    const [videoProgress25, setVideoProgress25] = useState<Metric[] | null>(null)
    const [videoProgress50, setVideoProgress50] = useState<Metric[] | null>(null)
    const [videoProgress75, setVideoProgress75] = useState<Metric[] | null>(null)
    const [totalVideoViewsGA, setTotalVideoViewsGA] = useState<Metric[] | null>(null)
    const [uniqueVideoViewsGA, setUniqueVideoViewsGA] = useState<Metric[] | null>(null)
    const [videoCompletionsGA, setVideoCompletionsGA] = useState<Metric[] | null>(null)
    const [totalVideoViewsVimeo, setTotalVideoViewsVimeo] = useState<Metric[] | null>(null)
    const [uniqueVideoViewsVimeo, setUniqueVideoViewsVimeo] = useState<Metric[] | null>(null)
    const [videoCompletionsVimeo, setVideoCompletionsVimeo] = useState<Metric[] | null>(null)
    const [avgVideoWatchedVimeo, setAvgVideoWatchedVimeo] = useState<Metric[] | null>(null)
    const [uniqueVideoCompletionsGA, setUniqueVideoCompletionsGA] = useState<Metric[] | null>(null)
    const [totalVideoViewsYouTube, setTotalVideoViewsYouTube] = useState<Metric[] | null>(null)
    const [youtubeVideoProgress10, setYoutubeVideoProgress10] = useState<Metric[] | null>(null)
    const [youtubeVideoProgress25, setYoutubeVideoProgress25] = useState<Metric[] | null>(null)
    const [youtubeVideoProgress50, setYoutubeVideoProgress50] = useState<Metric[] | null>(null)
    const [youtubeVideoProgress75, setYoutubeVideoProgress75] = useState<Metric[] | null>(null)
    const [totalVideoLikesYouTube, setTotalVideoLikesYouTube] = useState<Metric[] | null>(null)
    const [totalVideoFavoritesYouTube, setTotalVideoFavoritesYouTube] = useState<Metric[] | null>(null)
    const [totalVideoCommentsYouTube, setTotalVideoCommentsYouTube] = useState<Metric[] | null>(null)


    const dataReport = useDataReport();
    const dispatch = useDispatch();

    const getTotalVideoViewsYouTube = async (modifiedParams: any) => {
        modifiedParams.filter_filters.progress = null;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.totalVideoViewsYouTube.endpoint)).getResponseData();
        if (response.success === true) {
            setTotalVideoViewsYouTube(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.totalVideoViewsYouTube.id, data: response.data }));
        }
    }

    const getTotalVideoLikesYouTube = async (modifiedParams: any) => {
        modifiedParams.filter_filters.progress = null;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.totalVideoLikesYouTube.endpoint)).getResponseData();
        if (response.success === true) {
            setTotalVideoLikesYouTube(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.totalVideoLikesYouTube.id, data: response.data }));
        }
    }


    const getTotalVideoFavoritesYouTube = async (modifiedParams: any) => {
        modifiedParams.filter_filters.progress = null;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.totalVideoFavoritesYouTube.endpoint)).getResponseData();
        if (response.success === true) {
            setTotalVideoFavoritesYouTube(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.totalVideoFavoritesYouTube.id, data: response.data }));
        }
    }

    const getTotalVideoCommentsYouTube = async (modifiedParams: any) => {
        modifiedParams.filter_filters.progress = null;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.totalVideoCommentsYouTube.endpoint)).getResponseData();
        if (response.success === true) {
            setTotalVideoCommentsYouTube(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.totalVideoCommentsYouTube.id, data: response.data }));
        }
    }

    const getVideoProgress10 = async (modifiedParams: any) => {
        modifiedParams.filter_filters.progress = 10;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.videoProgress10Vimeo.endpoint)).getResponseData();
        if (response.success === true) {
            setVideoProgress10(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.videoProgress10Vimeo.id, data: response.data }));
        }
    }

    const getVideoProgress25 = async (modifiedParams: any) => {
        modifiedParams.filter_filters.progress = 25;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.videoProgress25Vimeo.endpoint)).getResponseData();
        if (response.success === true) {
            setVideoProgress25(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.videoProgress25Vimeo.id, data: response.data }));
        }
    }

    const getVideoProgress50 = async (modifiedParams: any) => {
        modifiedParams.filter_filters.progress = 50;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.videoProgress50Vimeo.endpoint)).getResponseData();
        if (response.success === true) {
            setVideoProgress50(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.videoProgress50Vimeo.id, data: response.data }));
        }
    }

    const getVideoProgress75 = async (modifiedParams: any) => {
        modifiedParams.filter_filters.progress = 75;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.videoProgress75Vimeo.endpoint)).getResponseData();
        if (response.success === true) {
            setVideoProgress75(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.videoProgress75Vimeo.id, data: response.data }));
        }
    }

    const getVimeoVisits = async (modifiedParams: any) => {
        modifiedParams.filter_filters.progress = null;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.totalVideoViewsVimeo.endpoint)).getResponseData();
        if (response.success === true) {
            setTotalVideoViewsVimeo(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.totalVideoViewsVimeo.id, data: response.data }));
        }
    }

    const getUniqueVideoViewsVimeo = async (modifiedParams: any) => {
        modifiedParams.filter_filters.progress = null;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.uniqueVideoViewsVimeo.endpoint)).getResponseData();
        if (response.success === true) {
            setUniqueVideoViewsVimeo(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.uniqueVideoViewsVimeo.id, data: response.data }));
        }
    }

    const getTotalVideoViewsGA = async (modifiedParams: any) => {
        modifiedParams.filter_filters.progress = null;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.totalVideoViewsGA.endpoint)).getResponseData();
        if (response.success === true) {
            setTotalVideoViewsGA(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.totalVideoViewsGA.id, data: response.data }));
        }
    }

    const getUniqueVideoViewsGA = async (modifiedParams: any) => {
        modifiedParams.filter_filters.progress = null;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.uniqueVideoViewsGA.endpoint)).getResponseData();
        if (response.success === true) {
            setUniqueVideoViewsGA(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.uniqueVideoViewsGA.id, data: response.data }));
        }
    }

    const getVideoCompletionsGA = async (modifiedParams: any) => {
        modifiedParams.filter_filters.progress = null;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.videoCompletionsGA.endpoint)).getResponseData();
        if (response.success === true) {
            setVideoCompletionsGA(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.videoCompletionsGA.id, data: response.data }));
        }
    }

    const getVideoCompletionsVimeo = async (modifiedParams: any) => {
        modifiedParams.filter_filters.progress = null;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.videoCompletionsVimeo.endpoint)).getResponseData();
        if (response.success === true) {
            setVideoCompletionsVimeo(response.data);
        }
    }

    const getAvgVideoWatchedVimeo = async (modifiedParams: any) => {
        modifiedParams.filter_filters.progress = null;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.avgVideoWatchedVimeo.endpoint)).getResponseData();
        if (response.success === true) {
            setAvgVideoWatchedVimeo(response.data);
        }
    }

    const getUniqueVideoCompletionsGA = async (modifiedParams: any) => {
        modifiedParams.filter_filters.progress = null;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.uniqueVideoCompletionsGA.endpoint)).getResponseData();
        if (response.success === true) {
            setUniqueVideoCompletionsGA(response.data);
        }
    }

    const getYoutubeVideoProgress10 = async (modifiedParams: any) => {
        //modifiedParams.filter_filters.progress = 10;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.videoProgress10Youtube.endpoint)).getResponseData();
        if (response.success === true) {
            setYoutubeVideoProgress10(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.videoProgress10Youtube.id, data: response.data }));
        }
    }

    const getYoutubeVideoProgress25 = async (modifiedParams: any) => {
        //modifiedParams.filter_filters.progress = 25;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.videoProgress25Youtube.endpoint)).getResponseData();
        if (response.success === true) {
            setYoutubeVideoProgress25(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.videoProgress25Youtube.id, data: response.data }));
        }
    }

    const getYoutubeVideoProgress50 = async (modifiedParams: any) => {
        //modifiedParams.filter_filters.progress = 50;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.videoProgress50Youtube.endpoint)).getResponseData();
        if (response.success === true) {
            setYoutubeVideoProgress50(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.videoProgress50Youtube.id, data: response.data }));
        }
    }

    const getYoutubeVideoProgress75 = async (modifiedParams: any) => {
        //modifiedParams.filter_filters.progress = 75;
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.videoProgress75Youtube.endpoint)).getResponseData();
        if (response.success === true) {
            setYoutubeVideoProgress75(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.videoProgress75Youtube.id, data: response.data }));
        }
    }

    /** 
     * To calculate amount and percent of latest "n" periods, we need to modify date filtered before 
     * make request. We need to do that because the widget needs to retrive last 24
     * times periods before the requested date to calculate the amount 
    */
    const getMetrics = () => {

        const calculateStartDate = MetricUtil.calculateStartDate(dataReport.params.filter_filters.between_dates.from, dataReport.params.filter_filters.mode);

        const modifiedParams = {
            ...dataReport.params,
            filter_filters: {
                ...dataReport.params.filter_filters,
                between_dates: {
                    from: calculateStartDate,
                    to: dataReport.params.filter_filters.between_dates.to
                }
            }
        };

        let total_video_views_ga = getLoadedMetricById(metrics.totalVideoViewsGA.id);
        let unique_video_views_ga = getLoadedMetricById(metrics.uniqueVideoViewsGA.id);
        let vimeo_visits = getLoadedMetricById(metrics.totalVideoViewsVimeo.id);
        let unique_video_views_vimeo = getLoadedMetricById(metrics.uniqueVideoViewsVimeo.id);
        let video_progress_10 = getLoadedMetricById(metrics.videoProgress10Vimeo.id);
        let video_progress_25 = getLoadedMetricById(metrics.videoProgress25Vimeo.id);
        let video_progress_50 = getLoadedMetricById(metrics.videoProgress50Vimeo.id);
        let video_progress_75 = getLoadedMetricById(metrics.videoProgress75Vimeo.id);
        let video_completions_ga = getLoadedMetricById(metrics.videoCompletionsGA.id);
        let video_completions_vimeo = getLoadedMetricById(metrics.videoCompletionsVimeo.id);
        let avg_video_watched_vimeo = getLoadedMetricById(metrics.avgVideoWatchedVimeo.id);
        let unique_video_completion_ga = getLoadedMetricById(metrics.uniqueVideoCompletionsGA.id);
        let total_video_views_youTube = getLoadedMetricById(metrics.totalVideoViewsYouTube.id);
        let youtube_video_progress_10 = getLoadedMetricById(metrics.videoProgress10Youtube.id);
        let youtube_video_progress_25 = getLoadedMetricById(metrics.videoProgress25Youtube.id);
        let youtube_video_progress_50 = getLoadedMetricById(metrics.videoProgress50Youtube.id);
        let youtube_video_progress_75 = getLoadedMetricById(metrics.videoProgress75Youtube.id);
        let total_video_likes_youTube = getLoadedMetricById(metrics.totalVideoLikesYouTube.id);
        let total_video_favorites_youTube = getLoadedMetricById(metrics.totalVideoFavoritesYouTube.id);
        let total_video_comments_youTube = getLoadedMetricById(metrics.totalVideoCommentsYouTube.id);

        if (total_video_views_youTube !== null && total_video_views_youTube !== undefined) {
            setTotalVideoViewsYouTube(total_video_views_youTube);
        } else {
            getTotalVideoViewsYouTube(modifiedParams);
        }

        if (total_video_likes_youTube !== null && total_video_likes_youTube !== undefined) {
            setTotalVideoLikesYouTube(total_video_likes_youTube);
        } else {
            getTotalVideoLikesYouTube(modifiedParams);
        }

        if (total_video_favorites_youTube !== null && total_video_favorites_youTube !== undefined) {
            setTotalVideoFavoritesYouTube(total_video_favorites_youTube);
        } else {
            getTotalVideoFavoritesYouTube(modifiedParams);
        }


        if (total_video_comments_youTube !== null && total_video_comments_youTube !== undefined) {
            setTotalVideoCommentsYouTube(total_video_comments_youTube);
        } else {
            getTotalVideoCommentsYouTube(modifiedParams);
        }

        if (total_video_views_ga !== null && total_video_views_ga !== undefined) {
            setTotalVideoViewsGA(total_video_views_ga);
        } else {
            getTotalVideoViewsGA(modifiedParams);
        }

        if (vimeo_visits !== null && vimeo_visits !== undefined) {
            setTotalVideoViewsVimeo(vimeo_visits);
        } else {
            getVimeoVisits(modifiedParams);
        }

        if (unique_video_views_ga !== null && unique_video_views_ga !== undefined) {
            setUniqueVideoViewsGA(unique_video_views_ga);
        } else {
            getUniqueVideoViewsGA(modifiedParams);
        }

        if (unique_video_views_vimeo !== null && unique_video_views_vimeo !== undefined) {
            setUniqueVideoViewsVimeo(unique_video_views_vimeo);
        } else {
            getUniqueVideoViewsVimeo(modifiedParams);
        }

        if (video_progress_10 !== null && video_progress_10 !== undefined) {
            setVideoProgress10(video_progress_10);
        } else {
            getVideoProgress10(modifiedParams);
        }

        if (video_progress_25 !== null && video_progress_25 !== undefined) {
            setVideoProgress25(video_progress_25);
        } else {
            getVideoProgress25(modifiedParams);
        }

        if (video_progress_50 !== null && video_progress_50 !== undefined) {
            setVideoProgress50(video_progress_50);
        } else {
            getVideoProgress50(modifiedParams);
        }

        if (video_progress_75 !== null && video_progress_75 !== undefined) {
            setVideoProgress75(video_progress_75);
        } else {
            getVideoProgress75(modifiedParams);
        }

        if (video_completions_ga !== null && video_completions_ga !== undefined) {
            setVideoCompletionsGA(video_completions_ga);
        } else {
            getVideoCompletionsGA(modifiedParams);
        }

        if (video_completions_vimeo !== null && video_completions_vimeo !== undefined) {
            setVideoCompletionsVimeo(video_completions_vimeo);
        } else {
            getVideoCompletionsVimeo(modifiedParams);
        }

        if (avg_video_watched_vimeo !== null && avg_video_watched_vimeo !== undefined) {
            setAvgVideoWatchedVimeo(avg_video_watched_vimeo);
        } else {
            getAvgVideoWatchedVimeo(modifiedParams);
        }


        if (unique_video_completion_ga !== null && unique_video_completion_ga !== undefined) {
            setUniqueVideoCompletionsGA(unique_video_completion_ga);
        } else {
            getUniqueVideoCompletionsGA(modifiedParams);
        }

        if (youtube_video_progress_10 !== null && youtube_video_progress_10 !== undefined) {
            setYoutubeVideoProgress10(youtube_video_progress_10);
        } else {
            getYoutubeVideoProgress10(modifiedParams);
        }

        if (youtube_video_progress_25 !== null && youtube_video_progress_25 !== undefined) {
            setYoutubeVideoProgress25(youtube_video_progress_25);
        } else {
            getYoutubeVideoProgress25(modifiedParams);
        }

        if (youtube_video_progress_50 !== null && youtube_video_progress_50 !== undefined) {
            setYoutubeVideoProgress50(youtube_video_progress_50);
        } else {
            getYoutubeVideoProgress50(modifiedParams);
        }

        if (youtube_video_progress_75 !== null && youtube_video_progress_75 !== undefined) {
            setYoutubeVideoProgress75(youtube_video_progress_75);
        } else {
            getYoutubeVideoProgress75(modifiedParams);
        }

    }

    const resetReports = () => {
        setTotalVideoViewsYouTube(null);
        setTotalVideoLikesYouTube(null);
        setTotalVideoFavoritesYouTube(null);
        setTotalVideoCommentsYouTube(null);
        setTotalVideoViewsGA(null);
        setTotalVideoViewsVimeo(null);
        setUniqueVideoViewsGA(null);
        setUniqueVideoViewsVimeo(null);
        setVideoProgress10(null);
        setVideoProgress25(null);
        setVideoProgress50(null);
        setVideoProgress75(null);
        setVideoCompletionsGA(null);
        setVideoCompletionsVimeo(null);
        setAvgVideoWatchedVimeo(null);
        setUniqueVideoCompletionsGA(null);
        setYoutubeVideoProgress10(null);
        setYoutubeVideoProgress25(null);
        setYoutubeVideoProgress50(null);
        setYoutubeVideoProgress75(null);
    }


    useEffect(() => {
        resetReports();
        getMetrics();
    }, [dataReport.params])


    return (

        <div className='row g-5 g-xl-8' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <div className="col-xl-12">
                <hr></hr>
            </div>
            {/* begin::Col */}
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image={'/media/svg/social-logos/' + metrics.totalVideoViewsYouTube.logo}
                    snapshotId={metrics.totalVideoViewsYouTube.id}
                    title={metrics.totalVideoViewsYouTube.title}
                    subtitle={metrics.totalVideoViewsYouTube.subtitle}
                    startPeriod={24}
                    metrics={totalVideoViewsYouTube}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                    snapshotSwitch={true}
                />
            </div>
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image={'/media/svg/social-logos/' + metrics.totalVideoLikesYouTube.logo}
                    snapshotId={metrics.totalVideoLikesYouTube.id}
                    title={metrics.totalVideoLikesYouTube.title}
                    subtitle={metrics.totalVideoLikesYouTube.subtitle}
                    startPeriod={24}
                    metrics={totalVideoLikesYouTube}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                    snapshotSwitch={true}
                />
            </div>
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image={'/media/svg/social-logos/' + metrics.totalVideoFavoritesYouTube.logo}
                    snapshotId={metrics.totalVideoFavoritesYouTube.id}
                    title={metrics.totalVideoFavoritesYouTube.title}
                    subtitle={metrics.totalVideoFavoritesYouTube.subtitle}
                    startPeriod={24}
                    metrics={totalVideoFavoritesYouTube}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                    snapshotSwitch={true}
                />
            </div>
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image={'/media/svg/social-logos/' + metrics.totalVideoCommentsYouTube.logo}
                    snapshotId={metrics.totalVideoCommentsYouTube.id}
                    title={metrics.totalVideoCommentsYouTube.title}
                    subtitle={metrics.totalVideoCommentsYouTube.subtitle}
                    startPeriod={24}
                    metrics={totalVideoCommentsYouTube}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                    snapshotSwitch={true}
                />
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image='/media/svg/brand-logos/google-icon.svg'
                    snapshotId={metrics.totalVideoViewsGA.id}
                    title={metrics.totalVideoViewsGA.title}
                    subtitle={metrics.totalVideoViewsGA.subtitle}
                    startPeriod={24}
                    metrics={totalVideoViewsGA}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                    snapshotSwitch={true}
                />
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image='/media/svg/brand-logos/vimeo.svg'
                    snapshotId={metrics.totalVideoViewsVimeo.id}
                    title={metrics.totalVideoViewsVimeo.title}
                    subtitle={metrics.totalVideoViewsVimeo.subtitle}
                    startPeriod={24}
                    metrics={totalVideoViewsVimeo}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                />
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image={'/media/svg/metric_icons/' + metrics.uniqueVideoViewsGA.logo}
                    snapshotId={metrics.uniqueVideoViewsGA.id}
                    title={metrics.uniqueVideoViewsGA.title}
                    subtitle={metrics.uniqueVideoViewsGA.subtitle}
                    startPeriod={24}
                    metrics={uniqueVideoViewsGA}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                    snapshotSwitch={true}
                />
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image={'/media/svg/metric_icons/' + metrics.uniqueVideoViewsVimeo.logo}
                    snapshotId={metrics.uniqueVideoViewsVimeo.id}
                    title={metrics.uniqueVideoViewsVimeo.title}
                    subtitle={metrics.uniqueVideoViewsVimeo.subtitle}
                    startPeriod={24}
                    metrics={uniqueVideoViewsVimeo}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                />
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            {/*}
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image='/media/svg/brand-logos/google-icon.svg'
                    snapshotId={metrics.totalUniqueVideoViewsGT.id}
                    title={metrics.totalUniqueVideoViewsGT.title}
                    subtitle={metrics.totalUniqueVideoViewsGT.subtitle}
                    startPeriod={24}
                    metrics={totalVideoViewsGA}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                />
            </div>
            */}
            {/* end::Col */}
            {/* begin::Col */}

            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image={'/media/svg/metric_icons/' + metrics.avgVideoWatchedVimeo.logo}
                    snapshotId={metrics.avgVideoWatchedVimeo.id}
                    title={metrics.avgVideoWatchedVimeo.title}
                    subtitle={metrics.avgVideoWatchedVimeo.subtitle}
                    startPeriod={24}
                    metrics={avgVideoWatchedVimeo}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                />
            </div>

            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image={'/media/svg/metric_icons/' + metrics.videoProgress10Vimeo.logo}
                    snapshotId={metrics.videoProgress10Vimeo.id}
                    title={metrics.videoProgress10Vimeo.title}
                    subtitle={metrics.videoProgress10Vimeo.subtitle}
                    startPeriod={24}
                    metrics={videoProgress10}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                    snapshotSwitch={true}
                />
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image={'/media/svg/metric_icons/' + metrics.videoProgress25Vimeo.logo}
                    snapshotId={metrics.videoProgress25Vimeo.id}
                    title={metrics.videoProgress25Vimeo.title}
                    subtitle={metrics.videoProgress25Vimeo.subtitle}
                    startPeriod={24}
                    metrics={videoProgress25}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                />
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image={'/media/svg/metric_icons/' + metrics.videoProgress50Vimeo.logo}
                    snapshotId={metrics.videoProgress50Vimeo.id}
                    title={metrics.videoProgress50Vimeo.title}
                    subtitle={metrics.videoProgress50Vimeo.subtitle}
                    startPeriod={24}
                    metrics={videoProgress50}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                />
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image={'/media/svg/metric_icons/' + metrics.videoProgress75Vimeo.logo}
                    snapshotId={metrics.videoProgress75Vimeo.id}
                    title={metrics.videoProgress75Vimeo.title}
                    subtitle={metrics.videoProgress75Vimeo.subtitle}
                    startPeriod={24}
                    metrics={videoProgress75}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                />
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image={'/media/svg/metric_icons/' + metrics.videoCompletionsGA.logo}
                    snapshotId={metrics.videoCompletionsGA.id}
                    title={metrics.videoCompletionsGA.title}
                    subtitle={metrics.videoCompletionsGA.subtitle}
                    startPeriod={24}
                    metrics={videoCompletionsGA}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                    snapshotSwitch={true}
                />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image={'/media/svg/metric_icons/' + metrics.videoCompletionsVimeo.logo}
                    snapshotId={metrics.videoCompletionsVimeo.id}
                    title={metrics.videoCompletionsVimeo.title}
                    subtitle={metrics.videoCompletionsVimeo.subtitle}
                    startPeriod={24}
                    metrics={videoCompletionsVimeo}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                    snapshotSwitch={true}
                />
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image='/media/svg/brand-logos/google-icon.svg'
                    snapshotId={metrics.uniqueVideoCompletionsGA.id}
                    title={metrics.uniqueVideoCompletionsGA.title}
                    subtitle={metrics.uniqueVideoCompletionsGA.subtitle}
                    startPeriod={24}
                    metrics={uniqueVideoCompletionsGA}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                />
            </div>

            {/* end::Col */}
            {/* begin::Col */}
            {/* 
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image='/media/svg/brand-logos/vimeo.svg'
                    snapshotId={metrics.videoCompletionsVimeo.id}
                    title={metrics.videoCompletionsVimeo.title}
                    subtitle={metrics.videoCompletionsVimeo.subtitle}
                    startPeriod={24}
                    metrics={totalVideoViewsGA}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                />
            </div>
            */}
            {/* end::Col */}
            {/* begin::Col */}
            {/*
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image='/media/svg/brand-logos/vimeo.svg'
                    snapshotId={metrics.uniqueVideoCompletionsVimeo.id}
                    title={metrics.uniqueVideoCompletionsVimeo.title}
                    subtitle={metrics.uniqueVideoCompletionsVimeo.subtitle}
                    startPeriod={24}
                    metrics={totalVideoViewsGA}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                />
            </div>
             */}
            {/* end::Col */}

            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image={'/media/svg/social-logos/' + metrics.videoProgress10Youtube.logo}
                    snapshotId={metrics.videoProgress10Youtube.id}
                    title={metrics.videoProgress10Youtube.title}
                    subtitle={metrics.videoProgress10Youtube.subtitle}
                    startPeriod={24}
                    metrics={youtubeVideoProgress10}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                //snapshotSwitch={true}
                />
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image={'/media/svg/social-logos/' + metrics.videoProgress25Youtube.logo}
                    snapshotId={metrics.videoProgress25Youtube.id}
                    title={metrics.videoProgress25Youtube.title}
                    subtitle={metrics.videoProgress25Youtube.subtitle}
                    startPeriod={24}
                    metrics={youtubeVideoProgress25}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                />
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image={'/media/svg/social-logos/' + metrics.videoProgress50Youtube.logo}
                    snapshotId={metrics.videoProgress50Youtube.id}
                    title={metrics.videoProgress50Youtube.title}
                    subtitle={metrics.videoProgress50Youtube.subtitle}
                    startPeriod={24}
                    metrics={youtubeVideoProgress50}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                />
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-xl-6 flex-xl-fill mt-0'>
                <ReachMetric
                    logo_image={'/media/svg/social-logos/' + metrics.videoProgress75Youtube.logo}
                    snapshotId={metrics.videoProgress75Youtube.id}
                    title={metrics.videoProgress75Youtube.title}
                    subtitle={metrics.videoProgress75Youtube.subtitle}
                    startPeriod={24}
                    metrics={youtubeVideoProgress75}
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='250px'
                />
            </div>
            {/* end::Col */}
        </div>

    )
}


export default EngagementVideo;