import { useCallback, useEffect, useState } from "react";
import MarketingTableCustom from "./MarketingTableCustom";
import { SingleValue } from "react-select";
import CustomSelect from "@components/form/CustomSelect";
import MarketingWebsiteChart from "./MarketingWebsiteLineChart";
import { useMarketing } from "../MarketingProvider";
import MarketingExtendedWebsiteTable from "./MarketingExtendedWebsiteTable";
import { formatNumber, limitDecimals, replaceUnderscoresWithSpacesAndCapitalize } from "app/utils/utils";
import SpinnerCustom from "@components/SpinnerCustom";
import { metricMarketingUtils } from "app/services/metric/MetricMarketingUtils";
const MarketingWebsiteTable = () => {

    const Marketing = useMarketing(); // MARKETING CONTEXT

    // ABORT CONTROLLERS

    const abortControllers = {
      sessions: new AbortController(),
      newUsers: new AbortController(),
      returningUsers: new AbortController(),
      activeUsers: new AbortController(),
      engagedSessions: new AbortController(),
      engagementRate: new AbortController(),
      avgEngagementTime: new AbortController(),
    };

    // STATES

    const [option, setOption] = useState('');
    const [oldData, setOldData] = useState<any>(null);
    const [show , setShow] = useState(false);
    const [allMetrics, setAllMetrics] = useState<any>([]);
    const [title, setTitle] = useState('');
    const [rowsChart, setRowsChart] = useState<any>([]);
    const [extendedTableMetric, setExtendedTableMetric] = useState<any>('');
    const [chartMetric, setChartMetric] = useState<any>('');
    const [extendedTableTitle, setExtendedTableTitle] = useState('');
    const [extendedTableShow, setExtendedTableShow] = useState(false);
    const [data, setData] = useState<any>(null);

    // OPTIONS FOR SELECTS

    const technologyOptions = [ { value: 'device_category', label: 'Device category' }, { value: 'browser', label: 'Browser' }, { value: 'operating_system', label: 'Operating system' }, { value: 'platform', label: 'Platform' } ];
    const demographicsOptions = [ { value: 'region' , label: 'GEO - Region'}, { value: 'country' , label: 'GEO - Country'}, { value: 'language' , label: 'Language'} ]  
    const options = [{value: Marketing.params.filter_filters.group , label: `Insight level selected: ${replaceUnderscoresWithSpacesAndCapitalize(Marketing.params.filter_filters.group || '')}`}, { value: 'channel', label: 'Channel' }, { value: 'sources', label: 'Source' }, 
    { value: 'medium', label: 'Medium' }, { value: 'demographics', label: 'Demographics' }, { value: 'technology', label: 'Technology' } ];
  
    const [subGroup, setSubGroup] = useState<any>([])

    // FUNCTION TO CALCULATE TOTALS

    const calculateTotals = (data: any) => {
      let count =  0
      // DEFAULT TOTALS
      let totals = {totalSessions: 0, totalNewUsers: 0, totalReturningUsers: 0, totalActiveUsers: 0, totalEngagedSessions: 0, totalEngagementRate: 0, totalAvgEngagementTime: 0};
      data && data.forEach((element: any) => {
        totals.totalSessions += parseFloat(element.sessions); // TOTALS FOR SESSIONS
        totals.totalNewUsers += parseFloat(element.newUsers); // TOTALS FOR NEW USERS
        totals.totalReturningUsers += parseFloat(element.returningUsers); // TOTALS FOR RETURNING USERS
        totals.totalActiveUsers += parseFloat(element.activeUsers); // TOTALS FOR ACTIVE USERS
        totals.totalEngagedSessions += parseFloat(element.engagedSessions); // TOTALS FOR ENGAGED SESSIONS
        totals.totalEngagementRate += parseFloat(element.engagementRate); // TOTALS FOR ENGAGEMENT RATE
        totals.totalAvgEngagementTime += parseFloat(element.avgEngagementTime); // TOTALS FOR AVG ENGAGEMENT TIME
        count ++; // TOTALS FOR COUNT
      });

      if (count > 0) {
        totals.totalAvgEngagementTime /= count;
      }    

      return totals;
    }

    // FUNCTION TO CHANGE SELECTED OPTION

    const onChangeOption = (selected: SingleValue<unknown>) => {
      let optionSelected: any = selected;
      setSubGroup(null);

      if (optionSelected.value !== 'technology' || optionSelected.value !== 'demographics') { // IF SELECTED OPTION IS NOT TECHNOLOGY OR DEMOGRAPHICS, UPDATE FILTERS
        Marketing.updateFilters({ ...Marketing.params.filter_filters, selected_group: optionSelected.value});
      }
      setOption(optionSelected.value);
    }

    // FUNCTION TO GENERATE SELECTS DEPENDING ON SELECTED OPTION
  
    const renderSelects = useCallback(() => {
      switch (option) {
          case 'demographics':
            return (
              <div className='form-group fs-12p d-flex justify-content-center mt-2 ms-2' key={subGroup}>
                <CustomSelect options={demographicsOptions} placeholder="Select Option" customFontSize="12px" onChangeSingle={(selected: SingleValue<unknown>) => {
                    let optionSelected: any = selected;
                    setSubGroup(optionSelected.value);
                  }} minWidth="200px" defaultValue={{label:demographicsOptions.find((option) => option.value === subGroup)?.label, value: subGroup}}/>
              </div>
            )
          case 'technology':
              return (
                <div className='form-group fs-12p d-flex justify-content-center mt-2 ms-2' key={subGroup}>
                  <CustomSelect options={technologyOptions} placeholder="Select Option" customFontSize="12px" onChangeSingle={(selected: SingleValue<unknown>) => {
                    let optionSelected: any = selected;
                    setSubGroup(optionSelected.value);
                  }} minWidth="200px" defaultValue={{label:technologyOptions.find((option) => option.value === subGroup)?.label, value: subGroup}}/>
                </div>
              )
          default:
              return (
                  <></>
              )
      }
  }, [option, subGroup])

  const getMetricsData = async () => {
    let group = Marketing.params.filter_filters.group; // GET SELECTED GROUP

    if (group === 'therapeutic_areas') {
      group = 'therapeutic_areas_ids';
    }

    const marketingUtils = new metricMarketingUtils();

    // GET DATA FOR EACH METRIC

    const metricsArray = await Promise.all([
      marketingUtils.getTotalMetricWebsiteMain(abortControllers.sessions.signal, 'website_visits', Marketing , subGroup),
      marketingUtils.getTotalMetricWebsiteMain(abortControllers.newUsers.signal, 'website_new_user', Marketing , subGroup),
      marketingUtils.getTotalMetricWebsiteMain(abortControllers.returningUsers.signal, 'website_return_user', Marketing , subGroup),
      marketingUtils.getTotalMetricWebsiteMain(abortControllers.activeUsers.signal, 'website_active_user', Marketing , subGroup),
      marketingUtils.getTotalMetricWebsiteMain(abortControllers.engagedSessions.signal, 'website_engaged_session', Marketing , subGroup),
      marketingUtils.getTotalMetricWebsiteMain(abortControllers.engagementRate.signal, 'website_engaged_rate', Marketing , subGroup),
      marketingUtils.getTotalMetricWebsiteMain(abortControllers.avgEngagementTime.signal, 'website_avg_engaged_time', Marketing , subGroup),
    ]).then((values) => ({
      sessionsData: values[0],
      newUsersData: values[1],
      returningUsersData: values[2],
      activeUsersData: values[3],
      engagedSessionsData: values[4],
      websiteEngagementRate: values[5],
      avgEngagementTimeData: values[6],
    }));

    setAllMetrics(metricsArray);

    const uniqueGroups = Array.from(
      new Set(
        (await Promise.all(
          Object.values(metricsArray).flatMap(async (metricPromise: any) => {
            const metricData = await metricPromise;
            return metricData.map((element: any) => element.group);
          })
        )).flat().filter(Boolean)
      )
    );
  
    let arrayColumns = Marketing.params.filter_filters.selected_group == Marketing.params.filter_filters.group ?
    Marketing.params.filter_filters.selected_names : 
    uniqueGroups; // GET ARRAY OF SELECTED VALUES


    const data = Array.isArray(arrayColumns) ?  // IF ARRAY, RETURN DATA FOR EACH VALUE
      await Promise.all(arrayColumns.map(async (column: any, index: number) => {
            // IF DATA IS NULL, RETURN 0 AND IF CONTAINS DECIMALS, LIMIT TO 3 DECIMALS
            let sessions = limitDecimals(metricsArray.sessionsData.find((element: any) => element.group === column)?.total == null ? 0 : metricsArray.sessionsData.find((element: any) => element.group === column).total);
            let newUsers = limitDecimals(metricsArray.newUsersData.find((element: any) => element.group === column)?.total == null ? 0 : metricsArray.newUsersData.find((element: any) => element.group === column).total);
            let returningUsers = limitDecimals(metricsArray.returningUsersData.find((element: any) => element.group === column)?.total == null ? 0 : metricsArray.returningUsersData.find((element: any) => element.group === column).total);
            let activeUsers = limitDecimals(metricsArray.activeUsersData.find((element: any) => element.group === column)?.total == null ? 0 : metricsArray.activeUsersData.find((element: any) => element.group === column).total);
            let engagedSessions = limitDecimals(metricsArray.engagedSessionsData.find((element: any) => element.group === column)?.total == null ? 0 : metricsArray.engagedSessionsData.find((element: any) => element.group === column).total);
            let engagementRate = limitDecimals(metricsArray.websiteEngagementRate.find((element: any) => element.group === column)?.total == null ? 0 : metricsArray.websiteEngagementRate.find((element: any) => element.group === column).total);
            let avgEngagementTime = limitDecimals(metricsArray.avgEngagementTimeData.find((element: any) => element.group === column)?.total == null ? 0 : metricsArray.avgEngagementTimeData.find((element: any) => element.group === column).total);

            if ( Marketing.params.filter_filters.group === 'overall' && Marketing.params.filter_filters.selected_group === 'overall' ) {
              sessions = limitDecimals(metricsArray.sessionsData[0]?.total == null ? 0 : metricsArray.sessionsData[0].total);
              newUsers = limitDecimals(metricsArray.newUsersData[0]?.total == null ? 0 : metricsArray.newUsersData[0].total);
              returningUsers = limitDecimals(metricsArray.returningUsersData[0]?.total == null ? 0 : metricsArray.returningUsersData[0].total);
              activeUsers = limitDecimals(metricsArray.activeUsersData[0]?.total == null ? 0 : metricsArray.activeUsersData[0].total);
              engagedSessions = limitDecimals(metricsArray.engagedSessionsData[0]?.total == null ? 0 : metricsArray.engagedSessionsData[0].total);
              engagementRate = limitDecimals(metricsArray.websiteEngagementRate[0]?.total == null ? 0 : metricsArray.websiteEngagementRate[0].total);
              avgEngagementTime = limitDecimals(metricsArray.avgEngagementTimeData[0]?.total == null ? 0 : metricsArray.avgEngagementTimeData[0].total);
            }

            if ( Marketing.params.filter_filters.group == 'formats' && Marketing.params.filter_filters.selected_group == 'formats' ) {
              let formatColumn = column.toLowerCase().replace(' ', '-').trim();
              sessions = limitDecimals(metricsArray.sessionsData.find((element: any) => element.group === formatColumn)?.total == null ? 0 : metricsArray.sessionsData.find((element: any) => element.group === formatColumn).total);
              newUsers = limitDecimals(metricsArray.newUsersData.find((element: any) => element.group === formatColumn)?.total == null ? 0 : metricsArray.newUsersData.find((element: any) => element.group === formatColumn).total);
              returningUsers = limitDecimals(metricsArray.returningUsersData.find((element: any) => element.group === formatColumn)?.total == null ? 0 : metricsArray.returningUsersData.find((element: any) => element.group === formatColumn).total);
              activeUsers = limitDecimals(metricsArray.activeUsersData.find((element: any) => element.group === formatColumn)?.total == null ? 0 : metricsArray.activeUsersData.find((element: any) => element.group === formatColumn).total);
              engagedSessions = limitDecimals(metricsArray.engagedSessionsData.find((element: any) => element.group === formatColumn)?.total == null ? 0 : metricsArray.engagedSessionsData.find((element: any) => element.group === formatColumn).total);
              engagementRate = limitDecimals(metricsArray.websiteEngagementRate.find((element: any) => element.group === formatColumn)?.total == null ? 0 : metricsArray.websiteEngagementRate.find((element: any) => element.group === formatColumn).total);
              avgEngagementTime = limitDecimals(metricsArray.avgEngagementTimeData.find((element: any) => element.group === formatColumn)?.total == null ? 0 : metricsArray.avgEngagementTimeData.find((element: any) => element.group === formatColumn).total);
            }

            let insight = Marketing.params.filter_filters.selected_group == Marketing.params.filter_filters.group ?
            Marketing.params.filter_filters.selected_names.find((element: any) => element === column) :
            column == "" || column == null ? "Other" : column;

          return {
            insight: insight, // GET NAME OF SELECTED VALUE
            sessions,
            newUsers,
            returningUsers,
            activeUsers,
            engagedSessions,
            engagementRate,
            avgEngagementTime
          };
        }))
      : []; // IF NOT ARRAY, RETURN EMPTY ARRAY
    return data;
  };

  useEffect(() => {

    if (Marketing.params.filter_filters.group === '') return
    if (subGroup === null && (Marketing.params.filter_filters.selected_group === 'technology' || Marketing.params.filter_filters.selected_group === 'demographics')) return

    setOldData(data); // SET OLD DATA

    const fetchData = async () => {
      const data = await getMetricsData();
      setData(data); // SET DATA
    };

    fetchData(); // FETCH DATA

    // RESET STATES

    setShow(false);
    setTitle('');
    setRowsChart([]);
    setExtendedTableShow(false);
    setExtendedTableTitle('');
    setExtendedTableMetric(null);

    // ABORT CONTROLLERS

    return () => {
      Object.values(abortControllers).forEach(controller => controller.abort());
    };



  }, [Marketing.params.filter_filters, subGroup]);

    return (
        <>
        <div className='col-xl-12 position-relative'>
                <>
                {data ? (
                <>
                <div className='bg-primary text-center fw-bolder text-light p-2 fs-4' style={{borderTopLeftRadius : '5px' ,borderTopRightRadius : '5px'}}>WEBSITE KPIs</div>
                <div className="p-1" style={{backgroundColor: '#33B4E5'}}></div>
                <MarketingTableCustom className="card-primary" 
                  title="Third-party data"
                  tableClassName="w-100"
                  subtitle="List of third-party data"
                  key={Marketing.params.filter_filters.group}
                  showTotalsColumn={true}
                  totalsRowPosition='top'
                  totalsRow={calculateTotals(data)}
                  data={data}
                  actionRow={[
                    {
                      title: "Edit",
                      actionGroup: [
                        {
                          buttonType: "icon",
                          iconPath: "/media/icons/duotune/graphs/gra013.svg",
                          svgClassName: "svg-table-icon",
                          callback: () => {
                              setShow(true);
                              setTitle('Visits over time');
                              setChartMetric('website_visits');
                              if (Marketing.params.filter_filters.selected_group == Marketing.params.filter_filters.group && Marketing.params.filter_filters.group == 'formats') {
                                  let formatsColumns = Marketing.params.filter_filters.selected_names.map((element: any) => element.toLowerCase().replace(' ', '-').trim());
                                  setRowsChart(allMetrics.sessionsData.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10).filter((element: any) => formatsColumns.includes(element)))
                              } else if (Marketing.params.filter_filters.selected_group == Marketing.params.filter_filters.group) {
                                  setRowsChart(allMetrics.sessionsData.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10).filter((element: any) => Marketing.params.filter_filters.selected_names.includes(element)))
                              } else {
                                  setRowsChart(allMetrics.sessionsData.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10))
                              }
                          }
                        },
                        {
                          buttonType: "icon",
                          iconPath: "/media/icons/duotune/files/fil026.svg",
                          svgClassName: `svg-table-icon-2`,
                          additionalClasses: Marketing.params.filter_filters.group == Marketing.params.filter_filters.selected_group ? 'd-none' : 'd-block',
                          callback: () => {
                            setExtendedTableShow(true);
                            setExtendedTableTitle('Visits');
                            setExtendedTableMetric('website_visits');
                          }
                        }
                      ]
                    },
                    {
                      title: "Edit",
                      actionGroup: [
                        {
                          buttonType: "icon",
                          iconPath: "/media/icons/duotune/graphs/gra013.svg",
                          svgClassName: "svg-table-icon",
                          callback: () => {
                            setShow(true);
                            setTitle('New Users over time');
                            setChartMetric('website_new_user');
                            if (Marketing.params.filter_filters.selected_group == Marketing.params.filter_filters.group && Marketing.params.filter_filters.group == 'formats') {
                              let formatsColumns = Marketing.params.filter_filters.selected_names.map((element: any) => element.toLowerCase().replace(' ', '-').trim());
                              setRowsChart(allMetrics.newUsersData.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10).filter((element: any) => formatsColumns.includes(element)))
                            } else if (Marketing.params.filter_filters.selected_group == Marketing.params.filter_filters.group) {
                              setRowsChart(allMetrics.newUsersData.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10).filter((element: any) => Marketing.params.filter_filters.selected_names.includes(element)))
                            } else {
                              setRowsChart(allMetrics.newUsersData.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10))
                            }
                          }
                        },
                        {
                          buttonType: "icon",
                          iconPath: "/media/icons/duotune/files/fil026.svg",
                          svgClassName: "svg-table-icon-2",
                          additionalClasses: Marketing.params.filter_filters.group == Marketing.params.filter_filters.selected_group ? 'd-none' : 'd-block',
                          callback: () => {
                            setExtendedTableShow(true);
                            setExtendedTableTitle('New Users');
                            setExtendedTableMetric('website_new_user');
                          }
                        }
                      ]
                    },
                    {
                      title: "Edit",
                      actionGroup: [
                        {
                          buttonType: "icon",
                          iconPath: "/media/icons/duotune/graphs/gra013.svg",
                          svgClassName: "svg-table-icon",
                          callback: () => {
                            setShow(true);
                            setTitle('Returning Users over time');
                            setChartMetric('website_return_user');
                            if (Marketing.params.filter_filters.selected_group == Marketing.params.filter_filters.group && Marketing.params.filter_filters.group == 'formats') {
                              let formatsColumns = Marketing.params.filter_filters.selected_names.map((element: any) => element.toLowerCase().replace(' ', '-').trim());
                              setRowsChart(allMetrics.returningUsersData.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10).filter((element: any) => formatsColumns.includes(element)))
                            } else if (Marketing.params.filter_filters.selected_group == Marketing.params.filter_filters.group) {
                              setRowsChart(allMetrics.returningUsersData.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10).filter((element: any) => Marketing.params.filter_filters.selected_names.includes(element)))
                            } else {
                              setRowsChart(allMetrics.returningUsersData.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10))
                            }
                          }
                        },
                        {
                          buttonType: "icon",
                          iconPath: "/media/icons/duotune/files/fil026.svg",
                          svgClassName: "svg-table-icon-2",
                          additionalClasses: Marketing.params.filter_filters.group == Marketing.params.filter_filters.selected_group ? 'd-none' : 'd-block',
                          callback: () => {
                            setExtendedTableShow(true);
                            setExtendedTableTitle('Returning Users');
                            setExtendedTableMetric('website_return_user');
                          }
                        }
                      ]
                    },
                    {
                      title: "Edit",
                      actionGroup: [
                        {
                          buttonType: "icon",
                          iconPath: "/media/icons/duotune/graphs/gra013.svg",
                          svgClassName: "svg-table-icon",
                          callback: () => {
                            setShow(true);
                            setTitle('Active Users over time');
                            setChartMetric('website_active_user');
                            if (Marketing.params.filter_filters.selected_group == Marketing.params.filter_filters.group && Marketing.params.filter_filters.group == 'formats') {
                              let formatsColumns = Marketing.params.filter_filters.selected_names.map((element: any) => element.toLowerCase().replace(' ', '-').trim());
                              setRowsChart(allMetrics.activeUsersData.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10).filter((element: any) => formatsColumns.includes(element)))
                            } else if (Marketing.params.filter_filters.selected_group == Marketing.params.filter_filters.group) {
                              setRowsChart(allMetrics.activeUsersData.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10).filter((element: any) => Marketing.params.filter_filters.selected_names.includes(element)))
                            } else {
                              setRowsChart(allMetrics.activeUsersData.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10))
                            }
                          }
                        },
                        {
                          buttonType: "icon",
                          iconPath: "/media/icons/duotune/files/fil026.svg",
                          svgClassName: "svg-table-icon-2",
                          additionalClasses: Marketing.params.filter_filters.group == Marketing.params.filter_filters.selected_group ? 'd-none' : 'd-block',
                          callback: () => {
                            setExtendedTableShow(true);
                            setExtendedTableTitle('Active Users');
                            setExtendedTableMetric('website_active_user');
                          }
                        }
                      ]
                    },
                    {
                      title: "Edit",
                      actionGroup: [
                        {
                          buttonType: "icon",
                          iconPath: "/media/icons/duotune/graphs/gra013.svg",
                          svgClassName: "svg-table-icon",
                          callback: () => {
                            setShow(true);
                            setTitle('Engaged Sessions over time');
                            setChartMetric('website_engaged_session');
                            if (Marketing.params.filter_filters.selected_group == Marketing.params.filter_filters.group && Marketing.params.filter_filters.group == 'formats') {
                              let formatsColumns = Marketing.params.filter_filters.selected_names.map((element: any) => element.toLowerCase().replace(' ', '-').trim());
                              setRowsChart(allMetrics.engagedSessionsData.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10).filter((element: any) => formatsColumns.includes(element)))
                            } else if (Marketing.params.filter_filters.selected_group == Marketing.params.filter_filters.group) {
                              setRowsChart(allMetrics.engagedSessionsData.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10).filter((element: any) => Marketing.params.filter_filters.selected_names.includes(element)))
                            } else {
                              setRowsChart(allMetrics.engagedSessionsData.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10))
                            }
                          }
                        },
                        {
                          buttonType: "icon",
                          iconPath: "/media/icons/duotune/files/fil026.svg",
                          svgClassName: "svg-table-icon-2",
                          additionalClasses: Marketing.params.filter_filters.group == Marketing.params.filter_filters.selected_group ? 'd-none' : 'd-block',
                          callback: () => {
                            setExtendedTableShow(true);
                            setExtendedTableTitle('Engaged Sessions');
                            setExtendedTableMetric('website_engaged_session');
                          }
                        }
                      ]
                    },
                    {
                      title: "Edit",
                      actionGroup: [
                        {
                          buttonType: "icon",
                          iconPath: "/media/icons/duotune/graphs/gra013.svg",
                          svgClassName: "svg-table-icon",
                          callback: () => {
                            setShow(true);
                            setTitle('Engagement rate over time');
                            setChartMetric('website_engaged_rate');
                            if (Marketing.params.filter_filters.selected_group == Marketing.params.filter_filters.group && Marketing.params.filter_filters.group == 'formats') {
                              let formatsColumns = Marketing.params.filter_filters.selected_names.map((element: any) => element.toLowerCase().replace(' ', '-').trim());
                              setRowsChart(allMetrics.websiteEngagementRate.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10).filter((element: any) => formatsColumns.includes(element)))
                            } else if (Marketing.params.filter_filters.selected_group == Marketing.params.filter_filters.group) {
                              setRowsChart(allMetrics.websiteEngagementRate.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10).filter((element: any) => Marketing.params.filter_filters.selected_names.includes(element)))
                            } else {
                              setRowsChart(allMetrics.websiteEngagementRate.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10))
                            }
                          }
                        },
                        {
                          buttonType: "icon",
                          iconPath: "/media/icons/duotune/files/fil026.svg",
                          svgClassName: "svg-table-icon-2",
                          additionalClasses: Marketing.params.filter_filters.group == Marketing.params.filter_filters.selected_group ? 'd-none' : 'd-block',
                          callback: () => {
                            setExtendedTableShow(true);
                            setExtendedTableTitle('Engagement rate');
                            setExtendedTableMetric('website_engaged_rate');
                          }
                        }
                      ]
                    },
                    {
                      title: "Edit",
                      actionGroup: [
                        {
                          buttonType: "icon",
                          iconPath: "/media/icons/duotune/graphs/gra013.svg",
                          svgClassName: "svg-table-icon",
                          callback: () => {
                            setShow(true);
                            setTitle('Avg. Engagement time over time');
                            setChartMetric('website_avg_engaged_time');
                            if (Marketing.params.filter_filters.selected_group == Marketing.params.filter_filters.group && Marketing.params.filter_filters.group == 'formats') {
                              let formatsColumns = Marketing.params.filter_filters.selected_names.map((element: any) => element.toLowerCase().replace(' ', '-').trim());
                              setRowsChart(allMetrics.avgEngagementTimeData.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10).filter((element: any) => formatsColumns.includes(element)))
                            } else if (Marketing.params.filter_filters.selected_group == Marketing.params.filter_filters.group) {
                              setRowsChart(allMetrics.avgEngagementTimeData.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10).filter((element: any) => Marketing.params.filter_filters.selected_names.includes(element)))
                            } else {
                              setRowsChart(allMetrics.avgEngagementTimeData.sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10))
                            }
                          }
                        },
                        {
                          buttonType: "icon",
                          iconPath: "/media/icons/duotune/files/fil026.svg",
                          svgClassName: "svg-table-icon-2",
                          additionalClasses: Marketing.params.filter_filters.group == Marketing.params.filter_filters.selected_group ? 'd-none' : 'd-block',
                          callback: () => {
                            setExtendedTableShow(true);
                            setExtendedTableTitle('Avg. Engagement time');
                            setExtendedTableMetric('website_avg_engaged_time');
                          }
                        }
                      ]
                    },
                  ]}
                  columns={[
                    {
                      name: 'insightValue', keyValue: 'insight',
                      className: 'text-center bg-marketing-fields-init pre-line fs-12p min-w-200px',
                      renderHeader: () => {
                        return (
                          <div id={option}>
                            <div className='form-group fs-12p d-flex justify-content-center ms-2' style={{overflow: 'visible'}}>
                                <CustomSelect options={options} placeholder={`Select Option`} customFontSize="12px" onChangeSingle={onChangeOption} 
                                defaultValue={
                                  {
                                    label: options.find((option) => option.value === Marketing.params.filter_filters.selected_group)?.label,
                                    value: Marketing.params.filter_filters.selected_group,
                                  }
                                } minWidth="200px"/>
                            </div>
                            {renderSelects()}
                          </div>
                        );
                      },
                      render: (element: any) => {
                        return (
                          <>
                            <div className="text-center fw-bold">
                              <span>{element.insight}</span>
                            </div>
                          </>
                        );
                      }
                    },
                    {
                      name: "Visits", keyValue: 'website_visits', 
                      className: 'align-middle text-center bg-marketing-fields fs-12p',
                      render: (element: any) => {
                        return (
                          <>
                            <div className="text-center">
                              <span>{formatNumber(element.sessions)}</span>
                            </div>
                          </>
                        );
                      }
                    },
                    {
                    name: "New Users", keyValue: 'newUsers',
                      className: 'align-middle text-center bg-marketing-fields fs-12p',
                      render: (element: any) => {
                        return (
                          <>
                            <div className="text-center">
                              <span>{formatNumber(element.newUsers)}</span>
                            </div>
                          </>
                        );
                      }
                    },
                    {
                      name: "Returning Users", keyValue: 'returningUsers',
                      className: 'align-middle text-center bg-marketing-fields fs-12p',
                      render: (element: any) => {
                        return (
                          <>
                            <div className="text-center">
                              <span>{formatNumber(element.returningUsers)}</span>
                            </div>
                          </>
                        );
                      }
                    },
                    {
                      name: "Active Users", keyValue: 'activeUsers',
                      className: 'align-middle text-center bg-marketing-fields fs-12p',
                      render: (element: any) => {
                        return (
                          <>
                            <div className="text-center">
                              <span>{formatNumber(element.activeUsers)}</span>
                            </div>
                          </>
                        );
                      }
                    },
                    {
                      name: "Engaged Sessions", keyValue: 'engagedSessions',
                      className: 'align-middle text-center bg-marketing-fields fs-12p',
                      render: (element: any) => {
                        return (
                          <>
                            <div className="text-center">
                              <span>{formatNumber(element.engagedSessions)}</span>
                            </div>
                          </>
                        );
                      }
                    },
                    {   
                      name: "Engagement rate", keyValue: 'engagementRate',
                      className: 'align-middle text-center bg-marketing-fields fs-12p',
                      render: (element: any) => {
                        return (
                          <>
                            <div className="text-center">
                              <span>{formatNumber(element.engagementRate)}</span>
                            </div>
                          </>
                        );
                      }
                    },
                    {
                      name: "Avg. Engagement time", keyValue: 'avgEngagementTime',
                      className: 'align-middle text-center bg-marketing-fields fs-12p',
                      render: (element: any) => {
                        return (
                          <>
                            <div className="text-center">
                              <span>{formatNumber(element.avgEngagementTime)}</span>
                            </div>
                          </>
                        );
                      }
                    },
                  ]}
                />
                <div className="pt-5">
                    <MarketingWebsiteChart show={show} setShow={setShow} title={title} subtitle="" rows={rowsChart} metric={chartMetric} subGroup={subGroup}/>
                </div>
                <div className="pt-5">
                    <MarketingExtendedWebsiteTable show={extendedTableShow} setShow={setExtendedTableShow} title={extendedTableTitle} extendedMetrics={extendedTableMetric}
                    rows={rowsChart} subGroup={subGroup}
                    />
                </div> 
              </>
                ) : (<></>)}
                </>
              <div className={data == oldData ? 'custom-overlay' : 'd-none'}>
                <SpinnerCustom className='mt-custom' size='medium'/>
              </div>
        </div>
      </>
    );
}

export default MarketingWebsiteTable;