import CustomSelect from "@components/form/CustomSelect";
import _ from "lodash";
import { FC, useState } from "react";
import { useExternalProviders } from "../ExternalProvidersProvider";
import { CustomDateRangePicker, RangeDate } from "@components/form/CustomDateRangePicker";

export type ExternalProvidersFilters = {
  providers?: string[] | null;
  campaignId?: string[] | null;
  programme_ids?: string[] | null;
  between_dates?: {from: string, to: string} | null;
}

type Props = {
  setFilters: (filters: ExternalProvidersFilters) => void;
  filters: ExternalProvidersFilters | null;
}

const ExternalProviderListFilters: FC<Props> = ({ setFilters, filters }) => {

  const providers = useExternalProviders(); 

  const campaignIds = useExternalProviders();

  const programmesIds = useExternalProviders();

  const [resetKey, setResetKey] = useState("filters");

  const updateFilters = (value: Partial<ExternalProvidersFilters>) => {
    let newObject = {
      ...filters,
      ...value

    }

    if (_.isEqual(filters, newObject) === false) {
      setFilters(newObject)
    }

  }

  const onChangeDate = (range: RangeDate) => {

    if (range.startDate && range.endDate) {
        updateFilters({
            between_dates: {
                from: range.startDate,
                to: range.endDate
            }
        });
    }

}


  return (

    <div className="column">
      <div key={resetKey}>
        <div className="row mt-5">
          <h4> Between Dates </h4>
            <CustomDateRangePicker onChangeDate={onChangeDate}/>
        </div>
        <div className="row mt-5">
          <h4> Providers </h4>
          <CustomSelect is_multi options={providers.providers} onChange={(selected: any) => updateFilters({providers: selected.map((s: any) => s.value)})}> </CustomSelect>
        </div>
        <div className="row mt-5">
          <h4> Campaign ID  </h4>
            <CustomSelect is_multi options={campaignIds.campaignIds} onChange={(selected: any) => updateFilters({campaignId: selected.map((s: any) => s.value)})}> </CustomSelect>
        </div>
        <div className="row mt-5">
          <h4> Programme ID  </h4>
            <CustomSelect is_multi options={programmesIds.programmesIds} onChange={(selected: any) => updateFilters({programme_ids: selected.map((s: any) => s.value)})}> </CustomSelect>
        </div>
      </div>
      <div className="row mt-5">
        <button className="btn btn-primary" onClick={() => {
          setFilters({})
          setResetKey(resetKey == "filters" ? "resetFilters" : "filters")
        }
      }> Clear Filters </button>
      </div>
    </div>
  )
}


export default ExternalProviderListFilters;