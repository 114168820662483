import React from "react";
import classes from "_metronic/assets/sass/core/components/drawers/drawer.module.scss";
import { KTSVG } from "_metronic/helpers";

type Props = {
    open: boolean,
    anchor: 'left' | 'right',
    onClose: () => void,
    title: string
}

const CustomDrawer: React.FC<Props> = ({ open, anchor, onClose, title, children }) => {

    const { drawer, animate, hidden, overlay, overlayOpen, overlayHidden } = classes;

    const changeAnchor = (anchor: string, classes: any) => {
        switch (anchor) {
          case "left":
            return classes.left;
          case "right":
            return classes.right;
          default:
            return classes.left;
        }
      };

    return (
        <>
            <div className={`${overlay} ${!open && overlayHidden} ${open && overlayOpen}`} onClick={onClose} aria-hidden="true" />
            <div className={`${drawer} ${open && animate} ${!open && hidden} ${changeAnchor(anchor, classes)}`} >
                <div className='card w-100 rounded-0'>
                    <div className='card-header pe-5'>
                        <div className='card-title'>
                            <div className='d-flex justify-content-center flex-column me-3'>
                                <a href='#' className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
                                    {title}
                                </a>
                            </div>
                        </div>

                        <div className='card-toolbar'>
                            <div className='btn btn-sm btn-icon btn-active-light-primary' onClick={onClose}>
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
                            </div>
                        </div>
                    </div>

                    <div className='card-body'>
                        {children}
                    </div>

                </div>
            </div>
        </>
    );
};

CustomDrawer.defaultProps = {

};


export { CustomDrawer };