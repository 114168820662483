import { FC, useState } from 'react'
import { KTSVG } from '_metronic/helpers'
import '@/_metronic/assets/sass/components/_loading.scss'
import { ImportService } from 'app/services/import/srcurity/ImportService'
import Swal from 'sweetalert2'

const SpotifyImportBase: FC = () => {

    const [token, setToken] = useState<String | null>(null)
    const [tokenError, setTokenError] = useState<String | null>(null)
    const [tokenURL, setTokenURL] = useState<String | null>(null)

    const handleTokenChange = (token: any) => {
        setToken(token)
    }

    const submitData = async (type:string) => {
        let response;        
        const data = {
            code: token,
        }
        Swal.fire({
            showConfirmButton: false,
            title: '<h3> Importing Data... <h3>',
            loaderHtml: '<div class="custom-spinner mb-5"><span></span><span></span><span></span></div>',
            allowOutsideClick: false,
            validationMessage: '<p class="text-muted"> This operation could take a while </p>',
            html: '<p class="text-muted"> This operation could take a while </p>',
            iconHtml: '<img src = "/media/icons/duotune/social/spotify-logo.svg"/>',

            customClass: {
                loader: 'custom-loader',
                icon: 'custom-loader-icon',

            },
            willOpen: () => {
                Swal.showLoading()
            }
        });

        if (type === "URL") {
            response = (await (new ImportService).importSpotifyRemoteAccessURL()).getResponse();
        }
        else {
            response = (await (new ImportService).importSpotifyRemoteAccessToken(data)).getResponse();
        }        
        Swal.close();

        if (response != null && response.status == 200 && response.data.success == true) {
            if (type === "URL") {
                setTokenURL(response?.data.data);
                setTimeout(()=>{
                    setTokenURL(null)
                },10000)
            }

            Swal.fire({
                title: 'Success!',
                text: 'Data imported successfully',
                icon: 'success',
                confirmButtonText: 'Ok',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            })
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong',
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            })
        }
    }


    const handleSubmit = async (event: any) => {
        setTokenError(null)
        event.preventDefault();
        let tmpToken = token?.trim()
        if (!tmpToken) {
            await submitData("URL");
        }
        else {
            await submitData("TOKEN");
        }
    }


    return (

        <div className='justify-content-center d-flex'>
            <div className="card card-custom example example-compact col-sm-4">
                <div className='card-header'>
                    <h3 className='card-title'> <KTSVG path='/media/icons/duotune/social/spotify-card.svg' className='svg-icon-1 me-2' /> Import Spotify remote access token </h3>
                </div>

                <form onSubmit={handleSubmit} className="form">
                    <div className="card-body">
                        <div className="form-group validated">
                            <label className="form-label fw-bold" >Code 
                            </label>
                            <div className="input-group">
                                <textarea onChange={(e) => handleTokenChange(e.target.value)}
                                    className={`form-control ${tokenError !== null && tokenError !== undefined ? 'is-invalid' : ''}`}
                                    placeholder="Code"
                                />
                            </div>
                            {tokenError !== null && tokenError !== undefined && (
                                <small className='text-danger'> {tokenError} </small>
                            )}
                            <div className='row'>
                                <small className="form-text text-muted">Retrieved from redirect URL in Spotify Authentication URL</small>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary font-weight-bold me-2 d-flex">Import</button>
                    </div>
                </form>
                <div className="card-footer">
                    {tokenURL && <a href={`${tokenURL}`} target="_blanck">`${tokenURL.slice(0, 60)}...`</a>}
                </div>
            </div>
        </div>
    )
}

export { SpotifyImportBase }




