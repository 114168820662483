import { PEBarData, PEData, PEDonutData, PEPieData } from "@components/chart/types";
import WorkLoadComponent from "@components/workload_component/WorkLoadComponent";
import { MetricService } from "app/services/metric/MetricService";
import { getMetric, getMetricColors } from "app/utils/utils";
import React, { useEffect, useState } from "react";
import { ProgrammeExplorerFilterParams } from "../ProgrammeExplorerProvider";
import ProgrammeExplorerHorizontalBarChart from "./ProgrammeExplorerHorizontalBarChart";

interface Props {
  filters: ProgrammeExplorerFilterParams,
  metric: string
}

export const ProgrammeExplorerFilteredCardTop10ProgrammesMetric: React.FC<Props> = ({ filters, metric }) => {

  const [series, setSeries] = useState<PEPieData[] | null>(null)
  const mainAbortController = new AbortController()


  useEffect(() => {

    setSeries(null)

    getMetrics(mainAbortController.signal)

    return () => {
      mainAbortController.abort()
    }

  }, [filters])


  const getMetrics = async (signal: AbortSignal) => {
    const response = await getTop10ProgrammesMetric(signal)
    setSeries(response)
  }


  const getTop10ProgrammesMetric = async (signal: AbortSignal): Promise<PEBarData[] | null> => {

    const modifiedFilters: any = {
      ...filters,
      filter_filters: {
        ...filters.filter_filters,
        programmes: [],
        group: "programme",
        campaigns: [],
        source: filters.filter_filters.source || undefined
      }
    }

    const response = (await (new MetricService()).getMetricEndpointData(modifiedFilters, metric, 'group', signal)).getResponseData()

    if (response === undefined || !response.success) return null

    let barData: PEBarData[] = response.data.map((item: any, index: number) => {
      return {
        name: item.group,
        color: getMetricColors(0),
        data: item.total,
      }
    })

    return barData

  }

  return (
    <WorkLoadComponent evaluateElement={series}>

      <ProgrammeExplorerHorizontalBarChart data={series || []} position={'right'} ></ProgrammeExplorerHorizontalBarChart>

    </WorkLoadComponent>
  )
}