import { MetricService } from "app/services/metric/MetricService";
import { MetricUtil } from "app/services/metric/MetricUtil";
import React, { useEffect, useState } from "react";
import { Metric, ReachMetric } from "../../../components/metrics/ReachMetric";
import { useDataReport } from "../../../DataReportProvider";
import { metrics } from "app/utils/data/metrics";
import { updateLoadedMetrics } from "app/store/reducers/metric_reducer";
import { useDispatch } from "react-redux";
import { getLoadedMetricById } from "app/utils/utils";

const ReachVideo: React.FC = () => {

    const [videoImpressions, setImpressions] = useState<Metric[] | null>(null)
    const [videoUniqueImpressions, setUniqueImpressions] = useState<Metric[] | null>(null)

    const dataReport = useDataReport()
    const dispatch = useDispatch();

    const getVideoImpressions = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.videoImpressions.endpoint)).getResponseData();
        if (response.success === true) {
            setImpressions(response.data);
            dispatch(updateLoadedMetrics({id: metrics.videoImpressions.id, data: response.data}));
        }
    }

    const getVideoUniqueImpressions = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.videoUniqueImpressions.endpoint)).getResponseData();
        if (response.success === true) {
            setUniqueImpressions(response.data);
            dispatch(updateLoadedMetrics({id: metrics.videoUniqueImpressions.id, data: response.data}));

        }
    }

    /** 
     * To calculate amount and percent of latest "n" periods, we need to modify date filtered before 
     * make request. We need to do that because the widget needs to retrive last 24
     * times periods before the requested date to calculate the amount 
    */
    const getMetrics = () => {

        const calculateStartDate = MetricUtil.calculateStartDate(dataReport.params.filter_filters.between_dates.from, dataReport.params.filter_filters.mode);

        const modifiedParams = {
            ...dataReport.params,
            filter_filters: {
                ...dataReport.params.filter_filters,
                between_dates: {
                    from: calculateStartDate,
                    to: dataReport.params.filter_filters.between_dates.to
                }
            }
        };

        let video_impressions = getLoadedMetricById(metrics.videoImpressions.id);
        let video_unique_impressions = getLoadedMetricById(metrics.videoUniqueImpressions.id);

        if(video_impressions !== null && video_impressions !== undefined) {
            setImpressions(video_impressions);
        }else{
            getVideoImpressions(modifiedParams);
        }

        if(video_unique_impressions !== null && video_unique_impressions !== undefined) {
            setUniqueImpressions(video_unique_impressions);
        }else{
            getVideoUniqueImpressions(modifiedParams);
        }

    }

    const resetReports = () => {
        setImpressions(null);
        setUniqueImpressions(null);
    }


    useEffect(() => {
        resetReports();
        getMetrics();
    }, [dataReport.params])


    return (
        <>
            <div className='row g-5 g-xl-8' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                <div className="col-xl-12">
                    <hr></hr>
                </div>
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/'+metrics.videoImpressions.logo}
                        snapshotId={metrics.videoImpressions.id}
                        title={metrics.videoImpressions.title}
                        subtitle={metrics.videoImpressions.subtitle}
                        startPeriod={24}
                        metrics={videoImpressions}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/'+metrics.videoUniqueImpressions.logo}
                        snapshotId={metrics.videoUniqueImpressions.id}
                        title={metrics.videoUniqueImpressions.title}
                        subtitle={metrics.videoUniqueImpressions.subtitle}
                        startPeriod={24}
                        metrics={videoUniqueImpressions}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
            </div>
        </>
    )
}


export default ReachVideo;