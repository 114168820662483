import ConfigRoutes from 'app/routing/ConfigRoutes';
import { ContactService } from 'app/services/contacts/ContactService';
import { FC, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { KTSVG } from '_metronic/helpers';
import { PageTitle } from '_metronic/layout/core';
import { ContactList } from './ContactList'
import { ErrorList } from './ErrorList';
import { StatusList } from './StatusList';

const ContactsViewWrapper: FC = () => {
    const [selectedView, setSelectedView] = useState<Number>(0);
    const [fileName, setFileName] = useState<string |undefined>('');

    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        setFileName('Imported File ' + id);
    });
    return (
        <>
            <PageTitle>{fileName}</PageTitle>
            <div className='d-flex overflow-auto h-55px category-nav ps-5 pt-5 pb-5'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap w-100'>
                    <li className='nav-item'>
                        <Link className={`text-primary me-6`} to={ConfigRoutes.admin.imports.contacts.list.fullPath} >
                            <KTSVG path='/media/icons/duotune/arrows/arr026.svg' />
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <a role='button' className={`nav-link text-active-primary me-6 ` +
                            (selectedView === 0 && 'active')} onClick={() => setSelectedView(0)}> Contact List </a>
                    </li>
                    <li className='nav-item'>
                        <a role='button' className={`nav-link text-active-primary me-6 ` +
                            (selectedView === 1 && 'active')} onClick={() => setSelectedView(1)}> Status List </a>
                    </li>
                    <li className='nav-item'>
                        <a role='button' className={`nav-link text-active-primary me-6 ` +
                            (selectedView === 2 && 'active')} onClick={() => setSelectedView(2)}> Error List </a>
                    </li>
                </ul>
            </div>


            {selectedView === 0 && <ContactList />}
            {selectedView === 1 && <StatusList />}
            {selectedView === 2 && <ErrorList />}
        </>

    )
}

export { ContactsViewWrapper }