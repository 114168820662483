import { CognitoRefreshToken, CognitoUser, CognitoUserPool, CognitoUserSession, ICognitoUserPoolData } from 'amazon-cognito-identity-js';
import { ResponseType } from 'axios';
import { AuthState, AuthUserState, refreshUserToken, removeUser, setUser } from '../../../store/reducers/auth_reducer';
import store from '../../../store/store';
import { AuthModel, UserModel, UserRefreshToken } from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'auth'
const USER_LOCAL_STORAGE_KEY = 'user'
const DEFAULT_ATTEMPTS = 3

var attempts: number = DEFAULT_ATTEMPTS;
var interval: number|null = null;
const pendingRequests: any[] = [];
var isRefreshingToken = false;

const getAuth = (): AuthState | undefined => {
    if (!store.getState()) {
        return
    }

    return store.getState().auth;
}

const getCurrentUser = (): UserModel | undefined => {
    return;
}

const setAuth = (auth: AuthState) => {
    if (!localStorage) {
        return
    }

    try {
        const lsValue = JSON.stringify(auth)
        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
    } catch (error) {
        console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
    }
}

const setCurrentUser = (user: UserModel | undefined) => {
    if (!localStorage) {
        return
    }

    try {
        const lsValue = JSON.stringify(user)
        localStorage.setItem(USER_LOCAL_STORAGE_KEY, lsValue)
    } catch (error) {
        console.error('USER LOCAL STORAGE SAVE ERROR', error)
    }
}

const removeAuth = () => {
    if (!localStorage) {
        return
    }

    try {
        localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
    } catch (error) {
        console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
    }
}

const startTimeout = async (axios: any, error: any) => {

    return new Promise((resolve, reject) => {
        interval = window.setTimeout(async () => {
            attempts--;
            // If error is unauthorized we launch unauthorized flow.
            if (error.response?.status === 401) {
                
                if (attempts > 0) {
                    await executeUnauthorizedFlow(error)
                    return resolve(axios.request(error.config));
                } else {
                    // if attempts is consumed, we reset attempts and reject the request.
                    attempts = DEFAULT_ATTEMPTS;
                    // We logout the user if attempts arrive to 0.
                    store.dispatch(removeUser());
                    return reject(error);
                }
            }
    
            if (error.response?.status === 404) {
                error.response.data.success = false;
                error.response.data.message = "URL not found";
                return resolve(error.response);
            }
            
    
        }, 1000); 
    })
    
}

const processPendingRequests = () => {
    const auth = getAuth()
    if (auth && auth.user?.auth) {
        pendingRequests.forEach(request => {
            const {resolve, reject, originalRequest, axios} = request;
            originalRequest.headers.Authorization = `Bearer ${auth.user?.auth.access_token}`
            resolve(axios(originalRequest));
        })
    }
    
}


export function setupAxios(axios: any) {
    axios.defaults.headers.Accept = 'application/json'

    axios.interceptors.request.use(
        (config: { headers: { Authorization: string } }) => {
            const auth = getAuth()
            if (auth && auth.user?.auth) {
                config.headers.Authorization = `Bearer ${auth.user?.auth.access_token}`
            }

            return config
        },
        (err: any) => Promise.reject(err)
    )




    // Response interceptor.
    const interceptor = axios.interceptors.response.use(
        (response: ResponseType) => {
            attempts = DEFAULT_ATTEMPTS;
            return Promise.resolve(response);
        },
        async (error: any) => {
            
            const originalRequest = error.config;

            if(error.response.status === 403) {
                return new Promise((resolve, reject) => {
                    resolve(error.response);
                });
            }

            if(error.response.status === 401 && isRefreshingToken) {
                // If we are refreshing token, we queue the request
                return new Promise((resolve, reject) => {
                    pendingRequests.push({resolve, reject, originalRequest, axios});
                })
            }

            // If error is 401 and not is refreshing token, we try to refresh token. This condition only entry the first time and the original request.
            if(error.response.status === 401 && !isRefreshingToken) {

                // update the flag for avoid that other requests come inside this condition.
                isRefreshingToken = true;
                
                // try to refresh the token
                await refreshToken()
                
                // sub the attempts for not recall multiple times.
                attempts--;
                
                // Axios require that we return always a promise for resolve the request.
                return new Promise((resolve, reject) => {

                    // Control attempts. When attempts is 0 we remove the user and force logout.
                    if(attempts <= 0) {
                        store.dispatch(removeUser());
                        console.error("Imposible to refresh user token")
                        attempts = DEFAULT_ATTEMPTS
                        reject("Imposible to refresh user token")
                        return;
                    }

                    // if refresh is success we retrieve the access token from localStorage and set new request headers
                    const auth = getAuth()
                    if (auth && auth.user?.auth) {
                        originalRequest.headers.Authorization = `Bearer ${auth.user?.auth.access_token}`
                    }

                    console.info("Attempt of refresh token", attempts);

                    // resolve the original request trying to call with the refreshed access token
                    resolve(axios(originalRequest))

                    // finished we process al requests that are in queue
                    processPendingRequests();

                    // Set refreshing token to false for reset the process.
                    isRefreshingToken = false;
                })
            }

            /*
                return new Promise((resolve, reject) => {

                    // Unauthorized to access to this resource.
                    if(error.response.status === 403) {
                        resolve(error.response);
                        return;
                    }

                    // Internal Server error
                    if(error.response.status === 500) {
                        resolve(error.response);
                        return;
                    }
                    
                    // User token has expired, then we have to try to refresh it.
                    if(error.response.status === 401) {

                        const originalRequest = error.config;
                        
                        if(!isRefreshingToken) {

                            isRefreshingToken = true;
                            
                            refreshToken()

                            axios.interceptor.request.eject(interceptor)

                            
                        }
                        
                        console.log(originalRequest);

                        //store.dispatch(removeUser());


                    }

                    resolve(error.response);
                    return;
                })
             */
            

        }
    )
}

/**
 * This function try to refresh user token and make the last
 * request if the token was refreshed succesfully.
 * 
 */
async function executeUnauthorizedFlow(error: any) {

    try {
        let refreshStatus = await refreshToken();
    

        // If refreshToken causes an error... We logout user.
        if (refreshStatus === null) {
            
            store.dispatch(removeUser());
        } else {
            let userRefreshToken: UserRefreshToken = {
                access_token: refreshStatus.getAccessToken().getJwtToken(),
                refresh_token: refreshStatus.getRefreshToken().getToken()
            }
            // If token is refreshed succesfully, we update the user object and repeat the request.
            store.dispatch(refreshUserToken(userRefreshToken));
        }
    } catch(e: any) {
        console.error("Error refreshing token");
        store.dispatch(removeUser());
    }
    

}


/** 
* Function to refresh user token using Cognito SDK.
*/
async function refreshToken(): Promise<CognitoUserSession | null> {

    return new Promise((resolve, reject) => {
        const { user } = store.getState().auth;

        let userRefreshToken = user?.auth.refresh_token;
        let username = user?.email;

        if (userRefreshToken !== undefined && username !== undefined) {

            let cognitoUser = getCognitoUser(username);
            let refreshToken = new CognitoRefreshToken({ RefreshToken: userRefreshToken });

            cognitoUser.refreshSession(refreshToken, (err, session: CognitoUserSession | null) => {
                if (err === null) {
                    const tokenPayload = {
                        access_token: session?.getAccessToken().getJwtToken() ?? "",
                        refresh_token: session?.getRefreshToken().getToken() ?? ""
                    }
                    store.dispatch(refreshUserToken(tokenPayload))
                    resolve(session);
                }
                if (err !== null) {
                    // throw new error();
                    reject(null);
                }
            });

        }
    })

}


/**
     * Returns CognitoUser with environment configuration and email pass like parameter.
     * 
     * @param username 
     * @returns 
     */
function getCognitoUser(username: string): CognitoUser {
    let poolData: ICognitoUserPoolData = {
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!,
    }

    let userPool = new CognitoUserPool(poolData);

    let userData = {
        Username: username,
        Pool: userPool,
    }

    return new CognitoUser(userData);

}


export { getAuth, setAuth, removeAuth, getCurrentUser, setCurrentUser, AUTH_LOCAL_STORAGE_KEY, USER_LOCAL_STORAGE_KEY }
