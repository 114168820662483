import {animations, parentValues, parents, setParentValues} from '@formkit/drag-and-drop'
import {useDragAndDrop} from '@formkit/drag-and-drop/react'
import {Operation} from '../../CustomMetricConfiguratorProvider'
import { KTSVG } from '_metronic/helpers'
import Swal from 'sweetalert2'

interface CustomMetricOperationsProps {
  targetClone: any
  dragEnd: any
  operations: Operation[]
  setShowModal: any
  setType: any
  setElementKey: any
  setElementToEdit: any
}

const CustomMetricOperations = (props: CustomMetricOperationsProps) => {

  // DRAG AND DROP

  const [operationsList, operations] = useDragAndDrop(props.operations, {    
    group: 'operations',
    sortable: true,
    handleEnd: () => props.dragEnd(),
    plugins: [props.targetClone, animations()],
  })

  // CUSTOM ON CLICK
  
  const customOnClick = (e: any , operation : any) => {
    if (operation.type == 'operation') {
      Swal.fire({
        icon: 'error',
        title: 'Delete operation',
        text: 'Are you sure you want to delete this operation?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: '#477995',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          const operationsListElement = document.getElementById('operationsList');
          if (operationsListElement) {
            let parentObject = parents.get(operationsListElement) as any; 
            let intialParentValues = parentValues(operationsListElement, parentObject).filter(
              (x: any) => x.key !== operation.key
            )
            setParentValues(operationsListElement, parentObject, intialParentValues)
            props.dragEnd()
          }
        }
      })
      return
    }
    props.setShowModal(true)
    props.setType(operation.type)
    props.setElementKey(operation.key)
    props.setElementToEdit(operation)
  }

  return (
    <div className='card'>
      <div className='card-header d-flex align-items-center'>
        <h2 className='card-title'>Operations</h2>
      </div>
      <div className='card-body'>
        {
          //@ts-ignore
          <ul ref={operationsList} className='row d-flex align-items-center operations-min-height' id='operationsList'>
            {operations.length > 0 &&
              operations.map((operation: any) => {
                return (
                  <li
                    key={operation.key}
                    className={`${ 'col-auto'
                    } mb-2 li-custom-metrics-operations`}
                    onClick={(e) => customOnClick(e , operation)}
                  >
                    <span
                      className={`me-2 ${
                        operation.type == 'operation'
                          ? 'span-operation'
                          : operation.type == 'metric_tag'
                          ? 'span-custom'
                          : 'span-manual'
                      } `}
                      data-key={operation.key}
                    >
                      {operation.label}
                      {operation.label == 'Existing metric' || operation.label == 'Manual Value' ? (
                        <KTSVG
                          path='/media/icons/duotune/general/gen044.svg'
                          className='svg-icon-1 ms-2 text-warning'
                        />
                      ) : null}
                    </span>
                  </li>
                )
              })}
            {operations.length == 0 && (
              <span className='text-muted d-flex flex-column justify-content-center align-items-center fw-bold fs-3'>
                <KTSVG path='/media/icons/duotune/abstract/abs007.svg' className='svg-icon-1 me-2'/>
                Drag and drop items here
              </span>
            )}
          </ul>
        }
      </div>
    </div>
  )
}

export default CustomMetricOperations
