import { RestServiceConnection } from "../RestServiceConnection";


export class CastosService extends RestServiceConnection {
    
    uploadStatFile = async (data: any) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/castos/listener/upload',
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }, true);
        return this;
    }
}