import { RestServiceConnection } from "../RestServiceConnection";


export class ConnectService extends RestServiceConnection {
    
    search = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/connect/search',
            data: filter
        }, true);
        return this;
    }

}