import CustomModalBody from "@components/modal/body";
import CustomModalHeader from "@components/modal/header";
import CustomModal from "@components/modal/modal";
import { SnapshotService } from "app/services/snapshot/SnapshotService";
import { UserService } from "app/services/user/UserService";
import { FC, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import SnapshotData from "./SnapshotData";
import SnapshotForm from "./SnapshotForm";


interface Props {
    snapshotId: string | null,
    onHideModal: Function,
    reloadTable: Function
}

const SnapshotModal: FC<Props> = ({ snapshotId, onHideModal, reloadTable }) => {

    const [showStatus, setShowStatus] = useState<boolean>(false);
    const [users, setUsers] = useState([{}]);
    const [defaultUsers, setDefaultUsers] = useState<Array<Object> | null>(null);
    const [defaultName, setDefaultName] = useState<string | null>(null);

    const hideModal = () => {
        onHideModal(null);
        setDefaultUsers(null)
        setDefaultName(null)
    }

    const handleSubmitResponse = (response: any) => {
        if (response == true) {
            Swal.fire({
                icon: 'success',
                title: 'Snapshot created successfully',
                showConfirmButton: false,
                timer: 3000
            })
            hideModal();
            reloadTable();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                confirmButtonColor: '#477995',
            })
        }
    }

    const getUsers = async () => {
        let response = await (await (new UserService()).getUsers()).getResponseData();
        let users = [];
        for (let user of response.data.data) {
            users.push({ value: user.id, label: user.name });
        }
        setUsers(users);
    }

    const getSnapshot = async () => {

        if (snapshotId != null) {

            let snapshotUsers = await (await (new SnapshotService()).getSnapshotUsers(snapshotId)).getOnlyData();
            let snapshotData = await (await (new SnapshotService()).getSnapshotById(snapshotId)).getOnlyData();
            
            let usersData = [];

            for (let user of snapshotUsers) {
                usersData.push({ value: user.user.id, label: user.user.name });
            }
            setDefaultUsers(usersData);
            setDefaultName(snapshotData.name)
        }
    }

    useEffect(() => {
        setShowStatus((snapshotId != null && snapshotId != undefined) ? true : false);

        (async () => {
            await getUsers();
            await getSnapshot();
        })();

    }, [snapshotId])


    return (
        <CustomModal identifier="create_user_modal" show={showStatus} onHideModal={hideModal}>
            <CustomModalHeader classes="pb-0 border-0">
                <div className="modal-title justify-content-start">
                    <h2>Edit Snapshot</h2>
                </div>
            </CustomModalHeader>

            <CustomModalBody classes="mx-5 mx-xl-18 pt-5 pb-15">
                {(defaultUsers != null && defaultUsers != undefined && snapshotId != null && snapshotId != undefined && defaultName != null && defaultName != undefined) ?  //statement used to prevent react-select using blank delfault values
                    <SnapshotForm snapshotId={snapshotId} users={users} defaultUsers={defaultUsers}
                        defaultName={defaultName} handleSubmitResponse={handleSubmitResponse} />
                    : 
                    (
                    <div className="text-center"> 
                        <Spinner animation="border"></Spinner>
                    </div>
                    )
                }
            </CustomModalBody>

        </CustomModal>
    );
}

SnapshotModal.defaultProps = {
    snapshotId: null
}

export default SnapshotModal;