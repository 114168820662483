
import { FC } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageTitle } from '_metronic/layout/core'
import ConfigRoutes from '../../routing/ConfigRoutes'
import { DashboardBase } from './components/DashboardBase'


const DashboardWrapper: FC = () => {

    return (
        <>
            <Routes>
                <Route element={<> <Outlet /><PageTitle>Dashboard</PageTitle> </>}>
                    <Route
                        path={ConfigRoutes.admin.dashboard.counters.path}
                        element={
                            <>
                                <DashboardBase/>
                            </>
                        }
                    />
                </Route>
                <Route index element={<Navigate to={ConfigRoutes.admin.dashboard.counters.fullPath} />} />
            </Routes>



        </>

    )
}

export { DashboardWrapper }
