import { RestServiceConnection } from "../RestServiceConnection";


export class CustomMetricService extends RestServiceConnection {
    
    createCustomMetric = async (values: any) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/metric-custom/create',
            data: values
        }, true);
        return this;
    }

    deleteCustomMetric = async (id: string) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/metric-custom/delete',
            data: {
                metricCustomId: id
            }
        }, true);
        return this;
    }

    editCustomMetric = async (values: any) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/metric-custom/edit',
            data: values
        }, true);
        return this;
    }

    listCustomMetric = async (values: any) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/metric-custom/list',
            data: {
                filter_filters: { ...values }
            }
        }, true);
        return this;
    }

    getCustomMetric = async (id: string) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/metric-custom/get',
            data: {
                metricCustomId : id
            }
        }, true);
        return this;
    }

    createCustomMetricConfiguration = async (values: any) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/metric-custom-configurator/create',
            data: values
        }, true);
        return this;
    }

    editCustomMetricConfiguration = async (values: any) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/metric-custom-configurator/edit',
            data: values
        }, true);
        return this;
    }

   calculateCustomMetricConfiguration = async (filter: any, metric:string, signal?: any) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/metric-custom-configurator/calculate',
            params: filter,
        }, true);
        return this;
    }

}