import { RestServiceConnection } from "../RestServiceConnection";


export class ExternalProviderService extends RestServiceConnection {

    getExternalProviderById = async (externalProviderId: string) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/external-provider/get',
            data: {
                id: externalProviderId
            }
        }, true)
        return this;
    }



    getExternalProviders = async (params?: any) => {


        this.response = await this.makeRequest({
            method: "POST",
            url: '/external-provider/list',
            data: params !== undefined ? params : {}
        }, true)
        return this;
    }

    createExternalProvider = async (data: any) => {

        this.response = await this.makeRequest({
            method: "POST",
            url: '/external-provider/create',
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: data,
        }, true)
        return this;
    }

    deleteExternalProvider = async (data: any) => {

        this.response = await this.makeRequest({
            method: "POST",
            url: '/external-provider/delete',
            data: data,
        }, true)
        return this;
    }

    deleteExternalProviders = async (data: any) => {
            
            this.response = await this.makeRequest({
                method: "POST",
                url: '/external-provider/delete-providers-selected',
                data: data,
            }, true)
            return this;
        }

    updateExternalProvider = async (data: FormData) => {

        this.response = await this.makeRequest({
            method: "POST",
            url: '/external-provider/update',
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: data,
        }, true)
        return this;
    }

    getProviders = async () => {
            
            this.response = await this.makeRequest({
                method: "POST",
                url: '/external-provider/get-providers',
            }, true)
            return this;
    }

    getCampaignsIDs = async () => {

        this.response = await this.makeRequest({
            method: "POST",
            url: '/external-provider/get-campaigns-ids',
        }, true)
        return this;
    }

    getProgrammesIDs = async () => {

        this.response = await this.makeRequest({
            method: "POST",
            url: '/external-provider/get-programme-ids',
        }, true)
        return this;
    }

    prepareUpload = async (data: FormData) => {
            
            this.response = await this.makeRequest({
                method: "POST",
                url: '/external-provider/prepare-upload',
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                data: data,
            }, true)
            return this;
    }

    getFieldsUpload = async (data: FormData) => {

        this.response = await this.makeRequest({
            method: "POST",
            url: '/external-provider/prepare-upload',
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: data,
        }, true)
        return this;
    }

    uploadFile = async (data: FormData) => {

        this.response = await this.makeRequest({
            method: "POST",
            url: '/external-provider/upload',
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: data,
        }, true)
        return this;
    }

}