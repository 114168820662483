import { UserFilters } from 'app/pages/users/filters/UserListFilters';
import ConfigRoutes from 'app/routing/ConfigRoutes';
import { ContactService } from 'app/services/contacts/ContactService';
import moment from 'moment';
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { TablesWidget9 } from '_metronic/partials/widgets/tables/TablesWidget9';


const ContactsListBase: FC = () => {

    const [contacts, setContacts] = useState<Array<Object> | null>(null);
    const [pageCount, setPageCount] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");
    const [filters, setFilters] = useState<UserFilters | null>(null);

    useEffect(() => {
        getContacts(currentPage, pageSize, undefined, searchValue);

    }, [currentPage, pageSize, filters, searchValue]);


    const getContacts = async (page?: number, limit?: number, orderFilters?: any, textSearch?: string) => {

        let params: any = {
            limit: limit == undefined ? pageSize : limit,
            page: page == undefined ? currentPage + 1 : page + 1,
            filter_filters: filters != null ? filters : {},
        }

        if (orderFilters != undefined) {
            params['filter_order'] = [orderFilters]
        }

        if (textSearch != undefined) {
            params.filter_filters['search'] = textSearch
        }

        let contacts = (await (new ContactService).getContacts(params)).getOnlyData();

        if (contacts.data != undefined && contacts.data.length > 0) {
            setContacts(contacts.data);
        } else {
            setContacts([]);
        }

        setPageCount(contacts.lastPage);
    }

    const navigate = useNavigate();

    const handlePagination = async (pageObject: any) => {

        if (pageObject.selected < 0) {
            return;
        }

        setCurrentPage(pageObject.selected);
        await getContacts(pageObject.selected, pageSize);

    }

    const handlePerPage = async (pageSizeObject: any) => {
        setPageSize(pageSizeObject.value);
        setCurrentPage(0);
        await getContacts(0, pageSizeObject.value);
    }

    const handleSearch = (value: string) => {
        setSearchValue(value);
        getContacts(0, pageSize, undefined, value);
    }
    
    const handleNavigation = (id: string) => {
        navigate(ConfigRoutes.admin.imports.contacts.view.fullPath.replace(":id", id));
    }

    const downloadFile = async (path: string, name: string) => {

        let response = (await (new ContactService).getContactsFile()).getResponse();

        if (response !== null) {
            let extension = path.split('.').pop();
            const link = document.createElement("a");
            link.target = "_blank";
            link.download = name;
            link.href = URL.createObjectURL(new Blob([response.data], { type: extension }));
            link.click();
        }
    }


    return (
        <div>
            {
                contacts === null ?
                    (
                        <div className="text-justify-content-md-center">
                            <span className='indicator-progress text-center' style={{ display: 'block' }}>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        </div>

                    )
                    :
                    (
                        <>
                            <TablesWidget9 className="card-primary" title="Users"
                                searchInput={{ placeholder: "Search...", onSearch: handleSearch }}
                                data={contacts}
                                pagination={true}
                                paginationData={{
                                    pageSize: pageSize, pageCount: pageCount, currentPage: currentPage,
                                    handlePagination: handlePagination, handlePerPage: handlePerPage
                                }}
                                columns={[
                                    {
                                        name: "ID", keyValue: 'id', className: "min-w-50px", render: (element: any) => {

                                            return (
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='text-muted me-2 fs-7 fw-bold'> {element.id} </span>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "Created At", keyValue: 'creationDate', className: "min-w-100px", render: (element: any) => {
                                            let formatDate = moment(element.createdAt.date);
                                            return (
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='text-muted me-2 fs-7 fw-bold'>{formatDate.format('YYYY-MM-DD HH:mm')}</span>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "Name", keyValue: 'name', className: "min-w-100px", render: (element: any) => {
                                            return (
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='text-bold me-2 fs-7 fw-bold'> {element.name} </span>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "Data Source", keyValue: 'dataSource', className: "min-w-120px", render: (element: any) => {
                                            return (
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='text-bold me-2 fs-7 fw-bold'> {element.dataSource} </span>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "Type", keyValue: 'type', className: "min-w-120px", render: (element: any) => {
                                            return (
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='text-bold me-2 fs-7 fw-bold'> {element.type} </span>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "Total", keyValue: 'total', className: "min-w-120px", render: (element: any) => {
                                            return (
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='text-bold me-2 fs-7 fw-bold'> {element.total} </span>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "Validate", keyValue: 'validate', className: "min-w-120px", render: (element: any) => {
                                            return (
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='text-bold me-2 fs-7 fw-bold'> {element.validate} </span>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "Zero Bounce", keyValue: 'zeroBounce', className: "min-w-120px", render: (element: any) => {
                                            return (
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='text-bold me-2 fs-7 fw-bold'> {element.zeroBounce} </span>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "Active Campaign", keyValue: 'activeCampaign', className: "min-w-120px", render: (element: any) => {
                                            return (
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='text-bold me-2 fs-7 fw-bold'> {element.activeCampaign} </span>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "Status", keyValue: 'creationDate', className: "min-w-120px", render: (element: any) => {
                                            return (
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='text-bold me-2 fs-7 fw-bold'> {element.status} </span>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "Progress", keyValue: 'creationDate', className: "min-w-120px", render: (element: any) => {
                                            return (
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='text-bold me-2 fs-7 fw-bold'> {element.progress} </span>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    },
                                    { name: "Actions", className: "min-w-100px text-end", isActionCell: true }
                                ]}
                                actions={[
                                    {
                                        title: "Download",
                                        iconPath: "/media/icons/duotune/files/fil010.svg",
                                        description: "Download",
                                        callback: (element: any) => {
                                            downloadFile(element.path, element.name);
                                        }
                                    },
                                    {
                                        title: "View",
                                        iconPath: "/media/icons/duotune/general/gen060.svg",
                                        description: "View snapshot",
                                        callback: (element: any) => {
                                            handleNavigation(element.id);
                                        }
                                    }
                                ]}

                            ></TablesWidget9>
                        </>
                    )
            }
        </div>
    )
}

export { ContactsListBase }




