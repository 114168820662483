import ConfigRoutes from 'app/routing/ConfigRoutes';
import { FC } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { DataReportProvider, useDataReport } from '../../DataReportProvider';
import ReachNewsletter from './tabs/Newsletter';
import ReachSocialMedia from './tabs/SocialMedia';
import ReachVideo from './tabs/Video';
import ReachWebsite from './tabs/Website';


const ReachWrapper: FC = () => {

    const dataReport = useDataReport();
    return (
        <>
            <Routes>
                <Route
                        element={<Outlet />}
                >
                    <Route path={ConfigRoutes.admin.dataReport.reach.website.path} element={<ReachWebsite/>} />

                    <Route path={ConfigRoutes.admin.dataReport.reach.newsletter.path} element={<ReachNewsletter />} />

                    <Route path={ConfigRoutes.admin.dataReport.reach.socialMedia.path} element={<ReachSocialMedia />} />

                    <Route path={ConfigRoutes.admin.dataReport.reach.video.path} element={<ReachVideo />} />

                    <Route index element={<Navigate to={ConfigRoutes.admin.dataReport.reach.website.path} />} />

                </Route>
                
            </Routes>

        </>

    )
}

export { ReachWrapper }
