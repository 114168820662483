import { FC, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useLayout } from "../../../../_metronic/layout/core";
import CustomModalBody from "../../../utils/components/modal/body";
import CustomModalHeader from "../../../utils/components/modal/header";
import CustomModal from "../../../utils/components/modal/modal";
import { ExternalProviderService } from "app/services/external_povider/ExternalProviderService";
import EditExternalProviderForm from "../forms/EditExternalProviderForm";

interface Props {
    show?: boolean,
    onHideModal: () => void,
    onSuccessSubmit: (values: any) => void,
    externalProvider: string|null
}

const EditExternalProviderModal: FC<Props> = ({ show, onHideModal, onSuccessSubmit, externalProvider }) => {

    const [externalProviderObject, setExternalProviderObject] = useState<any|null>(null);
    const [showStatus, setShowStatus] = useState<boolean>(false);
    const layout = useLayout();

    const determineShowStatus = () => {
        if(show !== showStatus) {
            setShowStatus(show ? show : false);
        }
    }

    const getUser = async () => {
        if(externalProvider !== null && showStatus) {
            let userResponse = await (new ExternalProviderService()).getExternalProviderById(externalProvider);
            if(userResponse.getResponseData().success === false) {
                layout.toast?.error("Error getting third-party data");
                setTimeout(() => {
                    onHideModal();
                }, 1000);
            } else {
                setExternalProviderObject(userResponse.getOnlyData());
            }
        }
    }

    useEffect(() => {
        determineShowStatus();
        if(show) {
            getUser()
        }
    }, [show, showStatus])
    

    const successSubmit = (values: any) => {
        setExternalProviderObject(null);
        onSuccessSubmit(values)
    }

    const hideModal = () => {
        setExternalProviderObject(null);
        onHideModal();
    }

    return (
        <CustomModal identifier="create_third_party_data" show={showStatus} onHideModal={hideModal}>
            <CustomModalHeader classes="pb-0 border-0">
                <div className="modal-title justify-content-start">
                    <h2>Edit Third-party data</h2>
                </div>
            </CustomModalHeader>
            <CustomModalBody classes="mx-5 mx-xl-18 pt-5 pb-15">
                {externalProviderObject && <EditExternalProviderForm successSubmit={successSubmit} externalProvider={externalProviderObject}></EditExternalProviderForm>}
                {!externalProviderObject && <div className="text-center"><Spinner animation="border"></Spinner></div>}
                
            </CustomModalBody>
        </CustomModal>
    );
}

EditExternalProviderModal.defaultProps = {
    show: false
}

export default EditExternalProviderModal;