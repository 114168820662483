import { FC } from "react";
import CustomModalBody from "../../../utils/components/modal/body";
import CustomModalHeader from "../../../utils/components/modal/header";
import CustomModal from "../../../utils/components/modal/modal";
import CreateExternalProviderForm from "../forms/CreateExternalProviderForm";
interface Props {
    show?: boolean,
    onHideModal: () => void,
    onSuccessSubmit: (values: any) => void,
}

const CreateExternalProviderModal: FC<Props> = ({ show, onHideModal, onSuccessSubmit }) => {

    return (
        <CustomModal identifier="create_external_provider_modal" show={show} onHideModal={onHideModal}>
            <CustomModalHeader classes="pb-0 border-0">
                <div className="modal-title justify-content-start">
                    <h2>Create Third-party data</h2>
                </div>
            </CustomModalHeader>
            <CustomModalBody classes="mx-5 mx-xl-18 pt-5 pb-15">
                <CreateExternalProviderForm successSubmit={onSuccessSubmit}></CreateExternalProviderForm>
            </CustomModalBody>
        </CustomModal>
    );
}

CreateExternalProviderModal.defaultProps = {
    show: false
}

export default CreateExternalProviderModal;