import { FC } from "react";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

const DEFAULT_IMAGE_URL = "/media/misc/image.png";

interface Props {
    imgSrc: ArrayBuffer | string | null | Blob
    alt: string
    type?: "avatar" | "preview" | null
    noReferer?: boolean
}

const ImgOrDefault: FC<Props> = ({ imgSrc, alt, noReferer, type }) => {

    let imageUrl = imgSrc;
    let properties: any = {
        src: imageUrl,
        alt: alt
    }

    if (imageUrl === null) {
        imageUrl = toAbsoluteUrl(DEFAULT_IMAGE_URL)
        properties.src = imageUrl
    }

    if (noReferer === true) {
        properties.referrerPolicy = "no-referrer";
    }

    if (type === "avatar") {
        properties.className = "w-25";
    }
    if (type === "preview") {
        properties.className = "img-thumbnail mw-200px";
    }

    return (
        <img alt={properties.alt} {...properties} ></img>
    );
}

ImgOrDefault.defaultProps = {
    imgSrc: null,
    alt: "",
    type: null,
    noReferer: false
}

export default ImgOrDefault;