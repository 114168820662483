import { FC } from 'react'
import { toAbsoluteUrl } from '_metronic/helpers'

const Error404: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/icons/duotune/files/fil007.svg')}
          className='h-50px mb-5 me-2'
        />
        Page Not Found</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        The page you looked not found! <br /> Plan your blog post by choosing a topic
      </div>
    </>
  )
}

export { Error404 }
