/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils'
import { Spinner } from 'react-bootstrap'
import { TablesWidget9 } from '_metronic/partials/widgets'
import { KTSVG, toAbsoluteUrl } from '_metronic/helpers'
import { SnapshotSwitch } from './SnapshotSwitch'
import { useDataReport } from '../../DataReportProvider'
import Chart from "react-apexcharts";
import { colors } from 'react-select/dist/declarations/src/theme'

export type Metric = {
    date: string,
    value: number
}

type Props = {
    logo_image?: string
    updateSnapshotField?: Function,
    snapshotId: string,
    title: string
    subtitle?: string
    metrics: Metric[] | null
    className: string
    chartColor: string
    chartHeight: string
    valueType?: 'normal' | 'percent'
    startPeriod?: number
    snapshotSwitch?: boolean
}

const ReachMetric: React.FC<Props> = ({ className, chartColor, chartHeight, title, subtitle, metrics, startPeriod, valueType, snapshotId, snapshotSwitch, updateSnapshotField, logo_image }) => {

    const DataReport = useDataReport();

    const [editField, setEditField] = useState<Number | null>(null);
    const [showChart, setShowChart] = useState<Boolean>(false);
    const [switchValue, setSwitchValue] = useState<boolean>(DataReport.metrics.includes(snapshotId) ? true : false);

    const editInput = useRef<HTMLInputElement>(null);

    const chartRef = useRef<HTMLDivElement | null>(null)

    /*const closeEditField = (e: any) => {

        if (editInput.current && !editInput.current.contains(e.target)) {
            setEditField(null);
        }
    }*/

    //document.addEventListener('mousedown', closeEditField)

    useEffect(() => {
    }, [metrics])

    const updateMetric = () => {
        DataReport.updateMetrics(snapshotId);
        setSwitchValue(!switchValue);
    }

    const getLastPercentPeriod = (element: Metric) => {
        // Calculate previousPeriodPercent
        let indexOfElement = metrics?.indexOf(element)
        let previousPeriodPercent: number | null = null;
        let perviousPeriodClass = 'badge-light-success';
        let previousPeriodIcon: JSX.Element = <KTSVG path='/media/icons/duotune/arrows/arr081.svg' className='text-success'></KTSVG>;

        if (indexOfElement !== undefined && indexOfElement > 0) {
            const lastElement = metrics ? metrics[indexOfElement - 1] : null;
            if (lastElement !== null && lastElement.value > 0) {
                let percent: number = ((element.value * 100) / lastElement.value) - 100;
                previousPeriodPercent = parseFloat(percent.toFixed(2));
            }
        }

        if (previousPeriodPercent !== null && previousPeriodPercent < 0) {
            perviousPeriodClass = 'badge-light-danger';
            previousPeriodIcon = <KTSVG path='/media/icons/duotune/arrows/arr082.svg' className='text-danger'></KTSVG>;
        }

        if (previousPeriodPercent === null) {
            return <div className={'badge badge-light-warning'}> N/A </div>
        }


        return (<div className={'badge ' + perviousPeriodClass}> {previousPeriodIcon} {previousPeriodPercent} %</div>);

    }

    const getLast12PeriodsAmount = (element: Metric): number | null => {

        let indexOfElement = metrics?.indexOf(element)
        let sumTotal: number | null = null;

        if (indexOfElement !== undefined && indexOfElement >= 11) {

            let sumCounter = 1;
            sumTotal = 0;

            for (let index = indexOfElement; index >= 0; index--) {
                sumTotal = + sumTotal + +(metrics ? metrics[index].value : 0)
                if (sumCounter === 12) {
                    break;
                }
                sumCounter++;
            }
        }
        return sumTotal;
    }

    const getLastVSPrevious12PeriodsPercent = (element: Metric): JSX.Element | null => {
        let percent = null;
        let perviousPeriodClass = 'badge-light-success';
        let previousPeriodIcon: JSX.Element = <KTSVG path='/media/icons/duotune/arrows/arr081.svg' className='text-success'></KTSVG>;
        
        if (metrics) {
            let indexOfElement = metrics?.indexOf(element);
            if (indexOfElement !== undefined && indexOfElement >= 23) {
                let evaluateLas12PeriodTotalSum = getLast12PeriodsAmount(metrics[indexOfElement - 12]);
                let currentElementLast12PeriodTotalSum = getLast12PeriodsAmount(element);
                if (evaluateLas12PeriodTotalSum !== null && currentElementLast12PeriodTotalSum !== null) {
                    // Calculate percent.
                    percent = ((currentElementLast12PeriodTotalSum - evaluateLas12PeriodTotalSum) / currentElementLast12PeriodTotalSum) * 100;
                }
            }
        }

        if (percent !== null) {
            if (percent < 0) {
                perviousPeriodClass = 'badge-light-danger';
                previousPeriodIcon = <KTSVG path='/media/icons/duotune/arrows/arr082.svg' className='text-danger'></KTSVG>;
            }

            // if percent is Not a Number
            if (percent.toFixed(2) === "NaN") {
                return <div className={'badge badge-light-warning'}> N/A </div>
            }

            return (
                <div className={'badge ' + perviousPeriodClass}> {previousPeriodIcon} {percent.toFixed(2)} %</div>
            );

        }

        return <></>
    }

    const changeValue = (e: any, indexOfElement: any, byButton: boolean) => {
        if (byButton === false) {

            let valueAsNumber = parseFloat(e.target.value);
            const value = isNaN(valueAsNumber) ? null : valueAsNumber;

            if (e.key === ('Enter') && value !== null) {
                setEditField(null)
                if (updateSnapshotField !== undefined) {
                    updateSnapshotField(e, indexOfElement, snapshotId)
                }
            }
        } else if (byButton === true) {
            setEditField(null)

            if (updateSnapshotField !== undefined) {
                updateSnapshotField({ target: { value: e } }, indexOfElement, snapshotId)
            }
        }
    }

    const getFieldValue = (element: any) => {

        let valueFloat = parseFloat(element.value);

        if (valueType == 'percent') {
            if (valueFloat.toFixed(2) == "NaN") {
                return <div className={'badge badge-light-warning'}> N/A </div>
            }
            return <div>{valueFloat.toFixed(2)}%</div>;
        }

        return <div>{valueFloat.toLocaleString()}</div>;
    }

    const getEditableField = (element: any) => {

        let indexOfElement = metrics?.indexOf(element)
        let valueFloat = parseFloat(element.value);

        if (indexOfElement === editField) {

            return (
                <div className='row w-100px '>
                    <div className='col-md-6'>
                        <input
                            onKeyPress={(((e) => e.key == ('Enter' || 'NumpadEnter') ? changeValue(e, indexOfElement, false) : null))}
                            ref={editInput}
                            className='w-100 custom-metric-input'
                            defaultValue={element.value} />
                    </div>
                    <div className='col-md-6'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div role='button' onClick={() => changeValue(editInput.current?.value, indexOfElement, true)}>
                                    <KTSVG path='/media/icons/duotune/general/gen043.svg' className='text-success'></KTSVG>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div role='button' onClick={() => setEditField(null)}>
                                    <KTSVG path='/media/icons/duotune/general/gen040.svg' className='text-danger'></KTSVG>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)

        } else {
            if (valueType == 'percent') {
                if (valueFloat.toFixed(2) == "NaN") {
                    return <div className={'badge badge-light-warning'}> N/A </div>
                }
                return <div>{valueFloat.toFixed(2)}% { }</div>;
            } else {
                return <div>{valueFloat.toLocaleString()}</div>;
            }
        }
    }

    const toggleChartVisibility = () => {
        showChart ? setShowChart(false) : setShowChart(true);
    }

    const toggleEditField = (indexOfElement: any) => {
        if (editField == indexOfElement) {
            setEditField(null)
        } else {
            setEditField(indexOfElement)
        }
    }

    const renderMetricBody = () => {

        if(metrics && metrics.length == 1) {
            startPeriod = 0;
        }
        
        return (
            <>

                <div className='card-body d-flex flex-column pt-0'>

                    {metrics != null && showChart && (
                        <div className="pb-5">
                            <Chart
                                height={chartHeight}
                                series={chartSeries(title, metrics, startPeriod)}
                                options={chartOptions(chartColor, chartHeight, metrics, startPeriod)}>
                            </Chart>
                        </div>
                    )}

                    <div className='overflow-scroll' style={{ maxHeight: '500px' }}>
                        <TablesWidget9 title="Users"
                            className=''
                            tableClassName='table-condensed'
                            subtitle="List of Cognito users accesing this application"
                            data={metrics}
                            startElementToShow={startPeriod}
                            columns={[
                                {
                                    name: "Period", keyValue: 'date', className: "min-w-60px", render: (element: any) => {
                                        var indexOfElement = metrics?.indexOf(element)

                                        if (updateSnapshotField !== undefined) {
                                            return (
                                                <div className='row'>
                                                    <div className='col-md-8'>
                                                        <b className='me-1'> {element.date} </b>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div role='button' onClick={() => toggleEditField(indexOfElement !== undefined ? indexOfElement : null)}>
                                                            <KTSVG path='/media/icons/duotune/general/gen055.svg' className='text-primary'></KTSVG>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <b> {element.date} </b>
                                            )
                                        }

                                    }
                                },
                                {
                                    name: title, keyValue: 'value', className: "min-w-60px", render: (element: any) => {

                                        if (updateSnapshotField !== undefined) {
                                            return getEditableField(element);
                                        } else {
                                            return getFieldValue(element);
                                        }

                                    }
                                },
                                {
                                    name: "VS Previous", keyValue: 'value', className: "min-w-60px", render: (element: any) => {
                                        return getLastPercentPeriod(element);
                                    }
                                },
                                {
                                    name: "Latest 12 periods", keyValue: 'value', className: "min-w-60px", render: (element: any) => {
                                        let sumTotalLatest12Periods = getLast12PeriodsAmount(element);
                                        if (sumTotalLatest12Periods !== null) {
                                            let valueFloat = sumTotalLatest12Periods;

                                            if (valueType == 'percent') {
                                                    valueFloat = sumTotalLatest12Periods / 12;
                                            }

                                            return <div>{valueFloat.toLocaleString()} {valueType !== undefined && valueType == "percent" && <> % </>}</div>
                                        }
                                        return <></>
                                    }
                                },

                                {
                                    name: "VS Previous 12 periods", keyValue: 'value', className: "min-w-60px", render: (element: any) => {
                                        let sumTotalLatest12Periods = getLastVSPrevious12PeriodsPercent(element);
                                        if (sumTotalLatest12Periods !== null) {
                                            return <div>{sumTotalLatest12Periods}</div>
                                        }
                                        return <></>
                                    }
                                },
                            ]}
                        >

                        </TablesWidget9>

                    </div>

                </div>
            </>
        )
    }

    return (

        <div className={`card ${className} shadow-sm mt-1`}>

            <div className='card-header border-0 py-5 d-flex'>

                <div className='d-flex align-items-center col-md-9'>

                    {logo_image !== undefined && (
                        <div className='symbol symbol-50px row me-5'>
                            <span className='symbol-label bg-light'>
                                <img src={toAbsoluteUrl(logo_image)} className='h-50 align-self-center' alt='' />
                            </span>
                        </div>
                    )}

                    <div className='row'>
                        <span className='d-inline-block card-label fw-bolder fs-3 mb-1'>{title}</span>
                        <span className='text-wrap text-muted fw-bold fs-7'>{subtitle}</span>
                    </div>
                </div>

                <div className='d-flex mt-2 align-items-center justify-content-end col-md-3'>
                    <div className='row me-1' role='button' onClick={() => toggleChartVisibility()}>
                        <div className='symbol symbol-40px'>
                            <span className={`symbol-label ${showChart ? 'bg-primary' : 'bg-light'}`}>
                                <KTSVG path='/media/icons/duotune/graphs/gra008.svg' className={`${showChart ? 'svg-icon-light' : 'svg-icon-primary'}`} />
                            </span>
                        </div>
                    </div>
                    <div className='row'>
                        {snapshotSwitch == true && <SnapshotSwitch id={snapshotId} value={switchValue} onUpdate={updateMetric} />}
                    </div>
                </div>
            </div>


            {
                metrics === null && <div className='d-flex w-100 justify-content-center align-items-center p-5'><Spinner animation='border'></Spinner></div>
            }

            {
                metrics !== null && renderMetricBody()
            }
        </div>
    )
}

const chartSeries = (metricName: string, metrics: Metric[], startPeriod?: number): any => {
    let series = [
        {
            name: metricName,
            data: metrics.filter((metric: Metric, index: number) => {
                if (startPeriod !== undefined) {
                    if (index >= (startPeriod)) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true
                }
            }).map((metric: Metric) => {
                if (typeof metric.value === 'number') {
                  return parseFloat(metric.value.toFixed(2));
                } else {
                  return metric.value; // Si no es un número, mantener el valor original
                }
            }),
        },
    ]

    return series
}

const chartOptions = (chartColor: string, chartHeight: string, metrics: Metric[], startPeriod?: number): ApexOptions => {

    return {
        chart: {
            id: 'realtime',
            height: chartHeight,
            type: 'line',
            animations: {
                enabled: true,
                easing: 'linear',
                dynamicAnimation: {
                    speed: 1000
                }
            },
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            colors: [chartColor]
        },
        markers: {
            size: 0
        },
        xaxis: {
            tickAmount: 7,
            categories: metrics.filter((metric: Metric, index: number) => {
                if (startPeriod !== undefined) {
                    if (index >= (startPeriod)) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true
                }
            }).map((metric: Metric) => metric.date),
        },
        yaxis: {
            min: 0,
            tickAmount: 4,
            max: Math.max(
                ...(
                    metrics.filter((metric: Metric, index: number) => {
                        if (startPeriod !== undefined) {
                            if (index >= (startPeriod)) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return true
                        }
                    }).map((metric: Metric) => {
                        if (typeof metric.value === 'number') {
                          return parseFloat(metric.value.toFixed(2));
                        } else {
                          return metric.value; // Si no es un número, mantener el valor original
                        }
                    })
                )
            ),
        },
        legend: {
            show: false
        }
    }
}


ReachMetric.defaultProps = {
    subtitle: '',
    valueType: 'normal'
}

export { ReachMetric }