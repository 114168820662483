import { RestServiceConnection } from "../RestServiceConnection";


export class FormatService extends RestServiceConnection {
    
    search = async () => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/programme/search/type',
            data: {}
        }, true);
        return this;
    }

}