/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import ConfigRoutes from 'app/routing/ConfigRoutes'
import {PrivateRoutes} from "../../../../app/routing/PrivateRoutes";

export function AsideMenuMain() {
    return (
        <>
            {/* <AsideMenuItem
                to='/dashboard'
                icon='/media/icons/duotune/general/gen022.svg'
                title='Dashboard'
                //title={intl.formatMessage({ id: 'MENU.DATAREPORT' })}
                fontIcon='bi-app-indicator'

            /> */}

            {/* <AsideMenuItem
                to='/dataReport'
                icon='/media/icons/duotune/art/art002.svg'
                title='Data Report'
                //title={intl.formatMessage({ id: 'MENU.DATAREPORT' })}
                fontIcon='bi-app-indicator'
            /> */}
            <AsideMenuItem
                to='/programmeExplorer'
                icon='/media/icons/duotune/maps/map006.svg'
                title='Programme Explorer'
                //title={intl.formatMessage({ id: 'MENU.DATAREPORT' })}
                fontIcon='bi-app-indicator'
            />
            <AsideMenuItem
                to='/supporterProgrammeExplorer'
                icon='/media/icons/duotune/maps/map006.svg'
                title='Outcomes Report'
                fontIcon='bi-app-indicator'
            />
            <AsideMenuItem
                to='/marketing'
                icon='/media/icons/duotune/ecommerce/ecm002.svg'
                title='Marketing Dashboard'
                fontIcon='bi-app-indicator'
            />
            <AsideMenuItem
              to='/individual-metrics'
              icon='/media/icons/duotune/graphs/gra001.svg'
              title='Individual Metrics'
              fontIcon='bi-app-indicator'
            />
            <AsideMenuItem
              to='/database-manager'
              icon='/media/icons/duotune/abstract/abs027.svg'
              title='Database Manager'
              fontIcon='bi-app-indicator'
            />
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Administration</span>
                </div>
            </div>
            <AsideMenuItem
                to={ConfigRoutes.admin.campaigns.base}
                title='Campaign Manager'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/ecommerce/ecm010.svg'
            />

            <AsideMenuItemWithSub
                to='#'
                title='Third-party Data'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/technology/teh0012.svg'
            >
                <AsideMenuItem
                    to='/admin/third_party_data/list'
                    icon='/media/icons/duotune/general/gen059.svg'
                    title={'List'}
                    fontIcon='bi-app-indicator'
                />

                <AsideMenuItem
                  to='/admin/third_party_data/new_import_list'
                  icon='/media/icons/duotune/files/fil017.svg'
                  title={'New Import List'}
                  fontIcon='bi-app-indicator'
                />


                
            </AsideMenuItemWithSub>

            <AsideMenuItemWithSub
                to='#'
                title='Users'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/general/gen049.svg'
            >
                <AsideMenuItem
                    to='/admin/users/list'
                    icon='/media/icons/duotune/general/gen059.svg'
                    title={'List'}
                    fontIcon='bi-app-indicator'
                />


                
            </AsideMenuItemWithSub>

            <AsideMenuItemWithSub
              to='#'
              title='Metric Manager'
              fontIcon='bi-archive'
              icon='/media/icons/duotune/abstract/abs046.svg'
            >
              <AsideMenuItem
                to={ConfigRoutes.admin.metrics.metric_list.fullPath}
                icon='/media/icons/duotune/general/gen059.svg'
                title='List'
                fontIcon='bi-app-indicator'
              />

              <AsideMenuItem
                to={ConfigRoutes.admin.metrics.role_metrics.fullPath}
                icon='/media/icons/duotune/general/gen019.svg'
                title='Permissions'
                fontIcon='bi-app-indicator'
              />
              <AsideMenuItem
                to={ConfigRoutes.admin.metrics.metric_custom.list.principal}
                icon='/media/icons/duotune/general/gen032.svg'
                title='Custom Metrics'
                fontIcon='bi-app-indicator'
              />
            </AsideMenuItemWithSub>
            {/**
            <AsideMenuItem
                to='/admin/snapshots'
                icon='/media/icons/duotune/abstract/abs034.svg'
                title='Snapshots'
                fontIcon='bi-app-indicator'
            />

            <AsideMenuItem
                to='/admin/your_snapshots/last_records'
                icon='/media/icons/duotune/abstract/abs034.svg'
                title='User Snapshots'
                fontIcon='bi-app-indicator'
            />
             */}

            <AsideMenuItemWithSub
                to='#'
                title='Imports'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/files/fil017.svg'
            >
                <AsideMenuItem
                  to='/admin/imports/contacts'
                  icon='/media/icons/duotune/communication/com013.svg'
                  title={'Contacts'}
                  fontIcon='bi-app-indicator'
                />

                <AsideMenuItem
                  to='/admin/imports/castos'
                  icon='/media/icons/duotune/social/microfono.svg'
                  title={'Castos'}
                  fontIcon='bi-app-indicator'
                />

                <AsideMenuItem
                  to='/admin/imports/spotify'
                  icon='/media/icons/duotune/social/spotify.svg'
                  title={'Spotify'}
                  fontIcon='bi-app-indicator'
                />

                <AsideMenuItem
                  to={ConfigRoutes.admin.imports.twitter.base}
                  icon='/media/icons/duotune/social/twitter.svg'
                  title={'Twitter'}
                  fontIcon='bi-app-indicator'
                />

                <AsideMenuItem
                    to='/admin/imports/vimeo'
                    icon='/media/icons/duotune/social/vimeo.svg'
                    title={'Vimeo'}
                    fontIcon='bi-app-indicator'
                />










            </AsideMenuItemWithSub>

            <AsideMenuItemWithSub
              to='#'
              title='Settings'
              fontIcon='bi-archive'
              icon='/media/icons/duotune/coding/cod001.svg'
            >
              <AsideMenuItemWithSub
                to='#'
                title='Security'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/security/security.svg'
              >
                <AsideMenuItem
                  to='/admin/imports/security/spotify'
                  icon='/media/icons/duotune/social/spotify.svg'
                  title={'Spotify'}
                  fontIcon='bi-app-indicator'
                />
                <AsideMenuItem
                  to='/admin/imports/security/youtube_analytics'
                  icon='/media/icons/duotune/social/youtube.svg'
                  title={'YouTube Analytics'}
                  fontIcon='bi-app-indicator'
                />
              </AsideMenuItemWithSub>
              <AsideMenuItem
                  to='/admin/imports/security/cache'
                  icon='/media/icons/duotune/files/fil027.svg'
                  title={'Clear Cache'}
                  fontIcon='bi-app-indicator'
                />
              <AsideMenuItem
                  to='/admin/imports/security/bots'
                  icon='/media/icons/duotune/technology/teh013.svg'
                  title={'Bots'}
                  fontIcon='bi-app-indicator'
                />
            </AsideMenuItemWithSub>
        </>
    )
}
