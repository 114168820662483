import CustomModalBody from "@components/modal/body";
import CustomModalHeader from "@components/modal/header";
import CustomModal from "@components/modal/modal";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import Swal from "sweetalert2";
import CustomSelect from "@components/form/CustomSelect";
import { SingleValue } from "react-select";
import * as yup from 'yup';
import {parentValues, parents , setParentValues} from '@formkit/drag-and-drop'

interface Props {
    show: boolean,
    setShow: any, 
    setElementToEdit: any
    type?: 'manual_value' | 'metric_tag'
    elementKey?: string
    elementToEdit?: any
    customMetric?: any
}

interface formValues {
    value: string,
    label: string
}

// VALIDATION SCHEMA FOR MANUAL VALUE

const MetricModalManual = yup.object({
    value: yup.string()
    .required('Value is required')
    .matches(/^[-+]?[0-9]*\.?[0-9]+$/,
    'Value must be a number')
});

// VALIDATION SCHEMA FOR CUSTOM METRIC

const MetricModalCustom = yup.object({
    value: yup.string().required('Value is required')
});

const MetricModal: FC<Props> = ({ show , setShow, type, elementKey, elementToEdit, customMetric, setElementToEdit }) => {

    // FUNCTIONS

    /**
     * 
     * @description Hide the modal
     * 
     * @returns void
     */

    const hideModal = () => {
        setShow(false);
        setElementToEdit(null);
    }

    /**
     * 
     * @description Handle the edit of the element
     * 
     * @param values
     *  
     * @returns void
     */

    const handleEditElement = (values: any) => {
        let newElement: any = {
            key: Math.random().toString(36).substring(2, 15),
            type: type,
            isMetric: true,
        }
        // Get the parent object
        let parentObject = parents.get(document.getElementById('operationsList') as any) as any; 
        // Modify the element
        if (type == 'manual_value') {
            newElement.label = values.value
        } else {
            newElement.label = values.label
            newElement.value = values.value
        }

        // Modify the parent object
        let modifiedParent = parentValues(document.getElementById('operationsList') as any, parentObject).map(
            (x: any) => x.key === elementKey ? newElement : x
        )

        let operations = modifiedParent.map((operation: any) => {
            return {
                value: operation.type == 'metric_tag' ? operation.value : operation.label,
                type: operation.type,
                }
        })

        customMetric.setOperations(operations).then(() => {
            setParentValues(document.getElementById('operationsList') as any, parentObject ,modifiedParent)
            hideModal()
        })
    }

    const handleDeleteElement = () => {
        let parentObject = parents.get(document.getElementById('operationsList') as any) as any; 
        let modifiedParent = parentValues(document.getElementById('operationsList') as any, parentObject).filter(
            (x: any) => x.key !== elementKey
        )
        let operations = modifiedParent.map((operation: any) => {
            return {
                value: operation.type == 'metric_tag' ? operation.value : operation.label,
                type: operation.type,
                }
        })
        customMetric.setOperations(operations).then(() => {
            setParentValues(document.getElementById('operationsList') as any, parentObject ,modifiedParent)
            hideModal()
        })
    }

    /**
     * 
     * @description Handle the change of the metric
     * 
     * @param newValue 
     * 
     * @returns void
     */

    const onChangeMetric = (newValue: SingleValue<any>) => {
        let metricSelected: any = newValue.value;
        // Set the value of the metric
        type !== 'manual_value' &&  formik.setFieldValue('label', newValue.label);
        formik.setFieldValue('value', metricSelected);
    }

    // INITIAL VALUES

    const initialValues: formValues = {
        value: '',
        label : ''
    }

    // FORMIK

    const formik = useFormik({
        initialValues: {
            ...initialValues
        },
        validationSchema: type == 'manual_value' ? MetricModalManual : MetricModalCustom,
        onSubmit: async (values) => {
            handleEditElement(values)
        },
    });

    // USE EFFECTS

    useEffect(() => {
        formik.resetForm();
        if (elementToEdit !== null) {
            if (elementToEdit.label !== 'Manual Value' && elementToEdit.label !== 'Existing metric') {
                formik.setValues({
                    value: type == 'manual_value' ? elementToEdit.label : elementToEdit.value,
                    label: type == 'metric_tag' ? elementToEdit.value : ''
                })
            }
        }
    }, [show]);


    return (
        <CustomModal identifier="create_custom_metric" show={show} onHideModal={hideModal} position="center" aditionalClasses="customMetrics-modal-z-index">
            <CustomModalHeader classes="pb-0 border-0">
                <div className="modal-title justify-content-start">
                    <h2>{type == 'manual_value' ? ' Edit Manual Value' : 'Select existing metric'}</h2>
                </div>
            </CustomModalHeader>
            <CustomModalBody classes="mx-5 mx-xl-18 pt-5 pb-10 overflow-visible">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        { type == 'manual_value' && customMetric.metrics.length > 0 ?
                            (<div className="col-lg-12 fv-row mt-3">
                                <label className="required fw-bold fs-6 mb-2">Manual Value</label>
                                <input
                                    type="text"
                                    className={`form-control form-control-sm`}
                                    name="value"
                                    required
                                    value={formik.values.value}
                                    onChange={formik.handleChange}                               
                                />
                                {formik.errors.value ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block text-danger">{formik.errors.value}</div>
                                    </div>
                                ) : null}
                            </div>)
                        :
                            (<div className="col-lg-12 fv-row mt-3">
                                <label className="required fw-bold fs-6 mb-2">Metric</label>
                                <CustomSelect options={customMetric.metrics} is_multi={false} defaultValue={customMetric.metrics.find((x: any) => x.value == formik.values.value)}
                                placeholder="Select Metric..." onChangeSingle={onChangeMetric} key={formik.values.value} />
                                {formik.errors.value ? (
                                    <div className="fv-plugins-message-container mt-1">
                                        <div className="fv-help-block text-danger">{formik.errors.value}</div>
                                    </div>
                                ) : null}
                            </div>)
                        }
                        <div className="col-6 fv-row mt-6 d-flex justify-content-center">
                            <button type="submit" disabled={formik.isSubmitting || !formik.isValid} className="btn btn-primary mt-3 fw-bold">
                                Submit
                            </button>
                        </div>
                        <div className="col-6 fv-row mt-6 d-flex justify-content-center">
                            <button type="button" disabled={formik.isSubmitting || !formik.isValid} className="btn btn-danger mt-3 fw-bold" onClick={handleDeleteElement}>
                                Delete
                            </button>
                        </div>
                    </div>
                </form>
            </CustomModalBody>
        </CustomModal>
    );
}

export default MetricModal;