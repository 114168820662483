import CustomSelect from "@components/form/CustomSelect";
import { CustomMetricService } from "app/services/custom_metric/CustomMetric";
import { useFormik } from "formik";
import { useEffect } from "react";
import { SingleValue } from "react-select";
import toastr from "toastr";
import Swal from "sweetalert2";
import * as yup from 'yup';
import { useCustomMetricConfiguratorProvider } from "../../CustomMetricConfiguratorProvider";

interface EditCustomMetricCardProps {
    metricCustomId: string;
    metricData: any;
}

const EditCustomMetricSchema = yup.object({
    name: yup.string().required('Name is required').min(3 , 'Name must be at least 3 characters'),
    description: yup.string().required('Description is required').min(3 , 'Description must be at least 3 characters'),
    category: yup.string().required('Category is required').min(1 , 'Select a category'),
    format: yup.string().required('Format is required').min(1 , 'Select a format'),
});

const formatOptions = [
    { value: 'number', label: 'Number' },
    { value: 'percentage', label: 'Percentage' },
]

const categoriesOptions = [
    { value: 'Engagement', label: 'Engagement' },
    { value: 'Reach', label: 'Reach' },
    { value: 'Impact', label: 'Impact' },
]

interface formValues {
    name: string,
    description: string,
    howIsCalculated: string | null,
    additionalInfo: string | null,
    category: string,
    //visibility: string[],
    format: string
}

const EditCustomMetricCard = ({ metricCustomId , metricData }: EditCustomMetricCardProps) => {

    const customMetric = useCustomMetricConfiguratorProvider()

    const initialValues = {
        name: '',
        description: '',
        howIsCalculated:  '',
        additionalInfo:  '',
        category: '',
        format: '',
    }

    const handleSubmitResponse = (response: any) => {
        if(response == true){
            Swal.fire({
                icon: 'success',
                title: 'Custom Metric edited successfully',
                showConfirmButton: false,
                timer: 1000,
            })
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                confirmButtonColor: '#477995',
            })
        }
    }

    const handleEditCustomMetric = async (values: any) => {
        Swal.fire({
            showConfirmButton: false,
            title: '<h3> Please Wait... <h3>',
            loaderHtml:
              '<div class="custom-spinner mb-5"><span></span><span></span><span></span></div>',
            allowOutsideClick: false,
            validationMessage: '<p class="text-muted"> This operation could take a while </p>',
            html: '<p class="text-muted"> This operation could take a while </p>',
    
            customClass: {
              loader: 'custom-loader',
              icon: 'd-none',
            },
            willOpen: () => {
              Swal.showLoading()
            },
        })
        values.metricCustomId = metricCustomId;
        const response = (await (await (new CustomMetricService()).editCustomMetric(values)).getResponseData().success)
        if (response === false) {
            Swal.close();
            handleSubmitResponse(response);
        } else {
            const customMetricService = new CustomMetricService();
            const response = await customMetricService.createCustomMetricConfiguration({
            metricCustomId: metricCustomId,
            metricCustomConfiguratorArray: customMetric.customMetric.operations
            });
            Swal.close();
            if (response.getResponseData().success === true) {
                Swal.fire({
                    customClass: "customMetrics-modal-z-index",
                    title: 'Success',
                    text: 'Custom metric configuration saved successfully',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    cancelButtonColor: '#477995',
                })
            } else {
                response.getResponseData().data.errors.forEach((error: any) => {
                    toastr.error(error.message);
                });
            }
        }
    }

    /**
     * 
     * @description Handle the change of the categories
     * 
     * @param newValue 
     * 
     * @returns void
     */

    const onChangeCategories = (newValue: SingleValue<any>) => {
        let categorySelected: any = newValue.value;
        formik.setFieldValue('category', categorySelected);
    }

    /**
     * 
     * @description Handle the change of the format
     * 
     * @param newValue 
     * 
     * @returns void
     */

    const onChangeFormat = (newValue: SingleValue<any>) => {
        let formatSelected: any = newValue.value;
        formik.setFieldValue('format', formatSelected);
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: EditCustomMetricSchema,
        onSubmit: async (values : formValues) => {
            if (values.howIsCalculated === '') { values.howIsCalculated = null }
            if (values.additionalInfo === '') { values.additionalInfo = null }
            if ( metricCustomId && metricData ) {
                handleEditCustomMetric(values);
            }
        },
    });

    useEffect(() => {
        if (metricData) {
            formik.setValues({
                name: metricData.name,
                description: metricData.description,
                howIsCalculated: metricData.howIsCalculated,
                additionalInfo: metricData.additionalInfo,
                category: metricData.category,
                format: metricData.format,
            });
        }
    }, [metricData]);

    return (    
        <div className='card'>
        <div className='card-header d-flex align-items-center'>
        <div className="pb-0 border-0 card-header">
              <div className="card-title justify-content-start">
                  <h2>Edit Custom Metric</h2>
              </div>
          </div>
          <div className="mx-5 mx-xl-18 pt-5 pb-10 overflow-visible">
              <form onSubmit={formik.handleSubmit} id="customMetricForm">
                  <div className="row">
                      <div className="col-lg-12 fv-row mt-3">
                            <label className="required fw-bold fs-6 mb-2">Metric Name</label>
                            <input
                                type="text"
                                className={`form-control form-control-sm`}
                                name="name"
                                required
                                value={formik.values.name}
                                onChange={formik.handleChange}                               
                            />
                            {formik.errors.name ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block text-danger">{formik.errors.name}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className="col-lg12 fv-row mt-3">
                            <label className="fw-bold fs-6 mb-2">How is calculated?</label>
                            <input
                                type="text"
                                className={`form-control form-control-sm`}
                                name="howIsCalculated"
                                value={formik.values.howIsCalculated || ''}
                                onChange={formik.handleChange}                               
                            />
                        </div>
                        <div className="col-lg-12 fv-row mt-3">
                            <label className="required fw-bold fs-6 mb-2">Description</label>
                            <textarea
                                className={`form-control form-control-sm`}
                                name="description"
                                required
                                value={formik.values.description}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.description ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block text-danger">{formik.errors.description}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className="col-lg-12 fv-row mt-3">
                            <label className="fw-bold fs-6 mb-2">Adittional Information</label>
                            <textarea
                                className={`form-control form-control-sm`}
                                name="additionalInfo"
                                value={formik.values.additionalInfo || ''}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="col-lg-12 fv-row mt-3">
                            <label className="required fw-bold fs-6 mb-2">Category</label>
                            <CustomSelect options={categoriesOptions} is_multi={false} defaultValue={categoriesOptions.find((category) => category.value === formik.values.category)}
                            placeholder="Select Categories..." onChangeSingle={onChangeCategories} key={formik.values.category} />
                            {formik.errors.category ? (
                                <div className="fv-plugins-message-container mt-1">
                                    <div className="fv-help-block text-danger">{formik.errors.category}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className="col-lg-12 fv-row mt-3" key={formik.values.format}>
                            <label className="required fw-bold fs-6 mb-2">Format</label>
                            <CustomSelect options={formatOptions} defaultValue={formatOptions.find((format) => format.value === formik.values.format)}
                            is_multi={false} placeholder="Select Format..." onChangeSingle={onChangeFormat} />
                            {formik.errors.format ? (
                                <div className="fv-plugins-message-container mt-1">
                                    <div className="fv-help-block text-danger">{formik.errors.format}</div>
                                </div>
                            ) : null}
                        </div>
                  </div>
              </form>
          </div>
      </div>
    </div>
    );
}

export default EditCustomMetricCard;