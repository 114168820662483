import { RestServiceConnection } from "../RestServiceConnection";

const BASE_URL = '/programme-media';

export class ProgrammeMediaService extends RestServiceConnection {

    getProjectCodes = async () => {
        this.response = await this.makeRequest({
            method: "get",
            url: `${BASE_URL}/get-project-codes`
        }, true);
        return this;
    }

    getGroupsByProjectCode = async (projectCode: string) => {
        this.response = await this.makeRequest({
            method: "post",
            url: `${BASE_URL}/get-group-names`,
            data: { projectCodeId : projectCode }
        }, true);
        return this;
    }

    getProgrammeMediasByGroupAndProjectCode = async (projectCode: string | null, groups: any[]) => {
        this.response = await this.makeRequest({
            method: "post",
            url: `${BASE_URL}/get-programme-medias`,
            data: { projectCodeId: projectCode, groupNameIds: groups }
        }, true);
        return this;
    }
}