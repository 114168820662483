import { TablesWidget9 } from "_metronic/partials/widgets";
import { formatNumber, limitDecimals } from "app/utils/utils";
import { FC } from "react";

interface IndividualMetricsTableProps {
    data?: any;
}

const IndividualMetricsTable:FC<IndividualMetricsTableProps> = ({data}) => {

    return (
        <>
                <div className="mt-6">
                    <TablesWidget9 className="card-primary mt-3" title="Third-party data"
                        subtitle="List of third-party data"
                        data={data}
                        columns={[
                        {
                            name: "Name", keyValue: 'name', className: "min-w-150px",
                            render: (element: any) => {
                                return (
                                    <div className='text-dark d-block fs-6'>
                                        {element?.name || "-"}
                                    </div>
                                );
                            }
                        },
                        { name: "Category", keyValue: 'category', className: "min-w-150px",
                            render: (element: any) => {
                                return (
                                    <>
                                        <div className='text-dark d-block fs-6'>
                                            { element?.category?.charAt(0)?.toUpperCase() + element?.category?.slice(1) || "-"}
                                        </div>
                                    </>
                                )
                            }

                        },
                        {
                            name: "Group", keyValue: 'group', className: "min-w-150px",
                            render: (element: any) => {
                            return (
                                <div className='text-dark d-block fs-6'>
                                    { element?.group?.charAt(0)?.toUpperCase() + element?.group?.slice(1) || "-"}
                                </div>
                            );
                            }
                        },
                        { name: "Result", keyValue: 'result', className: "min-w-150px",
                            render: (element: any) => {
                                return (
                                    <>
                                        <div className={`fw-bolder d-block fs-6 ${element.result !== null ? 'text-muted': 'text-danger'}`}>
                                            {element.result !== null ? formatNumber(limitDecimals(element.result)) : 'Math Error'}
                                        </div>
                                    </>
                                )
                            }

                        },
                        { 
                            name: "Data Source", keyValue: 'dataSource', className: "min-w-150px",
                            render: (element: any) => {
                            return (
                                <div className='text-dark d-block fs-6'>
                                    {element?.dataSource || "-"}
                                </div>
                            )
                            }

                        },
                        {
                            name: "Description", keyValue: 'description', className: "min-w-150px",
                            render: (element: any) => {
                                return (
                                    <div className='text-dark d-block fs-6'>
                                        {element?.description || "-"}
                                    </div>
                                );
                            }
                        },
                        ]}
                    ></TablesWidget9>
                </div>
        </>
    );
}

export { IndividualMetricsTable }