import { KTSVG } from '_metronic/helpers';
import { ApexOptions } from 'apexcharts';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useMarketing } from '../MarketingProvider';
import { MetricService } from 'app/services/metric/MetricService';
import SpinnerCustom from '@components/SpinnerCustom';
import CustomSelect from '@components/form/CustomSelect';
import { SingleValue } from 'react-select';
import { limitDecimals } from 'app/utils/utils';

interface ChartProps {
  show: boolean;
  setShow: Function;
  title?: string;
  subtitle?: string;
  rows: any;
  metric?: string;
  subGroup?: string;
  singleExtendedValue?: any;
}

const MarketingWebsiteChart: React.FC<ChartProps> = ({ show, setShow, title, subtitle, rows, metric = 'website_visits', subGroup, singleExtendedValue }) => {
  const Marketing = useMarketing();
  const abortController = new AbortController();
  const [loading, setLoading] = useState<boolean>(true);
  const modes = [{value: 'days', label: 'Days'}, {value: 'weeks', label: 'Weeks'}, {value: 'months', label: 'Months'}, {value: 'Q', label: 'Quarters'}, {value: 'years', label: 'Years'}];
  const [mode, setMode] = useState(modes[0].value);
  const [chartData, setChartData] = useState<ApexOptions>({
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: [],
    },
    legend: {
      position: 'top',
    },
    colors: ["#477995"], // Usa los colores de la primera serie
    series: [],
  });


  const onChangeModes = (newValue: SingleValue<unknown>) => {
    let mode: any = newValue
    setMode(mode.value)
  }

  const getTotalMetric = async (signal: AbortSignal, row: string): Promise<any> => {
    let modifiedFilters = {
      filter_filters: {
        between_dates: {
          from: Marketing.params.filter_filters.between_dates.from,
          to: Marketing.params.filter_filters.between_dates.to,
        },
        group: subGroup !== null && subGroup !== undefined && subGroup.length > 0 ? subGroup : Marketing.params.filter_filters.selected_group ,
        mode : mode,
      },
    };

    let selectedGroup = Marketing.params.filter_filters.selected_group;

    if (selectedGroup === 'therapeutic_areas')  {
      selectedGroup = 'therapeutic_areas_ids';
    } else if (subGroup !== null && subGroup !== undefined && subGroup.length > 0) {
      selectedGroup = subGroup;
    }

    if (selectedGroup === 'therapeutic_areas_ids' || selectedGroup === 'connects' || selectedGroup === 'programmes') {
      (modifiedFilters.filter_filters as any)[selectedGroup] = [row];
    } else {
      let groupKey = Marketing.params.filter_filters.group as any;

      (modifiedFilters.filter_filters as any)[selectedGroup] = row;

      if (singleExtendedValue !== undefined && singleExtendedValue !== null) {
        if (groupKey === 'therapeutic_areas') {
          (modifiedFilters.filter_filters as any).therapeutic_areas_ids = [singleExtendedValue];
        } else {
          (modifiedFilters.filter_filters as any)[groupKey] = [singleExtendedValue];
        }
      } else {
        if (groupKey === 'therapeutic_areas') {
          (modifiedFilters.filter_filters as any).therapeutic_areas_ids = (Marketing.params.filter_filters as any)['therapeutic_areas_ids'];
        } else {
          (modifiedFilters.filter_filters as any)[groupKey] = (Marketing.params.filter_filters as any)[groupKey];
        }
      }
    }

    const metricService = new MetricService();
    const response = await metricService.getMetricEndpointData(modifiedFilters, metric, 'timeseries', signal);
    const responseData = response.getResponseData();
    if (responseData.success === false) {
      return 0;
    }

    return responseData.data;
  };

  const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const getMetricsData = async () => {
    let group = Marketing.params.filter_filters.selected_group;
  
    if (group === 'therapeutic_areas') {
      group = 'therapeutic_areas_ids';
    }
  
    let rawData: Record<string, { x: string, y: number, insight: string, color: string }[]> = {};
    let allDates: string[] = [];
    let chartDataMetric: { name: string, data: number[] }[] = [];

    if (rows.length === 0) {
      rows = ['Overall'];
    }

    rows = rows.slice(0, 10);
  
    // Usar Promise.all para lanzar todas las consultas simultáneamente
    await Promise.all(rows.map(async (row: any, index: number) => {
      try {
        const color = rawData[row]?.[0]?.color || generateRandomColor();

        const data = await getTotalMetric(abortController.signal, group === 'therapeutic_areas_ids' || group === 'connects' || group === 'programmes' || group === 'formats' ?
        Marketing.params.filter_filters[`${group}`][index] : row);

        const chartDataValues = data.map((element: any) => ({
          x: element.date,
          y: element.value,
          insight: row,
          color: color,
        }));
  
        // Inicializar rawData[row] como un array vacío si aún no está definido
        rawData[row] = rawData[row] || [];
        rawData[row].push(...chartDataValues);
  
        chartDataValues.forEach((dataPoint: any) => {
          const date = dataPoint.x;
          if (!allDates.includes(date)) {
            allDates.push(date);
          }
        });
      } catch (error: any) {
      }
    }));
    // Construir chartDataMetric después de haber recopilado todas las fechas
    chartDataMetric = rows.map((row: string) => ({
      name: row,
      data: allDates.map(date => {
        const dataPoint = rawData[row].find(point => point.x === date);
        return dataPoint ? limitDecimals(dataPoint.y) : 0;
      }),
      color: rawData[row][0].color,
    }));
  
    return {chartDataMetric , allDates}
  };
  

  useEffect(() => {
    if (metric == '' || metric == '') return;
    if (show === false) return;
    setLoading(true);

    const fetchData = async () => {
      const data = await getMetricsData();
      setChartData({
        ...chartData,
        xaxis: {
          categories: data.allDates,
        },
        colors: data.chartDataMetric.length > 0 ? data.chartDataMetric.map((series: any) => series.color) : ["#477995"],
        series: data.chartDataMetric.length > 0 ? data.chartDataMetric.map((series: any) => ({ name: series.name == null || series.name == "" ? "Other" : series.name, data: series.data })) : [],
      });
      setLoading(false);
    };

    fetchData();
    
    return () => {
      abortController.abort();
    }
  }, [show, title, subtitle, Marketing.params.filter_filters, mode]);

  return (
    <>
      <div className="card-header border-0 pt-5" style={{ display: show ? 'block' : 'none' }}>
        <div className="d-flex justify-content-between">
          <h3 className="card-title d-flex justify-content-center align-items-center">{title}</h3> 
          <h5 className='d-flex flex-wrap justify-content-center align-items-center'>
            <div className='p-2 flex-fill' style={{ minWidth: '250px' }}>
              <div className='form-group' id={'connect'}>
                 <CustomSelect options={modes} placeholder="Select Mode" onChangeSingle={onChangeModes} key={'mode'}
                 defaultValue={modes.find((option: any) => option.value === mode)} />
              </div>
            </div>
            <button onClick={() => { setShow(false) }} className='btn bg-transparent d-flex justify-content-center ms-2'>
              <KTSVG path={'/media/icons/duotune/arrows/arr061.svg'} svgClassName={'w-40px h-40px text-primary'} />
            </button>
          </h5>
        </div>
        {!loading ? (
          <div key={mode}>
            <ReactApexChart
              options={chartData}
              series={chartData.series}
              type="line"
              height={350}
            /> 
          </div>
          ) : (
            <div className='d-flex justify-content-center align-items-center'>
              <SpinnerCustom/>
            </div>
          )}
      </div>
    </>
  );
};

export default MarketingWebsiteChart;