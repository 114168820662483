import {useCustomMetricConfiguratorProvider} from '../CustomMetricConfiguratorProvider'
import {parents, parentValues, dragValues, setParentValues} from '@formkit/drag-and-drop'
import CustomMetricOperands from './containers/CustomMetricOperands'
import CustomMetricValues from './containers/CustomMetricsValues'
import CustomMetricOperations from './containers/CustomMetricOperations'
import MetricModal from './modal/MetricModal'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { CustomMetricService } from 'app/services/custom_metric/CustomMetric'
import { Button } from 'react-bootstrap'
import EditCustomMetricCard from './containers/EditCustomMetricCard'

const CustomMetricConfiguratorMain = () => {

  // GET ID OF THE METRIC TO CONFIGURE FROM URL SEARCH PARAMS

  let [ searchParams ] = useSearchParams();

  // CUSTOM METRIC PROVIDER

  const customMetric = useCustomMetricConfiguratorProvider()

  // STATES

  const [showModal, setShowModal] = useState(false)
  const [type, setType] = useState<'manual_value' | 'metric_tag'>('manual_value')
  const [elementToEdit, setElementToEdit] = useState<any>(null)
  const [elementKey, setElementKey] = useState('')
  const [customMetricData, setCustomMetricData] = useState<any>(null)

  // GET DATA FROM METRIC TO CONFIGURE

  const fetchCustomMetric = async (id : string) => {
    const customMetricService = new CustomMetricService();
    const response = await customMetricService.getCustomMetric(id as string);
    return response.getResponseData() as any;
  };

  // CUSTOM PLUGINS

  /**
   * 
   * @description This function is used to set the sourceClone plugin
   * 
   * @param parent 
   * @returns  Plugin Source Transfer setted
   */

  const sourceClone = (parent: any) => {
    const parentData = parents.get(parent)

    if (!parentData) return

    return {
      setup() {
        parentData.config.performTransfer = sourceTransfer
      },
    }
  }

  /**
   * 
   * @description This function is used to set the targetClone plugin
   * 
   * @param parent 
   * @returns Plugin Target Transfer setted
   */

  const targetClone = (parent: any) => {
    const parentData = parents.get(parent)

    if (!parentData) return

    return {
      setup() {
        parentData.config.performTransfer = targetTransfer
      },
    }
  }

  // EVENT HANDLERS

  /**
   * 
   * @description This function is used to handle the drag end event
   * 
   * @param e 
   * @returns void
   */
  
  const hanldeDragEnd = (e: any) => {
    let parentObject = parents.get(document.getElementById('operationsList') as any) as any;

    let operations = parentValues(document.getElementById('operationsList') as any, parentObject).map((operation: any, index: number) => {
      return {
        value: operation.type == 'metric_tag' ? operation.value : operation.label,
        type: operation.type,
      }
    })
          
    customMetric.setOperations(operations).then(() => {})
  }


  // CUSTOM FUNCTIONS FOR TRANSFER

  /**
   * 
   * @description This function is used to transfer the source
   * 
   * @param state 
   * @param data 
   * 
   * @returns Parent Values Setted
   */

  const sourceTransfer = (state: any, data: any) => {
    const draggedValues = dragValues(state)

    const lastParentValues = parentValues(state.lastParent.el, state.lastParent.data).filter(
      (x) => !draggedValues.includes(x)
    )

    setParentValues(state.lastParent.el, state.lastParent.data, lastParentValues)
  }

  /**
   * 
   * @description This function is used to transfer the target
   * 
   * @param state 
   * @param data 
   * 
   * @returns Parent Values Setted
   */

  const targetTransfer = (state: any, data: any) => {
    const draggedValues = dragValues(state)

    const targetParentValues : any = parentValues(data.targetData.parent.el, data.targetData.parent.data)

    const reset =
      state.initialParent.el === data.targetData.parent.el &&
      data.targetData.parent.data.config.sortable === false

    let targetIndex : any

    if ('node' in data.targetData) {
      if (reset) {
        targetIndex = state.initialIndex
      } else if (data.targetData.parent.data.config.sortable === false) {
        targetIndex = data.targetData.parent.data.enabledNodes.length
      } else {
        targetIndex = data.targetData.node.data.index
      }

      targetParentValues.splice(targetIndex, 0, ...draggedValues)
    } else {
      targetIndex = reset ? state.initialIndex : data.targetData.parent.data.enabledNodes.length 
      targetParentValues.splice(targetIndex, 0, ...draggedValues)
    }

    const duplicates = findDuplicates(targetParentValues)

    for (const duplicate of duplicates) {
      if (!('key' in duplicate) || typeof duplicate !== 'object') continue
      const index = targetParentValues.indexOf(duplicate)
      const newKey = `${Math.random().toString(36).substring(2, 15)}`

      targetParentValues[index] = {
        // @ts-ignore
        ...targetParentValues[index],
        key: newKey,
      }
    }

    setParentValues(data.targetData.parent.el, data.targetData.parent.data, targetParentValues)
  }
    

  // UTILS FUNCTIONS

  /**
   * 
   * @description This function is used to find duplicates in an array
   * 
   * @param values
   * @returns array of duplicates
   * 
   */

  const findDuplicates = (values: any) => {
    const uniqueElements = new Set()
    const duplicates: any = []

    values.forEach((item: any, index: number) => {
        if (uniqueElements.has(item)) {
            duplicates.push(item);
        } else {
            uniqueElements.add(item);
        }
    })

    return duplicates
  }

  /**
   * 
   * @description This function is used to parse the operations
   * 
   * @param operations 
   * @returns void
   */

  const parseOperations = (operations: any) => {
    let parentObject = parents.get(document.getElementById('operationsList') as any) as any;

    let operationsOrdered = operations.map((operation: any, index: number) => {
      
      let operationObject: any = {};

      if (operation.manualValue !== null) {
        operationObject.isMetric = true;
        operationObject.key = "manual-value-" + index;
        operationObject.label = operation.manualValue;
        operationObject.type = 'manual_value';
      } else if (operation.metricTag !== null) {
        operationObject.isMetric = true;
        operationObject.key = "manual-value-" + index;
        operationObject.value = operation.metricTag;
        operationObject.label = customMetric.metrics?.find((metric: any) => metric.value == operation.metricTag)?.label || 'Searching...';
        operationObject.type = 'metric_tag';
      } else {
        operationObject.label = operation.operation;
        operationObject.key = "operation-" + index;
        operationObject.type = 'operation';
      }

      return operationObject
    })

    setParentValues(document.getElementById('operationsList') as any, parentObject, operationsOrdered)
  }

  // USE EFFECT

  useEffect(() => {
    let id = searchParams.get('metricCustomId')
    if (!id && customMetric.metrics.length > 0) return;
    fetchCustomMetric(id || '').then((data) => {
      customMetric.setCustomMetricOperations(data.data.metricCustomConfigurators).then(() => {
        parseOperations(data.data.metricCustomConfigurators)
        setCustomMetricData(data.data)
      })

    })
  }, [customMetric.metrics])

  return (
    <>
    <div className='row'>
      <div className="col-5">
        <EditCustomMetricCard metricCustomId={searchParams.get('metricCustomId') || ''} metricData={customMetricData}/>
      </div>
      <div className='row col-7'>
        <div className='row mb-3'>
            <CustomMetricValues sourceClone={sourceClone} dragEnd={hanldeDragEnd}/>
            <CustomMetricOperands sourceClone={sourceClone} dragEnd={hanldeDragEnd}/>
        </div>
        <CustomMetricOperations 
          targetClone={targetClone} 
          dragEnd={hanldeDragEnd}
          operations={customMetric.customMetric.operations} 
          setShowModal={setShowModal} 
          setType={setType} 
          setElementKey={setElementKey}
          setElementToEdit={setElementToEdit}
        />
        <div className='row mt-3 d-flex justify-content-between'>
          <div className='col-12 d-flex justify-content-end align-items-center'>
            <Button className='btn btn-primary fw-bold' type='submit' form='customMetricForm'>
              Update Custom Metric
            </Button>
          </div>
        </div>
      </div>
        <MetricModal 
          setShow={setShowModal} 
          show={showModal} 
          type={type}
          elementKey={elementKey} 
          elementToEdit={elementToEdit} 
          setElementToEdit={setElementToEdit}
          customMetric={customMetric}
        />
      </div>
    </>
  )
}

export default CustomMetricConfiguratorMain
