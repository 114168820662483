import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Metric } from 'app/pages/data_report/components/metrics/ReachMetric';

type LoadedMetrics = {
    metrics: LoadedMetric[];
}

export type LoadedMetric = {
    id: string,
    data: Metric[]
};

const initialState: LoadedMetrics = {
    metrics: []
};

const authSlice = createSlice({
    name: 'metrics',
    initialState,

    reducers: {
        updateLoadedMetrics: (state: LoadedMetrics, action: PayloadAction<LoadedMetric>) => {

            let isLoaded: boolean = false;
            let alreadyLoadedMetrics = state.metrics;

            alreadyLoadedMetrics.forEach((loadedMetric: { id: string; data: any[]; }) => {
                if (loadedMetric.id === action.payload.id) {
                    isLoaded = true;
                }
            })

            if (isLoaded === false) {
                alreadyLoadedMetrics.push(action.payload);
                state.metrics = alreadyLoadedMetrics;
            }
        },
        resetLoadedMetrics: (state: LoadedMetrics) => {
            state.metrics = [];
        }
    }
});

export const { updateLoadedMetrics, resetLoadedMetrics } = authSlice.actions;

export default authSlice.reducer;
