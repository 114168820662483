import { Options } from "react-select";

export type Metric = {
    id                           : number;
    metricTag                    : string;
    name                         : string;
    category                     : string;
    description                  : string;
    canOpen                      : boolean;
    showFilteredTimeseries       : boolean;
    showSplitBySource            : boolean;
    showSplitByRegion            : boolean;
    showProgrammesRanking        : boolean;
    showTherapeuticAreaRanking   : boolean;
    canShow                      : boolean;
    enabled                      : boolean;
    img                          : Img;
}

export const metricOptions = [
    { value: 'engagement', label: 'ENGAGEMENT' },
    { value: 'reach', label: 'REACH' },
    { value: 'impact', label: 'IMPACT' },
]

export type Img = {
    id:           string;
    originalName: string;
    extension:    string;
    fileName:     string;
    subdirectory: string;
    createdAt:    AtedAt;
    updatedAt:    AtedAt;
    status:       boolean;
}


export type AtedAt = {
    date:          Date;
    timezone_type: number;
    timezone:      string;
}
