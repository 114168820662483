import { RestServiceConnection } from "../RestServiceConnection";
import QueryString from "qs" ;
import { Metric } from "app/models/metrics/Metric";

export class MetricService extends RestServiceConnection {
    
    getVisits = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/visits',
            data: filter
        }, true);
        return this;
    }

    getVimeoUniqueVisits = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/vimeo/video/stat/get/viewed/unique',
            data: filter
        }, true);
        return this;
    }

    getVimeoVisits = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/vimeo/video/stat/get/viewed',
            data: filter
        }, true);
        return this;
    }

    getReturningVisits = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/returning-visits',
            data: filter
        }, true);
        return this;
    }

    getTotalUsers = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/total-users',
            data: filter
        }, true);
        return this;
    }

    getNewUsers = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/new-users',
            data: filter
        }, true);
        return this;
    }

    getReturningUsers = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/returning-users',
            data: filter
        }, true);
        return this;
    }

    getPageViews = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/page-views',
            data: filter
        }, true);
        return this;
    }

    getSlideDownload = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/slide-download',
            data: filter
        }, true);
        return this;
    }

    getUniqueSlideDownload = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/unique-slide-download',
            data: filter
        }, true);
        return this;
    }


    getVideoViewed = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/video-viewed',
            data: filter
        }, true);
        return this;
    }

    getVideoImpressions = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/vimeo/video/stat/get/impresed',
            data: filter
        }, true);
        return this;
    }

    getFeedbacksCompleted = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/feedback/get/completed',
            data: filter
        }, true);
        return this;
    }

    getFeedbacksUniqueCompleted = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/feedback/get/completed/unique',
            data: filter
        }, true);
        return this;
    }

    getVideoUniqueImpressions = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/vimeo/video/stat/get/impresed/unique',
            data: filter
        }, true);
        return this;
    }

    getUniqueVideoViewed = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/unique-video-viewed',
            data: filter
        }, true);
        return this;
    }

    getNewslettersDelivered = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ac/get/newsletters-delivered',
            data: filter
        }, true);
        return this;
    }

    getSentCampaigns = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ac/search/campaign',
            data: filter
        }, true);
        return this;
    }

    getUniqueOpens = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ac/get/unique-opens',
            data: filter
        }, true);
        return this;
    }

    getOpens = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ac/get/opens',
            data: filter
        }, true);
        return this;
    }

    getAVGOpens = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ac/get/avg-opens',
            data: filter
        }, true);
        return this;
    }

    getTotalClicks = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ac/get/total-clicks',
            data: filter
        }, true);
        return this;
    }

    getUniqueClicks = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ac/get/unique-clicks',
            data: filter
        }, true);
        return this;
    }

    getVideoProgress = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/video-progress',
            data: filter
        }, true);
        return this;
    }

    getVideoFinished = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/video-finished',
            data: filter
        }, true);
        return this;
    }

    getPodcastSubscribes = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/podcast-subscribe',
            data: filter
        }, true);
        return this;
    }
    
    getSessionDuration = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/session-duration',
            data: filter
        }, true);
        return this;
    }

    getAvgBounceRate = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/bounce-rate',
            data: filter
        }, true);
        return this;
    }

    getUniqueClickRate = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ac/get/unique-click-rate',
            data: filter
        }, true);
        return this;
    }

    getTotalOpenList = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ac/get/total-open-list',
            data: filter
        }, true);
        return this;
    }

    getUniqueOpenList = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ac/get/unique-open-list',
            data: filter
        }, true);
        return this;
    }

    getUniqueOpenRate = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ac/get/open-rate',
            data: filter
        }, true);
        return this;
    }

    getTotalVimeoVideos = async () => {

        this.response = await this.makeRequest({
            method: "get",
            url: '/vimeo/video/stat/get/total',
        }, true);
        return this;
    }


    getTotalContacts = async () => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/counters/get/total-contacts',
        }, true);
        return this;
    }

    getTotalVisits = async () => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/counters/get/total-visits',
        }, true);
        return this;
    }

    getTotalNewsletterDelivered = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ac/get/total-newsletters-delivered',
            data: filter
        }, true);
        return this;
    }

    getTotalOpenListExplorer = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ac/get/total-open-list-explorer',
            data: filter
        }, true);
        return this;
    }

    getTotalVisitsExplorer = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/total-visits-explorer',
            data: filter
        }, true);
        return this;
    }

    getNewUsersExplorer = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/new-users-explorer',
            data: filter
        }, true);
        return this;
    }

    getWebsiteUsersExplorer = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/total-users-explorer',
            data: filter
        }, true);
        return this;
    }

    getPageViewsExplorer = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/page-views-explorer',
            data: filter
        }, true);
        return this;
    }

    getReturningVisitsExplorer = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/returning-visits-explorer',
            data: filter
        }, true);
        return this;
    }

    getReturningUsersExplorer = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/returning-users-explorer',
            data: filter
        }, true);
        return this;
    }

    getChartOpensSpecialties = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ac/get/count-specialties',
            data: filter
        }, true);
        return this;
    }

    getChartOpensCountries = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ac/get/count-countries',
            data: filter
        }, true);
        return this;
    }

    getEngagedSessions = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/engaged-sessions',
            data: filter
        }, true);
        return this;
    }

    getEngagedRate = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/engaged-rate',
            data: filter
        }, true);
        return this;
    }

    getSearches = async (filter: any) => {

        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ga/get/searches',
            data: filter
        }, true);
        return this;
    }

    getCheckpointUsersRegistered = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/checkpoint/learner/get/registered',
            data: filter
        }, true);
        return this;
    }

    getCheckpointEnrolments = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/checkpoint/enrol/get/registered',
            data: filter
        }, true);
        return this;
    }

    getCheckpointIssuedCertificates = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/checkpoint/certificate/get/issued',
            data: filter
        }, true);
        return this;
    }

    getCheckpointCompletedCourses = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/checkpoint/enrol/get/completed',
            data: filter
        }, true);
        return this;
    }


    /* Generic method to call the API with Data Report Metric because
    they all have the same structure */
    getMetricData = async (filter: any, endpoint: string) => {
        this.response = await this.makeRequest({
            method: "post",
            url: endpoint,
            data: filter
        }, true);
        return this;
    }


    /* Get the metric data based on data type
    they all have the same structure */
    getMetricEndpointData = async (filter: any, metric:string, metricData: string, signal?: any) => {
        this.response = await this.makeRequest({
            method: metric == 'metric_custom' ? "post" : "get",
            signal: signal,
            url: metric == 'metric_custom' ? '/metric-custom-configurator/calculate?metricCustomId=8' : `/metric-data/${metric}/${metricData}`,
            params: filter,
            paramsSerializer: {
                serialize: (params: any) => QueryString.stringify(params) ,
            }
        }, true);

        return this;
    }

     /* Get the metric data based on data type
    they all have the same structure */
    getIndividualMetricEndpointData = async (filter: any, metric:string, metricData: string, signal?: any) => {
        this.response = await this.makeRequest({
            method: "get",
            signal: signal,
            url: `/metric-individual-data/${metric}/${metricData}`,
            params: filter,
            paramsSerializer: {
                serialize: (params: any) => QueryString.stringify(params) ,
            }
        }, true);

        return this;
    }

    /**
     * Retrieve allowed metric of logged user in the current context
     */
    getAllowedMetricsByCategory = async (category: string, show: boolean) => {
        this.response = await this.makeRequest({
            method: "get",
            url: `/allowed-metrics/me`,
            params: {
                filter_filters: {
                    category, 
                    show
                }
            },
            paramsSerializer: {
                serialize: (params: any) => QueryString.stringify(params) ,
            }
        }, true);

        return this;
    }

    /**
     * Retrieve allowed metric of logged user in the current context
     */
    getAllowedIndividualMetricsByCategoryOrTag = async (category?: string[], group?: string[], tags?: string[]) => {
        this.response = await this.makeRequest({
            method: "get",
            url: `/allowed-individual-metrics/me`,
            params: {
                filter_filters: {
                    category,
                    group,
                    tags
                }
            },
            paramsSerializer: {
                serialize: (params: any) => QueryString.stringify(params) ,
            }
        }, true);

        return this;
    }

    /**
     * Retrieve allowed metric of logged user in the current context
     */
    getAllowedIndividualMetrics = async () => {
        this.response = await this.makeRequest({
            method: "get",
            url: `/allowed-individual-metrics/me`,
            params: {
            },
            paramsSerializer: {
                serialize: (params: any) => QueryString.stringify(params) ,
            }
        }, true);

        return this;
    }


    updateMetric = async (metric: Metric) => {
        this.response = await this.makeRequest({
            method: "post",
            url: `/metric-management/update`,
            data: metric
        }, true);
        return this;
    }
}