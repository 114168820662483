import { PEData, PEDonutData, PEPieData } from "@components/chart/types";
import WorkLoadComponent from "@components/workload_component/WorkLoadComponent";
import { MetricService } from "app/services/metric/MetricService";
import { getMetric, getMetricColors } from "app/utils/utils";
import React, { useEffect, useState } from "react";
import { ProgrammeExplorerFilterParams } from "../ProgrammeExplorerProvider";
import ProgrammeExplorerDonutChart from "./ProgrammeExplorerDonutChart";

interface Props {
    filters: ProgrammeExplorerFilterParams,
    metric: string
}

export const ProgrammeExplorerFilteredCardSplitBySourceMetric: React.FC<Props> = ({ filters, metric }) => {

    const [series, setSeries] = useState<PEDonutData[] | null>(null)
    const mainAbortController = new AbortController()


    useEffect(() => {

        setSeries(null)

        getMetrics(mainAbortController.signal)

        return () => {
            mainAbortController.abort()
        }

    }, [filters])


    const getMetrics = async (signal: AbortSignal) => {
        const response = await getTrafficSourceMetric(signal)
        setSeries(response)
    }


    const getTrafficSourceMetric = async (signal: AbortSignal): Promise<PEDonutData[] | null> => {

        let { programmes, campaigns } = filters.filter_filters

        if (campaigns !== undefined && campaigns.length > 0) {
            programmes = []
        }

        const modifiedFilters: any = {
            ...filters,
            filter_filters: {
                ...filters.filter_filters,
                group: "source",
                therapeutic_areas: [],
                programmes,
                source: filters.filter_filters.source || undefined
            }
        }

        const response = (await (new MetricService()).getMetricEndpointData(modifiedFilters, metric, 'group', signal)).getResponseData()

        if (response === undefined || !response.success) return null

        let pieData: PEDonutData[] = response.data.map((item: any, index: number) => {
            return {
                name: item.group,
                color: getMetricColors(index),
                data: item.total,
            }
        })

        return pieData

    }

    return (
        <WorkLoadComponent evaluateElement={series}>
            <ProgrammeExplorerDonutChart data={series || []} ></ProgrammeExplorerDonutChart>
        </WorkLoadComponent>
    )
}