import { MetricService } from "app/services/metric/MetricService";
import { MetricUtil } from "app/services/metric/MetricUtil";
import React, { useEffect, useState } from "react";
import { Metric, ReachMetric } from "../../../components/metrics/ReachMetric";
import { useDataReport } from "../../../DataReportProvider";
import { metrics } from "app/utils/data/metrics";
import { useDispatch } from "react-redux";
import { updateLoadedMetrics } from "app/store/reducers/metric_reducer";
import { getLoadedMetricById } from "app/utils/utils";

const ReachWebsite: React.FC = () => {

    const [websiteVisits, setWebsiteVisits] = useState<Metric[] | null>(null)
    const [totalUsers, setTotalUsers] = useState<Metric[] | null>(null)
    const [newUsers, setNewUsers] = useState<Metric[] | null>(null)
    
    const dataReport = useDataReport()
    const dispatch = useDispatch();

    const getVisits = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.websiteVisits.endpoint)).getResponseData();
        if (response.success === true) {
            setWebsiteVisits(response.data);
            dispatch(updateLoadedMetrics({id: metrics.websiteVisits.id, data: response.data}));
        }
    }

    const getTotalUsers = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.websiteVisitors.endpoint)).getResponseData();
        if (response.success === true) {
            setTotalUsers(response.data);
            dispatch(updateLoadedMetrics({id: metrics.websiteVisitors.id, data: response.data}));
        }
    }

    const getNewUsers = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.websiteNewVisitors.endpoint)).getResponseData();
        if (response.success === true) {
            setNewUsers(response.data);
            dispatch(updateLoadedMetrics({id: metrics.websiteNewVisitors.id, data: response.data}));        }
    }



    /** 
     * To calculate amount and percent of latest "n" periods, we need to modify date filtered before 
     * make request. We need to do that because the widget needs to retrive last 24
     * times periods before the requested date to calculate the amount 
    */
    const getMetrics = () => {

        const calculateStartDate = MetricUtil.calculateStartDate(dataReport.params.filter_filters.between_dates.from, dataReport.params.filter_filters.mode);

        const modifiedParams = {
            ...dataReport.params,
            filter_filters: {
                ...dataReport.params.filter_filters,
                between_dates: {
                    from: calculateStartDate,
                    to: dataReport.params.filter_filters.between_dates.to
                }
            }
        };

        let visits = getLoadedMetricById(metrics.websiteVisits.id);
        let total_users = getLoadedMetricById(metrics.websiteVisitors.id);
        let new_users = getLoadedMetricById(metrics.websiteNewVisitors.id);

        if(visits !== null && visits !== undefined) {
            setWebsiteVisits(visits);
        }else{
            getVisits(modifiedParams);
        }

        if(total_users !== null && total_users !== undefined) {
            setTotalUsers(total_users);
        }else{
            getTotalUsers(modifiedParams);
        }

        if(new_users !== null && new_users !== undefined) {
            setNewUsers(new_users);
        }else{
            getNewUsers(modifiedParams);
        }
        
    }

    const resetReports = () => {
        setWebsiteVisits(null);
        setTotalUsers(null);
        setNewUsers(null);
    }

    useEffect(() => {
        resetReports();
        getMetrics();
    }, [dataReport.params])

    return (
        <>
            <div className='row g-5 g-xl-8' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                <div className="col-xl-12">
                    <hr></hr>
                </div>
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/'+metrics.websiteVisits.logo}
                        snapshotId={metrics.websiteVisits.id}
                        title={metrics.websiteVisits.title}
                        subtitle={metrics.websiteVisits.subtitle}
                        startPeriod={24}
                        metrics={websiteVisits}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/'+metrics.websiteVisitors.logo}
                        snapshotId={metrics.websiteVisitors.id}
                        title={metrics.websiteVisitors.title}
                        subtitle={metrics.websiteVisitors.subtitle}
                        startPeriod={24}
                        metrics={totalUsers}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/'+metrics.websiteNewVisitors.logo}
                        snapshotId={metrics.websiteNewVisitors.id}
                        title={metrics.websiteNewVisitors.title}
                        subtitle={metrics.websiteNewVisitors.subtitle}
                        startPeriod={24}
                        metrics={newUsers}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                {/* 
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/'+metrics.googleAdsImpressions.logo+'.svg'}
                        snapshotId={metrics.googleAdsImpressions.id}
                        title={metrics.googleAdsImpressions.title}
                        subtitle={metrics.googleAdsImpressions.subtitle}
                        startPeriod={24}
                        metrics={newUsers}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                    />
                </div>
                */}
                {/* end::Col */}
            </div>
        </>
    )
}


export default ReachWebsite;