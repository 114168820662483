import { RestServiceConnection } from "../RestServiceConnection";


export class TwitterService extends RestServiceConnection {
    
    uploadStatFile = async (data: any) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/twitter/account/upload',
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }, true);
        return this;
    }
    
    uploadTweetTweetFile = async (data: any) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/twitter/tweet/upload',
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }, true);
        return this;
    }
}