import { FC, useEffect, useState } from "react";
import MarketingTableCustom, { actionCell } from "./MarketingTableCustom";
import MarketingWebsiteChart from "./MarketingWebsiteLineChart";
import { useMarketing } from "../MarketingProvider";
import { KTSVG } from "_metronic/helpers";
import { formatNumber, limitDecimals, replaceUnderscoresWithSpacesAndCapitalize } from "app/utils/utils";
import SpinnerCustom from "@components/SpinnerCustom";
import { metricMarketingUtils } from "app/services/metric/MetricMarketingUtils";

interface MarketingExtendedWebsiteTableProps {
  show: boolean;
  setShow: any;
  title: string;
  extendedMetrics: any;
  rows?: any;
  subGroup?: string;
}

const MarketingExtendedWebsiteTable: FC<MarketingExtendedWebsiteTableProps> = ({ show, setShow, title, extendedMetrics, rows, subGroup }) => {
  const Marketing = useMarketing();
  const [apiAllData, setApiAllData] = useState<any>([]);
  const [option, setOption] = useState<string>('');
  const [showChart, setShowChart] = useState(false);
  const [titleChart, setTitleChart] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [rowsChart, setRowsChart] = useState<any>([]);
  const [singleExtendedValue, setSingleExtendedValue] = useState<any>(null);

  const extendedController = new AbortController();

  const [data, setData] = useState<any>([]);
  const [calculateTotalsData, setCalculateTotalsData] = useState<any>([]);

  const calculateTotals = (data: any) => {
    let totals: Record<string, number> = {};

    Marketing.params.filter_filters.selected_names.forEach((curr: any) => {
      totals[curr] = 0;
    });

    data.forEach((row: any) => {
      Marketing.params.filter_filters.selected_names.forEach((column: any) => {
        totals[column] += row[column] || 0;
      });
    });

    return totals;
  };

  const firstColumn = {
    name: 'insightValue',
    keyValue: 'insight',
    className: 'text-center bg-marketing-extended-website-header pre-line fs-12p min-w-200px',
    renderHeader: (element: any) => {
      return (
        <div className="d-flex justify-content-center align-items-center">
          Insight level selected: {replaceUnderscoresWithSpacesAndCapitalize(Marketing.params.filter_filters.group || '')}
        </div>
      );
    },
    render: (element: any) => {
      return (
        <>
          <div className="text-center fw-bold">
            <span>{element.insight}</span>
          </div>
        </>
      );
    },
  };

  const columns = [
    firstColumn,
    ...Marketing.params.filter_filters.selected_names.map((column: any, index: any) => {
      return {
        name: column,
        keyValue: column,
        className: 'text-center bg-marketing-extended-website-header pre-line fs-12p min-w-200px bg-marketing-fields',
        render: (element: any) => {
          return (
            <div className="d-flex justify-content-center align-items-center">
              {formatNumber(element[column])}
            </div>
          );
        },
      };
    }),
  ];

  const generateActionRow = (columnName: string, index: any): actionCell => ({
    title: columnName,
    actionGroup: [
      {
        buttonType: 'icon',
        iconPath: '/media/icons/duotune/graphs/gra013.svg',
        svgClassName: 'svg-table-icon',
        callback: () => {
          setShowChart(true);
          setTitleChart(columnName);
          const groupKey = Marketing.params.filter_filters.group as any;
          setSingleExtendedValue(
            (groupKey === 'therapeutic_areas' ? Marketing.params.filter_filters['therapeutic_areas_ids'][index] : 
              (Marketing.params.filter_filters[groupKey as keyof typeof Marketing.params.filter_filters] as any)[index]
            ),
          );
          setRowsChart(apiAllData[columnName].sort((a: any, b: any) => a.total - b.total).reverse().map((element: any) => element.group).slice(0, 10))
        },
      },
    ],
  });

  const generateActionsForSelectedNames = (): actionCell[] => {
    return Marketing.params.filter_filters.selected_names.map((columnName: any, index : any) => generateActionRow(columnName, index));
  };

  const getMetricsData = async () => {
    let group = Marketing.params.filter_filters.group; // GET SELECTED GROUP

    if (group === 'therapeutic_areas') {
      group = 'therapeutic_areas_ids';
    }

    let arrayColums: any = Marketing.params.filter_filters[`${group}` as keyof typeof Marketing.params.filter_filters];
    let rawData: any = [];
    let allInsights: any = [];

    const marketingUtils = new metricMarketingUtils();

    await Promise.all(arrayColums.map(async (column: any, index: number) => {
          const apiData = await marketingUtils.getTotalMetricWebsiteExtended(extendedController.signal, column, Marketing, extendedMetrics, subGroup);

          rawData[Marketing.params.filter_filters.selected_names[index]] = apiData;

          apiData.forEach((dataPoint: any) => {
            const insight = dataPoint.group;
            if (!allInsights.includes(insight)) {
              allInsights.push(insight);
            }
          });
    }));

    setApiAllData(rawData);

    let data: any = allInsights.map((insight: any) => {
      let row: any = {
        insight: insight,
      };

      Marketing.params.filter_filters.selected_names.forEach((column: any) => {
        const rawDataPoint = rawData[column].find((dataPoint: any) => dataPoint.group === insight);
        row[column] = rawDataPoint ? limitDecimals(rawDataPoint.total) : 0;
      });

      return row;
    });

   setCalculateTotalsData(calculateTotals(data));

   return data;
  };

  useEffect(() => {
    
    if (Marketing.params.filter_filters.group === '') return
    if (extendedMetrics === null) return

    setLoading(true);
    
    setData(null);

    const fetchData = async () => {
      const data = await getMetricsData();
      setData(data);
      setLoading(false);
    };

    fetchData();

    setShowChart(false);
    setTitleChart('');
    
    return () => {
      extendedController.abort();
    };

  }, [Marketing.params.filter_filters, extendedMetrics, option]);

  return (
    <>
      <div className='col-xl-12'>
        <div className={`${show ? 'd-block' : 'd-none'}`}>
          {!loading ? (
            <>
              <div className="d-flex flex-row d-flex justify-content-between align-items-center bg-primary" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                <div className='text-center fw-bolder text-light p-2 fs-4 ms-3'>KPI expanded view: {title}</div>
                <button onClick={() => setShow(false)} className='btn bg-transparent d-flex justify-content-center ms-2'>
                  <KTSVG path={'/media/icons/duotune/arrows/arr061.svg'} svgClassName={'w-40px h-40px text-light'} />
                </button>
              </div>
              <div className="p-1" style={{ backgroundColor: '#33B4E5' }}></div>
                  <MarketingTableCustom
                    className="card-primary"
                    title="Third-party data"
                    tableClassName="w-100"
                    subtitle="List of third-party data"
                    key={Marketing.params.filter_filters.group + title}
                    showTotalsColumn={true}
                    totalsRowPosition='top'
                    totalsRow={calculateTotalsData}
                    data={data}
                    topHeaderRow={[
                      {
                        name: `${title}`,
                        className: 'text-center pre-line fs-6 min-w-200px bg-marketing-fields align-middle',
                        colspan: true,
                      },
                    ]}
                    actionRow={generateActionsForSelectedNames()}
                    columns={columns}
                  />
                <div className="pt-5">
                  <MarketingWebsiteChart show={showChart} setShow={setShowChart} title={titleChart} subtitle="" subGroup={subGroup} rows={rowsChart} 
                  singleExtendedValue={singleExtendedValue} metric={extendedMetrics}/>
                </div>
            </>
        ) : (
          <div className='d-flex justify-content-center align-items-center'>
            <SpinnerCustom/>
          </div>
        )}
        </div>
      </div>
    </>
  );
}

export default MarketingExtendedWebsiteTable;