import { CognitoRefreshToken, CognitoUser, CognitoUserPool, CognitoUserSession, ICognitoUserPoolData } from 'amazon-cognito-identity-js';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { refreshUserToken, removeUser } from '../store/reducers/auth_reducer';
import store from '../store/store';



export class RestServiceConnection {

    baseUrl: string|undefined;
    response: AxiosResponse<any>|null = null;

    constructor() {
        this.baseUrl = process.env.REACT_APP_API_URL;
        this.response = null;
    }

    /**
     * Returns CognitoUser with environment configuration and email pass like parameter.
     * 
     * @param username 
     * @returns 
     */
    getCognitoUser = (username: string): CognitoUser => {
        let poolData: ICognitoUserPoolData = {
            UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
            ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!,
        }

        let userPool = new CognitoUserPool(poolData);

        let userData = {
            Username: username,
            Pool: userPool,
        }

        return new CognitoUser(userData);

    }

    /**
     * Make request to configured environment variable url and configure 
     * headers for correctly request format.
     * 
     * @param config 
     * @param isAuth 
     * @returns 
     */
    async makeRequest(config: AxiosRequestConfig, isAuth: boolean = false) {
        
        if(config.url !== undefined) {
            config.url = this.baseUrl + config.url
        }
       
        

        if(config.headers === undefined) {
            config.headers = {
                "Content-Type": "application/json"
            }
        }
        

        if(isAuth) {
            let token = store.getState()?.auth?.user?.auth.access_token;
            Object.assign(config.headers, {
                'Authorization': `Bearer ${token}`
            });
        }

        try {
            this.response = await axios(config);
        } catch (error: any) {
                // handle unauthorized error to refresh token and make call again.
            if(error.response?.status === 401) {
               //this.executeUnauthorizedFlow(config, isAuth);
            }
        }
        return this.response;
    }



    getResponse() {
        return this.response;
    }

    getResponseData() {
        return this.response?.data;
    }

    getOnlyData() {
        return this.response?.data?.data;
    }

    

}