import { FC, useEffect, useState } from "react";
import { PageTitle, useLayout } from "../../../../_metronic/layout/core";
import { SnapshotService } from "../../../services/snapshot/SnapshotService";
import { Metric, ReachMetric } from "app/pages/data_report/components/metrics/ReachMetric";
import { getMetric } from "app/utils/utils";
import HorizontalList from "@components/swipe_list/HorizontalList";
import ConfigRoutes from "app/routing/ConfigRoutes";
import { useNavigate } from "react-router-dom";

const breadcrumbs = [

  {
    title: 'Snapshot List',
    path: 'admin/snapshots/list',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '#',
    isSeparator: true,
    isActive: false,
  },
]

export const LastUserSnapshots: FC = () => {

  const [snapshots, setSnapshots] = useState<any>(null);
  const [currentSnapshot, setCurrentSnapshot] = useState<Metric[]| null>(null);
  const [activeCard, setActiveCard] = useState<number>(0);

  const layout = useLayout();
  const navigate = useNavigate();

  useEffect(() => {
    getSnapshot();
    addButtons();
  }, []);

  const getSnapshot = async () => {

      let snapshots = (await (new SnapshotService).getSnapshots({})).getOnlyData();
      setSnapshots(snapshots.data);
      updateCurrentSnapshot(snapshots.data[0].data, 0);
  }

  const updateCurrentSnapshot = (data: any, active: number) => {
    let snapshotMetrics = JSON.parse(data);
    setCurrentSnapshot(snapshotMetrics);
    setActiveCard(active);
  }

  const addButtons = () => {

    layout.setToolbarButtons([
      (
        <div className='me-0' key={'toolbar-create-button'}>
          <button
            className='btn btn-sm btn-primary'
            onClick={()=> navigate(ConfigRoutes.admin.user_snapshots.list.fullPath)}
          >
            See all Snapshots
          </button>
        </div>

      )
    ])
  }

  return (
    <>
      {
        (snapshots === null || currentSnapshot === null) ?
          (
            <div className="text-justify-content-md-center">
              <span className='indicator-progress text-center' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </div>

          )
          :
          (
            <>
            <div className="pb-5"> 
            <HorizontalList title="Last Snapshots" content={snapshots} onItemClick={updateCurrentSnapshot} activeCard={activeCard}/>
            </div>
              <PageTitle breadcrumbs={breadcrumbs}>{'Snapshot View'}</PageTitle>
              <div className='row g-5 g-xl-8 mt-3'>
                {Object.entries(currentSnapshot).map(([key, value]: any) => {
                  let metricInfo = getMetric(key);
                  return (
                    <div className='col-xl-6 flex-xl-fill mt-0' key={key}>
                      <ReachMetric
                        snapshotId={key}
                        title={metricInfo != undefined ? metricInfo.title : key}
                        subtitle={metricInfo != undefined ? metricInfo.title : key}
                        metrics={value}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px' />
                    </div>);
                }
                )}
              </div>
            </>
          )
      }
    </>
  );
}