import { MetricService } from "app/services/metric/MetricService"
import React, { createContext, useContext, useEffect, useState } from "react"

export type Operation = {
    value: string,
    type: string,
    order: number
}

export type CustomMetricConfiguratorOperations = {
    operations: Operation[]
}

type CustomMetricConfiguratorContextModel = {
    addOperation: (value: string, type: string) => Promise<void>,
    editOperation: (index: number, value: string, type: string) => Promise<void>,
    deleteOperation: (index: number) => Promise<void>,
    setOperations: (operations: {value: string, type: string}[]) => Promise<void>,
    orderOperation: (lastIndex: number, index: number) => Promise<void>,
    setCustomMetricOperations: (operations: Operation[]) => Promise<void>,
    customMetric: CustomMetricConfiguratorOperations,
    metrics: any[],
    resetOperations: () => void
}

const defaultOperations: CustomMetricConfiguratorOperations = {
    operations: []
}

const CustomMetricConfiguratorContext = createContext<CustomMetricConfiguratorContextModel>({
    customMetric: defaultOperations,
    metrics: [],
    addOperation: async () => { },
    editOperation: async () => { },
    deleteOperation: async () => { },
    setOperations: async () => { },
    orderOperation: async () => { },
    setCustomMetricOperations: async () => { },
    resetOperations: () => { }
})


const CustomMetricConfiguratorProvider: React.FC = ({ children }) => {

    const [customMetric, setCustomMetric] = useState<CustomMetricConfiguratorOperations>(defaultOperations)
    const [metrics, setMetrics] = useState<any[]>([])

    const orderOperations = (operations: Operation[]) => {

        return operations.map((operation, i) => {
            operation.order = i
            return operation
        })

    }

    const orderOperation = async (lastIndex: number, index: number) => {

        let newOperations = customMetric.operations

        let element = newOperations[lastIndex]
        newOperations.splice(lastIndex, 1)
        newOperations.splice(index, 0, element)

        let operationsOrdered = orderOperations(newOperations)

        setCustomMetric({
            operations: operationsOrdered
        })

        return
    }

    const addOperation = async (value: string, type: string) => {
        let newOperation: Operation = {
            value,
            type,
            order: customMetric.operations.length
        }

        setCustomMetric({
            operations: [...customMetric.operations, newOperation]
        })

        return
    }

    const deleteOperation = async (index: number) => {
        let newOperations = customMetric.operations.filter((operation, i) => i !== index)

        let operationsOrdered = orderOperations(newOperations)

        setCustomMetric({
            operations: operationsOrdered
        })

        return
    }
    
    const setOperations = async (operations: {value: string, type: string}[]) => {
        let operationsFormated: Operation[] = operations.map((operation, i) => {
            return {
                value: operation.value,
                type: operation.type,
                order: i
            }
        })

        setCustomMetric({
            operations: operationsFormated
        })
    }
    

    const editOperation = async (index: number, value: string, type: string) => {

        let newOperations = customMetric.operations

        newOperations[index] = {
            value,
            type,
            order: index
        }

        setCustomMetric({
            operations: newOperations
        })
    }

    const setCustomMetricOperations = async (operations: any[]) => {

        let operationsFormated: Operation[] = operations.map((operation, i) => {

            let operationObject: any = {}

            if (operation.manualValue !== null) {
                operationObject.value = operation.manualValue
                operationObject.type = 'manual_value'
                operationObject.order = i
            } else if (operation.metricTag !== null) {
                operationObject.value = operation.metricTag
                operationObject.type = 'metric_tag'
                operationObject.order = i
            } else {
                operationObject.value = operation.operation
                operationObject.type = 'operation'
                operationObject.order = i
            }

            return operationObject
        })

        setCustomMetric({
            operations: operationsFormated
        })
    }

    const resetOperations = () => {
        setCustomMetric(defaultOperations)
    }

    const getMetrics = async () => {
        const response = (await (new MetricService()).getAllowedIndividualMetrics()).getResponseData();
        if (response.success === false) {
            console.error("Error getting allowed metrics", response);
        } else {
            let metricsFormated = response.data.map((metric: any) => {
                return {
                    value: metric.metricTag,
                    label: metric.name
                }
            })
            setMetrics(metricsFormated);
        }
    }

    useEffect(() => {
        getMetrics()
    }, [])

    const value: CustomMetricConfiguratorContextModel = {
        addOperation,
        deleteOperation,
        orderOperation,
        editOperation,
        customMetric,
        resetOperations,
        setOperations,
        setCustomMetricOperations,
        metrics
    }

    return <CustomMetricConfiguratorContext.Provider value={value}>{children}</CustomMetricConfiguratorContext.Provider>
}


export { CustomMetricConfiguratorProvider , CustomMetricConfiguratorContext }

export function useCustomMetricConfiguratorProvider() {
    return useContext(CustomMetricConfiguratorContext);
}