import { UserFilters } from 'app/pages/users/filters/UserListFilters';
import ConfigRoutes from 'app/routing/ConfigRoutes';
import { ContactService } from 'app/services/contacts/ContactService';
import moment from 'moment';
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { TablesWidget9 } from '_metronic/partials/widgets/tables/TablesWidget9';


const ErrorList: FC = () => {

    const [errors, setErrors] = useState<Array<Object> | null>(null);
    const [pageCount, setPageCount] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");
    const [filters, setFilters] = useState<UserFilters | null>(null);

    const {id} = useParams();

    useEffect(() => {
        getErrors(currentPage, pageSize, undefined, searchValue);

    }, [currentPage, pageSize, filters, searchValue]);


    const getErrors = async (page?: number, limit?: number, orderFilters?: any, textSearch?: string) => {

        let params: any = {
            limit: limit == undefined ? pageSize : limit,
            page: page == undefined ? currentPage + 1 : page + 1,
            filter_filters: filters != null ? filters : {},
        }

        if (orderFilters != undefined) {
            params['filter_order'] = [orderFilters]
        }

        if (textSearch != undefined) {
            params.filter_filters['search'] = textSearch
        }

        let contacts = (await (new ContactService).getContactById(id as string, 'errors', params)).getOnlyData();

        if (contacts.data != undefined && contacts.data.length > 0) {
            setErrors(contacts.data);
        } else {
            setErrors([]);
        }

        setPageCount(contacts.lastPage);
    }

    const handlePagination = async (pageObject: any) => {

        if (pageObject.selected < 0) {
            return;
        }

        setCurrentPage(pageObject.selected);
        await getErrors(pageObject.selected, pageSize);

    }

    const handlePerPage = async (pageSizeObject: any) => {
        setPageSize(pageSizeObject.value);
        setCurrentPage(0);
        await getErrors(0, pageSizeObject.value);
    }

    const handleSearch = (value: string) => {
        setSearchValue(value);
        getErrors(0, pageSize, undefined, value);
    }

    return (
        <div>
            {
                errors === null ?
                    (
                        <div className="text-justify-content-md-center">
                            <span className='indicator-progress text-center' style={{ display: 'block' }}>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        </div>

                    )
                    :
                    (
                        <>
                            <TablesWidget9 className="card-primary" title="Users"
                                searchInput={{ placeholder: "Search...", onSearch: handleSearch }}
                                data={errors}
                                pagination={true}
                                paginationData={{
                                    pageSize: pageSize, pageCount: pageCount, currentPage: currentPage,
                                    handlePagination: handlePagination, handlePerPage: handlePerPage
                                }}
                                columns={[
                                    {
                                        name: "Created At", keyValue: 'creationDate', className: "min-w-100px", render: (element: any) => {
                                            let formatDate = moment(element.createdAt.date);
                                            return (
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='text-muted me-2 fs-7 fw-bold'>{formatDate.format('YYYY-MM-DD HH:mm')}</span>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "Header", keyValue: 'header', className: "min-w-120px", render: (element: any) => {
                                            return (
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='text-bold me-2 fs-7 fw-bold'> {element.header} </span>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "Value", keyValue: 'value', className: "min-w-120px", render: (element: any) => {
                                            return (
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='text-bold me-2 fs-7 fw-bold'> {element.value} </span>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "Exception", keyValue: 'exception', className: "min-w-120px", render: (element: any) => {
                                            return (
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='text-bold me-2 fs-7 fw-bold'> {element.exception} </span>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    },
                                ]}
                            ></TablesWidget9>
                        </>
                    )
            }
        </div>
    )
}

export { ErrorList }


