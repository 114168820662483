import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import ResetMetricsComponent from '_metronic/layout/core/ResetMetricsComponent'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './pages/auth'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
        <ResetMetricsComponent/>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>

    </Suspense>
  )
}

export { App }
