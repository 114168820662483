
import { configureStore } from '@reduxjs/toolkit';
import { loadState, saveState } from './browser_store';
import authReducer from './reducers/auth_reducer';
import loginFormReducer from './reducers/login_form_reducer';
import layoutReducer from './reducers/layout_reducer';
import metricReducer from './reducers/metric_reducer';
import botsReducer from './reducers/bots_reducer';

const store = configureStore({
    preloadedState: loadState(),
    reducer: {
        auth: authReducer,
        layout: layoutReducer,
        loginForm: loginFormReducer,
        metrics: metricReducer,
        bots: botsReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: ['@store/']
        }
    }),
});

store.subscribe(() => {
    setTimeout(() => {
        saveState(store.getState());
    }, 600);
    
});

export default store;