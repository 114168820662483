
const ConfigRoutes = {
    admin: {

        dashboard: {
            base: 'dashboard/*',
            principal: '/dashboard',

            counters: {
                path: 'counters',
                fullPath: '/dashboard/counters'
            }
        },

        programme_explorer: {
            base: 'programmeExplorer/*',
            principal: '/programmeExplorer',

            default: {
                base: 'default',
                principal: '/programmeExplorer/default',
            },

            reach: {
                base: 'reach',
                principal: 'reach/*'
            },
            engagement: {
                base: 'engagement',
                principal: 'engagement/*'
            },

            impact: {
                base: 'impact',
                principal: 'impact/*'
            },
            project_info: {
                base: 'projectInfo',
                principal: 'projectInfo/*'
            }
        },

        programme_explorer_supporter: {
            base: 'supporterProgrammeExplorer/*',
            principal: '/supporterProgrammeExplorer',

            default: {
                base: 'default',
                principal: '/supporterProgrammeExplorer/default',
            },

            project_info: {
                base: 'projectInfo',
                principal: 'projectInfo/*'
            }
        },

        marketing: {
            base : 'marketing/*',
            principal: '/marketing',

            newsletter: {
                base: 'newsletter',
                principal: '/marketing/newsletter'
            },

            website: {
                base: 'website',
                principal: '/marketing/website'
            },

            conversion: {
                base: 'conversion',
                principal: '/marketing/conversion'
            },

            navigation: {
                base: 'navigation',
                principal: '/marketing/navigation'
            },

            socialMedia: {
                base: 'socialMedia',
                principal: '/marketing/socialMedia'
            },
        },

        dataReport: {
            base: 'dataReport/*',
            principal: '/dataReport',

            reach: {
                base: 'dataReport/reach',
                principal: 'reach/*',

                website: {
                    path: 'website',
                    fullPath: '/dataReport/reach/website'
                },

                newsletter: {
                    path: 'newsletter',
                    fullPath: '/dataReport/reach/newsletter'
                },

                socialMedia: {
                    path: 'socialMedia',
                    fullPath: '/dataReport/reach/socialMedia'
                },

                podcast: {
                    path: 'podcast',
                    fullPath: '/dataReport/reach/podcast'
                },

                video: {
                    path: 'video',
                    fullPath: '/dataReport/reach/video'
                },

                elearning: {
                    path: 'elearning',
                    fullPath: '/dataReport/reach/elearning'
                },

                virtualMeeting: {
                    path: 'virtualMeeting',
                    fullPath: '/dataReport/reach/virtualMeeting'
                }
            },

            engagement: {
                base: 'dataReport/engagement',
                principal: 'engagement/*',

                website: {
                    path: 'website',
                    fullPath: '/dataReport/engagement/website'
                },

                newsletter: {
                    path: 'newsletter',
                    fullPath: '/dataReport/engagement/newsletter'
                },

                socialMedia: {
                    path: 'socialMedia',
                    fullPath: '/dataReport/engagement/socialMedia'
                },

                podcast: {
                    path: 'podcast',
                    fullPath: '/dataReport/engagement/podcast'
                },

                video: {
                    path: 'video',
                    fullPath: '/dataReport/engagement/video'
                },

                slides: {
                    path: 'slides',
                    fullPath: '/dataReport/engagement/slides'
                },

                elearning: {
                    path: 'elearning',
                    fullPath: '/dataReport/engagement/elearning'
                },

                virtualMeeting: {
                    path: 'virtual-Meeting',
                    fullPath: '/dataReport/engagement/virtual-Meeting'
                }
            },

            impact: {
                base: 'dataReport/impact',
                principal: 'impact/*',

                website: {
                    path: 'website',
                    fullPath: '/dataReport/impact/website'
                },

                virtualMeeting: {
                    path: 'virtualmeeting',
                    fullPath: '/dataReport/impact/virtualmeeting'
                },
            },

        },
        databaseManager: {
            base: 'database-manager',
        },
        individual_metrics: {
            base: 'individual-metrics',
        },
        users: {
            base: 'admin/users/*',
            list: '/list'
        },
        externalProviders: {
            base: 'admin/third_party_data/*',
            list: {
                path: 'list',
                fullPath: '/admin/third_party_data/list'
            },
            import: {
                path: 'new_import_list',
                fullPath: '/admin/third_party_data/new_import_list'
            },
        },
        snapshots: {
            base: 'admin/snapshots/*',
            list: {
                path: 'list',
                fullPath: '/admin/snapshots/list'
            },
            view: {
                path: 'view/:id',
                fullPath: '/admin/snapshots/view/:id'
            },
        },
        user_snapshots: {
            base: 'admin/your_snapshots/*',
            list: {
                path: 'list',
                fullPath: '/admin/your_snapshots/list'
            },
            last_records: {
                path: 'last_records',
                fullPath: '/admin/your_snapshots/last_records'
            },
            view: {
                path: 'view/:id',
                fullPath: '/admin/your_snapshots/view/:id'
            },
        },
        imports: {
            base: 'admin/imports/*',
            vimeo: {
                path: 'vimeo',
                fullPath: '/admin/imports/vimeo'
            },
            twitter: {
                base: 'admin/imports/twitter',
                principal: 'twitter/*',
                import: {
                    path: 'import_twitter',
                    fullPath: '/admin/imports/twitter/import'
                }
            },
            spotify: {
                path: 'spotify',
                fullPath: '/admin/imports/spotify'
            },
            castos: {
                path: 'castos',
                fullPath: '/admin/imports/castos'
            },

            contacts: {
                base: 'admin/imports/contacts',
                principal: 'contacts/*',

                import: {
                    path: 'import_contacts',
                    fullPath: '/admin/imports/contacts/import_contacts'
                },
                list: {
                    path: 'list',
                    fullPath: '/admin/imports/contacts/list'
                },
                view: {
                    base: 'view',
                    path: 'view/:id',
                    fullPath: '/admin/imports/contacts/view/:id'
                },
            },
            security: {
                base: 'admin/imports/security',
                principal: 'security/*',
                spotify: {
                    path: 'spotify',
                    fullPath: '/admin/imports/security/spotify'
                },
                youtubeAnalytics: {
                    path: 'youtube_analytics',
                    fullPath: '/admin/imports/security/youtube_analytics'
                },
                cache: {
                    path: 'cache',
                    fullPath: '/admin/imports/security/cache'
                },
                bots: {
                    path: 'bots',
                    fullPath: '/admin/imports/security/bots'
                }
            }
        },
        metrics: {
            base: 'admin/metrics/*',
            role_metrics: {
                path: 'metric_permissions',
                fullPath: '/admin/metrics/metric_permissions'
            },
            metric_list: {
                path: 'metric_list',
                fullPath: '/admin/metrics/metric_list'
            },
            metric_custom: {
                configurator : {
                    base: 'configurator',
                    principal: '/admin/metrics/metric-custom/configurator/*'
                },
                list: {
                    base: 'list',
                    principal: '/admin/metrics/metric-custom/list'
                }
            },
        },
        campaigns: {
            base: 'admin/campaigns',
        },
    }
}

export default ConfigRoutes;