import { getConnect, getMetric } from "../../../../utils/utils";
import { useFormik } from "formik";
import { FC, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { KTSVG } from '../../../../../_metronic/helpers';
import { UserService } from "../../../../services/user/UserService";
import ImgOrDefault from "../../../../utils/components/img_or_default";

interface Props {
    metrics: Array<string>,
    params: any,
}

const SnapshotData: FC<Props> = ({ metrics, params }) => {
    return (
        <>
            <div className='mb-5'>

                <div className='align-items-center flex-wrap'>

                    <div className="mb-3">
                        <div className='d-flex align-items-center'>
                            <small className="text-muted"> A Snapshot with the following Metrics will be created. Data will be biased by the filters you have currently selected.</small>
                        </div>

                    </div>

                    <div>
                        <div className='text-gray-400 fw-bold fs-7'>
                            <div className='mt-5 mb-1 pe-3 flex-grow-1'>
                                <a href='#' className='fs-3 text-gray-800 text-hover-primary fw-bolder'>
                                    Selected metrics
                                </a>
                            </div>
                            <div className="row">
                                {metrics.map(function (metric, index) {
                                    let metric_info = getMetric(metric);
                                    if (metric_info !== undefined) {
                                        return <div className="col-md-4" key={index}>{metric_info.title}</div>
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default SnapshotData;