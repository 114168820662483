import { RestServiceConnection } from "../RestServiceConnection";


export class ImportService extends RestServiceConnection {

    importVimeoMetricData = async (data: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/vimeo/video/extract',
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }, true);
        return this;
    }

    importContacts = async (data: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/sync/file/upload',
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }, true);
        return this;
    }

    importSpotifyMetricData = async (data: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/spotify/listener/import',
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }, true);
        return this;
    }

}