import { RestServiceConnection } from "../RestServiceConnection";


export class AvailableMetricsService extends RestServiceConnection {
    
    search = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "get",
            url: '/available-metrics/search',
            data: filter
        }, true);
        return this;
    }


    allow = async (role: number, metric: number) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/allowed-metrics/allow',
            data: {
                metric_id: metric,
                role_id: role
            }
        }, true);
        return this;
    }

    disallow = async (role: number, metric: number) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/allowed-metrics/disallow',
            data: {
                metric_id: metric,
                role_id: role
            }
        }, true);
        return this;
    }

    allowed_metrics_search = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "get",
            url: '/allowed-metrics/search',
            data: filter
        }, true);
        return this;
    }
}