import { MetricService } from "app/services/metric/MetricService";
import { MetricUtil } from "app/services/metric/MetricUtil";
import React, { useEffect, useState } from "react";
import { Metric, ReachMetric } from "../../../components/metrics/ReachMetric";
import { useDataReport } from "../../../DataReportProvider";
import { metrics } from "app/utils/data/metrics";
import { useDispatch } from "react-redux";
import { updateLoadedMetrics } from "app/store/reducers/metric_reducer";
import { getLoadedMetricById } from "app/utils/utils";

const ImpactWebsite: React.FC = () => {

    const [valuableRespondents, setValuableRespondents] = useState<Metric[] | null>(null)
    const [valuableAgreements, setValuableAgreements] = useState<Metric[] | null>(null)
    const [valuableAgreementsPercentage, setValuableAgreementsPercentage] = useState<Metric[] | null>(null)
    const [improvingRespondents, setImprovingRespondents] = useState<Metric[] | null>(null)
    const [improvingAgreements, setImprovingAgreements] = useState<Metric[] | null>(null)
    const [improvingAgreementsPercentage, setImprovingAgreementsPercentage] = useState<Metric[] | null>(null)
    const [changingRespondents, setChangingRespondents] = useState<Metric[] | null>(null)
    const [changingAgreements, setChangingAgreements] = useState<Metric[] | null>(null)
    const [changingAgreementsPercentage, setChangingAgreementsPercentage] = useState<Metric[] | null>(null)
    const [balancedRespondents, setBalancedRespondents] = useState<Metric[] | null>(null)
    const [balancedAgreements, setBalancedAgreements] = useState<Metric[] | null>(null)
    const [balancedAgreementsPercentage, setBalancedAgreementsPercentage] = useState<Metric[] | null>(null)

    const dataReport = useDataReport()
    const dispatch = useDispatch();

    const getValuableRespondents = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.valuableRespondents.endpoint)).getResponseData();
        if (response.success === true) {
            setValuableRespondents(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.valuableRespondents.id, data: response.data }));
        }
    }

    const getValuableAgreements = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.valuableAgreements.endpoint)).getResponseData();
        if (response.success === true) {
            setValuableAgreements(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.valuableAgreements.id, data: response.data }));
        }
    }

    const getValuableAgreementsPercentage = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.valuableAgreementsPercentage.endpoint)).getResponseData();
        if (response.success === true) {
            setValuableAgreementsPercentage(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.valuableAgreementsPercentage.id, data: response.data }));
        }
    }

    const getImprovingRespondents = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.improvingRespondent.endpoint)).getResponseData();
        if (response.success === true) {
            setImprovingRespondents(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.improvingRespondent.id, data: response.data }));
        }
    }

    const getImprovingAgreements = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.improvingAgreements.endpoint)).getResponseData();
        if (response.success === true) {
            setImprovingAgreements(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.improvingAgreements.id, data: response.data }));
        }
    }

    const getImprovingAgreementsPercentage = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.improvingAgreementsPercentage.endpoint)).getResponseData();
        if (response.success === true) {
            setImprovingAgreementsPercentage(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.improvingAgreementsPercentage.id, data: response.data }));
        }
    }

    const getChangingRespondents = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.changingRespondent.endpoint)).getResponseData();
        if (response.success === true) {
            setChangingRespondents(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.changingRespondent.id, data: response.data }));
        }
    }

    const getChangingAgreements = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.changingAgreements.endpoint)).getResponseData();
        if (response.success === true) {
            setChangingAgreements(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.changingAgreements.id, data: response.data }));
        }
    }

    const getChangingAgreementsPercentage = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.changingAgreementsPercentage.endpoint)).getResponseData();
        if (response.success === true) {
            setChangingAgreementsPercentage(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.changingAgreementsPercentage.id, data: response.data }));
        }
    }

    const getBalancedRespondents = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.balancedRespondent.endpoint)).getResponseData();
        if (response.success === true) {
            setBalancedRespondents(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.balancedRespondent.id, data: response.data }));
        }
    }

    const getBalancedAgreements = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.balancedAgreements.endpoint)).getResponseData();
        if (response.success === true) {
            setBalancedAgreements(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.balancedAgreements.id, data: response.data }));
        }
    }

    const getBalancedAgreementsPercentage = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.balancedAgreementsPercentage.endpoint)).getResponseData();
        if (response.success === true) {
            setBalancedAgreementsPercentage(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.balancedAgreementsPercentage.id, data: response.data }));
        }
    }


    /** 
     * To calculate amount and percent of latest "n" periods, we need to modify date filtered before 
     * make request. We need to do that because the widget needs to retrive last 24
     * times periods before the requested date to calculate the amount 
    */
    const getMetrics = () => {

        const calculateStartDate = MetricUtil.calculateStartDate(dataReport.params.filter_filters.between_dates.from, dataReport.params.filter_filters.mode);

        const modifiedParams = {
            ...dataReport.params,
            filter_filters: {
                ...dataReport.params.filter_filters,
                between_dates: {
                    from: calculateStartDate,
                    to: dataReport.params.filter_filters.between_dates.to
                }
            }
        };

        let valuable_respondents = getLoadedMetricById(metrics.valuableRespondents.id);
        let valuable_agreements = getLoadedMetricById(metrics.valuableAgreements.id);
        let valuable_agreements_percentage = getLoadedMetricById(metrics.valuableAgreementsPercentage.id);
        let improving_respondents = getLoadedMetricById(metrics.improvingRespondent.id);
        let improving_agreements = getLoadedMetricById(metrics.improvingAgreements.id);
        let improving_agreements_percentage = getLoadedMetricById(metrics.improvingAgreementsPercentage.id);
        let changing_respondents = getLoadedMetricById(metrics.changingRespondent.id);
        let changing_agreements = getLoadedMetricById(metrics.changingAgreements.id);
        let changing_agreements_percentage = getLoadedMetricById(metrics.changingAgreementsPercentage.id);
        let balanced_respondents = getLoadedMetricById(metrics.balancedRespondent.id);
        let balanced_agreements = getLoadedMetricById(metrics.balancedAgreements.id);
        let balanced_agreements_percentage = getLoadedMetricById(metrics.balancedAgreementsPercentage.id);

        if (valuable_respondents !== null && valuable_respondents !== undefined) {
            setValuableRespondents(valuable_respondents);
        } else {
            getValuableRespondents(modifiedParams);
        }

        if (valuable_agreements !== null && valuable_agreements !== undefined) {
            setValuableAgreements(valuable_agreements);
        } else {
            getValuableAgreements(modifiedParams);
        }

        if (valuable_agreements_percentage !== null && valuable_agreements_percentage !== undefined) {
            setValuableAgreementsPercentage(valuable_agreements_percentage);
        } else {
            getValuableAgreementsPercentage(modifiedParams);
        }

        if (improving_respondents !== null && improving_respondents !== undefined) {
            setImprovingRespondents(improving_respondents);
        } else {
            getImprovingRespondents(modifiedParams);
        }

        if (improving_agreements !== null && improving_agreements !== undefined) {
            setImprovingAgreements(improving_agreements);
        } else {
            getImprovingAgreements(modifiedParams);
        }

        if (improving_agreements_percentage !== null && improving_agreements_percentage !== undefined) {
            setImprovingAgreementsPercentage(improving_agreements_percentage);
        } else {
            getImprovingAgreementsPercentage(modifiedParams);
        }

        if (changing_respondents !== null && changing_respondents !== undefined) {
            setChangingRespondents(changing_respondents);
        } else {
            getChangingRespondents(modifiedParams);
        }

        if (changing_agreements !== null && changing_agreements !== undefined) {
            setChangingAgreements(changing_agreements);
        } else {
            getChangingAgreements(modifiedParams);
        }
        
        if (changing_agreements_percentage !== null && changing_agreements_percentage !== undefined) {
            setChangingAgreementsPercentage(changing_agreements_percentage);
        } else {
            getChangingAgreementsPercentage(modifiedParams);
        }

        if (balanced_respondents !== null && balanced_respondents !== undefined) {
            setBalancedRespondents(balanced_respondents);
        } else {
            getBalancedRespondents(modifiedParams);
        }

        if (balanced_agreements !== null && balanced_agreements !== undefined) {
            setBalancedAgreements(balanced_agreements);
        } else {
            getBalancedAgreements(modifiedParams);
        }

        if (balanced_agreements_percentage !== null && balanced_agreements_percentage !== undefined) {
            setBalancedAgreementsPercentage(balanced_agreements_percentage);
        } else {
            getBalancedAgreementsPercentage(modifiedParams);
        }
    }

    const resetReports = () => {
        setValuableRespondents(null);
        setValuableAgreements(null);
        setValuableAgreementsPercentage(null);
        setImprovingRespondents(null);
        setImprovingAgreements(null);
        setImprovingAgreementsPercentage(null);
        setChangingRespondents(null);
        setChangingAgreements(null);
        setChangingAgreementsPercentage(null);
        setBalancedRespondents(null);
        setBalancedAgreements(null);
        setBalancedAgreementsPercentage(null);
    }


    useEffect(() => {
        resetReports();
        getMetrics();
    }, [dataReport.params])


    return (
        <>
            <div className='row g-5 g-xl-8' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                <div className="col-xl-12">
                    <hr></hr>
                </div>
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.valuableRespondents.logo}
                        snapshotId={metrics.valuableRespondents.id}
                        title={metrics.valuableRespondents.title}
                        subtitle={metrics.valuableRespondents.subtitle}
                        startPeriod={24}
                        metrics={valuableRespondents}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.valuableAgreements.logo}
                        snapshotId={metrics.valuableAgreements.id}
                        title={metrics.valuableAgreements.title}
                        subtitle={metrics.valuableAgreements.subtitle}
                        startPeriod={24}
                        metrics={valuableAgreements}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.valuableAgreementsPercentage.logo}
                        snapshotId={metrics.valuableAgreementsPercentage.id}
                        title={metrics.valuableAgreementsPercentage.title}
                        subtitle={metrics.valuableAgreementsPercentage.subtitle}
                        startPeriod={24}
                        metrics={valuableAgreementsPercentage}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.improvingRespondent.logo}
                        snapshotId={metrics.improvingRespondent.id}
                        title={metrics.improvingRespondent.title}
                        subtitle={metrics.improvingRespondent.subtitle}
                        startPeriod={24}
                        metrics={improvingRespondents}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.improvingAgreements.logo}
                        snapshotId={metrics.improvingAgreements.id}
                        title={metrics.improvingAgreements.title}
                        subtitle={metrics.improvingAgreements.subtitle}
                        startPeriod={24}
                        metrics={improvingAgreements}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.improvingAgreementsPercentage.logo}
                        snapshotId={metrics.improvingAgreementsPercentage.id}
                        title={metrics.improvingAgreementsPercentage.title}
                        subtitle={metrics.improvingAgreementsPercentage.subtitle}
                        startPeriod={24}
                        metrics={improvingAgreementsPercentage}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.changingRespondent.logo}
                        snapshotId={metrics.changingRespondent.id}
                        title={metrics.changingRespondent.title}
                        subtitle={metrics.changingRespondent.subtitle}
                        startPeriod={24}
                        metrics={changingRespondents}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.changingAgreements.logo}
                        snapshotId={metrics.changingAgreements.id}
                        title={metrics.changingAgreements.title}
                        subtitle={metrics.changingAgreements.subtitle}
                        startPeriod={24}
                        metrics={changingAgreements}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.changingAgreementsPercentage.logo}
                        snapshotId={metrics.changingAgreementsPercentage.id}
                        title={metrics.changingAgreementsPercentage.title}
                        subtitle={metrics.changingAgreementsPercentage.subtitle}
                        startPeriod={24}
                        metrics={changingAgreementsPercentage}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.balancedRespondent.logo}
                        snapshotId={metrics.balancedRespondent.id}
                        title={metrics.balancedRespondent.title}
                        subtitle={metrics.balancedRespondent.subtitle}
                        startPeriod={24}
                        metrics={balancedRespondents}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.balancedAgreements.logo}
                        snapshotId={metrics.balancedAgreements.id}
                        title={metrics.balancedAgreements.title}
                        subtitle={metrics.balancedAgreements.subtitle}
                        startPeriod={24}
                        metrics={balancedAgreements}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.balancedAgreementsPercentage.logo}
                        snapshotId={metrics.balancedAgreementsPercentage.id}
                        title={metrics.balancedAgreementsPercentage.title}
                        subtitle={metrics.balancedAgreementsPercentage.subtitle}
                        startPeriod={24}
                        metrics={balancedAgreementsPercentage}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
            </div>
        </>
    )
}


export default ImpactWebsite;