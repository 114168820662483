import moment from 'moment'

const dbManagerInitialState = {
  query: {
    output: 'count',
    condition_groups: [
      {
        type: 'AND',
        conditions: [
          {
            type: '',
            field: '',
            condition: '',
            values: [],
          },
        ],
      },
    ],
  },
}

const dbManagerReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADD_CONDITION_GROUP':
      // Encuentra la posición del grupo actual
      const currentIndex = state.query.condition_groups.findIndex(
        (group: number) => group === action.payload.currentGroup
      )

      // Verifica si el grupo actual es el último
      const isLastGroup = currentIndex === state.query.condition_groups.length - 1

      // Crea el nuevo grupo
      const newGroup = {
        type: 'AND',
        conditions: [
          {
            type: '',
            field: '',
            condition: '',
            values: [],
          },
        ],
      }

      // Clona el arreglo de grupos de condiciones
      const newConditionGroups = [...state.query.condition_groups]

      // Inserta el nuevo grupo en la posición adecuada
      if (isLastGroup) {
        newConditionGroups.push(newGroup)
      } else {
        newConditionGroups.splice(currentIndex + 2, 0, newGroup)
      }

      return {
        ...state,
        query: {
          ...state.query,
          condition_groups: newConditionGroups,
        },
      }
    case 'ADD_CONDITION':
      return {
        ...state,
        query: {
          ...state.query,
          condition_groups: state.query.condition_groups.map((group: any, index: number) => {
            if (index === action.payload.groupIndex) {
              return {
                ...group,
                conditions: [
                  ...group.conditions,
                  {
                    type: '',
                    field: '',
                    condition: '',
                    values: [],
                  },
                ],
              }
            }

            return group
          }),
        },
      }
    case 'UPDATE_CONDITION_VALUE':
      const {field, condition, values} = action.payload


      return {
        ...state,
        query: {
          ...state.query,
          condition_groups: state.query.condition_groups.map((group: any, index: number) => {
            if (index === action.payload.groupIndex) {
              return {
                ...group,
                conditions: group.conditions.map((cond: any, idx: number) => {
                  if (idx === action.payload.conditionIndex) {
                    return {
                      ...cond,
                      field: field !== undefined ? field : cond.field,
                      condition: condition !== undefined ? condition : cond.condition,
                      values:
                        values !== undefined
                          ? values === null ? [] : values
                          : condition == 'between'
                          ? [
                              moment().startOf('week').format('YYYY-MM-DD 00:00'),
                              moment().endOf('week').format('YYYY-MM-DD 00:00'),
                            ]
                          : cond.values,
                    }
                  }

                  return cond
                }),
              }
            }
            return group
          }),
        },
      }
    case 'UPDATE_CONDITION_GROUP_TYPE':
      const {groupType} = action.payload

      return {
        ...state,
        query: {
          ...state.query,
          condition_groups: state.query.condition_groups.map((group: any, index: number) => {
            if (index === action.payload.groupIndex) {
              return {
                ...group,
                type: groupType,
              }
            }

            return group
          }),
        },
      }
    case 'UPDATE_CONDITION_TYPE':
      const {conditionType} = action.payload

      return {
        ...state,
        query: {
          ...state.query,
          condition_groups: state.query.condition_groups.map((group: any, index: number) => {
            if (index === action.payload.groupIndex) {
              return {
                ...group,
                conditions: group.conditions.map((cond: any, idx: number) => {
                  if (idx === action.payload.conditionIndex) {
                    return {
                      ...cond,
                      type: conditionType,
                    }
                  }

                  return cond
                }),
              }
            }

            return group
          }),
        },
      }
    case 'DELETE_CONDITION_GROUP':
      return {
        ...state,
        query: {
          ...state.query,
          condition_groups: state.query.condition_groups.filter(
            (group: any, index: number) => index !== action.payload.groupIndex
          ),
        },
      }
    case 'DELETE_CONDITION':
      const {conditionIndex} = action.payload

      return {
        ...state,
        query: {
          ...state.query,
          condition_groups: state.query.condition_groups.map((group: any, index: number) => {
            if (index === action.payload.groupIndex) {
              return {
                ...group,
                conditions: group.conditions.filter(
                  (cond: any, idx: number) => idx !== conditionIndex
                ),
              }
            }

            return group
          }),
        },
      }
    case 'UPDATE_OUTPUT_VALUE':
      const {outputValue} = action.payload
      let updatedQuery = {...state.query}

      if (outputValue === 'breakdown') {
        updatedQuery.output = outputValue
      } else {
        updatedQuery.output = outputValue
        delete updatedQuery.breakdown_field
      }

      return {
        ...state,
        query: updatedQuery,
      }

    case 'ADD_BREAKDOWN_FIELD':
      const {breakdownField} = action.payload

      if (state.query.output !== 'breakdown') {
        return state // No se agrega el campo de desglose si el tipo de salida no es de desglose
      }

      const updatedBreakdownField = breakdownField

      return {
        ...state,
        query: {
          ...state.query,
          breakdown_field: updatedBreakdownField,
        },
      }
    case 'RESET_QUERY':
      return {
        ...state,
        query: dbManagerInitialState.query,
      }
    case 'LOAD_QUERY':
      const {query} = action.payload

      // Recorre los grupos de condiciones y elimina el atributo 'type' de la última condición de cada grupo
      const modifiedConditionGroups = query.condition_groups.map((group: any) => {
        const lastConditionIndex = group.conditions.length - 1;
        const modifiedConditions = group.conditions.map((condition: any, index: number) => {
          if (index === lastConditionIndex) {
            return { ...condition, type: '', isLoaded: true };
          }
          return condition;
        });
    
        return { ...group, conditions: [...modifiedConditions] };
      });

      // Retorna el nuevo estado con la query modificada
      return {
        ...state,
        query: { ...query, condition_groups: [...modifiedConditionGroups] },
      };
    default:
      return state
  }
}

export {dbManagerInitialState, dbManagerReducer}
