import { ChangeEvent } from "react";

import { PageTitle } from "../../../../../_metronic/layout/core";
import { useAdminMetrics } from "../AdminMetricsHook";
import { AllowMetricRoleCheckbox } from "../../components/AllowMetricRoleCheckbox";
import { AvailableMetricsService } from "../../../../services/available_metric/AvailableMetricsService";
import toastr from "toastr";


const MetricRolesPage = () => {

  const { metrics, roles, groupedMetrics, currentAllowedMetrics } = useAdminMetrics();

  const findMetricsByCatName = (categoryName: string): any[] => {
    return metrics.filter((metric: any) => metric.category === categoryName.toLowerCase());
  }

  const allowMetric = async (role: number, metric: number) => {
    const allowResponse = await (new AvailableMetricsService().allow(role, metric))
    const allowResponseData = allowResponse.getResponseData()
    if (allowResponseData.success) {
      toastr.success("Metric allowed successfully")
    } else {
      toastr.error("Error during permission activation " + allowResponseData.message)
    }
  }

  const disallowMetric = async (role: number, metric: number) => {
    const disallowResponse = await (new AvailableMetricsService().disallow(role, metric))
    const disallowResponseData = disallowResponse.getResponseData()
    if (disallowResponseData.success) {
      toastr.success("Metric deactivated successfully")
    } else {
      toastr.error("Error during permission deactivation " + disallowResponseData.message)
    }
  }

  // Check if metric is already allowed
  const isAllowedMetric = (roleId: number, metricId: number): boolean => {
    const allowedFound = currentAllowedMetrics.filter((metricAllowedRole: any) => {
      return metricAllowedRole.role.id === roleId && metricAllowedRole.metric.id === metricId;
    })

    if (allowedFound.length > 0)
      return true;

    return false;
  }

  const onChangeAllow = (role: number, metric: number, status: boolean) => {
    if (status === true) {
      allowMetric(role, metric)
    } else {
      disallowMetric(role, metric)
    }
  }


  return (
    <>
      <PageTitle>Role metrics settings</PageTitle>
      <div className={'card card-custom'}>
          <div className={'card-body table-responsive'}>
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <thead>
                <tr>
                  <th ></th>
                  <th className="fw-bold">Metric Name</th>
                  <th className="fw-bold">Admin</th>
                  <th className="fw-bold">Project Lead</th>
                  <th className="fw-bold">Supporter</th>
                  <th className="fw-bold">User</th>
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(groupedMetrics).map((catName: string, catIndex: number) => {
                    return groupedMetrics[catName].map((metric: any, metricIndex: number) => {
                      return (
                        <tr key={metricIndex}>
                          {metricIndex === 0 ?
                            <td rowSpan={groupedMetrics[catName].length} className={"text-center"}>
                              <div style={{ transform: 'rotate(-90deg)' }}>
                                {catName}
                              </div>
                            </td>
                            : null
                          }
                          <td className="ps-0">{metric.name}</td>
                            {
                              roles.map((role: any, roleIndex: number) => {
                                return (
                                  <td key={metricIndex+roleIndex}>
                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                      <AllowMetricRoleCheckbox key={`checkbox-${role.id}-${metric.id}`} roleId={role.id}
                                        metricId={metric.id}
                                        defaultChecked={isAllowedMetric(role.id, metric.id)}
                                        onChange={onChangeAllow}></AllowMetricRoleCheckbox>
                                    </div>
                                  </td>
                                )
                              })
                            }
                        </tr>
                      )
                    })
                  })
                }
              </tbody>
            </table>
          </div>
      </div>
    </>
  )
}

export default MetricRolesPage