import {CustomDrawer} from '@components/drawer/CustomDrawer'
import {KTSVG} from '_metronic/helpers'
import {DatabaseManagerService} from 'app/services/database_manager/databaseManagerService'
import {FC, useEffect, useState} from 'react'
import Swal from 'sweetalert2'

interface DrawerDBProps {
  submitQuery: (array : any) => void
  dispatch: any
}

const DrawerDB: FC<DrawerDBProps> = ({ submitQuery, dispatch}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [savedQueries, setSavedQueries] = useState<any>([])
  const [selectedQuery, setSelectedQuery] = useState<any>(null)
  const [queryName, setQueryName] = useState<string>('')
  const [queryId, setQueryId] = useState<number>(0)

  const onClose = async () => {
    if (selectedQuery != null) {
      dispatch({type: 'LOAD_QUERY', payload: {query: selectedQuery}})
      Swal.fire({
        icon: 'success',
        title: `Query ${queryName} loaded successfully`,
        showConfirmButton: false,
        timer: 1500,
      })
    }
    setOpen(false)
  }

  const fetchQueries = async () => {
    const databaseManagerService = new DatabaseManagerService()
    const response = (await databaseManagerService.getSavedQueries()).response
    if (response?.status === 200) {
      setSavedQueries(response.data.data)
    }
  }

  useEffect(() => {
    setQueryId(0)
    setQueryName('')
    setSelectedQuery(null)
    fetchQueries()
  }, [open])

  const handleDeleteQuery = async (id: string, name: string) => {
    Swal.fire({
      title: `Are you sure to delete ${name}?`,
      text: `You will not be able to recover this query!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#477995',
      cancelButtonText: 'No, keep it',
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        const databaseManagerService = new DatabaseManagerService()
        const response = (await databaseManagerService.deleteQuery(id)).response
        if (response?.status === 200) {
          Swal.fire({
            icon: 'success',
            title: `Query ${queryName} deleted successfully`,
            showConfirmButton: false,
            timer: 1500,
          })

          setQueryId(0)
          setQueryName('')
          setSelectedQuery(null)

          fetchQueries()
        } else {
          Swal.fire({
            icon: 'error',
            title: `Query ${queryName} could not be deleted`,
            showConfirmButton: false,
            timer: 1500,
          })
        }
      }
    })
  }

  return (
    <>
      <CustomDrawer
        open={open}
        children={
          <div className='d-flex flex-column'>
            <span className='mb-5 text-muted fw-bold fs-4 text-center'>
              Select the query to load :{' '}
            </span>
            <div className='d-flex flex-column'>
              {savedQueries ? (
                savedQueries.map((query: any, key: any) => {
                  return (
                    <div className='row d-flex align-items-center' key={key}>
                      <div className='col-10'>
                        <button
                          className={`btn d-flex justify-content-between align-items-center mb-4 w-100 ${
                            queryId == query.id ? 'btn-success' : 'btn-primary'
                          }`}
                          onClick={() => {
                            setSelectedQuery(query.query_input)
                            setQueryId(query.id)
                            setQueryName(query.name)
                          }}
                        >
                          {queryId == query.id ? 'Query selected' : query.name}
                          <KTSVG
                            path={
                              queryId == query.id
                                ? '/media/icons/duotune/arrows/arr084.svg'
                                : '/media/icons/duotune/arrows/arr064.svg'
                            }
                            className='svg-icon-light ms-2'
                          />
                        </button>
                      </div>
                      <div className='col-2'>
                        <button
                          className={`btn d-flex justify-content-center align-items-center mb-4 w-100 btn-danger`}
                          onClick={() => {
                            handleDeleteQuery(query.id, query.name)
                          }}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-light ms-2'
                          />
                        </button>
                      </div>
                    </div>
                  )
                })
              ) : (
                <></>
              )}
            </div>
            <div className='row'>
              <div className='col-6 d-flex justify-content-center align-items-center'>
                <button
                  className={`btn mb-4 btn-primary`}
                  onClick={() => {
                    setSelectedQuery(null)
                    setQueryId(0)
                    setQueryName('')
                  }}
                >
                  Clear
                </button>
              </div>
              <div className='col-6 d-flex justify-content-center align-items-center'>
              <button
                className={`btn mb-4 btn-apply`}
                onClick={() => {
                  onClose()
                }}
              >
                Apply
              </button>
              </div>
            </div>
          </div>
        }
        anchor='right'
        onClose={() => {setOpen(false)}}
        title='Saved Queries'
      />

      <div className='me-5'>
        <button
          className={'btn btn-sm btn-primary'}
          id='#kt_drawer_db_toggle'
          onClick={() => setOpen(true)}
        >
          Select Query
          <KTSVG path='/media/icons/duotune/arrows/arr095.svg' className='svg-icon-light ms-2' />
        </button>
      </div>
    </>
  )
}

export {DrawerDB}
