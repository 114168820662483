import {AvailableMetricsService} from "../../../services/available_metric/AvailableMetricsService";
import {UserService} from "../../../services/user/UserService";
import {useEffect, useState} from "react";


export const useAdminMetrics = () => {

  const [groupedMetrics, setGroupedMetrics] = useState<any>({})
  const [metrics, setMetrics] = useState([])
  const [roles, setRoles] = useState<any>([])
  const [currentAllowedMetrics, setCurrentAllowedMetrics] = useState<any>([])

  const getAvailableMetrics = async () => {
    const metricsResponse = await (new AvailableMetricsService().search({}))
    const metricsData = metricsResponse.getResponseData()

    if (metricsData.success) {
      let metricsGrouped = groupByCategory(metricsData.data)
      setMetrics(metricsData.data)
      setGroupedMetrics(metricsGrouped)
    }
  }

  const getAvailableRoles = async () => {
    let rolesResponse = await (new UserService().getAvailableRoles());
    const rolesData = rolesResponse.getResponseData()
    if (rolesData.success) {
      setRoles(rolesData.data)
    }
  }

  const getCurrentMetricsAllowed = async () => {
    let allowedMetricsResponse = await (new AvailableMetricsService().allowed_metrics_search({}));
    const allowedMetricsData = allowedMetricsResponse.getResponseData()
    if (allowedMetricsData.success) {
      setCurrentAllowedMetrics(allowedMetricsData.data)
    }
  }

  const groupByCategory = (metrics: any) => {
    return metrics.reduce((groups: any, item: any) => {

      const categoryName = item.category.toUpperCase();
      const group = groups[categoryName] || [];

      group.push(item);

      return {
        ...groups,
        [categoryName]: group
      };

    }, {});
  }

  useEffect(() => {
    getAvailableMetrics()
    getAvailableRoles()
    getCurrentMetricsAllowed()
  }, [])

  return {
    metrics,
    groupedMetrics,
    roles,
    currentAllowedMetrics,
    getAvailableMetrics
  }
}