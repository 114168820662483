import { FC, useEffect, useState } from 'react'
import { KTSVG } from '_metronic/helpers'
import '@/_metronic/assets/sass/components/_loading.scss'
import Swal from 'sweetalert2'
import CustomSelect from '@components/form/CustomSelect'
import { TwitterService } from 'app/services/twitter/TwitterService'
import { GroupBase, OptionsOrGroups } from 'react-select'
import { ConnectService } from 'app/services/connect/ConnectService'
import { CustomDateRangePicker, RangeDate } from '@components/form/CustomDateRangePicker'

const types = [
    { value: 'DOCTOR', label: 'DOCTOR' },
    { value: 'PHARMA', label: 'PHARMA' },
    { value: 'PARTNERSHIP', label: 'PARTNERSHIP' },
    { value: 'OTHER', label: 'OTHER' },
]

const dataSources: OptionsOrGroups<unknown, GroupBase<unknown>> = [
    { value: 'APOLLO', label: 'APOLLO' },
    { value: 'MONOCL', label: 'MONOCL' },
    { value: 'NURSES', label: 'NURSES' },
]

type FormErrors = {
    fileError?: string | null,
    connectError?: string | null,
    typeError?: string | null,
    tweetFileError?: string | null,
    fileTypeError?: string | null,
    tweetDateError?: string | null,
}

const ImportTwitterIndex: FC = () => {

    const [errors, setErrors] = useState<FormErrors>()
    const [file, setFile] = useState<File | null>(null);
    const [availableConnects, setAvailableConnects] = useState<OptionsOrGroups<unknown, GroupBase<unknown>>>([]);
    const [connect, setConnect] = useState<string|null>(null);
    const [dateRange, setDateRange] = useState<string | null>(null);
    const [fileType, setFileType] = useState<any>(null);
    const [tweetFile, setTweetFile] = useState<File | null>(null);
    const [tweetConnect, setTweetConnect] = useState<string|null>(null);
    const [availableFileType, setAvailableFileType] = useState<OptionsOrGroups<unknown, GroupBase<unknown>>>([{
        value: 'data',
        label: 'Data'
    }, {
        value: 'video',
        label: 'Video'
    }]);

    useEffect(() => {
      getConnects();
    }, [])

    const getConnects = async () => {
        let connectsResponse = (await (new ConnectService).search({})).getResponseData();
        if(connectsResponse.success === false) {
            Swal.fire({
                title: 'Error during call of connects',
                text: 'Server response is wrong',
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                    confirmButton: 'btn btn-danger'
                }
            })
        } else {
            let formatConnects = connectsResponse.data.map((connect: any) => {
                return {
                    value: connect.id,
                    label: connect.name
                }
            })
            setAvailableConnects(formatConnects);
        }
        
    }

    const handleFileChange = (files: FileList | null) => {
        if (files !== null && files.length > 0) {
            setFile(files[0]);
        }
    }
    
    const handleTweetFileChange = (files: FileList | null) => {
        if (files !== null && files.length > 0) {
            setTweetFile(files[0]);
        }
    }

    const handleConnectChange = (value: unknown) => {
        let data: any = value;
        if (data.hasOwnProperty('value')) {
            setConnect(data.value);
        }
    }
    
    const handleFileTypeChange = (value: unknown) => {
        let data: any = value;
        if (data.hasOwnProperty('value')) {
            setFileType(data.value);
        }
    }

    const submitData = async (data: FormData) => {

        Swal.fire({
            showConfirmButton: false,
            title: '<h3> Importing Data... <h3>',
            loaderHtml: '<div class="custom-spinner mb-5"><span></span><span></span><span></span></div>',
            allowOutsideClick: false,
            validationMessage: '<p class="text-muted"> This operation could take a while </p>',
            html: '<p class="text-muted"> This operation could take a while </p>',

            customClass: {
                loader: 'custom-loader',
                icon: 'd-none',

            },
            willOpen: () => {
                Swal.showLoading()
            }
        });
        
        let response = (await (new TwitterService).uploadStatFile(data)).getResponse();

        Swal.close();

        if (response != null && response.status == 200 && response.data.success == true) {
            Swal.fire({
                title: 'Success!',
                text: 'Data imported successfully',
                icon: 'success',
                confirmButtonText: 'Ok',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            })
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong',
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            })
        }
    }
    
    const submitTwitterTweetData = async (data: FormData) => {

        Swal.fire({
            showConfirmButton: false,
            title: '<h3> Importing Data... <h3>',
            loaderHtml: '<div class="custom-spinner mb-5"><span></span><span></span><span></span></div>',
            allowOutsideClick: false,
            validationMessage: '<p class="text-muted"> This operation could take a while </p>',
            html: '<p class="text-muted"> This operation could take a while </p>',

            customClass: {
                loader: 'custom-loader',
                icon: 'd-none',

            },
            willOpen: () => {
                Swal.showLoading()
            }
        });
        
        let response = (await (new TwitterService).uploadTweetTweetFile(data)).getResponse();

        Swal.close();

        if (response != null && response.status == 200 && response.data.success == true) {
            Swal.fire({
                title: 'Success!',
                text: 'Data imported successfully',
                icon: 'success',
                confirmButtonText: 'Ok',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            })
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong',
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            })
        }
    }


    const handleSubmit = async (event: any) => {
        setErrors(errors => ({ ...errors, fileError: null, dataSourceError: null, typeError: null }));
        event.preventDefault();

        if (file !== null && connect !== null) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('connect', connect);
            await submitData(formData);
        } else {

            if (file === null) {
                setErrors(errors => ({ ...errors, fileError: 'File is required' }))
            }

            if (connect === null) {
                setErrors(errors => ({ ...errors, connectError: 'Connect is required' }))
            }
            setTimeout(() => {
                setErrors(errors => ({ ...errors, fileError: null, connectError: null}))
            }, 5000);
        }
    }
    
    const handleTweetSubmit = async (event: any) => {
        setErrors(errors => ({ ...errors, connectError: null, tweetFileError: null, fileTypeError: null }))
        event.preventDefault();

        if (tweetFile !== null && connect !== null && fileType !== '') {

            // call method of submit
            const formData = new FormData();
            formData.append('file', tweetFile);
            formData.append('type', fileType);
            formData.append('connect', connect);
            await submitTwitterTweetData(formData);
            
        } else {
            
            if (connect === null) {
                setErrors(errors => ({ ...errors, connectError: 'Connect is required' }))
            }
            
            if (fileType === null) {
                setErrors(errors => ({ ...errors, fileTypeError: 'Type is required' }))
            }
            
            if (tweetFile === null) {
                setErrors(errors => ({ ...errors, tweetFileError: 'File is required' }))
            }

            setTimeout(() => {
                setErrors(errors => ({ ...errors, connectError: null, tweetFileError: null, fileTypeError: null }))
            }, 5000);
        }
    }


    return (
        <div className='justify-content-center'>
            <div className="row justify-content-center mb-7">
                <div className="card card-custom col-md-6">
                    <div className='card-header'>
                        <h3 className='card-title'> <KTSVG path='/media/icons/duotune/social/twitter.svg' className='svg-icon-1 me-2' /> Import Twitter Stat File </h3>
                    </div>

                    <form onSubmit={handleSubmit} className="form">
                        <div className="card-body">
                            <div className={"alert alert-primary"}><KTSVG path='/media/icons/duotune/general/gen045.svg' className='svg-icon-1 me-2' /> The sheet should be named “daily_tweet_activity_metrics”.</div>
                            <div className="form-group validated mt-3">
                                <label className="form-label fw-bold" >Twitter stats file <span className='text-danger'>*</span></label>
                                <div className="input-group">
                                    <input accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' 
                                        type='file' onChange={(e) => handleFileChange(e.target.files)}
                                        className={`form-control`}
                                    />
                                </div>
                                {errors?.fileError !== null && errors?.fileError !== undefined && (
                                            <small className='text-danger'> {errors.fileError} </small>
                                        )}
                                <div className='row'>
                                    <small className="form-text text-muted">Allowed Extensions: XSL, XLSX, CSV</small>
                                </div>
                                
                                <div className='row'>
                                    <div className='col-md-12'>
                                        
                                        <div className="form-group mt-5">
                                            <label className="form-label fw-bold" >Connect <span className='text-danger'>*</span></label>
                                            <CustomSelect is_multi={false} options={availableConnects} onChangeSingle={handleConnectChange} />
                                        </div>
                                        {errors?.connectError !== null && errors?.connectError !== undefined && (
                                            <small className='text-danger'> {errors.connectError} </small>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary font-weight-bold me-2">Import</button>
                        </div>
                    </form>

                </div>
            </div>
            
            <div className="row justify-content-center">
                <div className="card card-custom col-md-6">
                    <div className='card-header'>
                        <h3 className='card-title'> <KTSVG path='/media/icons/duotune/social/twitter.svg' className='svg-icon-1 me-2' /> Import Twitter Tweet Stat file </h3>
                    </div>

                    <form onSubmit={handleTweetSubmit} className="form">
                        <div className="card-body">
                            <div className={"alert alert-primary"}><KTSVG path='/media/icons/duotune/general/gen045.svg' className='svg-icon-1 me-2' /> The sheet should be named “tweet_activity_metrics”. or “video_activity_metrics”.
                            <br/><br/>Download template <a href="/media/templates/tweet_import_stats.xlsx" target="_blank" download>here</a></div>
                            <div className="form-group validated mt-3">
                                <label className="form-label fw-bold" >Twitter Tweet stats file <span className='text-danger'>*</span></label>
                                <div className="input-group">
                                    <input accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' 
                                        type='file' onChange={(e) => handleTweetFileChange(e.target.files)}
                                        className={`form-control`}
                                    />
                                </div>
                                {errors?.tweetFileError !== null && errors?.tweetFileError !== undefined && (
                                            <small className='text-danger'> {errors.tweetFileError} </small>
                                        )}
                                
                                <div className='row'>
                                    <small className="form-text text-muted">Allowed Extensions: XSL, XLSX, CSV</small>
                                </div>

                                <div className='row'>
                                    <div className='col-md-12'>
                                        
                                        <div className="form-group mt-5">
                                            <label className="form-label fw-bold" >File Type <span className='text-danger'>*</span></label>
                                            <CustomSelect is_multi={false} options={availableFileType} onChangeSingle={handleFileTypeChange} />
                                        </div>
                                        {errors?.fileTypeError !== null && errors?.fileTypeError !== undefined && (
                                            <small className='text-danger'> {errors.fileTypeError} </small>
                                        )}
                                    </div>
                                </div>
                                
                                <div className="form-group mt-5">
                                    <label className="form-label fw-bold" >Connect <span className='text-danger'>*</span></label>
                                    <CustomSelect is_multi={false} options={availableConnects} onChangeSingle={handleConnectChange} />
                                </div>
                                {errors?.connectError !== null && errors?.connectError !== undefined && (
                                    <small className='text-danger'> {errors.connectError} </small>
                                )}
                            </div>
                        </div>
                        <div className="card-footer d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary font-weight-bold me-2">Import</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>

    )
}

export { ImportTwitterIndex }




