import { Metric } from "app/pages/data_report/components/metrics/ReachMetric";
import { RestServiceConnection } from "../RestServiceConnection";

export class SnapshotService extends RestServiceConnection {
    
    createSnapshot = async (params: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/snapshot/create',
            data: params
        }, true);
        return this;
    }


    getSnapshots = async (params: any) => {

        if(params.page == 0) {
            params.page = 1
        }

        this.response = await this.makeRequest({
            method: "POST",
            url: '/snapshot/search',
            data: params
        }, true)
        return this;
    }

    getSnapshotUsers = async (id: string) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/snapshot/get/users',
            data: {
                snapshotId: id
            }
        }, true)
        return this;
    }

    getSnapshotById = async (id: string) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/snapshot/get',
            data: {
                snapshotId: id
            }
        }, true)
        return this;
    }

    updateSnapshot = async (id: string, params: any) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/snapshot/update-data',
            data: {
                snapshotId: id,
                data: params
            }
        }, true)
        return this;
    }

    updateSnapshotUsers = async (id: string, name: string, users: Array<string>) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/snapshot/assign-users',
            data: {
                snapshotId: id,
                name: name,
                users: users
            }
        }, true)
        return this;
    }

    deleteSnapshot = async (id: string) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/snapshot/delete',
            data: {
                snapshotId: id
            }
        }, true)
        return this;
    }

}