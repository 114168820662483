import { FC } from "react";

interface Props {
    classes?: string
}
 
const CustomModalBody: FC<Props> = ({classes, children}) => {
    return ( 
        <div className={`modal-body scroll-y ${classes}`}>
            {children}
            {/*
            <div className='text-center mb-13'>
                <h1 className='mb-3'>Invite a Friend</h1>

                <div className='text-muted fw-bold fs-5'>
                    If you need more info, please check out
                    <a href='#' className='link-primary fw-bolder'>
                        {' '}
                        FAQ Page
                    </a>
                    .
                </div>
            </div>

            <div className='btn btn-light-primary fw-bolder w-100 mb-8'>
                <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                    className='h-20px me-3'
                />
                Invite Gmail Contacts
            </div>

            <div className='separator d-flex flex-center mb-8'>
                <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>or</span>
            </div>

            <textarea
                className='form-control form-control-solid mb-8'
                rows={3}
                placeholder='Type or paste emails here'
            ></textarea>

            <div className='mb-10'>
                <div className='fs-6 fw-bold mb-2'>Your Invitations</div>

                <div className='mh-300px scroll-y me-n7 pe-7'>
                </div>
            </div>

            <div className='d-flex flex-stack'>
                <div className='me-5 fw-bold'>
                    <label className='fs-6'>Adding Users by Team Members</label>
                    <div className='fs-7 text-muted'>
                        If you need more info, please check budget planning
                    </div>
                </div>

                <label className='form-check form-switch form-check-custom form-check-solid'>
                    <input className='form-check-input' type='checkbox' value='1' />

                    <span className='form-check-label fw-bold text-muted'>Allowed</span>
                </label>
            </div>
                */}
        </div>
     );
}
 
export default CustomModalBody;