import {useMarketing} from '../../MarketingProvider'
import { useEffect, useState } from 'react';
import MarketingTableCustom from '../../components/MarketingTableCustom';
import { formatNumber, limitDecimals, replaceUnderscoresWithSpacesAndCapitalize } from 'app/utils/utils';
import SpinnerCustom from '@components/SpinnerCustom';
import { metricMarketingUtils } from 'app/services/metric/MetricMarketingUtils';

const MarketingNewsletterWrapper = () => {

  const Marketing = useMarketing();

  const [data, setData] = useState<any>(null);

  const [oldData, setOldData] = useState<any>(null);

  const abortControllers = {
    open: new AbortController(),
    sent : new AbortController(),
    send: new AbortController(),
    click: new AbortController(),
    campaignSent: new AbortController(),
    clickRate: new AbortController(),
    openRate: new AbortController(),
    ctor: new AbortController(),
  };

  const getMetricsData = async () => {
    let group = Marketing.params.filter_filters.group; // GET GROUP

    if (group === 'therapeutic_areas') {
      group = 'therapeutic_areas_ids';
    }

    const marketingUtils = new metricMarketingUtils();

    // GET DATA FOR EACH METRIC

    const [campaignSentData, sendsData, opensData, openRateData, clicksData, clickRateData, ctorData] =
      await Promise.all([
        marketingUtils.getTotalMetricNewsLetter(abortControllers.sent.signal, 'campaigns_sent', Marketing),
        marketingUtils.getTotalMetricNewsLetter(abortControllers.send.signal, 'newsletter_email_send', Marketing),
        marketingUtils.getTotalMetricNewsLetter(abortControllers.open.signal, 'newsletter_open', Marketing),
        marketingUtils.getTotalMetricNewsLetter(abortControllers.openRate.signal, 'newsletter_open_rate', Marketing),
        marketingUtils.getTotalMetricNewsLetter(abortControllers.click.signal, 'newsletter_click', Marketing),
        marketingUtils.getTotalMetricNewsLetter(abortControllers.clickRate.signal, 'newsletter_click_rate', Marketing),
        marketingUtils.getTotalMetricNewsLetter(abortControllers.ctor.signal, 'newsletter_click_to_open_rate', Marketing),
    ]);
  
    const arrayColumns = Marketing.params.filter_filters[`${group}` as keyof typeof Marketing.params.filter_filters]; // GET ARRAY OF SELECTED VALUES

    const data = Array.isArray(arrayColumns) ?  // IF ARRAY, RETURN DATA FOR EACH VALUE
      await Promise.all(arrayColumns.map(async (column: any, index: number) => {

            // IF DATA IS NULL, RETURN 0 AND IF CONTAINS DECIMALS, LIMIT TO 3 DECIMALS

            const campaignSent = limitDecimals(campaignSentData[index]?.total == null ? 0 : campaignSentData[index].total);
            const sends = limitDecimals(sendsData[index]?.email_sends == null ? 0 : sendsData[index].email_sends);
            const opens = limitDecimals(opensData[index]?.opens == null ? 0 : opensData[index].opens);
            const openRate = limitDecimals(openRateData[index]?.open_rate == null ? 0 : openRateData[index].open_rate);
            const clicks = limitDecimals(clicksData[index]?.link_clicks == null ? 0 : clicksData[index].link_clicks);
            const clickRate = limitDecimals(clickRateData[index]?.link_rate == null ? 0 : clickRateData[index].link_rate);
            const ctor = limitDecimals(ctorData[index]?.click_to_open_rate == null ? 0 : ctorData[index].click_to_open_rate);

          return {
            insight:  Marketing.params.filter_filters.selected_names[index], // GET NAME OF SELECTED VALUE
            campaignSent,
            sends,
            opens,
            openRate,
            clicks,
            clickRate,
            ctor
          };
        }))
      : []; // IF NOT ARRAY, RETURN EMPTY ARRAY
  
    return data;
  };

  useEffect(() => {

    if (Marketing.params.filter_filters.group === '') return

    setOldData(data); // SET OLD DATA

    const fetchData = async () => {
      const data = await getMetricsData();
      setData(data);
    };

    fetchData();

    return () => {
      Object.values(abortControllers).forEach(controller => controller.abort());
    };

  }, [Marketing.params.filter_filters]);

  const calculateTotals = (data: any) => {
    let count =  0

    let totals = {
      totalCampaignSent: 0,
      totalSends: 0,
      totalOpens: 0,
      totalOpenRate: 0,
      totalClicks: 0,
      totalClickRate: 0,
      totalCtor: 0
    };

    data.forEach((element: any) => {
      totals.totalCampaignSent += parseFloat(element.campaignSent);
      totals.totalSends += parseFloat(element.sends);
      totals.totalOpens += parseFloat(element.opens);
      totals.totalOpenRate += parseFloat(element.openRate);
      totals.totalClicks += parseFloat(element.clicks);
      totals.totalClickRate += parseFloat(element.clickRate);
      totals.totalCtor += parseFloat(element.ctor);
      count++;
    });

    if (count > 0) {
      totals.totalClickRate /= count;
      totals.totalOpenRate /= count;
    }    


    return totals;
  };  

  return (
    <>
      {Marketing.params.filter_filters.group !== '' ? 
        Marketing.params.filter_filters.selected_names.length > 0 ? (
          <>
        <div className='col-xl-12 position-relative'>
          {data ?  (
          <>
          <div className='bg-primary text-center fw-bolder text-light p-2 fs-4' style={{borderTopLeftRadius : '5px' ,borderTopRightRadius : '5px'}}>NEWSLETTER KPIs</div>
          <div className="p-1" style={{backgroundColor: '#33B4E5'}}></div>
          <MarketingTableCustom
            className='card-primary'
            title='Third-party data'
            subtitle='List of third-party data'
            key={Marketing.params.filter_filters.group}
            showTotalsColumn={true}
            totalsRow={calculateTotals(data)}
            totalsRowPosition='bottom'
            data={data}
            columns={[
              {
                name: `Insight level selected\n ${replaceUnderscoresWithSpacesAndCapitalize(
                  Marketing.params.filter_filters.group || ''
                )}`,
                keyValue: 'insight',
                className: 'text-center bg-marketing-insight pre-line',
                render: (element: any) => {
                  return (
                    <>
                      <div className='text-center fw-bold'>
                        <span>{element.insight}</span>
                      </div>
                    </>
                  )
                },
              },
              {
                name: '# Campaigns sent',
                keyValue: 'campaignSent',
                className: 'align-middle text-center bg-marketing-fields',
                render: (element: any) => {
                  return (
                    <>
                      <div className='text-center'>
                        <span>{formatNumber(limitDecimals(element.campaignSent))}</span>
                      </div>
                    </>
                  )
                },
              },
              {
                name: 'Sends',
                keyValue: 'sends',
                className: 'align-middle text-center bg-marketing-fields',
                render: (element: any) => {
                  return (
                    <>
                      <div className='text-center'>
                        <span>{formatNumber(limitDecimals(element.sends))}</span>
                      </div>
                    </>
                  )
                },
              },
              {
                name: 'Opens',
                keyValue: 'opens',
                className: 'align-middle text-center bg-marketing-fields',
                render: (element: any) => {
                  return (
                    <>
                      <div className='text-center'>
                        <span>{formatNumber(limitDecimals(element.opens))}</span>
                      </div>
                    </>
                  )
                },
              },
              {
                name: 'Open Rate',
                keyValue: 'openRate',
                className: 'align-middle text-center bg-marketing-fields',
                render: (element: any) => {
                  return (
                    <>
                      <div className='text-center'>
                        <span>{formatNumber(limitDecimals(element.openRate))}</span>
                      </div>
                    </>
                  )
                },
              },
              {
                name: 'Clicks',
                keyValue: 'clisks',
                className: 'align-middle text-center bg-marketing-fields',
                render: (element: any) => {
                  return (
                    <>
                      <div className='text-center'>
                        <span>{formatNumber(limitDecimals(element.clicks))}</span>
                      </div>
                    </>
                  )
                },
              },
              {
                name: 'Click Rate',
                keyValue: 'clickRate',
                className: 'align-middle text-center bg-marketing-fields',
                render: (element: any) => {
                  return (
                    <>
                      <div className='text-center'>
                        <span>{formatNumber(limitDecimals(element.clickRate))}</span>
                      </div>
                    </>
                  )
                },
              },
              {
                name: 'CTOR',
                keyValue: 'ctor',
                className: 'align-middle text-center bg-marketing-fields',
                render: (element: any) => {
                  return (
                    <>
                      <div className='text-center'>
                        <span>{formatNumber(limitDecimals(element.ctor))}</span>
                      </div>
                    </>
                  )
                },
              }
            ]}
          />
          </>
      ) : <></>}
          <div className={data == oldData ? 'custom-overlay' : 'd-none'}>
            <SpinnerCustom className='mt-custom' size='medium'/>
          </div>
        </div>
        </>
      ) : (
        <h3 className={'text-muted text-center'}>Please select one or more {`${replaceUnderscoresWithSpacesAndCapitalize(Marketing.params.filter_filters.group)}s`}</h3>
      ) : (
        <h3 className={'text-muted text-center'}>Please select one insinght level</h3>
      )}
    </>
  )
}

export default MarketingNewsletterWrapper
