/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import LoginWithGoogle from './oauth_buttons/LoginWithGoogle'
import { LoginService } from '../../../services/auth/LoginService'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../../../store/reducers/auth_reducer'
import NormalLogin from './NormalLogin'
import { removeStatus, setLoginFormStatus } from '../../../store/reducers/login_form_reducer'
import { toAbsoluteUrl } from '_metronic/helpers'


/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {

    const [firstLoad, setFirstLoad] = useState(true);
    const dispatch = useDispatch();
    const loginFormStatus = useSelector((state: any) => state.loginForm.status);


    useEffect(() => {

        // On first load, reset messages.
        if(firstLoad) {
            dispatch(removeStatus());
            setFirstLoad(false);
        }
    });


    // Oauth login callback after success or error login by oauth2 method.
    const onOauthLogin = async (success: boolean, authData: any, message?: string) => {

        if (success) {
            let loginUserData = await (await (new LoginService()).loginOauthUser(authData)).getResponseData();
            if (loginUserData.success) {
                // Save user data to redux store.
                dispatch(setUser(loginUserData.data));
            } else {
                dispatch(setLoginFormStatus(loginUserData.message));
            }
        } else {
            dispatch(setLoginFormStatus(message));
        }
    }


    const onNormalLoginSuccess = async (result: any) => {

        let data = {
            access_token: result.accessToken.jwtToken,
            expires_in: 3600,
            id_token: result.idToken.jwtToken,
            refresh_token: result.refreshToken.token,
            token_type: 'Bearer',
        }

        try {
            let normalLoginResponse = await (await (new LoginService()).loginWithCredentials(data)).getResponseData();
           
            if(normalLoginResponse.success) {
                dispatch(setUser(normalLoginResponse.data));
            } else {
                dispatch(setLoginFormStatus(normalLoginResponse.message));
            }
            
        } catch (error: any) {
            dispatch(setLoginFormStatus(error.message));
        }
        
    }


    return (
        <div className='form'>
            {/* begin::Heading */}
                    {/* begin::Logo */}
            <a href='#' className='mb-2 d-flex justify-content-center'>
                <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.svg')} className='h-100px' />
            </a>
            {/* end::Logo */}
            {/* begin::Heading */}

            {loginFormStatus ? (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{loginFormStatus}</div>
                </div>
            ) : ""}
            <NormalLogin onNormalLoginSuccess={onNormalLoginSuccess}></NormalLogin>
            <div className='text-center text-muted text-uppercase fw-bolder mb-5'>Or</div>
            <LoginWithGoogle onOauthLogin={onOauthLogin}></LoginWithGoogle>
        </div>
    );

}
