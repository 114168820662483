import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { PageTitle } from "../../../_metronic/layout/core"
import ConfigRoutes from "../../routing/ConfigRoutes"
import { SnapshotList } from "./list"
import { SnapshotView } from "./view"




const SnapshotsWrapper = () => {

    return (
        <Routes>
            <Route element={
                <>
                    <PageTitle>Snapshots</PageTitle>
                    <Outlet />
                </>
            }>
                <Route
                    path={ConfigRoutes.admin.snapshots.list.path}
                    element={<SnapshotList />}
                />
                <Route
                    path={ConfigRoutes.admin.snapshots.view.path}
                    element={<SnapshotView />}
                />

                <Route index element={<Navigate to={ConfigRoutes.admin.snapshots.list.fullPath} replace />} />

            </Route>

        </Routes>

    )
}

export default SnapshotsWrapper