import { FC, useCallback, useEffect, useState } from "react";
import { PageTitle, useLayout } from "../../../../_metronic/layout/core";
import { TablesWidget9 } from "../../../../_metronic/partials/widgets";
import moment from "moment";
import {KTSVG} from "../../../../_metronic/helpers";
import { CustomDrawer } from "@components/drawer/CustomDrawer";
import { ExternalProviderService } from "app/services/external_povider/ExternalProviderService";
import ExternalProviderListFilters, { ExternalProvidersFilters } from "../filters/ExternalProviderListFilters";
import CreateExternalProviderModal from "../modals/CreateExternalProviderModal";
import EditExternalProviderModal from "../modals/EditExternalProviderModal";

const breadcrumbs = [
  {
    title: 'Administration',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '#',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Third-party Data',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '#',
    isSeparator: true,
    isActive: false,
  },
]

export const ExternalProvidersList: FC = () => {

  const [externalProviders, setExternalProviders] = useState<Array<Object> | null>(null);
  const [editExternalProviderObject, setEditExternalProviderObject] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filters, setFilters] = useState<ExternalProvidersFilters | null>(null);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectAll , setSelectAll] = useState<boolean>(false);

  const handleOpen = useCallback(() => setOpenFilterDrawer(true), []);

  const handleClose = useCallback(() => setOpenFilterDrawer(false), []);
  
  const layout = useLayout();


  useEffect(() => {
    addButtons(false);
  }, [])

  useEffect(() => {
    getExternalProviders(currentPage, pageSize, undefined, searchValue);
  }, [showModal, currentPage, pageSize, filters, searchValue]);

  const handleCheckboxChange = (id : string) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };


  const getExternalProviders = async (page?: number, limit?: number, orderFilters?: any, textSearch?: string) => {
    let params: any = {
      limit: limit == undefined ? pageSize : limit,
      page: page == undefined ? currentPage + 1 : page +1,
      filter_filters: filters != null ? filters : {},
    }

    if (orderFilters != undefined) {
      params['filter_order'] = [orderFilters]
    }

    if (textSearch != undefined) {
      params.filter_filters['search'] = textSearch
    }

    let externalProviders = (await (new ExternalProviderService).getExternalProviders(params)).getOnlyData();

    setExternalProviders(externalProviders.data);
    setPageCount(externalProviders.lastPage)
  }

  const launchCreateModal = () => {
    setShowModal(true)
  }

  /**
   * Function to add buttons in toolbar.
   */
  const addButtons = (filter: boolean) => {
    layout.setToolbarButtons([
      (
        <div className='me-4' key={"toolbar-filter-button"}>
          <button
            type="button"
            className={`btn btn-sm btn-flex btn-light btn-active-primary fw-bolder` + (filter ? " active" : "")}
            //id='kt_drawer_chat_toggle'
            onClick={handleOpen}
          >
            <KTSVG
              path='/media/icons/duotune/general/gen031.svg'
              className='svg-icon-5 svg-icon-gray-500 me-1'
            />
            Filter
          </button>
        </div>
      ),
      (
        <div className='me-0' key={'toolbar-create-button'}>
          <button
            className='btn btn-sm btn-primary'
            onClick={launchCreateModal}
          >
            Create
          </button>
        </div>

      )
    ])
  }

  const deleteExternalProvider = async (externalProvider: any) => {
    let confirmed = await layout.confirmAction({ title: "Delete Third-party data", description: "Are you sure you want delete this third-party data?" });

    if (confirmed) {

      let deleteResponse = (await (new ExternalProviderService()).deleteExternalProvider({ id: externalProvider.id }));
      let deleteResponseData = deleteResponse.getResponseData();

      if (deleteResponseData.success == false) {
        layout.toast?.error(`Error delenting third-party data: ${deleteResponseData.message}`)
      }

      setExternalProviders(null);
      getExternalProviders();

    }
  }

  const editExternalProvider = (externalProvider: any) => {
    setEditExternalProviderObject(externalProvider.id);
    setEditModal(true);
  }

  const replaceUnderscoresWithSpacesAndCapitalize = (inputString : string) => {
    // Replace underscores with spaces
    const words = inputString.replace(/_/g, ' ').split(' ');
  
    // Capitalize the first letter of each word
    const capitalizedWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  
    // Join capitalized words back into a string
    return capitalizedWords.join(' ');
  }

  const onHideModal = () => {
    setShowModal(false);
  }

  const onHideEditModal = () => {
    setEditModal(false);
  }


  // launch when create user success
  const onSuccessSubmit = (values: any) => {
    setShowModal(false)
    setExternalProviders(null)
    setTimeout(() => {
      getExternalProviders()
    }, 1000);
  }

  // launch when create user success
  const onSuccessEditSubmit = (values: any) => {
    setEditModal(false)
    setExternalProviders(null)
    setTimeout(() => {
      getExternalProviders()
    }, 1000);
  }


  const handlePagination = async (pageObject: any) => {
      if(pageObject.selected < 0){
        return;
      }
      setCurrentPage(pageObject.selected);
      await getExternalProviders(pageObject.selected, pageSize);
  }

  const handlePerPage = async (pageSizeObject: any) => {
    setPageSize(pageSizeObject.value);
    setCurrentPage(0);

    await getExternalProviders(0, pageSizeObject.value);
  }

  const handleSearch = (value: string) => {
    setSearchValue(value);
    getExternalProviders(0, pageSize, undefined, value);
  }

  const updateFilter = (filter: ExternalProvidersFilters) => {
    if(filter.providers != null && filter.providers.length > 0 || filter.campaignId != null && filter.campaignId.length > 0 || filter.programme_ids != null && filter.programme_ids.length > 0 || filter.between_dates != null){
      addButtons(true);
    }else{
      addButtons(false)
    }

    setFilters(filter);
    setCurrentPage(0);
  }

  const deleteExternalProviders = async () => {
    let confirmed = await layout.confirmAction({ title: "Delete Third-party data", description: "Are you sure you want delete this third-party data" });

    if (confirmed) {

      let deleteResponse = (await (new ExternalProviderService()).deleteExternalProviders({ ids: selectedRows }));
      let deleteResponseData = deleteResponse.getResponseData();

      if (deleteResponseData.success == false) {
        layout.toast?.error(`Error delenting third-party data: ${deleteResponseData.message}`)
      }

      setExternalProviders([]);
      setSelectedRows([]);
      getExternalProviders();

    }
  }

  const handleSelectAll = () => {
    if(selectAll){
      setSelectedRows([]);
      setSelectAll(false);
    }else{
      let allIds = externalProviders?.map((externalProvider : any) => externalProvider.id);
      setSelectedRows(allIds ?? []);
      setSelectAll(true);
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>List</PageTitle>
      <CreateExternalProviderModal show={showModal} onHideModal={onHideModal} onSuccessSubmit={onSuccessSubmit}></CreateExternalProviderModal>
      <EditExternalProviderModal show={editModal} onHideModal={onHideEditModal} onSuccessSubmit={onSuccessEditSubmit} externalProvider={editExternalProviderObject}></EditExternalProviderModal>

      <CustomDrawer title="Filters" anchor="right" open={openFilterDrawer} onClose={handleClose}>
        <ExternalProviderListFilters setFilters={updateFilter} filters={filters} />
      </CustomDrawer>

      {
        externalProviders === null ?
          (
            <div className="text-justify-content-md-center">
              <span className='indicator-progress text-center' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </div>

          )
          :
          (
            <>
              <TablesWidget9 className="card-primary" title="Third-party data"
                subtitle="List of third-party data"
                searchInput={{ placeholder: "Search...", onSearch: handleSearch }}
                headerButton={
                selectedRows.length > 0  ? (
                  <div className='me-0'>
                    <button
                      className='btn btn-danger'
                      onClick={deleteExternalProviders}
                    >
                      Delete Selected
                    </button>
                  </div>
                ) : <></>
                }
                data={externalProviders}
                selectableItems={true}
                onSelectAllItems={handleSelectAll}
                onChangeCheckedItems={handleCheckboxChange}
                pagination={true}
                paginationData={{
                  pageSize: pageSize, pageCount: pageCount, currentPage: currentPage,
                  handlePagination: handlePagination, handlePerPage: handlePerPage
                }}
                columns={[
                  {
                    name: "Name", sortable: true, keyValue: 'metricName', className: "min-w-150px",
                    sortColumn: (keyValue: string, order: "asc" | "desc") => { getExternalProviders(currentPage, pageSize, { field: keyValue, order: order }); },
                    render: (element: any) => {
                      return (
                        <>
                          <div className='text-dark fw-bolder d-block fs-6'>
                            {replaceUnderscoresWithSpacesAndCapitalize(element.metricName)}
                          </div>
                        </>
                      );
                    }
                  },
                  {
                    name: "Value", sortable: true, keyValue: 'metricValue', className: "min-w-150px",
                    sortColumn: (keyValue: string, order: "asc" | "desc") => { getExternalProviders(currentPage, pageSize, { field: keyValue, order: order }); },
                    render: (element: any) => {
                      return (
                        <>
                          <div className='text-dark fw-bolder d-block fs-6'>
                            {element.metricValue}
                          </div>
                        </>
                      );
                    }
                  },
                  {
                    name: "Date", sortable: true, keyValue: 'metricDate', className: "min-w-150px",
                    sortColumn: (keyValue: string, order: "asc" | "desc") => { getExternalProviders(currentPage, pageSize, { field: keyValue, order: order }); },
                    render: (element: any) => {
                      let formatDate = moment(element.metricDate.date);
                      return (
                        <div className='d-flex justify-content-center align-items-center'>
                            <span className='text-muted me-2 fs-6 fw-bold'>{formatDate.format('YYYY-MM-DD HH:mm')}</span>
                        </div>
                      );
                    }
                  },
                  { 
                    name: "Programme ID", sortable: true, keyValue: 'programmeId', className: "min-w-150px",
                    sortColumn: (keyValue: string, order: "asc" | "desc") => { getExternalProviders(currentPage, pageSize, { field: keyValue, order: order }); },
                    render: (element: any) => {
                      return (
                        <>
                          <div className='text-dark fw-bolderd-block fs-6'>
                            {element.programmeId}
                          </div>
                        </>
                      )
                    }

                  },
                  { name: "Campaign ID", sortable: true, keyValue: 'campaignId', className: "min-w-150px",
                    sortColumn: (keyValue: string, order: "asc" | "desc") => { getExternalProviders(currentPage, pageSize, { field: keyValue, order: order }); },
                    render: (element: any) => {
                      return (
                        <>
                          <div className='text-dark fw-bolder d-block fs-6'>
                            {element.campaignId}
                          </div>
                        </>
                      )
                    }

                  },
                  { name: "Provider", keyValue: 'provider', className: "text-center",
                    render: (element: any) => {
                      return (
                        <>
                          <div className='text-dark fw-bolder d-block fs-6'>
                            {replaceUnderscoresWithSpacesAndCapitalize(element.provider)}
                          </div>
                        </>
                      )
                    }

                  },
                  { name: "Actions", className: "min-w-100px text-end", isActionCell: true }
                ]}
                actions={[
                  {
                    title: "Edit",
                    buttonType: "normal",
                    iconPath: "/media/icons/duotune/art/art005.svg",
                    description: "Edit third-party data",
                    callback: (element: any) => {
                      editExternalProvider(element);
                    }
                  },
                  {
                    title: "Delete",
                    buttonType: "normal",
                    iconPath: "/media/icons/duotune/general/gen027.svg",
                    description: "Remove third-party data",
                    additionalClasses: "btn-danger",
                    callback: (element: any) => {
                      deleteExternalProvider(element);
                    }
                  }
                ]}
              ></TablesWidget9>
            </>
          )
      }
    </>
  );
}