import React, { createContext, useState, useContext, useEffect } from "react";
import { ExternalProviderService } from "app/services/external_povider/ExternalProviderService";

type ExternalProviderContextType = {
  providers: { label: string; value: string }[];
  campaignIds: { label: string; value: string }[];
  programmesIds: { label: string; value: string }[];
};

const ExternalProviderContext = createContext<ExternalProviderContextType>({
  providers: [],
  campaignIds: [],
  programmesIds: [],
});

const ExternalProviderProvider: React.FC = ({ children }) => {
  const [providers, setProviders] = useState([]);
  const [campaignIds, setCampaignIds] = useState([]);
  const [programmesIds, setProgrammesIds] = useState([]);

  const getProviders = async () => {
    const data = (await new ExternalProviderService().getProviders()).getOnlyData();
    const providers = data.map((provider : any) => ({
      label: provider.provider,
      value: provider.provider,
    }));
    setProviders(providers);
  };

  const getCampaignIds = async () => {
    const data = (await new ExternalProviderService().getCampaignsIDs()).getOnlyData();
    const ids = data.map((campaign : any) => ({
      label: campaign.campaignId,
      value: campaign.campaignId,
    }));
    setCampaignIds(ids);
  };

  const getProgrammesIds = async () => {
    const data = (await new ExternalProviderService().getProgrammesIDs()).getOnlyData();
    const ids = data.map((programme : any) => ({
      label: programme.programmeId,
      value: programme.programmeId,
    }));
    setProgrammesIds(ids);
  }

  useEffect(() => {
    getProviders();
    getCampaignIds();
    getProgrammesIds();
  }, []);

  return (
    <ExternalProviderContext.Provider value={{ providers, campaignIds, programmesIds }}>
      {children}
    </ExternalProviderContext.Provider>
  );
};

export default ExternalProviderProvider;

export const useExternalProviders = () => {
  return useContext(ExternalProviderContext);
};