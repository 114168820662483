
const KEY = "cor2ed_ARP";
/**
 * funcion para meter estado inicial
 * @returns 
 */
export function loadState() {
  try {
    const serializedState = localStorage.getItem(KEY);
    if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}
/**
 * función para guardar el estado en local storage
 * @param {*} state 
 */
export async function saveState(state: any) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(KEY, serializedState);
  } catch (e) {
    // Ignore
  }
}