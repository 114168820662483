import { MetricService } from "app/services/metric/MetricService";
import { MetricUtil } from "app/services/metric/MetricUtil";
import React, { useEffect, useState } from "react";
import { Metric, ReachMetric } from "../../../components/metrics/ReachMetric";
import { useDataReport } from "../../../DataReportProvider";
import { metrics } from "app/utils/data/metrics";
import { updateLoadedMetrics } from "app/store/reducers/metric_reducer";
import { useDispatch } from "react-redux";
import { getLoadedMetricById } from "app/utils/utils";

const ImpactVirtualMeeting: React.FC = () => {

    const dataReport = useDataReport()
    const dispatch = useDispatch();

    const [totalMaterialDownloads, setTotalMaterialDownloads] = useState<Metric[] | null>(null)

    const getTotalMaterialDownloads = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.totalMaterialDownloads.endpoint)).getResponseData();
        if (response.success === true) {
            setTotalMaterialDownloads(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.totalMaterialDownloads.id, data: response.data }));
        }
    }

    /** 
     * To calculate amount and percent of latest "n" periods, we need to modify date filtered before 
     * make request. We need to do that because the widget needs to retrive last 24
     * times periods before the requested date to calculate the amount 
    */
    const getMetrics = () => {

        const calculateStartDate = MetricUtil.calculateStartDate(dataReport.params.filter_filters.between_dates.from, dataReport.params.filter_filters.mode);

        const modifiedParams = {
            ...dataReport.params,
            filter_filters: {
                ...dataReport.params.filter_filters,
                between_dates: {
                    from: calculateStartDate,
                    to: dataReport.params.filter_filters.between_dates.to
                }
            }
        };

        let total_material_downloads = getLoadedMetricById(metrics.totalMaterialDownloads.id);

        if (total_material_downloads !== null && total_material_downloads !== undefined) {
            setTotalMaterialDownloads(total_material_downloads);
        } else {
            getTotalMaterialDownloads(modifiedParams);
        }
    }

    const resetReports = () => {
        setTotalMaterialDownloads(null);
    }


    useEffect(() => {
        resetReports();
        getMetrics();
    }, [dataReport.params])


    return (
        <>
            <div className='row g-5 g-xl-8' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                <div className="col-xl-12">
                    <hr></hr>
                </div>
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/general/' + metrics.totalMaterialDownloads.logo}
                        snapshotId={metrics.totalMaterialDownloads.id}
                        title={metrics.totalMaterialDownloads.title}
                        subtitle={metrics.totalMaterialDownloads.subtitle}
                        startPeriod={24}
                        metrics={totalMaterialDownloads}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
            </div>
        </>
    )
}


export default ImpactVirtualMeeting;