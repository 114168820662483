import { Campaign, Programme, TherapeuticArea, Wave } from "app/models/programmes/Programme";
import { ProgrammeService } from "app/services/programme/ProgrammeService";
import { useEffect, useState } from "react";
import { MultiValue, SingleValue } from "react-select";
import { useProgrammeExplorer } from "../ProgrammeExplorerProvider";
import toastr from "toastr";
import { ConnectService } from "app/services/connect/ConnectService";
import { RangeDate } from "@components/form/CustomDateRangePicker";
import moment from "moment";


const useProgrammeExplorerHeader = () => {

    const [selectedProjectCode, setSelectedProjectCode] = useState<string | null>(null);
    const [projectCodes, setProjectCodes] = useState<string[]>([]);

    const [betweenDates, setBetweenDates] = useState<any>(null);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    const [target, setTarget] = useState<string>('');

    const [connects, setConnects] = useState([]);
    const [selectedConnects, setSelectedConnects] = useState<MultiValue<unknown>>([]);

    const [programmes, setProgrammes] = useState<Programme[]>([]);
    const [selectedProgrammes, setSelectedProgrammes] = useState<Programme[]>([]);

    const [selectedWaves, setSelectedWaves] = useState<MultiValue<unknown>>([]);
    const [waves, setWaves] = useState<Wave[]>([]);
    const [waveOptions, setWaveOptions] = useState<MultiValue<unknown>>([]);

    const ProgrammeExplorer = useProgrammeExplorer();

    const targetOptions = [
        { value: 'overall', label: 'Overall' },
        { value: 'connect', label: 'Connect' },
        { value: 'project', label: 'Project' },
    ];

    useEffect(() => {
        getProjectCodes()
        getConnects()
    }, []);

    useEffect(() => {
        resetProgrammes()
        resetWaves()
        setBetweenDates(null)
        if (selectedProjectCode !== null) {
            getProgrammesByProjectCode(selectedProjectCode)
        }

    }, [selectedProjectCode]);

    useEffect(() => {
        resetWaves()
        if (programmes.length > 0) {
            getProgrammeDates(programmes);
        }
    }, [programmes])



    useEffect(() => {
        if (selectedProgrammes.length === 1) {
            getProgrammeWaves(selectedProgrammes[0]);
        } else {
            setWaves([]);
            setWaveOptions([])
        }
    }, [selectedProgrammes])

    useEffect(() => {
        setWaveOptions([]);
        if (waves.length > 0) {
            formatWaveOptions(waves);
        }

    }, [waves])


    const resetProgrammes = () => {
        setProgrammes([])
        setSelectedProgrammes([])
    }

    const resetWaves = () => {
        setWaves([])
        setSelectedWaves([])
    }

    const onChangeWaves = (selected: MultiValue<unknown>): void => {
        setSelectedWaves(selected);
    }

    const getProgrammesByProjectCode = async (projectCode: string) => {
        setLoading(true);
        let response = (await (new ProgrammeService().search(
            {
                filter_filters: {
                    project_code: projectCode,
                }
            }
        ))).getResponseData();

        if (response.success === false) return;

        setProgrammes(response.data);
        ProgrammeExplorer.updateProgrammeExplorerProgrammes(response.data);
        setLoading(false);
    }


    const getProgrammeValues = (): MultiValue<unknown> => {
        return programmes.map((programme: Programme) => {
            return {
                value: programme.id,
                label: `${programme.projectCode}: ${programme.name} [${programme.cmsId}]`
            }
        })
    }


    /**
     * When user select specific programmes, we need to recover the waves only if the user select one.
     */
    const onChangeProgrammes = (newValue: MultiValue<unknown>) => {
        setLoading(true);
        try {
            let programmeValues = newValue.map((value: any) => value.value);
            let programmesToFilter = programmes.filter((programme: Programme) => programmeValues.includes(programme.id));

            // If theres not programmes selected we check the programmes and if theres programmes saved in state we filter them
            if (programmeValues.length === 0 && programmes.length > 0) {
                programmesToFilter = programmes.map((programme: Programme) => programme);
            }

            setSelectedProgrammes(programmesToFilter);

            let firstPublicationDate = moment(programmesToFilter.sort((a: Programme, b: Programme) => {
                return moment(a.publishedAt?.date).diff(moment(b.publishedAt?.date))
            })[0].publishedAt.date).format('YYYY-MM-DD');

            setBetweenDates({
                from: firstPublicationDate,
                to: moment().format('YYYY-MM-DD')
            });
        } catch (error) {
            console.error("Error selecting programmes", error);
        } finally {
            setLoading(false);
        }
    }



    // Get defined date from y date to of programmes
    const getProgrammeDates = async (programmes: Programme[]) => {
        let response = (await (new ProgrammeService().searchDatesBetweenProgrammes({
            filter_filters: {
                programmes: programmes.map((programme: Programme) => programme.id),
            },
        }))).getResponseData();

        if (response == undefined || response?.success === false) {
            toastr.warning(response?.message)
            setBetweenDates({
                from: moment().startOf('month').format('YYYY-MM-DD'),
                to: moment().endOf('month').format('YYYY-MM-DD')
            });
            return
        };

        const { from, to } = response.data;

        setBetweenDates({
            from,
            to
        });
    }

    const getProjectCodes = async () => {
        let response = (await (new ProgrammeService().searchProjectCode({
            filter_filters: {
                search: "",
            },
        }))).getResponseData();

        if (response.success === false) return;

        let projectCodeOptions = response.data.map((projectCode: string) => {
            return {
                value: projectCode,
                label: projectCode
            }
        });

        setProjectCodes(projectCodeOptions);

    }


    const onChangeProgrammeProjectCode = (newValue: SingleValue<unknown>) => {
        // Recover the project code selected value and update the status
        let programmeSelected: any = newValue
        setSelectedProjectCode(programmeSelected.value);
    }


    const onChangeConnects = (newValue: MultiValue<unknown>) => {
        let connects = newValue.map((value: any) => value.value);
        setSelectedConnects(connects as never[]);
    }


    const onFixedToggle = (fixed: boolean) => {
        const header = document.getElementById('kt_header');

        if (fixed === true) {
            if (!header?.classList.contains('header-to-back')) {
                header?.classList.add('header-to-back');
            }
        }

        if (fixed === false) {
            if (header?.classList.contains('header-to-back')) {
                header?.classList.remove('header-to-back');
            }
        }
    }

    const getProgrammeWaves = async (programme: Programme) => {
        
        let waves = programme.campaigns.reduce((accumulator: Wave[], currentValue: Campaign) => {

            let indexInAccumulator = accumulator.findIndex((wave: Wave) => wave.label === `${currentValue.code}-${currentValue.wave}`);

            if (indexInAccumulator !== -1) {
                accumulator[indexInAccumulator].campaigns.push(currentValue);
                return accumulator;
            }

            let newWave: Wave = {
                name: currentValue.wave,
                label: `${currentValue.code}-${currentValue.wave}`,
                value: currentValue.wave,
                code: currentValue.code,
                campaigns: [currentValue]
            }

            return [...accumulator, newWave];
        }, []);

        setWaves(waves)
    }

    const formatWaveOptions = (waves: Wave[]) => {
        let waveOptions: MultiValue<unknown> = waves.map((wave: Wave) => {
            return {
                value: wave.campaigns.map((campaign: Campaign) => campaign.id),
                label: wave.label
            }
        })
        setWaveOptions(waveOptions);
    }

    const applyFilters = () => {

        let apllyFilters = {};

        if (target === 'project') {

            if (!selectedProjectCode) {
                toastr.warning("Please select a project code");
                return
            }

            let programmesToFilter: Programme[]


            programmesToFilter = programmes.map((programme: Programme) => programme)

            if(selectedProgrammes.length > 0) {
                programmesToFilter = selectedProgrammes.map((programme: Programme) => programme)
            }

            // Filter therapeutic areas of programme filtered
            let allTherapeuticAreasToFilter: number[] = programmesToFilter.reduce(
                (accumulator: number[], currentValue: Programme) => {
                    let therapeuticAreaIds = currentValue.therapeuticAreas.map((therapeuticArea: TherapeuticArea) => therapeuticArea.therapeutic_area_id)
                    return [...accumulator, ...therapeuticAreaIds]
                }, []
            );

            // reduce the array to unique values
            let therapeuticAreasToFilter = allTherapeuticAreasToFilter.reduce((accumulator: number[], currentValue: number) => {
                if (accumulator.includes(currentValue))
                    return accumulator

                return [...accumulator, currentValue]
            }, []);

            let campaignGroups = selectedWaves.map((wave: any) => wave.value)
            let campaignIds = campaignGroups.reduce((accumulator: number[], currentValue: number[]) => {
                return [...accumulator, ...currentValue]
            }, []);
            apllyFilters = {
                programmes: programmesToFilter.map((programme: Programme) => programme.id),
                connects: [],
                therapeutic_areas: therapeuticAreasToFilter,
                campaigns: campaignIds,
                // Update the fiter with selected campaigns realted to selected wave.
                between_dates: betweenDates
            };
        } else if (target === 'connect') {

            if (selectedConnects === null || selectedConnects.length === 0) {
                toastr.warning("Please select one or more connects");
                return
            }

            apllyFilters = {
                connects: selectedConnects.map((connect: any) => connect),
                campaigns: [],
                programmes: [],
                therapeutic_areas: [],
                between_dates: betweenDates
            }
        } else {
            apllyFilters = {
                connects: [],
                campaigns: [],
                programmes: [],
                therapeutic_areas: [],
                between_dates: betweenDates
            }   
    }
        ProgrammeExplorer.updateFilters({
            ...apllyFilters
        });
    }

    const getConnects = async () => {
        let response = (await (new ConnectService().search({ filter_order: [{ field: 'name', order: 'asc' }] }))).getResponseData();
        if (response.success) {
            // parse connect to select
            let connects = response.data.map((element: any) => ({ value: element.id, label: element.name }))
            setConnects(connects);
        } else {
            console.error("Error getting available connects.")
        }
    }

    const onChangeDate = (range: RangeDate) => {
        if (range.startDate && range.endDate) {
            setBetweenDates({
                from: range.startDate,
                to: range.endDate
            })
        }
    }

    const onChangeTarget = (newValue: SingleValue<unknown>) => {
        let targetSelected: any = newValue
        setTarget(targetSelected.value);
        if (target == null || target === '') {
            setBetweenDates({
                from: moment().startOf('month').format('YYYY-MM-DD'),
                to: moment().endOf('day').format('YYYY-MM-DD')
            });
        }
    }


    return {
        target,
        isOpened,
        loading,
        onChangeProgrammeProjectCode,
        programmes,
        getProgrammeValues,
        onChangeProgrammes,
        selectedProgrammes,
        betweenDates,
        waveOptions,
        applyFilters,
        projectCodes,
        onChangeWaves,
        selectedWaves,
        onFixedToggle,
        targetOptions,
        onChangeTarget,
        connects,
        onChangeConnects,
        setIsOpened,
        onChangeDate    
    }
}


export default useProgrammeExplorerHeader;