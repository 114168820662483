import React from "react";
import {type PEData, PEDonutData} from "@components/chart/types";
import Chart from "react-apexcharts";


export interface ProgrammeExplorerDonutChartProps {
  data: PEDonutData[]
}
const ProgrammeExplorerDonutChart: React.FC<ProgrammeExplorerDonutChartProps> = ({data, children}) => {

  const labels = data.map((element: PEDonutData) => element.name);
  const values = data.map((element: PEDonutData) => element.data);
  const colors = data.map((element: PEDonutData) => element.color);

  return (
    <>
      {
        data.length > 0 ?
          (<Chart
            type={'donut'}
            height={300}
            series={values}
            options={{
              chart: {
                id: 'realtime',
                height: 150,
                zoom: {
                  enabled: false
                },
                animations: {
                  enabled: true,
                  easing: 'linear',
                  dynamicAnimation: {
                    speed: 1000
                  }
                },
                toolbar: {
                  show: false
                },
              },
              colors: colors,
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      total: {
                        show: true,
                        fontSize: "25",
                        color: "#587d99",
                        fontWeight: "bold"
                      }
                    },
                  }
                }
              },
              labels: labels,
              dataLabels: {
                enabled: true,
              },
              stroke: {
                curve: 'smooth',
                colors: ['primary']
              },
              fill: {
                opacity: 1,
                type: ['solid'],
              },
              markers: {
                size: 0
              },
              grid: {
                show: false
              },
              legend: {
                show: true,
                position: 'bottom'
              }
            }}>
          </Chart>)
          : null
      }

      {children}
    </>
  )
}

export default ProgrammeExplorerDonutChart