import { MetricService } from "app/services/metric/MetricService";
import { MetricUtil } from "app/services/metric/MetricUtil";
import React, { useEffect, useState } from "react";
import { Metric, ReachMetric } from "../../../components/metrics/ReachMetric";
import { useDataReport } from "../../../DataReportProvider";
import { metrics } from "app/utils/data/metrics";
import { useDispatch } from "react-redux";
import { updateLoadedMetrics } from "app/store/reducers/metric_reducer";
import { getLoadedMetricById } from "app/utils/utils";

const EngagementWebsite: React.FC = () => {

    const [returningVisits, setReturningVisits] = useState<Metric[] | null>(null)
    const [returningVisitors, setReturningVisitors] = useState<Metric[] | null>(null)
    const [pageViews, setPageViews] = useState<Metric[] | null>(null)
    const [sessionDuration, setSessionDuration] = useState<Metric[] | null>(null)
    const [engagedSessions, setEngagedSessions] = useState<Metric[] | null>(null)
    const [engagedRate, setEngagedRate] = useState<Metric[] | null>(null)
    const [searchesGA, setSearchesGA] = useState<Metric[] | null>(null)
    const [searchesCMS, setSearchesCMS] = useState<Metric[] | null>(null)
    const [totalFeedbackCompleted, setTotalFeedbackCompleted] = useState<Metric[] | null>(null)
    const [uniqueFeedbackCompleted, setUniqueFeedbackCompleted] = useState<Metric[] | null>(null)
    const [totalFeedbackCompletedGA, setTotalFeedbackCompletedGA] = useState<Metric[] | null>(null)
    const [uniqueFeedbackCompletedGA, setUniqueFeedbackCompletedGA] = useState<Metric[] | null>(null)
    const [bounceRatePercentage, setBounceRatePercentage] = useState<Metric[] | null>(null)

    const dataReport = useDataReport()
    const dispatch = useDispatch();

    const getReturningVisits = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.returningVisits.endpoint)).getResponseData();
        if (response.success === true) {
            setReturningVisits(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.returningVisits.id, data: response.data }));
        }
    }

    const getReturningUsers = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.returningVisitors.endpoint)).getResponseData();
        if (response.success === true) {
            setReturningVisitors(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.returningVisitors.id, data: response.data }));
        }
    }

    const getPageViews = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.webpageViews.endpoint)).getResponseData();
        if (response.success === true) {
            setPageViews(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.webpageViews.id, data: response.data }));
        }
    }

    const getSessionDuration = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.avgSessionDurationByUsers.endpoint)).getResponseData();
        if (response.success === true) {
            setSessionDuration(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.avgSessionDurationByUsers.id, data: response.data }));
        }
    }

    const getEngagedSessions = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.engagedSessions.endpoint)).getResponseData();
        if (response.success === true) {
            setEngagedSessions(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.engagedSessions.id, data: response.data }));
        }
    }

    const getEngagedRate = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.engagementRate.endpoint)).getResponseData();
        if (response.success === true) {
            setEngagedRate(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.engagementRate.id, data: response.data }));
        }
    }

    const getSearchesGA = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.searchesNumberGA.endpoint)).getResponseData();
        if (response.success === true) {
            setSearchesGA(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.searchesNumberGA.id, data: response.data }));
        }
    }

    const getSearchesCMS = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.searchesNumberCMS.endpoint)).getResponseData();
        if (response.success === true) {
            setSearchesCMS(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.searchesNumberCMS.id, data: response.data }));
        }
    }

    const getTotalFeedbackCompletedGA = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.feedbackCompletedGA.endpoint)).getResponseData();
        if (response.success === true) {
            setTotalFeedbackCompletedGA(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.feedbackCompletedGA.id, data: response.data }));
        }
    }

    const getUniqueFeedbackCompletedGA = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.uniqueFeedbackCompletedGA.endpoint)).getResponseData();
        if (response.success === true) {
            setUniqueFeedbackCompletedGA(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.uniqueFeedbackCompletedGA.id, data: response.data }));
        }
    }

    const getTotalFeedbackCompleted = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.totalFeedbackCompleted.endpoint)).getResponseData();
        if (response.success === true) {
            setTotalFeedbackCompleted(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.totalFeedbackCompleted.id, data: response.data }));
        }
    }

    const getUniqueFeedbackCompleted = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.uniqueFeedbackCompleted.endpoint)).getResponseData();
        if (response.success === true) {
            setUniqueFeedbackCompleted(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.uniqueFeedbackCompleted.id, data: response.data }));
        }
    }

    const getBounceRatePercentage = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.bounceRatePercentage.endpoint)).getResponseData();
        if (response.success === true) {
            setBounceRatePercentage(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.bounceRatePercentage.id, data: response.data }));
        }
    }



    /** 
     * To calculate amount and percent of latest "n" periods, we need to modify date filtered before 
     * make request. We need to do that because the widget needs to retrive last 24
     * times periods before the requested date to calculate the amount 
    */
    const getMetrics = () => {

        const calculateStartDate = MetricUtil.calculateStartDate(dataReport.params.filter_filters.between_dates.from, dataReport.params.filter_filters.mode);

        const modifiedParams = {
            ...dataReport.params,
            filter_filters: {
                ...dataReport.params.filter_filters,
                between_dates: {
                    from: calculateStartDate,
                    to: dataReport.params.filter_filters.between_dates.to
                }
            }
        };

        let returning_visits = getLoadedMetricById(metrics.returningVisits.id);
        let returning_users = getLoadedMetricById(metrics.returningVisitors.id);
        let page_views = getLoadedMetricById(metrics.webpageViews.id);
        let sessions_duration = getLoadedMetricById(metrics.avgSessionDurationByUsers.id);
        let engaged_sessions = getLoadedMetricById(metrics.engagedSessions.id);
        let engaged_rate = getLoadedMetricById(metrics.engagementRate.id);
        let searchesGA = getLoadedMetricById(metrics.searchesNumberGA.id);
        let searchesCMS = getLoadedMetricById(metrics.searchesNumberCMS.id);
        let total_feedback_completed_GA = getLoadedMetricById(metrics.feedbackCompletedGA.id);
        let unique_feedback_completed_GA = getLoadedMetricById(metrics.uniqueFeedbackCompletedGA.id);
        let total_feedback_completed = getLoadedMetricById(metrics.totalFeedbackCompleted.id);
        let unique_feedback_completed = getLoadedMetricById(metrics.uniqueFeedbackCompleted.id);
        let bounce_rate_percentage = getLoadedMetricById(metrics.bounceRatePercentage.id);


        if (returning_visits !== null && returning_visits !== undefined) {
            setReturningVisits(returning_visits);
        } else {
            getReturningVisits(modifiedParams);
        }

        if (returning_users !== null && returning_users !== undefined) {
            setReturningVisitors(returning_users);
        } else {
            getReturningUsers(modifiedParams);
        }

        if (page_views !== null && page_views !== undefined) {
            setPageViews(page_views);
        } else {
            getPageViews(modifiedParams);
        }

        if (sessions_duration !== null && sessions_duration !== undefined) {
            setSessionDuration(sessions_duration);
        } else {
            getSessionDuration(modifiedParams);
        }

        if (engaged_sessions !== null && engaged_sessions !== undefined) {
            setEngagedSessions(engaged_sessions);
        } else {
            getEngagedSessions(modifiedParams);
        }

        if (engaged_rate !== null && engaged_rate !== undefined) {
            setEngagedRate(engaged_rate);
        } else {
            getEngagedRate(modifiedParams);
        }

        if (searchesGA !== null && searchesGA !== undefined) {
            setSearchesGA(searchesGA);
        } else {
            getSearchesGA(modifiedParams);
        }

        if (searchesCMS !== null && searchesCMS !== undefined) {
            setSearchesCMS(searchesCMS);
        } else {
            getSearchesCMS(modifiedParams);
        }

        if (total_feedback_completed !== null && total_feedback_completed !== undefined) {
            setTotalFeedbackCompleted(total_feedback_completed);
        } else {
            getTotalFeedbackCompleted(modifiedParams);
        }

        if (unique_feedback_completed !== null && unique_feedback_completed !== undefined) {
            setUniqueFeedbackCompleted(unique_feedback_completed);
        } else {
            getUniqueFeedbackCompleted(modifiedParams);
        }

        if (total_feedback_completed_GA !== null && total_feedback_completed_GA !== undefined) {
            setTotalFeedbackCompletedGA(total_feedback_completed);
        } else {
            getTotalFeedbackCompletedGA(modifiedParams);
        }

        if (unique_feedback_completed_GA !== null && unique_feedback_completed_GA !== undefined) {
            setUniqueFeedbackCompletedGA(unique_feedback_completed);
        } else {
            getUniqueFeedbackCompletedGA(modifiedParams);
        }

        if (bounce_rate_percentage !== null && bounce_rate_percentage !== undefined) {
            setBounceRatePercentage(bounce_rate_percentage);
        } else {
            getBounceRatePercentage(modifiedParams);
        }

    }

    const resetReports = () => {
        setReturningVisits(null);
        setReturningVisitors(null);
        setPageViews(null);
        setSessionDuration(null);
        setEngagedSessions(null);
        setEngagedRate(null);
        setSearchesGA(null);
        setSearchesCMS(null);
        setUniqueFeedbackCompleted(null);
        setTotalFeedbackCompleted(null);
        setBounceRatePercentage(null);
        setUniqueFeedbackCompletedGA(null);
        setTotalFeedbackCompletedGA(null);
    }


    useEffect(() => {
        resetReports();
        getMetrics();
    }, [dataReport.params])


    return (
        <>
            <div className='row g-5 g-xl-8' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                <div className="col-xl-12">
                    <hr></hr>
                </div>
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.returningVisits.logo}
                        snapshotId={metrics.returningVisits.id}
                        title={metrics.returningVisits.title}
                        subtitle={metrics.returningVisits.subtitle}
                        startPeriod={24}
                        metrics={returningVisits}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.returningVisitors.logo}
                        snapshotId={metrics.returningVisitors.id}
                        title={metrics.returningVisitors.title}
                        subtitle={metrics.returningVisitors.subtitle}
                        startPeriod={24}
                        metrics={returningVisitors}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.webpageViews.logo}
                        snapshotId={metrics.webpageViews.id}
                        title={metrics.webpageViews.title}
                        subtitle={metrics.webpageViews.subtitle}
                        startPeriod={24}
                        metrics={pageViews}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.bounceRatePercentage.logo}
                        snapshotId={metrics.bounceRatePercentage.id}
                        title={metrics.bounceRatePercentage.title}
                        subtitle={metrics.bounceRatePercentage.subtitle}
                        startPeriod={24}
                        metrics={bounceRatePercentage}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                        valueType='percent'
                    />
                </div>
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.avgSessionDurationByUsers.logo}
                        snapshotId={metrics.avgSessionDurationByUsers.id}
                        title={metrics.avgSessionDurationByUsers.title}
                        subtitle={metrics.avgSessionDurationByUsers.subtitle}
                        startPeriod={24}
                        metrics={sessionDuration}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.engagedSessions.logo}
                        snapshotId={metrics.engagedSessions.id}
                        title={metrics.engagedSessions.title}
                        subtitle={metrics.engagedSessions.subtitle}
                        startPeriod={24}
                        metrics={engagedSessions}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.engagementRate.logo}
                        snapshotId={metrics.engagementRate.id}
                        title={metrics.engagementRate.title}
                        subtitle={metrics.engagementRate.subtitle}
                        startPeriod={24}
                        metrics={engagedRate}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                        valueType='percent'
                    />
                </div>
                <div className='col-xl-6 flex-xl-fill mt-0 d-none'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.searchesNumberGA.logo}
                        snapshotId={metrics.searchesNumberGA.id}
                        title={metrics.searchesNumberGA.title}
                        subtitle={metrics.searchesNumberGA.subtitle}
                        startPeriod={24}
                        metrics={searchesGA}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                <div className='col-xl-6 flex-xl-fill mt-0 d-none'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.searchesNumberCMS.logo}
                        snapshotId={metrics.searchesNumberCMS.id}
                        title={metrics.searchesNumberCMS.title}
                        subtitle={metrics.searchesNumberCMS.subtitle}
                        startPeriod={24}
                        metrics={searchesCMS}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.feedbackCompletedGA.logo}
                        snapshotId={metrics.feedbackCompletedGA.id}
                        title={metrics.feedbackCompletedGA.title}
                        subtitle={metrics.feedbackCompletedGA.subtitle}
                        startPeriod={24}
                        metrics={totalFeedbackCompletedGA}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.uniqueFeedbackCompletedGA.logo}
                        snapshotId={metrics.uniqueFeedbackCompletedGA.id}
                        title={metrics.uniqueFeedbackCompletedGA.title}
                        subtitle={metrics.uniqueFeedbackCompletedGA.subtitle}
                        startPeriod={24}
                        metrics={uniqueFeedbackCompletedGA}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.totalFeedbackCompleted.logo}
                        snapshotId={metrics.totalFeedbackCompleted.id}
                        title={metrics.totalFeedbackCompleted.title}
                        subtitle={metrics.totalFeedbackCompleted.subtitle}
                        startPeriod={24}
                        metrics={totalFeedbackCompleted}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.uniqueFeedbackCompleted.logo}
                        snapshotId={metrics.uniqueFeedbackCompleted.id}
                        title={metrics.uniqueFeedbackCompleted.title}
                        subtitle={metrics.uniqueFeedbackCompleted.subtitle}
                        startPeriod={24}
                        metrics={uniqueFeedbackCompleted}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                    />
                </div>
            </div>
        </>
    )
}


export default EngagementWebsite;