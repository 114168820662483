import { FC } from 'react'
import { toAbsoluteUrl } from '_metronic/helpers'

const Unathorized: FC = () => {
    return (
        <>
            <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>
                <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/icons/duotune/general/gen051.svg')}
                    className='h-50px mb-5 me-2'
                />
                Unathorized</h1>

            <div className='fw-bold fs-3 text-gray-400 mb-15'>
                You don't have permissions to access this section! <br /> Please try with another account or go back to home.
            </div>
        </>
    )
}

export { Unathorized }
