import moment from "moment"
import React, { createContext, useContext, useEffect, useState } from "react"
import _ from 'lodash'
import { useDispatch } from "react-redux";
import { resetLoadedMetrics } from "app/store/reducers/metric_reducer";
import { MetricService } from "app/services/metric/MetricService";

export type MarketingFilterMode = 'months' | 'years' | 'weeks' | 'days' | 'Q';

export type MarketingFilters = {
    between_dates: BetweenDatesFilter
    mode: MarketingFilterMode
    group: string
    selected_group : string
    therapeutic_areas_ids: string[]
    overall: string[]
    connects: string[]
    programmes: string[],
    formats: string[],
    selected_names: string[],
    channel: string,
    source: string,
    medium: string,
}

export type BetweenDatesFilter = {
    from: string,
    to: string
}

export type MarketingFilterParams = {
    filter_filters: MarketingFilters
}

type MarketingContextModel = {
    params: MarketingFilterParams,
    metrics: MarketingMetrics,
    updateFilters: (filters: Partial<MarketingFilters|any>) => void,
    updateMetrics: (metric: string) => void
}

export const defaultFilterParams: MarketingFilterParams = {
    filter_filters: {
        between_dates: {
            from: moment().startOf('month').format('YYYY-MM-DD'),
            to: moment().startOf('day').format('YYYY-MM-DD'),
        },
        group: '',
        selected_group: '',
        therapeutic_areas_ids: [],
        connects: [],
        overall: ['Overall'],
        programmes: [],
        formats: [],
        mode: 'days',
        selected_names: [],
        channel: '',
        source: '',
        medium: '',
    }
}

type MarketingMetrics = string[];

const defaultMarketingMetrics: MarketingMetrics = [];

const MarketingContext = createContext<MarketingContextModel>({
    params: defaultFilterParams,
    metrics: defaultMarketingMetrics,
    updateFilters: (filters: Partial<MarketingFilters>) => {},
    updateMetrics: (metricId: string) => {}
})

const MarketingProvider: React.FC = ({children}) => {

    const [params, setParams] = useState<MarketingFilterParams>(defaultFilterParams)
    const [metrics, setMetrics] = useState<MarketingMetrics>(defaultMarketingMetrics)

    const dispatch = useDispatch();


    const updateFilters = (filters: Partial<MarketingFilters>) => {

        dispatch(resetLoadedMetrics());
        
        let newObject = {
            filter_filters: {
                ...params.filter_filters,
                ...filters
            }
        }

        setParams(newObject)
    }

    const updateMetrics = (metricId: string) => {

        let newMetrics =  metrics;  
        
        if(newMetrics.includes(metricId)) {
            newMetrics = newMetrics.filter((metric) => metric !== metricId);
        } else {
            newMetrics.push(metricId);
        }

        setMetrics(newMetrics);
    }

    const value: MarketingContextModel = {
        params,
        metrics,
        updateFilters,
        updateMetrics,
    }

    const getTotalMetric = async (signal: AbortSignal, metric: string, metricData: string = 'group'): Promise<any> => {
        let modifiedFilters = {
          filter_filters: {
            ...params.filter_filters,
          },
        };
    
        const metricService = new MetricService();
        const response = await metricService.getMetricEndpointData(modifiedFilters, metric, metricData, signal);
        const responseData = response.getResponseData();
        if (responseData.success === false) {
          return 0;
        }
    
        return responseData.data.value;
      };

    return <MarketingContext.Provider value={value}>{children}</MarketingContext.Provider>
}

export {MarketingProvider, MarketingContext}

export function useMarketing() {
    return useContext(MarketingContext);
}
