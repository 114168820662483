import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { LoginService } from '../../../services/auth/LoginService'
import { CognitoUser } from 'amazon-cognito-identity-js'

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
});


const initialValues = {
    email: '',
    password: '',
}

export const LoginForm = ({onLoginSuccess, onLoginError}: any) => {
    
    const [loading, setLoading] = useState(false);

    const onNeedNewPassword = (cognitoUser: CognitoUser, userAttributes: any) => {
        
        onLoginError({
            message: "You have to reset your password.",
            code: "NEW_PASSWORD_REQUIRED",
            cognitoUser,
            userAttributes
        })
    }

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {

            setLoading(true)

            try {

                let loginUserData = await (new LoginService()).loginNormalUser(values.email, values.password, onNeedNewPassword);

                onLoginSuccess(loginUserData);
                setSubmitting(false)
                setLoading(false)

            } catch (error: any) {

                setSubmitting(false)
                setLoading(false)
                onLoginError(error);

            }
        },
    })

    return (
        <form
            autoComplete='off'
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                <input
                    placeholder='Email'
                    aria-autocomplete='none'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                    type='email'
                    name='email'
                    autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.email}</span>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                        {/* begin::Label */}
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                        {/* end::Label */}
                    </div>
                </div>
                <input
                    type='password'
                    placeholder='Password'
                    autoComplete='off'
                    aria-autocomplete='none'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                            'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                            'is-valid': formik.touched.password && !formik.errors.password,
                        }
                    )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                )}

                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg btn-primary w-100 mb-5 mt-5'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>Sign in</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            {/* end::Form group */}
        </form>
    )
}