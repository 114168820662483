import { FC } from 'react'
import { PageTitle } from '_metronic/layout/core'
import { ImportTwitterIndex } from './components/import/ImportTwitterIndex'

const breadcrums = [
  {
    title: 'Administration',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '#',
    isSeparator: true,
    isActive: false
  },
  {
    title: 'Imports',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Import twitter',
    path: '#',
    isSeparator: true,
    isActive: false
  }
]

const ImportTwitterWrapper: FC = () => {

    return (
        <>
            <PageTitle breadcrumbs={breadcrums}>Import Twitter Files</PageTitle>
            <ImportTwitterIndex></ImportTwitterIndex>
        </>

    )
}

export { ImportTwitterWrapper }