import { RestServiceConnection } from "../RestServiceConnection";


export class ContactService extends RestServiceConnection {
    
    getContacts = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/sync/file/list',
            data: filter
        }, true);
        return this;
    }

    getContactById = async (id: string, field: string, filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/sync/file/'+id+'/'+field,
            data: filter
        }, true);
        return this;
    }

    getContactsFile = async () => {
        this.response = await this.makeRequest({
            method: "get",
            url: '/sync/file/export/3',
            responseType: 'blob'
        }, true);
        return this;
    }

}