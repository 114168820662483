
import ConfigRoutes from 'app/routing/ConfigRoutes'
import { FC } from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { PageTitle } from '_metronic/layout/core'
import { CastosImportBase } from './castos/components'
import { ContactsWrapper } from './contacts/ContactsWrapper'
import { SecurityWrapper } from './security/SecurityWrapper'
import { SpotifyImportBase } from './spotify/components'
import { ImportTwitterWrapper } from './twitter/ImportTwitterWrapper'
import { ExternalProvidersImport } from './external-providers'
import { VimeoImportBase } from './vimeo/components'

const ImportsWrapper: FC = () => {

    return (
        <>
            <Routes>

                <Route element={<> <Outlet /><PageTitle>Import Twitter Stat Files</PageTitle> </>}>
                    <Route path={ConfigRoutes.admin.imports.twitter.principal} element={ <ImportTwitterWrapper/> } />
                </Route>

                <Route element={<> <Outlet /><PageTitle>Vimeo Import</PageTitle> </>}>
                    <Route path={ConfigRoutes.admin.imports.vimeo.path} element={ <VimeoImportBase/> } />
                </Route>

                <Route element={<> <Outlet /><PageTitle>Contacts Import</PageTitle> </>}>
                    <Route path={ConfigRoutes.admin.imports.contacts.principal} element={ <ContactsWrapper/> } />
                </Route>

                <Route element={<> <Outlet /><PageTitle>Spotify Import</PageTitle> </>}>
                    <Route path={ConfigRoutes.admin.imports.spotify.path} element={ <SpotifyImportBase/> } />
                </Route>

                <Route element={<> <Outlet /><PageTitle>Castos Import</PageTitle> </>}>
                    <Route path={ConfigRoutes.admin.imports.castos.path} element={ <CastosImportBase/> } />
                </Route>

                <Route path={ConfigRoutes.admin.imports.security.principal} element={ <SecurityWrapper/> } />

                <Route index element={<Navigate to={ConfigRoutes.admin.imports.contacts.base} />} />
            </Routes>

        </>

    )
}

export { ImportsWrapper }