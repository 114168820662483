import { MetricService } from "app/services/metric/MetricService";
import { MetricUtil } from "app/services/metric/MetricUtil";
import React, { useEffect, useState } from "react";
import { Metric, ReachMetric } from "../../../components/metrics/ReachMetric";
import { useDataReport } from "../../../DataReportProvider";
import { metrics } from "app/utils/data/metrics";
import { getLoadedMetricById } from "app/utils/utils";
import { updateLoadedMetrics } from "app/store/reducers/metric_reducer";
import { useDispatch } from "react-redux";

const EngagementPodcast: React.FC = () => {

    const dataReport = useDataReport()
    const dispatch = useDispatch()

    const [totalPodcastSubscribe, setTotalPodcastSubscribe] = useState<Metric[] | null>(null)
    const [uniquePodcastSubscribe, setUniquePodcastSubscribe] = useState<Metric[] | null>(null)
    const [totalPodcastsListen, setTotalPodcastsListen] = useState<Metric[] | null>(null)
    const [totalStartsSpotify, setTotalStartsSpotify] = useState<Metric[] | null>(null)
    const [totalStreamsSpotify, setTotalStreamsSpotify] = useState<Metric[] | null>(null)
    const [totalListensSpotify, setTotalListensSpotify] = useState<Metric[] | null>(null)

    const getTotalPodcastSubscribe = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.totalPodcastSubscribe.endpoint)).getResponseData();
        if (response.success === true) {
            setTotalPodcastSubscribe(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.totalPodcastSubscribe.id, data: response.data }));
        }
    }

    const getUniquePodcastSubscribe = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.uniquePodcastsSubscribe.endpoint)).getResponseData();
        if (response.success === true) {
            setUniquePodcastSubscribe(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.uniquePodcastsSubscribe.id, data: response.data }));
        }
    }
    const getTotalStartsSpotify = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.totalStartsSpotify.endpoint)).getResponseData();
        if (response.success === true) {
            setTotalStartsSpotify(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.totalStartsSpotify.id, data: response.data }));
        }
    }

    const getTotalPodcastListen = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.totalPodcastListen.endpoint)).getResponseData();
        if (response.success === true) {
            setTotalPodcastsListen(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.totalPodcastListen.id, data: response.data }));
        }
    }

    const getTotalStreamsSpotify = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.totalStreamsSpotify.endpoint)).getResponseData();
        if (response.success === true) {
            setTotalStreamsSpotify(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.totalStreamsSpotify.id, data: response.data }));
        }
    }

    const getTotalListensSpotify = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.totalListensSpotify.endpoint)).getResponseData();
        if (response.success === true) {
            setTotalListensSpotify(response.data);
            dispatch(updateLoadedMetrics({ id: metrics.totalListensSpotify.id, data: response.data }));
        }
    }

    /** 
     * To calculate amount and percent of latest "n" periods, we need to modify date filtered before 
     * make request. We need to do that because the widget needs to retrive last 24
     * times periods before the requested date to calculate the amount 
    */
    const getMetrics = () => {

        const calculateStartDate = MetricUtil.calculateStartDate(dataReport.params.filter_filters.between_dates.from, dataReport.params.filter_filters.mode);

        const modifiedParams = {
            ...dataReport.params,
            filter_filters: {
                ...dataReport.params.filter_filters,
                between_dates: {
                    from: calculateStartDate,
                    to: dataReport.params.filter_filters.between_dates.to
                }
            }
        };

        let total_podcast_subscribe = getLoadedMetricById(metrics.totalPodcastSubscribe.id);
        let unique_podcast_subscribe = getLoadedMetricById(metrics.uniquePodcastsSubscribe.id);
        let total_podcasts_listen = getLoadedMetricById(metrics.totalPodcastListen.id);
        let total_starts_spotify = getLoadedMetricById(metrics.totalStartsSpotify.id);
        let total_streams_spotify = getLoadedMetricById(metrics.totalStreamsSpotify.id);
        let total_listens_spotify = getLoadedMetricById(metrics.totalListensSpotify.id);


        if (total_podcast_subscribe !== null && total_podcast_subscribe !== undefined) {
            setTotalPodcastSubscribe(total_podcast_subscribe);
        } else {
            getTotalPodcastSubscribe(modifiedParams);
        }

        if (unique_podcast_subscribe !== null && unique_podcast_subscribe !== undefined) {
            setUniquePodcastSubscribe(unique_podcast_subscribe);
        } else {
            getUniquePodcastSubscribe(modifiedParams);
        }

        if (total_podcasts_listen !== null && total_podcasts_listen !== undefined) {
            setTotalPodcastsListen(total_podcasts_listen);
        } else {
            getTotalPodcastListen(modifiedParams);
        }
        
        if (total_starts_spotify !== null && total_starts_spotify !== undefined) {
            setTotalStartsSpotify(total_starts_spotify);
        } else {
            getTotalStartsSpotify(modifiedParams);
        }
        
        if (total_streams_spotify !== null && total_streams_spotify !== undefined) {
            setTotalStreamsSpotify(total_streams_spotify);
        } else {
            getTotalStreamsSpotify(modifiedParams);
        }

        if (total_listens_spotify !== null && total_listens_spotify !== undefined) {
            setTotalListensSpotify(total_listens_spotify);
        } else {
            getTotalListensSpotify(modifiedParams);
        }
    }

    const resetReports = () => {
        setTotalPodcastSubscribe(null);
        setUniquePodcastSubscribe(null);
        setTotalPodcastsListen(null);
        setTotalStartsSpotify(null);
        setTotalStreamsSpotify(null)
        setTotalListensSpotify(null);
    }


    useEffect(() => {
        resetReports();
        getMetrics();
    }, [dataReport.params])


    return (
        <>
            <div className='row g-5 g-xl-8' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                <div className="col-xl-12">
                    <hr></hr>
                </div>

                {/*
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image='/media/svg/brand-logos/google-icon.svg'
                        snapshotId={metrics.totalPodcastListensCastos.id}
                        title={metrics.totalPodcastListensCastos.title}
                        subtitle={metrics.totalPodcastListensCastos.subtitle}
                        startPeriod={24}
                        metrics={visitsMetricData}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image='/media/svg/brand-logos/google-icon.svg'
                        snapshotId={metrics.uniquePodcastListensCastos.id}
                        title={metrics.uniquePodcastListensCastos.title}
                        subtitle={metrics.uniquePodcastListensCastos.subtitle}
                        startPeriod={24}
                        metrics={totalUsers}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image='/media/svg/brand-logos/google-icon.svg'
                        snapshotId={metrics.avgPodcastListenedCastos.id}
                        title={metrics.avgPodcastListenedCastos.title}
                        subtitle={metrics.avgPodcastListenedCastos.subtitle}
                        startPeriod={24}
                        metrics={newUsers}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image='/media/svg/brand-logos/google-icon.svg'
                        snapshotId={metrics.totalPodcastListensSpotify.id}
                        title={metrics.totalPodcastListensSpotify.title}
                        subtitle={metrics.totalPodcastListensSpotify.subtitle}
                        startPeriod={24}
                        metrics={newUsers}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image='/media/svg/brand-logos/google-icon.svg'
                        snapshotId={metrics.uniquePodcastListensSpotify.id}
                        title={metrics.uniquePodcastListensSpotify.title}
                        subtitle={metrics.uniquePodcastListensSpotify.subtitle}
                        startPeriod={24}
                        metrics={newUsers}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image='/media/svg/brand-logos/google-icon.svg'
                        snapshotId={metrics.avgPodcastListenedSpotify.id}
                        title={metrics.avgPodcastListenedSpotify.title}
                        subtitle={metrics.avgPodcastListenedSpotify.subtitle}
                        startPeriod={24}
                        metrics={newUsers}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image='/media/svg/brand-logos/google-icon.svg'
                        snapshotId={metrics.totalPodcastListensGooglePodcasts.id}
                        title={metrics.totalPodcastListensGooglePodcasts.title}
                        subtitle={metrics.totalPodcastListensGooglePodcasts.subtitle}
                        startPeriod={24}
                        metrics={newUsers}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image='/media/svg/brand-logos/google-icon.svg'
                        snapshotId={metrics.uniquePodcastListensGooglePodcasts.id}
                        title={metrics.uniquePodcastListensGooglePodcasts.title}
                        subtitle={metrics.uniquePodcastListensGooglePodcasts.subtitle}
                        startPeriod={24}
                        metrics={newUsers}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image='/media/svg/brand-logos/google-icon.svg'
                        snapshotId={metrics.avgPodcastListenedGooglePodcasts.id}
                        title={metrics.avgPodcastListenedGooglePodcasts.title}
                        subtitle={metrics.avgPodcastListenedGooglePodcasts.subtitle}
                        startPeriod={24}
                        metrics={newUsers}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image='/media/svg/brand-logos/google-icon.svg'
                        snapshotId={metrics.totalPodcastListensApplePodcasts.id}
                        title={metrics.totalPodcastListensApplePodcasts.title}
                        subtitle={metrics.totalPodcastListensApplePodcasts.subtitle}
                        startPeriod={24}
                        metrics={newUsers}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image='/media/svg/brand-logos/google-icon.svg'
                        snapshotId={metrics.uniquePodcastListensApplePodcasts.id}
                        title={metrics.uniquePodcastListensApplePodcasts.title}
                        subtitle={metrics.uniquePodcastListensApplePodcasts.subtitle}
                        startPeriod={24}
                        metrics={newUsers}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image='/media/svg/brand-logos/google-icon.svg'
                        snapshotId={metrics.avgPodcastListenedApplePodcasts.id}
                        title={metrics.avgPodcastListenedApplePodcasts.title}
                        subtitle={metrics.avgPodcastListenedApplePodcasts.subtitle}
                        startPeriod={24}
                        metrics={newUsers}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                    />
                </div>  
                */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/general/' + metrics.totalPodcastListen.logo}
                        snapshotId={metrics.totalPodcastListen.id}
                        title={metrics.totalPodcastListen.title}
                        subtitle={metrics.totalPodcastListen.subtitle}
                        startPeriod={24}
                        metrics={totalPodcastsListen}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.totalPodcastSubscribe.logo}
                        snapshotId={metrics.totalPodcastSubscribe.id}
                        title={metrics.totalPodcastSubscribe.title}
                        subtitle={metrics.totalPodcastSubscribe.subtitle}
                        startPeriod={24}
                        metrics={totalPodcastSubscribe}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/' + metrics.uniquePodcastsSubscribe.logo}
                        snapshotId={metrics.uniquePodcastsSubscribe.id}
                        title={metrics.uniquePodcastsSubscribe.title}
                        subtitle={metrics.uniquePodcastsSubscribe.subtitle}
                        startPeriod={24}
                        metrics={uniquePodcastSubscribe}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/brand-logos/' + metrics.totalStartsSpotify.logo}
                        snapshotId={metrics.totalStartsSpotify.id}
                        title={metrics.totalStartsSpotify.title}
                        subtitle={metrics.totalStartsSpotify.subtitle}
                        startPeriod={24}
                        metrics={totalStartsSpotify}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/brand-logos/' + metrics.totalListensSpotify.logo}
                        snapshotId={metrics.totalListensSpotify.id}
                        title={metrics.totalListensSpotify.title}
                        subtitle={metrics.totalListensSpotify.subtitle}
                        startPeriod={24}
                        metrics={totalListensSpotify}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>

                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/brand-logos/' + metrics.totalStreamsSpotify.logo}
                        snapshotId={metrics.totalStreamsSpotify.id}
                        title={metrics.totalStreamsSpotify.title}
                        subtitle={metrics.totalStreamsSpotify.subtitle}
                        startPeriod={24}
                        metrics={totalStreamsSpotify}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>

            </div>
        </>
    )
}


export default EngagementPodcast;