import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { UserService } from "../../../services/user/UserService";
import ImgOrDefault from "../../../utils/components/img_or_default";
import { Role } from "../../../models/users/Role";
import { ExternalProviderService } from "app/services/external_povider/ExternalProviderService";
import moment from "moment";
import { useExternalProviders } from "../ExternalProvidersProvider";

interface Props {
    successSubmit: (values: any) => void,
    externalProvider: any
}

const validate = (values: any) => {
    const errors: any = {};



    // metricName field
    if (!values.metricName) {
        errors.metricName = 'Required';
    } else if (values.metricName.length < 3) {
        errors.metricName = 'Must be 3 characters or more';
    }

    // metricValue field
    if (!values.metricValue) {
        errors.metricValue = 'Required';
    } else if (values.metricValue < 0) {
        errors.metricValue = 'Must be a positive number';
    }

    // metricDate field
    if (!values.metricDate) {
        errors.metricDate = 'Required';
    }

    // programmeID field
    if (!values.programmeId) {
        errors.programmeId = 'Required';
    } else if (values.programmeId < 0) {
        errors.programmeId = 'Must be a positive number';
    }

    // campaignID field
    if (!values.campaignId) {
        errors.campaignId = 'Required';
    } else if (values.campaignId < 0) {
        errors.campaignId = 'Must be a positive number';
    }

    // provider field
    let providers = ['google_analytics', 'google'];
    if (!values.provider) {
        errors.provider = 'Required';
    } else if (!providers.includes(values.provider)) {
        errors.provider = 'Invalid provider';
    }

    return errors;
}


const EditExternalProviderForm: FC<Props> = ({ successSubmit, externalProvider }) => {

    const [creating, setCreating] = useState(false);

    const providers = useExternalProviders();

    const formik = useFormik({
        initialValues: {
            ...externalProvider,
            metricDate: moment(externalProvider.metricDate.date).format("YYYY-MM-DD")
        },
        validate,
        onSubmit: (values) => {

            setCreating(true);
            let formData = new FormData(document.getElementById('edit-external-provider-form') as HTMLFormElement);
            formData.append('id', externalProvider.id);
            formData.append('metricName', values.metricName);
            formData.append('metricValue', values.metricValue);
            formData.append('metricDate', values.metricDate);
            formData.append('programmeId', values.programmeId);
            formData.append('campaignId', values.campaignId);
            formData.append('provider', values.provider);

            editExternalProvider(formData);
        }
    })

    const editExternalProvider = async (values: FormData) => {
        setCreating(true);

        let data = await (await (new ExternalProviderService()).updateExternalProvider(values)).getResponseData();

        setCreating(false);

        if (data.success == false) {
            if (data.message == "External Provider Not found") {
                formik.setErrors({
                    email: data.message
                })
            }
        } else {
            successSubmit(data)
        }
    }


    const renderForm = () => (
        <>
            <form id="edit-external-provider-form" onSubmit={formik.handleSubmit} encType="multipart/form-data"></form>

            <div className='form-group p-2 mb-2'>
                <label className='form-label fw-bold'>Metric Name: <span className="text-danger">*</span></label>
                <input type="text" form="edit-external-provider-form" name="metricName" className="form-control" onChange={formik.handleChange} value={formik.values.metricName} placeholder="slide_downloads" />
                {formik.errors.metricName ? <div className="text-danger">{formik.errors.metricName}</div> : null}
            </div>

            <div className='form-group p-2 mb-2'>
                <label className='form-label fw-bold'>Metric Value: <span className="text-danger">*</span></label>
                <input type="number" form="edit-external-provider-form" name="metricValue" className="form-control" onChange={formik.handleChange} value={formik.values.metricValue} placeholder="100" />
                {formik.errors.metricValue ? <div className="text-danger">{formik.errors.metricValue}</div> : null}
            </div>

            <div className='form-group p-2 mb-2'>
                <label className='form-label fw-bold'>Metric Date: <span className="text-danger">*</span></label>
                <input type="date" form="edit-external-provider-form" name="metricDate" className="form-control" onChange={formik.handleChange} value={formik.values.metricDate} placeholder="2023-01-01" />
                {formik.errors.metricDate ? <div className="text-danger">{formik.errors.metricDate}</div> : null}
            </div>

            <div className='form-group p-2 mb-2'>
                <label className='form-label fw-bold'>Programme ID: <span className="text-danger">*</span></label>
                <input type="number" form="edit-external-provider-form" name="programmeId" className="form-control" onChange={formik.handleChange} value={formik.values.programmeId} placeholder="1" />
                {formik.errors.programmeId ? <div className="text-danger">{formik.errors.programmeId}</div> : null}
            </div>

            <div className='form-group p-2 mb-2'>
                <label className='form-label fw-bold'>Campaign ID: <span className="text-danger">*</span></label>
                <input type="number" form="edit-external-provider-form" name="campaignId" className="form-control" onChange={formik.handleChange} value={formik.values.campaignId} placeholder="1" />
                {formik.errors.campaignId ? <div className="text-danger">{formik.errors.campaignId}</div> : null}
            </div>

            <div className='form-group p-2 mb-2'>
                <label className='form-label fw-bold'>Provider: <span className="text-danger">*</span></label>
                <select className="form-control" form="edit-external-provider-form" name="provider" value={formik.values.provider} onChange={formik.handleChange}>
                    <option value={""}>Select provider</option>
                    {providers.providers.map((provider) => {
                        return <option value={provider.value}>{provider.label}</option>
                    })}
                </select>
                {formik.errors.provider ? <div className="text-danger">{formik.errors.provider}</div> : null}
            </div>

            <input type="hidden" name="id" value={formik.values.id}></input>

            <div className="d-flex justify-content-end">
                <button disabled={creating} form="edit-external-provider-form" type="submit" className="btn btn-primary">
                    {creating && (<Spinner animation="border" color="primary" ></Spinner>)}
                    {!creating && "Update"}
                </button>
            </div>
        </>
    );

    return (
        <>{externalProvider ? renderForm() : ""}</>
    );
}

export default EditExternalProviderForm;