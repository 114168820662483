import { useEffect, useState } from "react";
import MetricMergerMainTable from "./MetricMergerMainTable";
import SpinnerCustom from "@components/SpinnerCustom";
import { useLayout } from "_metronic/layout/core";
import { KTSVG } from "_metronic/helpers";
import CustomMetricModal from "./CustomMetricModal";
import { CustomMetricService } from "app/services/custom_metric/CustomMetric";

const MetricMergerWrapperTable = () => {
    
    // PROVIDERS

    const layout = useLayout();

    // STATES

    const [allowedCustomMetrics, setAllowedCustomMetrics] = useState<any[]>([]);
    const [metricsData, setMetricsData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [emptyData, setEmptyData] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);

    // FUNCTIONS

    /**
     * 
     * @description Get allowed metrics for the selected filters
     * 
     * @returns allowed metrics for the selected filters
     */

    const getAllowedCustomMetrics = async () => {
        setLoading(true);
        const response = (await (new CustomMetricService()).listCustomMetric({})).getResponseData();
        if (response.success === false) {
            console.error("Error getting allowed metrics", response);
            toastr.error("Error getting allowed metrics");
        } else {
            setAllowedCustomMetrics(response.data);
            if (response.data.length === 0) {
                setEmptyData(true);
                setLoading(false);
            }
        }
    }

    /**
     * 
     * @description Get total metric for all metrics
     * 
     * @returns total metric for all metrics
     */
    const getTotalMetricForAllMetrics = () => {

        if (allowedCustomMetrics.length === 0) return ;

        setEmptyData(false);

        const allowedData = allowedCustomMetrics.map( metric => {
                return {
                    id: metric.id,
                    name: metric.name, 
                    description : metric.description, 
                    howIsCalculated: metric.howIsCalculated,
                    additionalInfo: metric.additionalInfo,
                    category : metric.category, 
                    format: metric.format
                };
        });

        setLoading(false);

        setMetricsData(allowedData.filter(data => data !== null));
    }

    /**
     * 
     * @description Handle the opening of the modal
     * 
     * @param edit 
     * 
     * @returns void
     */

    const handleOpenModal = () => {
        setShowModal(true);
    }
     
    /**
      * 
      * @description Add buttons to the layout
      * 
      * @returns buttons added to the layout
      */
 
    const addButtons = () => {
        layout.setToolbarButtons([
           (
             <button className={'btn btn-sm btn-primary'} onClick={() => handleOpenModal()}>
               Create Custom Metric
               <KTSVG
                 path='/media/icons/duotune/arrows/arr009.svg'
                 className='svg-icon-light ms-2'
               />
             </button>
           )
        ])
    }

    // USE EFFECTS

    useEffect(() => {
        getTotalMetricForAllMetrics();
    }, [allowedCustomMetrics]);
    
    useEffect(() => {
        addButtons();
        setShowModal(false);
        getAllowedCustomMetrics();
    }, [])

    return (
        <>
            {!loading ?
                !emptyData ?
                    metricsData !== null ? 
                    (<MetricMergerMainTable data={metricsData} setData={setMetricsData}/>) : (
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="text-muted fs-4 fw-bold">
                                Please select the filters and apply to see the data
                            </div>
                        </div>
                    ) :
                    <div className="text-center mt-5">
                        <h3 className="text-muted">No data available for selected category</h3>
                    </div>  
                : 
                <div className="d-flex justify-content-center mt-5"><SpinnerCustom/></div>
             }
             <CustomMetricModal setShow={setShowModal} show={showModal}/>
        </>
    )
};

export default MetricMergerWrapperTable;