import { RestServiceConnection } from "../RestServiceConnection";
import QueryString from "qs" ;
import { Metric } from "app/models/metrics/Metric";

export class MetricManagerService extends RestServiceConnection {

    updateMetric = async (metric: Metric) => {
        this.response = await this.makeRequest({
            method: "post",
            url: `/metric-management/update`,
            data: metric
        }, true);
        return this;
    }


    updateMetricImg = async (form: FormData) => {
        this.response = await this.makeRequest({
            method: "post",
            url: `/metric-management/update-img`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: form
        }, true);
        return this;
    }
}