import React, {useContext, useEffect} from 'react'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'
import './DatabaseManager.css'
import ConditionI from './components/ConditionI'
import DbManagerContext from './context/DbManagerProvider'
import {KTSVG} from '_metronic/helpers'
import DbModal from './components/dbModal'
import {DrawerDB} from './components/dbDrawer'
import Swal from 'sweetalert2'
import {DatabaseManagerService} from 'app/services/database_manager/databaseManagerService'
import OuptutSelection from './components/OutputSelection'
import ShowResult from './components/ShowResult'

const DbManager = () => {
  const breadcrumbs = [
    {
      title: 'Modules',
      path: '#',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Database Manager',
      path: 'database_manager',
      isSeparator: true,
      isActive: true,
    },
  ]

  const layout = useLayout();

  const {dbManagerState, dispatch} = useContext(DbManagerContext)

  const [show, setShow] = React.useState(false)

  const [executeData, setExecuteData] = React.useState<any>(null)

  const [showResult, setShowResult] = React.useState(false)

  const [result, setResult] = React.useState<any>(null)

  const handleResetQuery = () => {
    let data = false
    dbManagerState.query.condition_groups.map((group: any) => {
      group.conditions.map((condition: any) => {
        console.log('condition', condition)
        if (condition.field != '' || condition.condition != '' || condition.values.length > 0) {
          data = true
        }
      })
    })
    if (data) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will lose all the data of the current query',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, reset it!',
        confirmButtonColor: '#d33',
        cancelButtonColor: '#477995',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch({type: 'RESET_QUERY'})
          setShowResult(false)
          setResult(null)
          Swal.fire({
            title: 'Success!',
            text: 'Query reset successfully',
            icon: 'success',
          })
        }
      })
    } else {
      dispatch({type: 'RESET_QUERY'})
      setShowResult(false)
      setResult(null)
    }
  }

  const submitExecuteData = async () => {
    let resultOk = true

    dbManagerState.query.condition_groups.map((group: any) => {
      group.conditions.map((condition: any) => {
        if (
          (condition.values.length === 0 || condition.values[0] === '') &&
          condition.condition !== 'is_null' &&
          condition.condition !== 'is_not_null'
        ) {
          resultOk = false
        } else if (
          condition.condition === '' ||
          condition.field === '' ||
          condition.field === undefined
        ) {
          resultOk = false
        }
      })
    })

    if (dbManagerState.query.output === 'breakdown' && 
        (dbManagerState.query?.breakdown_field?.length === 0 ||
        dbManagerState.query?.breakdown_field === null ||
        dbManagerState.query?.breakdown_field === undefined
        )
    ) {
      resultOk = false
    }

    const dbService = new DatabaseManagerService()

    if (resultOk) {
      Swal.fire({
        showConfirmButton: false,
        title: '<h3> Please Wait... <h3>',
        loaderHtml:
          '<div class="custom-spinner mb-5"><span></span><span></span><span></span></div>',
        allowOutsideClick: false,
        validationMessage: '<p class="text-muted"> This operation could take a while </p>',
        html: '<p class="text-muted"> This operation could take a while </p>',

        customClass: {
          loader: 'custom-loader',
          icon: 'd-none',
        },
        willOpen: () => {
          Swal.showLoading()
        },
      })
      let response = (await dbService.dispatchQuery(dbManagerState.query)).response

      setExecuteData(dbManagerState.query)

      Swal.close()

      if (response != null && response.status === 200 && response.data.success === true) {
        setShowResult(true)
        setResult(response.data.data)
      } else {
        Swal.fire({
          title: 'Error!',
          text: response?.data.message.charAt(0).toUpperCase() + response?.data.message.slice(1),
          icon: 'error',
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Please fill all the fields to execute the query',
        icon: 'error',
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    }
  }

  const addButtons = (show: boolean) => {
    layout.setToolbarButtons([
      (
        <DrawerDB dispatch={dispatch} submitQuery={submitExecuteData} />
      ),
      (
        <button className={'btn btn-sm btn-secondary'} onClick={() => handleResetQuery()}>
          Create Query
          <KTSVG
            path='/media/icons/duotune/arrows/arr009.svg'
            className='svg-icon-light ms-2'
          />
        </button>
      )
    ])
  }

  useEffect(() => {
    addButtons(false);
  }, [])

  return (
    <>
      <>
        <PageTitle breadcrumbs={breadcrumbs}>Database Manager</PageTitle>
        <>
          <div className='row'>
            {dbManagerState.query.condition_groups.map((group: any, groupID: number) => (
              <React.Fragment key={groupID}>
                <div
                  className={`col-xl-12 d-flex justify-content-center mt-6 mb-6 ${
                    groupID === 0 ? 'd-none' : 'd-block'
                  }`}
                >
                  <div className='row'>
                    <div className='col-6'>
                      <button
                        className={`btn ps-19 pe-19 ${
                          group.type === 'AND'
                            ? 'bg-primary text-light'
                            : 'bg-secondary text-primary'
                        }`}
                        onClick={() =>
                          dispatch({
                            type: 'UPDATE_CONDITION_GROUP_TYPE',
                            payload: {groupIndex: groupID, groupType: 'AND'},
                          })
                        }
                      >
                        AND
                      </button>
                    </div>
                    <div className='col-6'>
                      <button
                        className={`btn ps-20 pe-20 ${
                          group.type === 'OR'
                            ? 'bg-primary text-light'
                            : 'bg-secondary text-primary'
                        }`}
                        onClick={() =>
                          dispatch({
                            type: 'UPDATE_CONDITION_GROUP_TYPE',
                            payload: {groupIndex: groupID, groupType: 'OR'},
                          })
                        }
                      >
                        OR
                      </button>
                    </div>
                  </div>
                </div>
                <div className={`card w-100 p-0 mt-2`}>
                  <div className='text-primary fw-bold mt-2 ms-3 d-flex align-items-center'>
                    CONDITION GROUP #{groupID + 1}
                    <div
                      className={`${
                        dbManagerState.query.condition_groups.length === 1
                          ? 'cursor-not-allowed'
                          : ''
                      }`}
                    >
                      <button
                        className={`btn bg-tranparent ps-0 cursor-pointer`}
                        disabled={dbManagerState.query.condition_groups.length === 1}
                        onClick={() =>
                          dispatch({
                            type: 'DELETE_CONDITION_GROUP',
                            payload: {groupIndex: groupID},
                          })
                        }
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className={`svg-icon-2 svg-icon-orange`}
                        />
                      </button>
                    </div>
                  </div>
                  <div className={'card-body'}>
                    <ConditionI group={group} dispatch={dispatch} groupID={groupID} />
                  </div>
                  <div className='d-flex justify-content-xl-start justify-content-center mt-2 mb-2'>
                    <button
                      className='btn btn-sm btn-light ms-2 bg-transparent text-dark fw-bolder'
                      onClick={() =>
                        dispatch({type: 'ADD_CONDITION_GROUP', payload: {currentGroup: groupID}})
                      }
                    >
                      Add new Condition goup +
                    </button>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
          <OuptutSelection dispatch={dispatch} query={dbManagerState.query} />
          <div className='d-flex justify-content-end mt-10'>
            <div className='me-4' key={'toolbar-execute-button'}>
              <div>
                <button
                  className={`btn btn-md btn-flex btn-primary btn-active-primary fw-bolder`}
                  onClick={() => {
                    submitExecuteData()
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen004.svg'
                    className='svg-icon-2 svg-icon-light'
                  />
                  Search
                </button>
              </div>
            </div>
          </div>
        </>
        <DbModal show={show} setShow={setShow} query={dbManagerState.query} />
      </>
      {showResult && result ? (
        <>
          <ShowResult
            query={executeData}
            result={result}
            setShow={setShow}
            setShowResult={setShowResult}
          />
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default DbManager
