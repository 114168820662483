import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { DefaultLayoutConfig, ILayout } from '../../../_metronic/layout/core';


const initialState: ILayout = DefaultLayoutConfig;

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setConfig: (state: ILayout, action: PayloadAction<ILayout>) => {
            Object.assign(state, action.payload);
        },
    }
});

export const {setConfig} = layoutSlice.actions;

export default layoutSlice.reducer;
