import { TablesWidget9 } from "_metronic/partials/widgets";
import { CustomMetricService } from "app/services/custom_metric/CustomMetric";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

interface MetricMergerMainTableProps {
    data?: any;
    setData?: any;
}

const MetricMergerMainTable:FC<MetricMergerMainTableProps> = ({data, setData}) => {

    // NAVIGATION 

    const navigate = useNavigate();

    // FUNCTIONS

    /**
     * 
     * @description Delete metric
     * 
     * @param element metric to delete
     * 
     * @returns void
     */

    const deleteMetric = async (element: any) => {
        const customMetricService = new CustomMetricService();
        const response = await customMetricService.deleteCustomMetric(element.id);
        if(response.getResponseData()?.success === true) {
            Swal.fire({
                text: "Metric deleted successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
            });
            setData(data.filter((metric: any) => metric.id !== element.id));
        }
    }


    return (
        <>
                <div className="mt-6">
                    <TablesWidget9 className="card-primary mt-3" title="Metric Merger Table"
                        subtitle="List of custom metrics"
                        data={data}
                        columns={[
                        {
                            name: "Name", keyValue: 'name', className: "min-w-150px",
                            render: (element: any) => {
                                return (
                                    <div className='text-dark d-block fs-6'>
                                        {element.name || "-"}
                                    </div>
                                );
                            }
                        },
                        { name: "Category", keyValue: 'category', className: "min-w-150px",
                            render: (element: any) => {
                                return (
                                    <>
                                        <div className='text-dark d-block fs-6'>
                                            { element?.category?.charAt(0)?.toUpperCase() + element?.category?.slice(1) || "-"}
                                        </div>
                                    </>
                                )
                            }

                        },
                        {
                            name: "How is Calculated", keyValue: 'howIsCalculated', className: "min-w-150px",
                            render: (element: any) => {
                            return (
                                <div className='text-dark d-block fs-6'>
                                    {element.howIsCalculated || "-"}
                                </div>
                            );
                            }
                        },
                        {
                            name: "Description", keyValue: 'description', className: "min-w-150px",
                            render: (element: any) => {
                                return (
                                    <div className='text-dark d-block fs-6'>
                                        {element.description || "-"}
                                    </div>
                                );
                            }
                        },
                        { 
                            name: "Adicional Info", keyValue: 'aditionalInfo', className: "min-w-150px",
                            render: (element: any) => {
                            return (
                                <div className='text-dark d-block fs-6'>
                                    {element.aditionalInfo || "-"}
                                </div>
                            )
                            }

                        },
                        { name: "Actions", className: "min-w-100px text-end", isActionCell: true }
                        ]}
                        actions={[
                            {
                              title: "Edit",
                              buttonType: "icon",
                              iconPath: "/media/icons/duotune/art/art005.svg",
                              description: "Edit user",
                              additionalClasses: "btn-primary",
                              callback: (element: any) => {
                                navigate(`/admin/metrics/metric-custom/configurator?metricCustomId=${element.id}`);
                              }
                            },
                            {
                              title: "Delete",
                              buttonType: "icon",
                              iconPath: "/media/icons/duotune/general/gen027.svg",
                              description: "Remove user",
                              additionalClasses: "btn-danger",
                              callback: (element: any) => {
                                Swal.fire({
                                    text: "Are you sure you want to delete this metric?",
                                    title: "Delete Metric",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonText: "Yes, delete it!",
                                    confirmButtonColor: "#477995",
                                    cancelButtonColor: "#d33",
                                    cancelButtonText: "No, cancel!",
                                    }).then(({ isConfirmed }) => {
                                    if (isConfirmed) {
                                        deleteMetric(element);
                                    }
                                    });
                              }
                            }
                          ]}
                    ></TablesWidget9>
                </div>
        </>
    );
}

export default MetricMergerMainTable;