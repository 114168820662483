import moment from "moment"
import React, { createContext, useContext, useEffect, useState } from "react"
import _ from 'lodash'
import { Programme } from "app/models/programmes/Programme";
import { Metric } from "app/models/metrics/Metric";
import { ProgrammeExplorerCard } from "./components/ProgrammeExplorerCard";
import {DocumentService} from "../../services/document/DocumentService";

export type ProgrammeExplorerFilterMode = 'months' | 'years' | 'weeks' | 'days' | 'Q';

export type ProgrammeExplorerFilters = {
    mode: ProgrammeExplorerFilterMode,
    connects?: number[],
    programmes?: number[]
    campaigns?: number[],
    therapeutic_areas?: number[],
    between_dates?: any,
    source?: string | undefined
}

type BetweenDatesFilter = {
    from: string,
    to: string
}

export type ProgrammeExplorerFilterParams = {
    filter_filters: ProgrammeExplorerFilters

}

type ProgrammeExplorerContextModel = {
    params: ProgrammeExplorerFilterParams,
    metrics: ProgrammeExplorerMetrics,
    showSnapshotModal: boolean,
    programmes: Programme[],
    updateFilters: (filters: Partial<ProgrammeExplorerFilters>) => void,
    updateMetrics: (metric: string) => void,
    updateProgrammeExplorerProgrammes: (programmes: Programme[]) => void,
    resetFilters: () => void,
    updateShowSnapshotModal: (show: boolean) => void,
    getMetricProgrammeExplorerCard: (metrics: Metric, filters: ProgrammeExplorerFilterParams) => JSX.Element[] | JSX.Element
}

const defaultFilterParams: ProgrammeExplorerFilterParams = {
    filter_filters: {
        campaigns: [],
        connects: [],
        programmes: [],
        mode: 'weeks'
    }
}

type ProgrammeExplorerMetrics = string[];

type ShowSnapshotModal = boolean;

const defaultProgrammeExplorerMetrics: ProgrammeExplorerMetrics = [];

const defaultShowSnapshotModal: ShowSnapshotModal = false;

const ProgrammeExplorerContext = createContext<ProgrammeExplorerContextModel>({
    params: defaultFilterParams,
    metrics: defaultProgrammeExplorerMetrics,
    showSnapshotModal: defaultShowSnapshotModal,
    programmes: [],
    updateFilters: (filters: Partial<ProgrammeExplorerFilters>) => { },
    updateMetrics: (metricId: string) => { },
    updateShowSnapshotModal: (show: boolean) => { },
    updateProgrammeExplorerProgrammes: (programmes: Programme[]) => { },
    resetFilters: () => { },
    getMetricProgrammeExplorerCard: (metrics: Metric, filters: ProgrammeExplorerFilterParams) => <></>,
})


const ProgrammeExplorerProvider: React.FC = ({ children }) => {

    const [params, setParams] = useState<ProgrammeExplorerFilterParams>(defaultFilterParams)
    const [metrics, setMetrics] = useState<ProgrammeExplorerMetrics>(defaultProgrammeExplorerMetrics)
    const [showSnapshotModal, setShowSnapshotModal] = useState<ShowSnapshotModal>(defaultShowSnapshotModal)
    const [programmes, setProgrammes] = useState<Programme[]>([])
    // const [allowedMetrics, setAllowedMetrics] = useState<string[]>([])


    const updateShowSnapshotModal = (show: boolean) => {
        setShowSnapshotModal(show)
    }


    const updateFilters = (filters: Partial<ProgrammeExplorerFilters>) => {

        let newObject = {
            ...params,
            filter_filters: {
                ...params.filter_filters,
                ...filters
            }
        }

        if (_.isEqual(params, newObject) === false) {
            setParams(newObject)
        }

    }

    const resetFilters = () => {
        setParams(defaultFilterParams)
    }

    const updateMetrics = (metricId: string) => {

        let newMetrics = metrics;

        if (newMetrics.includes(metricId)) {
            newMetrics = newMetrics.filter((metric) => metric !== metricId);
        } else {
            newMetrics.push(metricId);
        }

        setMetrics(newMetrics);
    }

    const updateProgrammeExplorerProgrammes = (programmes: Programme[]) => {
        setProgrammes(programmes)
    }

    const getImageRender = (metric: Metric) => {
        if (metric.img !== null) {
            return (new DocumentService()).renderDocument(metric.img.id);
        }

        return '/media/svg/brand-logos/google-icon.svg';

    }

    /** Render metric card */
    const getMetricProgrammeExplorerCard = (metric: Metric, filters: ProgrammeExplorerFilterParams): JSX.Element => {
        return (
            <ProgrammeExplorerCard
                key={metric.id}
                className='card-xl-stretch mb-5 mb-xl-8'
                image={getImageRender(metric)}
                title={metric.name}
                description={metric.description}
                filters={filters}
                metric={metric.metricTag}
                canOpen={metric.canOpen}
                showFilteredTimeSeries={metric.showFilteredTimeseries}
                showFilteredSplitBySource={metric.showSplitBySource}
                showFilteredSplitByRegion={metric.showSplitByRegion}
                showFilteredProgrammesRanking={metric.showProgrammesRanking}
                showFilteredTherapeuticAreaRanking={metric.showTherapeuticAreaRanking}
            ></ProgrammeExplorerCard>
        )
        
    }

    const value: ProgrammeExplorerContextModel = {
        params,
        metrics,
        showSnapshotModal,
        programmes,
        updateFilters,
        updateMetrics,
        resetFilters,
        updateProgrammeExplorerProgrammes,
        updateShowSnapshotModal,
        getMetricProgrammeExplorerCard
    }

    useEffect(() => {
        // console.log("do request...");
    }, [params.filter_filters])


    return <ProgrammeExplorerContext.Provider value={value}>{children}</ProgrammeExplorerContext.Provider>
}


export { ProgrammeExplorerProvider, ProgrammeExplorerContext }

export function useProgrammeExplorer() {
    return useContext(ProgrammeExplorerContext);
}