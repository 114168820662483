import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { UserRefreshToken } from 'app/pages/auth';

export type AuthUserState = {
    id: string,
    email: string,
    name: string,
    picture: string|null,
    provider: string,
    role: string|null,
    auth: {
        id_token: string,
        access_token: string,
        refresh_token: string,
        expires_in: number,
        token_type: string
    }
}

export type AuthState = {
    user: null|AuthUserState
}

const initialState: AuthState = {
    user: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state: AuthState, action: PayloadAction<AuthUserState>) => {
            state.user = action.payload;
        },
        removeUser: (state: AuthState) => {
            state.user = null;
        },
        refreshUserToken: (state: AuthState, action: PayloadAction<UserRefreshToken>) => {
            if(state.user !== null) {
                state.user.auth.access_token = action.payload.access_token;
                state.user.auth.refresh_token = action.payload.refresh_token;
            }
        }
    }
});

export const {setUser, removeUser, refreshUserToken} = authSlice.actions;

export default authSlice.reducer;
