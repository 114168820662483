import CustomModalBody from "@components/modal/body";
import CustomModalHeader from "@components/modal/header";
import CustomModal from "@components/modal/modal";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import Swal from "sweetalert2";
import CustomSelect from "@components/form/CustomSelect";
import { MultiValue, SingleValue } from "react-select";
import * as yup from 'yup';
import { CustomMetricService } from "app/services/custom_metric/CustomMetric";
import { useNavigate } from "react-router-dom";

interface Props {
    show: boolean,
    setShow: any
}

interface formValues {
    name: string,
    description: string,
    howIsCalculated: string | null,
    additionalInfo: string | null,
    category: string,
    //visibility: string[],
    format: string
}

const CreateCustomMetricSchema = yup.object({
    name: yup.string().required('Name is required').min(3 , 'Name must be at least 3 characters'),
    description: yup.string().required('Description is required').min(3 , 'Description must be at least 3 characters'),
    category: yup.string().required('Category is required').min(1 , 'Select a category'),
    //visibility: yup.array().required().min(1 , 'Select at least one visibility'),
    format: yup.string().required('Format is required').min(1 , 'Select a format'),
});

const categories = [
    { value: 'Engagement', label: 'Engagement' },
    { value: 'Reach', label: 'Reach' },
    { value: 'Impact', label: 'Impact' },
]

const formats = [
    {value: 'number', label: 'Number'},
    {value: 'percentage', label: 'Percentage'},
]

const visibility = [
    {value: 'dr', label: 'DR'},
    {value: 'pe', label: 'PE'},
    {value: 'or', label: 'OR'},
    {value: 'mkt', label: 'MKT'}
] 

const CustomMetricModal: FC<Props> = ({ show , setShow }) => {

    const navigate = useNavigate();

    // FUNCTIONS

    /**
     * 
     * @description Hide the modal
     * 
     * @returns void
     */

    const hideModal = () => {
        setShow(false);
    }

    /**
     * 
     * @description Handle the response from the submit
     * 
     * @param response 
     * 
     * @returns void
     */

    const handleSubmitResponse = (response: any) => {
        if(response == true){
            Swal.fire({
                icon: 'success',
                title: 'Custom Metric ' + 'created' + ' successfully',
                showConfirmButton: false,
                timer: 3000
            })
            hideModal();
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                confirmButtonColor: '#477995',
            })
        }
    }

    const handleCreateCustomMetric = async (values: any) => {
        const response = (await (await (new CustomMetricService()).createCustomMetric(values)).getResponseData())
        if (response.success === false) {
            handleSubmitResponse(response.success);
        } else {
            handleSubmitResponse(response.success);
            navigate(`/admin/metrics/metric-custom/configurator?metricCustomId=${response.data.id}`);
        }
    }

    /**
     * 
     * @description Handle the change of the categories
     * 
     * @param newValue 
     * 
     * @returns void
     */

    const onChangeCategories = (newValue: SingleValue<any>) => {
        let categorySelected: any = newValue.value;
        formik.setFieldValue('category', categorySelected);
    }

    /**
     * 
     * @description Handle the change of the visibility
     * 
     * @param newValue 
     * 
     * @returns void
     */

    const onChangeVisibility = (newValue: MultiValue<unknown>) => {
        let visibilitySelected: any = newValue.map((value: any) => value.value);
        formik.setFieldValue('visibility', visibilitySelected);
    }

    /**
     * 
     * @description Handle the change of the format
     * 
     * @param newValue 
     * 
     * @returns void
     */

    const onChangeFormat = (newValue: SingleValue<any>) => {
        let formatSelected: any = newValue.value;
        formik.setFieldValue('format', formatSelected);
    }

    const initialValues: formValues = {
        name: '',
        description: '',
        howIsCalculated: '',
        additionalInfo: '',
        category: '',
        //visibility: [],
        format: ''
    }

    // FORMIK

    const formik = useFormik({
        initialValues: {
            ...initialValues
        },
        validationSchema: CreateCustomMetricSchema,
        onSubmit: async (values) => {
            if (values.howIsCalculated === '') { values.howIsCalculated = null }
            if (values.additionalInfo === '') { values.additionalInfo = null }
            handleCreateCustomMetric(values);
        },
    });

    // USE EFFECTS

    useEffect(() => {
        formik.resetForm();
    }, [show]);


    return (
        <CustomModal identifier="create_custom_metric" show={show} onHideModal={hideModal} position="center">
            <CustomModalHeader classes="pb-0 border-0">
                <div className="modal-title justify-content-start">
                    <h2>Create Custom Metric</h2>
                </div>
            </CustomModalHeader>
            <CustomModalBody classes="mx-5 mx-xl-18 pt-5 pb-10 overflow-visible">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6 fv-row mt-3">
                            <label className="required fw-bold fs-6 mb-2">Metric Name</label>
                            <input
                                type="text"
                                className={`form-control form-control-sm`}
                                name="name"
                                required
                                value={formik.values.name}
                                onChange={formik.handleChange}                               
                            />
                            {formik.errors.name ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block text-danger">{formik.errors.name}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className="col-lg-6 fv-row mt-3">
                            <label className="fw-bold fs-6 mb-2">How is this metric calculated?</label>
                            <input
                                type="text"
                                className={`form-control form-control-sm`}
                                name="howIsCalculated"
                                value={formik.values.howIsCalculated || ''}
                                onChange={formik.handleChange}                               
                            />
                        </div>
                        <div className="col-lg-6 fv-row mt-3">
                            <label className="required fw-bold fs-6 mb-2">Description</label>
                            <textarea
                                className={`form-control form-control-sm`}
                                name="description"
                                required
                                value={formik.values.description}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.description ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block text-danger">{formik.errors.description}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className="col-lg-6 fv-row mt-3">
                            <label className="fw-bold fs-6 mb-2">Adittional Information</label>
                            <textarea
                                className={`form-control form-control-sm`}
                                name="additionalInfo"
                                value={formik.values.additionalInfo || ''}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="col-lg-12 fv-row mt-3">
                            <label className="required fw-bold fs-6 mb-2">Category</label>
                            <CustomSelect options={categories} is_multi={false} defaultValue={categories.find((category) => category.value === formik.values.category)}
                            placeholder="Select Categories..." onChangeSingle={onChangeCategories} key={formik.values.category}/>
                            {formik.errors.category ? (
                                <div className="fv-plugins-message-container mt-1">
                                    <div className="fv-help-block text-danger">{formik.errors.category}</div>
                                </div>
                            ) : null}
                        </div>
                        {/*<div className="col-lg-6 fv-row mt-3">
                            <label className="required fw-bold fs-6 mb-2">Visibility</label>
                            <CustomSelect options={visibility} is_multi={true} placeholder="Select Visibility..." onChange={onChangeVisibility} />
                            {formik.errors.visibility ? (
                                <div className="fv-plugins-message-container mt-1">
                                    <div className="fv-help-block text-danger">{formik.errors.visibility}</div>
                                </div>
                            ) : null}
                        </div>*/}
                        <div className="col-lg-12 fv-row mt-3" key={formik.values.format}>
                            <label className="required fw-bold fs-6 mb-2">Format</label>
                            <CustomSelect options={formats} defaultValue={formats.find((format) => format.value === formik.values.format)} is_multi={false} placeholder="Select Format..." onChangeSingle={onChangeFormat} />
                            {formik.errors.format ? (
                                <div className="fv-plugins-message-container mt-1">
                                    <div className="fv-help-block text-danger">{formik.errors.format}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className="col-lg-12 fv-row mt-6 d-flex justify-content-center">
                            <button type="submit" disabled={formik.isSubmitting || !formik.isValid} className="btn btn-primary mt-3">
                                Create Custom Metric
                            </button>
                        </div>
                    </div>
                </form>
            </CustomModalBody>
        </CustomModal>
    );
}

export default CustomMetricModal;