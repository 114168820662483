import {useDragAndDrop} from '@formkit/drag-and-drop/react'
import {useState} from 'react'

interface CustomMetricValuesProps {
  sourceClone: any
  dragEnd: any
}

const CustomMetricValues = (props: CustomMetricValuesProps) => {

  // INITIAL STATE

  const [values] = useState([
    {
      label: 'Existing metric',
      type: 'metric_tag',
      isMetric: true,
      key: 'custom-metric',
    },
    {
      label: 'Manual Value',
      type: 'manual_value',
      isMetric: true,
      key: 'manual-value',
    },
  ])

  // DRAG AND DROP

  const [valueList, value] = useDragAndDrop(values, {
    group: 'operations',
    sortable: false,
    dropZone: false,
    handleEnd: (e) => props.dragEnd(),
    plugins: [props.sourceClone],
  })

  return (
    <div className='col-6 mb-3'>
      <h2 className='mb-5 text-center'>Value Options</h2>
      {
        //@ts-ignore
        <ul ref={valueList} className='row' id = 'valueList'>
          {value.map((values: any) => (
            < li key={values.key} className='li-custom-metrics col-12' >
              <span
                className={`me-2 ${values.type === 'metric_tag' ? 'span-custom' : 'span-manual'}`}
              >
                {values.label}
              </span>
            </li>
          ))}
        </ul>
      }
    </div>
  )
}

export default CustomMetricValues
