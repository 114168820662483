import {useDragAndDrop} from '@formkit/drag-and-drop/react'
import {useState} from 'react'

interface CustomMetricOperandsProps {
  sourceClone: any
  dragEnd: any
}

const CustomMetricOperands = (props: CustomMetricOperandsProps) => {

  // INITIAL STATE

  const [operands] = useState([
    {
      label: '+',
      type: 'operation',
      key: 'operand-plus',
    },
    {
      label: '-',
      type: 'operation',
      key: 'operand-minus',
    },
    {
      label: '/',
      type: 'operation',
      key: 'operand-divide',
    },
    {
      label: '*',
      type: 'operation',
      key: 'operand-multiply',
    },
    {
      label: '(',
      type: 'operation',
      key: 'operand-open-parenthesis',
    },
    {
      label: ')',
      type: 'operation',
      key: 'operand-close-parenthesis',
    },
  ])

  // DRAG AND DROP

  const [operandList, operand] = useDragAndDrop(operands, {
    group: 'operations',
    sortable: false,
    dropZone: false,
    handleEnd: (e) => props.dragEnd(),
    plugins: [props.sourceClone],
  })

  return (
    <div className='col-6 mb-3'>
      <h2 className='mb-5 text-center'>Operations</h2>
      {
        //@ts-ignore
        <ul ref={operandList} className='row' id='operandList'>
          {operand.map((operation: any) => (
            <li key={operation.key} className='li-custom-metrics col-4'>
              <span className='me-2 span-operation'>{operation.label}</span>
            </li>
          ))}
        </ul>
      }
    </div>
  )
}

export default CustomMetricOperands
