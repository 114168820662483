import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type LoginFormState = {status: string|null|undefined}

const initialState: LoginFormState = {
    status: null
};

const loginFormSlice = createSlice({
    name: 'loginForm',
    initialState,
    reducers: {
        setLoginFormStatus: (state: LoginFormState, action: PayloadAction<string|undefined>) => {
            state.status = action.payload;
        },
        removeStatus: (state: LoginFormState) => {
            state.status = null;
        }
    }
});

export const {setLoginFormStatus, removeStatus} = loginFormSlice.actions;

export default loginFormSlice.reducer;
