import {ChangeEvent, useEffect, useState} from "react";

export const AllowMetricRoleCheckbox = ({roleId, metricId, defaultChecked, onChange}: any) => {

  const [checked, setChecked] = useState(false)

  const handleChange = (ev: ChangeEvent<HTMLInputElement>, roleId: number, metricId: number) => {
    const checkedStatus = ev.target.checked;
    setChecked(checkedStatus);
    onChange(roleId, metricId, checkedStatus);
  }

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked])

  return (
    <input className="form-check-input" type="checkbox" checked={checked} onChange={(ev) => {
      handleChange(ev, roleId, metricId)
    }} value=""/>
  )

}