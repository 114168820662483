import { FC, useState } from 'react'
import { KTSVG } from '_metronic/helpers'
import '@/_metronic/assets/sass/components/_loading.scss'
import { ImportService } from 'app/services/import/ImportService'
import Swal from 'sweetalert2'
import CustomSelect from '@components/form/CustomSelect'

const types = [
    { value: 'DOCTOR', label: 'DOCTOR' },
    { value: 'PHARMA', label: 'PHARMA' },
    { value: 'PARTNERSHIP', label: 'PARTNERSHIP' },
    { value: 'OTHER', label: 'OTHER' },
]

const dataSources = [
    { value: 'APOLLO', label: 'APOLLO' },
    { value: 'MONOCL', label: 'MONOCL' },
    { value: 'NURSES', label: 'NURSES' },
]

type FormErrors = {
    fileError?: string | null,
    dataSourceError?: string | null
    typeError?: string | null
}

const ContactsImportBase: FC = () => {

    const [errors, setErrors] = useState<FormErrors>()
    const [file, setFile] = useState<File | null>(null);
    const [dataSource, setDataSource] = useState<string | null>(null);
    const [type, setType] = useState<string | null>(null);

    const handleFileChange = (files: FileList | null) => {
        if (files !== null && files.length > 0) {
            setFile(files[0]);
        }
    }

    const handleDataSourceChange = (value: unknown) => {
        let data: any = value;
        if (data.hasOwnProperty('value')) {
            setDataSource(data.value);
        }
    }

    const handleTypeChange = (value: unknown) => {
        let data: any = value;
        if (data.hasOwnProperty('value')) {
            setType(data.value);
        }
    }

    const submitData = async (data: FormData) => {

        Swal.fire({
            showConfirmButton: false,
            title: '<h3> Importing Data... <h3>',
            loaderHtml: '<div class="custom-spinner mb-5"><span></span><span></span><span></span></div>',
            allowOutsideClick: false,
            validationMessage: '<p class="text-muted"> This operation could take a while </p>',
            html: '<p class="text-muted"> This operation could take a while </p>',

            customClass: {
                loader: 'custom-loader',
                icon: 'd-none',

            },
            willOpen: () => {
                Swal.showLoading()
            }
        });

        let response = (await (new ImportService).importContacts(data)).getResponse();

        Swal.close();

        if (response != null && response.status == 200 && response.data.success == true) {
            Swal.fire({
                title: 'Success!',
                text: 'Data imported successfully',
                icon: 'success',
                confirmButtonText: 'Ok',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            })
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong',
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            })
        }
    }


    const handleSubmit = async (event: any) => {
        setErrors(errors => ({ ...errors, fileError: null, dataSourceError: null, typeError: null }));
        event.preventDefault();

        if (file !== null && dataSource !== null && type !== null) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', type);
            formData.append('dataSource', dataSource);
            await submitData(formData);
        } else {

            if (file === null) {
                setErrors(errors => ({ ...errors, fileError: 'File is required' }))
            }

            if (dataSource === null) {
                setErrors(errors => ({ ...errors, dataSourceError: 'Data Source is required' }))
            }

            if (type === null) {
                setErrors(errors => ({ ...errors, typeError: 'Type is required' }))
            }

            setTimeout(() => {
                setErrors(errors => ({ ...errors, fileError: null, dataSourceError: null, typeError: null }))
            }, 5000);
        }
    }


    return (



        <div className='justify-content-center d-flex'>

            <div className="card card-custom example example-compact col-sm-4">
                <div className='card-header'>
                    <h3 className='card-title'> <KTSVG path='/media/icons/duotune/communication/com005.svg' className='svg-icon-1 me-2' /> Import Contacts </h3>
                </div>

                <form onSubmit={handleSubmit} className="form">
                    <div className="card-body">
                        <div className={"alert alert-primary"}><KTSVG path='/media/icons/duotune/general/gen045.svg' className='svg-icon-1 me-2' /> The sheet should be named “export”.</div>
                        <div className="form-group validated mt-3">
                            <label className="form-control-label" >Contacts File</label>
                            <div className="input-group">
                                <input accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' 
                                    type='file' onChange={(e) => handleFileChange(e.target.files)}
                                    className={`form-control`}
                                />
                            </div>
                            {errors?.fileError !== null && errors?.fileError !== undefined && (
                                        <small className='text-danger'> {errors.fileError} </small>
                                    )}
                            <div className='row'>
                                <small className="form-text text-muted">Allowed Extensions: XSL, XLSX, CSV</small>
                            </div>
                            
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label className="form-control-label mt-5" >Data Source</label>
                                    <div className="input-group">
                                        <CustomSelect is_multi={false} options={dataSources} onChangeSingle={handleDataSourceChange} />
                                    </div>
                                    {errors?.dataSourceError !== null && errors?.dataSourceError !== undefined && (
                                        <small className='text-danger'> {errors.dataSourceError} </small>
                                    )}
                                </div>
                                <div className='col-md-6'>
                                    <label className="form-control-label mt-5" >Type</label>
                                    <div className="input-group">
                                        <CustomSelect is_multi={false} options={types} onChangeSingle={handleTypeChange} />
                                    </div>
                                    {errors?.typeError !== null && errors?.typeError !== undefined && (
                                        <small className='text-danger'> {errors.typeError} </small>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button type="submit" className="btn btn-primary font-weight-bold me-2">Import</button>
                    </div>
                </form>

            </div>
        </div>

    )
}

export { ContactsImportBase }




