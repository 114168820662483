import clsx from 'clsx'
import {FC} from 'react'
import { useSelector } from 'react-redux'
import { AuthUserState } from '../../../../app/store/reducers/auth_reducer'
import ImgOrDefault from '../../../../app/utils/components/img_or_default'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const {config} = useLayout()
  const user: AuthUserState = useSelector((state: any) => state.auth.user);

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <ImgOrDefault imgSrc={user.picture} alt={"User profile img"} noReferer={true}></ImgOrDefault>
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

    </div>
  )
}

export {Topbar}
