import { CustomDateRangePicker, RangeDate } from '@components/form/CustomDateRangePicker'
import { FC, useState } from 'react'
import { KTSVG } from '_metronic/helpers'
import '@/_metronic/assets/sass/components/_loading.scss'
import { ImportService } from 'app/services/import/ImportService'
import Swal from 'sweetalert2'
import {PageTitle} from "../../../../../_metronic/layout/core";


const breadcrums = [
    {
        title: 'Administration',
        path: '#',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '#',
        isSeparator: true,
        isActive: false
    },
    {
        title: 'Imports',
        path: '#',
        isSeparator: false,
        isActive: false,
    },
    {
        title: 'Import Vimeo',
        path: '#',
        isSeparator: true,
        isActive: false
    }
]

const VimeoImportBase: FC = () => {

    type FormErrors = {
        dateError?: string | null,
        tokenError?: string | null
    }

    const [dateRange, setDateRange] = useState<RangeDate | null>(null)
    const [token, setToken] = useState<String | null>(null)
    const [errors, setErrors] = useState<FormErrors>()

    const handleDateChange = (dateRange: RangeDate) => {
        setDateRange(dateRange)
    }

    const handleTokenChange = (token: any) => {
        setToken(token)
    }

    const submitData = async () => {

        const data = {
            token: token,
            dateStart: dateRange?.startDate,
            dateEnd: dateRange?.endDate
        }
        Swal.fire({
            showConfirmButton: false,
            title: '<h3> Importing Data... <h3>',
            loaderHtml: '<div class="custom-spinner mb-5"><span></span><span></span><span></span></div>',
            allowOutsideClick: false,
            validationMessage: '<p class="text-muted"> This operation could take a while </p>',
            html: '<p class="text-muted"> This operation could take a while </p>',
            iconHtml: '<img src = "/media/icons/duotune/social/vimeo-logo.svg"/>',

            customClass: {
                loader: 'custom-loader',
                icon: 'custom-loader-icon',

            },
            willOpen: () => {
                Swal.showLoading()
            }
        });
        let response = (await (new ImportService).importVimeoMetricData(data)).getResponse();
        Swal.close();

        if (response != null && response.status == 200 && response.data.success == true) {
            Swal.fire({
                title: 'Success!',
                text: 'Data imported successfully',
                icon: 'success',
                confirmButtonText: 'Ok',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            })
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong',
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            })
        }
    }


    const handleSubmit = async (event: any) => {
        setErrors(errors => ({ ...errors, dateError: null, tokenError: null }))
        event.preventDefault();

        if (dateRange !== null && token !== null && token !== '') {

            await submitData();

        } else {

            if (dateRange === null) {
                setErrors(errors => ({ ...errors, dateError: 'Date range is required' }))
            }

            if (token === null || token === '') {
                setErrors(errors => ({ ...errors, tokenError: 'Token is required' }))
            }

            setTimeout(() => {
                setErrors(errors => ({ ...errors, dateError: null, tokenError: null }))
            }, 5000);
        }
    }


    return (
        <>
            <PageTitle breadcrumbs={breadcrums}>Import Vimeo Files</PageTitle>
            <div className='justify-content-center d-flex'>
                <div className="card card-custom example example-compact col-sm-4">
                    <div className='card-header'>
                        <h3 className='card-title'> <KTSVG path='/media/icons/duotune/social/vimeo001.svg' className='svg-icon-1 me-2' /> Import Vimeo metric data </h3>
                    </div>

                    <form onSubmit={handleSubmit} className="form">
                        <div className="card-body">

                            <div className="form-group validated">
                                <label className="form-label fw-bold" >Data range <span className='text-danger'>*</span></label>
                                {<CustomDateRangePicker inputError={errors?.dateError !== null && errors?.dateError !== undefined ? true : false}
                                                        customClass={dateRange == null ? 'text-muted' : ''} onChangeDate={handleDateChange} > </CustomDateRangePicker>}

                                {errors?.dateError !== null && errors?.dateError !== undefined && (
                                  <small className='text-danger'> {errors.dateError} </small>
                                )}
                            </div>

                            <div className="form-group validated mt-5">
                                <label className="form-label fw-bold" >Token <span className='text-danger'>*</span></label>
                                <div className="input-group">
                                <textarea onChange={(e) => handleTokenChange(e.target.value)}
                                          className={`form-control ${errors?.tokenError !== null && errors?.tokenError !== undefined ? 'is-invalid' : ''}`}
                                          placeholder="Token"
                                />
                                </div>
                                {errors?.tokenError !== null && errors?.tokenError !== undefined && (
                                  <small className='text-danger'> {errors.tokenError} </small>
                                )}
                                <div className='row'>
                                    <small className="form-text text-muted">Retrieved from statistics panel on Vimeo session</small>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary font-weight-bold me-2 d-flex">Import</button>
                        </div>
                    </form>
                </div>
            </div>
        </>


    )
}

export { VimeoImportBase }




