import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import ConfigRoutes from "../../routing/ConfigRoutes"
import { ExternalProvidersList } from "./list"
import { ExternalProvidersImport } from "../imports/external-providers"




const ExternalProvidersWrapper = () => {

  return (
    <Routes>

      <Route element={<Outlet />}>
        <Route
          path={ConfigRoutes.admin.externalProviders.list.path}
          element={
            <>
              <ExternalProvidersList />
            </>
          }
        />
         <Route
          path={ConfigRoutes.admin.externalProviders.import.path}
          element={
            <>
              <ExternalProvidersImport />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/admin/third_party_data/list' replace/>} />
    </Routes>
  )
}

export default ExternalProvidersWrapper