import { RestServiceConnection } from "../RestServiceConnection";


export class CampaignService extends RestServiceConnection {
    
    search = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ac/search/campaign',
            data: filter
        }, true);
        return this;
    }

    searchByProgramme = async (filter: any) => {
        this.response = await this.makeRequest({
            method: "post",
            url: '/collector/ac/search/campaign/by-programme',
            data: filter
        }, true);
        return this;
    }

}