import { FC, useEffect, useState } from 'react'
import { DashboardCard } from './DashboardCard'
import { MetricService } from 'app/services/metric/MetricService'
import { Spinner } from 'react-bootstrap'

const DashboardBase: FC = () => {

    const [totalContacts, setTotalContacts] = useState<any>(null)
    const [totalVisits, setTotalVisits] = useState<any>(null)
    const [totalVimeoVideos, setTotalVimeoVideos] = useState<any>(null)

    const getTotalContacts = async () => {
        let response = (await (new MetricService()).getTotalContacts()).getResponseData();

        setTotalContacts(response.data)
    }

    const getTotalVimeoVideos = async () => {
        let response = (await (new MetricService()).getTotalVimeoVideos()).getResponseData();

        setTotalVimeoVideos(response.data)
    }


    const getTotalVisits = async () => {
        let response = (await (new MetricService()).getTotalVisits()).getResponseData();
        setTotalVisits(response.data)
    }


    const getMetricsData = () => {
        getTotalContacts();
        getTotalVisits();
        getTotalVimeoVideos();
    }

    useEffect(() => {
        getMetricsData();
    }, [])


    return (
        <>
            <div className='row'>
                {/** Total Contacts */}
                <div className='col-xl-3'>
                    {totalContacts != null ?
                        <DashboardCard
                            className='card-xl-stretch mb-5 mb-xl-8'
                            image='/media/logos/logo-icon-transparent.png'
                            title='AC Database'
                            variation={totalContacts.variation.toFixed(2)}
                            counter={new Intl.NumberFormat('en-US').format(totalContacts.counter)}
                            ascend={totalContacts.ascend} />
                        : (
                            <DashboardCard
                                className='card-xl-stretch mb-5 mb-xl-8'
                                image='/media/logos/logo-icon-transparent.png'
                                title='AC Database' />
                        )}
                </div>

                {/** Total Visits */}
                <div className='col-xl-3'>
                    {totalVisits != null ?
                        <DashboardCard
                            className='card-xl-stretch mb-5 mb-xl-8'
                            image='/media/logos/logo-icon-transparent.png'
                            title='Website'
                            variation={totalVisits.variation.toFixed(2)}
                            counter={new Intl.NumberFormat('en-US').format(totalVisits.counter)}
                            ascend={totalVisits.ascend} />
                        : (
                            <DashboardCard
                                className='card-xl-stretch mb-5 mb-xl-8'
                                image='/media/logos/logo-icon-transparent.png'
                                title='Website Visits' />
                        )}
                </div>

                {/** Youtube views */}
                <div className='col-xl-3'>
                    {totalVisits != null ?
                        <DashboardCard
                            className='card-xl-stretch mb-5 mb-xl-8'
                            image='/media/logos/logo-icon-transparent.png'
                            title='Checkpoint'
                            variation={-12.34}
                            counter={new Intl.NumberFormat('en-US').format(2342543)}
                            ascend={false} />
                        : (
                            <DashboardCard
                                className='card-xl-stretch mb-5 mb-xl-8'
                                image='/media/logos/logo-icon-transparent.png'
                                title='Checkpoint' />
                        )}
                </div>

                {/** Spotify views */}
                <div className='col-xl-3'>
                    {totalVisits != null ?
                        <DashboardCard
                            className='card-xl-stretch mb-5 mb-xl-8'
                            image='/media/logos/logo-icon-transparent.png'
                            title='Engage'
                            variation={23.14}
                            counter={new Intl.NumberFormat('en-US').format(4353543)}
                            ascend={true} />
                        : (
                            <DashboardCard
                                className='card-xl-stretch mb-5 mb-xl-8'
                                image='/media/logos/logo-icon-transparent.png'
                                title='Engage' />
                        )}
                </div>

            </div>
            
            
            <div className='row'>
                {/** Total Contacts */}
                <div className='col-xl-3'>
                    {totalContacts != null ?
                        <DashboardCard
                            className='card-xl-stretch mb-5 mb-xl-8'
                            image='/media/svg/general/microfono.svg'
                            title='Castos'
                            variation={totalContacts.variation.toFixed(2)}
                            counter={new Intl.NumberFormat('en-US').format(totalContacts.counter)}
                            ascend={totalContacts.ascend} />
                        : (
                            <DashboardCard
                                className='card-xl-stretch mb-5 mb-xl-8'
                                image='/media/svg/general/microfono.svg'
                                title='Castos' />
                        )}
                </div>

                {/** Total Visits */}
                <div className='col-xl-3'>
                    {totalVisits != null ?
                        <DashboardCard
                            className='card-xl-stretch mb-5 mb-xl-8'
                            image='/media/svg/brand-logos/spotify-2.svg'
                            title='Spotify'
                            variation={totalVisits.variation.toFixed(2)}
                            counter={new Intl.NumberFormat('en-US').format(totalVisits.counter)}
                            ascend={totalVisits.ascend} />
                        : (
                            <DashboardCard
                                className='card-xl-stretch mb-5 mb-xl-8'
                                image='/media/svg/brand-logos/spotify-2.svg'
                                title='Spotify' />
                        )}
                </div>

                {/** Youtube views */}
                <div className='col-xl-3'>
                    {totalVisits != null ?
                        <DashboardCard
                            className='card-xl-stretch mb-5 mb-xl-8'
                            image='/media/svg/brand-logos/google-podcasts.svg'
                            title='Google Podcasts'
                            variation={-12.34}
                            counter={new Intl.NumberFormat('en-US').format(2342543)}
                            ascend={false} />
                        : (
                            <DashboardCard
                                className='card-xl-stretch mb-5 mb-xl-8'
                                image='/media/svg/brand-logos/google-podcasts.svg'
                                title='Google Podcasts' />
                        )}
                </div>

                {/** Spotify views */}
                <div className='col-xl-3'>
                    {totalVisits != null ?
                        <DashboardCard
                            className='card-xl-stretch mb-5 mb-xl-8'
                            image='/media/svg/brand-logos/apple-podcast.svg'
                            title='Apple Podcasts'
                            variation={23.14}
                            counter={new Intl.NumberFormat('en-US').format(4353543)}
                            ascend={true} />
                        : (
                            <DashboardCard
                                className='card-xl-stretch mb-5 mb-xl-8'
                                image='/media/svg/brand-logos/apple-podcast.svg'
                                title='Apple Podcasts' />
                        )}
                </div>

            </div>


            <div className='row'>
                {/** Total Contacts */}
                <div className='col-xl-3'>
                    {totalContacts != null ?
                        <DashboardCard
                            className='card-xl-stretch mb-5 mb-xl-8'
                            image='/media/svg/social-logos/youtube.svg'
                            title='Youtube'
                            variation={totalContacts.variation.toFixed(2)}
                            counter={new Intl.NumberFormat('en-US').format(totalContacts.counter)}
                            ascend={totalContacts.ascend} />
                        : (
                            <DashboardCard
                                className='card-xl-stretch mb-5 mb-xl-8'
                                image='/media/svg/social-logos/youtube.svg'
                                title='Youtube' />
                        )}
                </div>

                {/** Total Visits */}
                <div className='col-xl-3'>
                    {totalVimeoVideos != null ?
                        <DashboardCard
                            className='card-xl-stretch mb-5 mb-xl-8'
                            image='/media/svg/brand-logos/vimeo.svg'
                            title='Vimeo'
                            variation={totalVimeoVideos.variation.toFixed(2)}
                            counter={new Intl.NumberFormat('en-US').format(totalVimeoVideos.counter)}
                            ascend={totalVimeoVideos.ascend} />
                        : (
                            <DashboardCard
                                className='card-xl-stretch mb-5 mb-xl-8'
                                image='/media/svg/brand-logos/vimeo.svg'
                                title='Vimeo' />
                        )}
                </div>

            </div>


            <div className='row'>
                {/** Total Contacts */}
                <div className='col-xl-3'>
                    {totalContacts != null ?
                        <DashboardCard
                            className='card-xl-stretch mb-5 mb-xl-8'
                            image='/media/svg/brand-logos/twitter.svg'
                            title='Twitter'
                            variation={totalContacts.variation.toFixed(2)}
                            counter={new Intl.NumberFormat('en-US').format(totalContacts.counter)}
                            ascend={totalContacts.ascend} />
                        : (
                            <DashboardCard
                                className='card-xl-stretch mb-5 mb-xl-8'
                                image='/media/svg/brand-logos/twitter.svg'
                                title='Twitter' />
                        )}
                </div>

                {/** Total Visits */}
                <div className='col-xl-3'>
                    {totalVisits != null ?
                        <DashboardCard
                            className='card-xl-stretch mb-5 mb-xl-8'
                            image='/media/svg/brand-logos/linkedin-1.svg'
                            title='Linkedin'
                            variation={totalVisits.variation.toFixed(2)}
                            counter={new Intl.NumberFormat('en-US').format(totalVisits.counter)}
                            ascend={totalVisits.ascend} />
                        : (
                            <DashboardCard
                                className='card-xl-stretch mb-5 mb-xl-8'
                                image='/media/svg/brand-logos/linkedin-1.svg'
                                title='Linkedin' />
                        )}
                </div>

                {/** Youtube views */}
                <div className='col-xl-3'>
                    {totalVisits != null ?
                        <DashboardCard
                            className='card-xl-stretch mb-5 mb-xl-8'
                            image='/media/svg/social-logos/tiktok.svg'
                            title='TikTok'
                            variation={-12.34}
                            counter={new Intl.NumberFormat('en-US').format(2342543)}
                            ascend={false} />
                        : (
                            <DashboardCard
                                className='card-xl-stretch mb-5 mb-xl-8'
                                image='/media/svg/social-logos/tiktok.svg'
                                title='TikTok' />
                        )}
                </div>

            </div>

        </>
    )
}

export { DashboardBase }
