import { MetricService } from "app/services/metric/MetricService";
import { useEffect, useState } from "react";
import { useIndividualMetric } from "../IndividualMetricProvider";
import { IndividualMetricsTable } from "./IndividualMetricsTable";
import SpinnerCustom from "@components/SpinnerCustom";
import { CustomMetricService } from "app/services/custom_metric/CustomMetric";

const IndividualMetricsWrapperTable = () => {
    
    const individualMetrics = useIndividualMetric();
    const [allowedIndividualMetrics, setAllowedIndividualMetrics] = useState<any[]>([]);
    const [metricsData, setMetricsData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [emptyData, setEmptyData] = useState<boolean>(false);

    const getAllowedIndividualMetrics = async () => {
        if (individualMetrics.params.filter_filters.category?.length < 1 && individualMetrics.params.filter_filters.tags?.length < 1 && individualMetrics.params.filter_filters.custom_tags?.length < 1) return;
        setLoading(true);
        if (individualMetrics.params.filter_filters.category?.length < 1 && individualMetrics.params.filter_filters.tags?.length < 1 && individualMetrics.params.filter_filters.custom_tags?.length > 0) {
            setAllowedIndividualMetrics(
                [...individualMetrics.params.filter_filters.custom_tags]
            );
            return;
        }
        const response = (await (new MetricService()).getAllowedIndividualMetricsByCategoryOrTag(
            individualMetrics.params.filter_filters.category || '' , 
            individualMetrics.params.filter_filters.group || '',
            individualMetrics.params.filter_filters.tags
        )).getResponseData();
        if (response.success === false) {
            console.error("Error getting allowed metrics", response);
            toastr.error("Error getting allowed metrics");
        } else {
            response.data = response.data.concat(individualMetrics.params.filter_filters.custom_tags);
            setAllowedIndividualMetrics(response.data);
            if (response.data.length === 0) {
                setEmptyData(true);
                setLoading(false);
            }
        }
    }

    const getTotalMetricForAllMetrics = async (signal: AbortSignal) => {

        if (allowedIndividualMetrics.length === 0) return ;
        setEmptyData(false);

        let {campaigns, programmes} = individualMetrics.params.filter_filters;
        if ( campaigns !== undefined && campaigns.length != 0 ) {
            programmes = [];
        }

        let modifiedParams = {
            filter_filters: {
                ...individualMetrics.params.filter_filters,
                programmes: programmes,
                category: [],
                group: [],
                tags : [],
                custom_tags : []
            }
        }

        const promises = allowedIndividualMetrics.map(async metric => {

            if (metric == undefined) return null;

            if (metric.metricGroup !== undefined) {

                const metricService = new MetricService();
            
                const responseData = (await (await metricService.getIndividualMetricEndpointData(modifiedParams, metric.metricTag, 'total', signal)).getResponseData());

                if (responseData) {
                    return {
                        group: metric.metricGroup, 
                        name: metric.name, 
                        description : metric.description, 
                        dataSource : metric.metricSource, 
                        category : metric.category, 
                        result: responseData.data
                    };
                }

            } else {

                const customMetricService = new CustomMetricService();

                let modifiedParamsWithMetricCustomId = {
                    metricCustomId: metric.value,
                    filter_filters: {
                        ...modifiedParams.filter_filters
                    }
                }

                const responseData = (await (await customMetricService.calculateCustomMetricConfiguration(modifiedParamsWithMetricCustomId, metric.name, signal)).getResponseData());

                if (responseData) {
                    return {
                        group: null, 
                        name: metric.label, 
                        description : metric.description, 
                        dataSource : null, 
                        category : metric.category, 
                        result: responseData.data,
                    };
                }
            }

            return null;
        });
    
        const allMetricsData = await Promise.all(promises);

        setLoading(false);

        setMetricsData(allMetricsData.filter(data => data !== null).sort((a, b) => a?.name.localeCompare(b?.name)));
     }

    useEffect(() => {
        getAllowedIndividualMetrics();
    }, [individualMetrics.params.filter_filters])

    useEffect(() => {

        const controller = new AbortController();
        const signal = controller.signal;

        getTotalMetricForAllMetrics(signal);

        return () => {
            controller.abort();
        };

    }, [allowedIndividualMetrics]);

    return (
        <>
            {!loading ?
                !emptyData ?
                    metricsData !== null ? 
                    (<IndividualMetricsTable data={metricsData}/>) : (
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="text-muted fs-4 fw-bold">
                                Please select the filters and apply to see the data
                            </div>
                        </div>
                    ) :
                    <div className="text-center mt-5">
                        <h3 className="text-muted">No data available for selected category</h3>
                    </div>  
                : 
                <div className="d-flex justify-content-center mt-5"><SpinnerCustom/></div>
             }
        </>
    )
};

export default IndividualMetricsWrapperTable;