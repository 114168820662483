import {KTSVG} from '_metronic/helpers'
import {TablesWidget9} from '_metronic/partials/widgets'
import React, {FC, useEffect} from 'react'
import DbModal from './dbModal'
import {OverlayTrigger, Tooltip} from "react-bootstrap";

interface Props {
  data: any
  setShowResult: (data: boolean) => void
  query: any
  exportFunction: any
}

const BreakDownResult: FC<Props> = ({data, setShowResult, query, exportFunction}) => {
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const [fields, setFields] = React.useState<any>([])

  const getColumns = () => {
    let columns: any = []
    query.breakdown_field.map((field: any, key: number) => {
      columns.push({
        name: field
          .trim()
          .replace(/_/g, ' ')
          .replace(/^\w/, (c: any) => c.toUpperCase()),
        keyValue: 'group_name_' + key,
        className: 'min-w-80px',
        render: (row: any) => {
          return (
            <span className='text-dark fw-bolder text-hover-primary fs-6'>
              {row['group_name_' + key] || 'Other'}
            </span>
          )
        },
      })
    })
    columns.push({
      name: 'Count',
      keyValue: 'total',
      className: 'min-w-80p',
      render: (row: any) => {
        return <span className='text-primary fw-bolder text-end fs-6'>{row.total}</span>
      },
    })
    return columns
  }

  return (
    <div className={`card w-100 p-0 mt-8`}>
      <div className='card-header'>
        <div className='card-title'>
          <h3 className='card-label text-muted fw-bold'>Search Result</h3>
          <OverlayTrigger overlay={<Tooltip id="tooltip">This query only returns the first 5000 records to improve performance</Tooltip>}>
            <div>
              <KTSVG
                  path='/media/icons/duotune/general/gen045.svg'
                  className='svg-icon-2 svg-icon-warning'
              />
            </div>
          </OverlayTrigger>
        </div>
        <div className={'d-flex justify-content-around align-items-center'}>
          <button className={'btn btn-apply'} onClick={exportFunction}>
            <KTSVG
                path='/media/icons/duotune/arrows/arr091.svg'
                className='svg-icon-2 svg-icon-light'
            />
            Download Search Results
          </button>
        </div>
        <div className='d-flex align-items-center'>
          <button
            onClick={() => {
              setShowModal(true)
            }}
            className='me-2 btn float-right btn-primary'
          >
            <KTSVG
                    path='/media/icons/duotune/technology/teh011.svg'
                    className='svg-icon-2 svg-icon-light text-light'
            />
            Save Search
          </button>
        </div>
      </div>
      {data.length > 0 ? (
        <div className={'card-body'} key={query.breakdown_field}>
          <TablesWidget9
            className='card-primary'
            title='Results'
            pagination={true}
            data={data}
            columns={getColumns()}
          />
        </div>
      ) : (
        <div className={'card-body'}>
          <div className={'d-flex justify-content-around align-items-center'}>
            <div className={'fs-4'}>No results found</div>
          </div>
        </div>
      )}
      <DbModal show={showModal} setShow={setShowModal} query={query} />
    </div>
  )
}

export default BreakDownResult
