import { RestServiceConnection } from "../RestServiceConnection";


export class CampaignManagerService extends RestServiceConnection {

    list = async (data: any) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/campaign-manager/list',
            data: data
        }, true);
        return this;
    }

    delete = async (id: number) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/campaign-manager/delete',
            data: {
                id
            }
        }, true);
        return this;
    }

    create = async (data: any) => {
        this.response = await this.makeRequest({
            method: "POST",
            url: '/campaign-manager/create',
            data
        }, true);
        return this;
    }
}