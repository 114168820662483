
import { FC } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageTitle } from '_metronic/layout/core'
import ConfigRoutes from '../../routing/ConfigRoutes'
import { ProgrammeExplorerBase } from './components/ProgrammeExplorerBase'
import { ProgrammeExplorerHeader } from './components/ProgrammeExplorerHeader'
import { ProgrammeExplorerSupporterProvider } from './ProgrammeExplorerSupporterProvider'
import { ProgrammeExplorerSupporterProjectInfoWrapper } from './tabs/project_info/ProgrammeExplorerSupporterProjectInfoWrapper'

const breadcrumbs = [
    {
        title: 'Modules',
        path: '#',
        isSeparator: false,
        isActive: false,
    },
    {
        title: 'Programme exlorer (Programme Lead)',
        path: 'programmeExplorer/engagement',
        isSeparator: true,
        isActive: true,
    },
]

const ProgrammeExplorerSupporterWrapper: FC = () => {

    return (
        <>
            <Routes>
                <Route element={
                    <ProgrammeExplorerSupporterProvider>
                        <PageTitle breadcrumbs={breadcrumbs}>Programme Explorer for Supporter</PageTitle>
                        <ProgrammeExplorerHeader />
                        <Outlet />
                    </ProgrammeExplorerSupporterProvider>}
                >
                    <Route path={ConfigRoutes.admin.programme_explorer_supporter.default.base} element={<ProgrammeExplorerBase />} />
                    <Route path={ConfigRoutes.admin.programme_explorer_supporter.project_info.base} element={<ProgrammeExplorerSupporterProjectInfoWrapper />} />

                    <Route index element={<Navigate to={ConfigRoutes.admin.programme_explorer_supporter.default.base} />} />
                </Route>
            </Routes>
        </>
    )
}

export { ProgrammeExplorerSupporterWrapper }
