import React, { createContext, useContext, useEffect, useState } from "react"
import _ from 'lodash'
import { Programme } from "app/models/programmes/Programme";
import { Metric } from "app/models/metrics/Metric";

export type ProgrammeExplorerSupporterFilterMode = 'months' | 'years' | 'weeks' | 'days' | 'Q';

export type ProgrammeExplorerSupporterFilters = {
  mode: ProgrammeExplorerSupporterFilterMode
  programmes?: number[]
  campaigns?: number[],
  therapeutic_areas?: number[],
  connects?: number[],
  between_dates?: any,
  source?: string | undefined
}

type BetweenDatesFilter = {
  from: string,
  to: string
}

export type ProgrammeExplorerSupporterFilterParams = {
  filter_filters: ProgrammeExplorerSupporterFilters

}

type ProgrammeExplorerSupporterContextModel = {
  params: ProgrammeExplorerSupporterFilterParams,
  metrics: ProgrammeExplorerSupporterMetrics,
  showSnapshotModal: boolean,
  programmes: Programme[],
  updateFilters: (filters: Partial<ProgrammeExplorerSupporterFilters>) => void,
  updateMetrics: (metric: string) => void
  resetFilters: () => void
  updateProgrammeExplorerProgrammes: (programmes: Programme[]) => void,
  updateShowSnapshotModal: (show: boolean) => void
  getMetricProgrammeExplorerCard: (metrics: Metric, filters: ProgrammeExplorerSupporterFilterParams) => JSX.Element[] | JSX.Element
  updateIsElearning: (value: boolean) => void
  elearning: boolean
  updateIsPodcast: (value: boolean) => void
  podcast: boolean

}

const defaultFilterParams: ProgrammeExplorerSupporterFilterParams = {
  filter_filters: {
    campaigns: [],
    programmes: [],
    connects: [],
    mode: 'weeks'
  }
}

type ProgrammeExplorerSupporterMetrics = string[];

type ShowSnapshotModal = boolean;

const defaultProgrammeExplorerSupporterMetrics: ProgrammeExplorerSupporterMetrics = [];

const defaultShowSnapshotModal: ShowSnapshotModal = false;

const ProgrammeExplorerSupporterContext = createContext<ProgrammeExplorerSupporterContextModel>({
  params: defaultFilterParams,
  metrics: defaultProgrammeExplorerSupporterMetrics,
  showSnapshotModal: defaultShowSnapshotModal,
  programmes: [],
  updateFilters: (filters: Partial<ProgrammeExplorerSupporterFilters>) => { },
  updateMetrics: (metricId: string) => { },
  updateShowSnapshotModal: (show: boolean) => { },
  updateProgrammeExplorerProgrammes: (programmes: Programme[]) => { },
  resetFilters: () => { },
  getMetricProgrammeExplorerCard: (metrics: Metric, filters: ProgrammeExplorerSupporterFilterParams) => <></>,
  updateIsElearning: (value: boolean) => {},
  elearning: false,
  updateIsPodcast: (value: boolean) => {},
  podcast: false
})


const ProgrammeExplorerSupporterProvider: React.FC = ({ children }) => {

  const [params, setParams] = useState<ProgrammeExplorerSupporterFilterParams>(defaultFilterParams)
  const [metrics, setMetrics] = useState<ProgrammeExplorerSupporterMetrics>(defaultProgrammeExplorerSupporterMetrics)
  const [showSnapshotModal, setShowSnapshotModal] = useState<ShowSnapshotModal>(defaultShowSnapshotModal)
  const [programmes, setProgrammes] = useState<Programme[]>([])
  const [elearning, setElearning] = useState<boolean>(false)
  const [podcast, setPodcast] = useState<boolean>(false)

  // const [allowedMetrics, setAllowedMetrics] = useState<string[]>([])


  const updateShowSnapshotModal = (show: boolean) => {
    setShowSnapshotModal(show)
  }

  const updateIsElearning = (value: boolean) => {
    setElearning(value)
  }

  const updateIsPodcast = (value: boolean) => {
    setPodcast(value)
  }


  const updateFilters = (filters: Partial<ProgrammeExplorerSupporterFilters>) => {

    let newObject = {
      ...params,
      filter_filters: {
        ...params.filter_filters,
        ...filters
      }
    }

    if (_.isEqual(params, newObject) === false) {
      setParams(newObject)
    }

  }

  const resetFilters = () => {
    setParams(defaultFilterParams)
  }

  const updateMetrics = (metricId: string) => {

    let newMetrics = metrics;

    if (newMetrics.includes(metricId)) {
      newMetrics = newMetrics.filter((metric) => metric !== metricId);
    } else {
      newMetrics.push(metricId);
    }

    setMetrics(newMetrics);
  }

  const updateProgrammeExplorerProgrammes = (programmes: Programme[]) => {
    setProgrammes(programmes)
  }
  /** Render metric card */
  const getMetricProgrammeExplorerCard = (metric: Metric, filters: ProgrammeExplorerSupporterFilterParams): JSX.Element => {

    return (<></>)

  }


  const value: ProgrammeExplorerSupporterContextModel = {
    params,
    metrics,
    showSnapshotModal,
    programmes,
    updateFilters,
    updateMetrics,
    resetFilters,
    updateProgrammeExplorerProgrammes,
    updateShowSnapshotModal,
    getMetricProgrammeExplorerCard,
    updateIsElearning,
    elearning,
    updateIsPodcast,
    podcast
  }

  useEffect(() => {
    // console.log("do request...");
  }, [params.filter_filters])


  return <ProgrammeExplorerSupporterContext.Provider value={value}>{children}</ProgrammeExplorerSupporterContext.Provider>
}


export { ProgrammeExplorerSupporterProvider, ProgrammeExplorerSupporterContext }

export function useProgrammeExplorerSupporter() {
  return useContext(ProgrammeExplorerSupporterContext);
}