import { MetricService } from "app/services/metric/MetricService";
import { MetricUtil } from "app/services/metric/MetricUtil";
import React, { useEffect, useState } from "react";
import { Metric, ReachMetric } from "../../../components/metrics/ReachMetric";
import { useDataReport } from "../../../DataReportProvider";
import { metrics } from "app/utils/data/metrics";
import { updateLoadedMetrics } from "app/store/reducers/metric_reducer";
import { useDispatch } from "react-redux";
import { getLoadedMetricById } from "app/utils/utils";

const EngagementSlides: React.FC = () => {

    const [totalSlidesDownloads, setTotalSlidesDownloads] = useState<Metric[] | null>(null)
    const [uniqueSlidesDownloads, setUniqueSlidesDownloads] = useState<Metric[] | null>(null)

    const dataReport = useDataReport()
    const dispatch = useDispatch();

    const getTotalSlidesDownloads = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.totalSlideDownloads.endpoint)).getResponseData();
        if (response.success === true) {
            setTotalSlidesDownloads(response.data);
            dispatch(updateLoadedMetrics({id: metrics.totalSlideDownloads.id, data: response.data}));
        }
    }

    const getUniqueSlidesDownloads = async (modifiedParams: any) => {
        let response = (await (new MetricService()).getMetricData(modifiedParams, metrics.uniqueSlideDownloads.endpoint)).getResponseData();
        if (response.success === true) {
            setUniqueSlidesDownloads(response.data);
            dispatch(updateLoadedMetrics({id: metrics.uniqueSlideDownloads.id, data: response.data}));
        }
    }

    /** 
     * To calculate amount and percent of latest "n" periods, we need to modify date filtered before 
     * make request. We need to do that because the widget needs to retrive last 24
     * times periods before the requested date to calculate the amount 
    */
    const getMetrics = () => {

        const calculateStartDate = MetricUtil.calculateStartDate(dataReport.params.filter_filters.between_dates.from, dataReport.params.filter_filters.mode);

        const modifiedParams = {
            ...dataReport.params,
            filter_filters: {
                ...dataReport.params.filter_filters,
                between_dates: {
                    from: calculateStartDate,
                    to: dataReport.params.filter_filters.between_dates.to
                }
            }
        };

        let total_slide_downloads = getLoadedMetricById(metrics.totalSlideDownloads.id);
        let unique_slide_downloads = getLoadedMetricById(metrics.uniqueSlideDownloads.id);

        if(total_slide_downloads !== null && total_slide_downloads !== undefined) {
            setTotalSlidesDownloads(total_slide_downloads);
        }else{
            getTotalSlidesDownloads(modifiedParams);
        }

        if(unique_slide_downloads !== null && unique_slide_downloads !== undefined) {
            setUniqueSlidesDownloads(unique_slide_downloads);
        }else{
            getUniqueSlidesDownloads(modifiedParams);
        }

    }

    const resetReports = () => {
        setUniqueSlidesDownloads(null);
        setTotalSlidesDownloads(null);
    }


    useEffect(() => {
        resetReports();
        getMetrics();
    }, [dataReport.params])


    return (
        <>
            <div className='row g-5 g-xl-8' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                <div className="col-xl-12">
                    <hr></hr>
                </div>
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/'+metrics.totalSlideDownloads.logo}
                        snapshotId={metrics.totalSlideDownloads.id}
                        title={metrics.totalSlideDownloads.title}
                        subtitle={metrics.totalSlideDownloads.subtitle}
                        startPeriod={24}
                        metrics={totalSlidesDownloads}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-6 flex-xl-fill mt-0'>
                    <ReachMetric
                        logo_image={'/media/svg/metric_icons/'+metrics.uniqueSlideDownloads.logo}
                        snapshotId={metrics.uniqueSlideDownloads.id}
                        title={metrics.uniqueSlideDownloads.title}
                        subtitle={metrics.uniqueSlideDownloads.subtitle}
                        startPeriod={24}
                        metrics={uniqueSlidesDownloads}
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='250px'
                        snapshotSwitch={true}
                    />
                </div>
                {/* end::Col */}
            </div>
            
        </>
    )
}


export default EngagementSlides;